$shell-yellow: #fbce07;
$shell-red: #dd1d21;
$shell-blue: #055093;
$shell-light-blue: #3f98c5;

// Gray Colors
$shell-black: #000000;
$shell-very-dark-gray: #404040;
$shell-dark-gray: #595959;
$shell-mid-gray: #7f7f7f;
$shell-light-gray: #a6a6a6;
$shell-pale-gray: #d9d9d9;
$shell-very-pale-gray: #f7f7f7;
$white: #ffffff;

// Font
$font-ShellFutura: "ShellFutura", Arial, Helvetica, Verdana, sans-serif;
$font-size-base: 16;
$line-height-base: 1.3;

// Text
$color-base: $shell-black;
$color-yellow: $shell-yellow;
$color-red: $shell-red;
$color-base-grey: $shell-very-dark-gray;

// Backgrounds
$bg-header: #ffffff;
$bg-burger: $shell-red;
$bg-menu-mob: #ffffff;
$bg-search-mob: #ffffff;

// Buttons
$btn-yellow-bg: $shell-yellow;
$btn-yellow-bg-hover: $shell-red;
$btn-yellow-color: $shell-red;
$btn-yellow-color-hover: #ffffff;

$btn-red-bg: #ffffff;
$btn-red-bg-hover: $shell-red;
$btn-red-border: $shell-red;
$btn-red-border-hover: $shell-red;
$btn-red-color: $shell-red;
$btn-red-color-hover: #ffffff;

$btn-red-yellow-bg: #ffffff;
$btn-red-yellow-bg-hover: $shell-yellow;
$btn-red-yellow-border: $shell-red;
$btn-red-yellow-border-hover: $shell-yellow;
$btn-red-yellow-color: $shell-red;
$btn-red-yellow-color-hover: $shell-red;

$btn-yellow-border-bg: #ffffff;
$btn-yellow-border-bg-hover: $shell-yellow;
$btn-yellow-border-border: $shell-yellow;
$btn-yellow-border-border-hover: $shell-yellow;
$btn-yellow-border-color: $shell-red;
$btn-yellow-border-color-hover: $shell-red;

$btn-checkbox-bg: #ffffff;
$btn-checkbox-bg-checked: $shell-yellow;
$btn-checkbox-bg-not-checked: #cccccc;
$btn-checkbox-bg-not-checked1: $shell-pale-gray;
$btn-checkbox-bg-not-checked2: $shell-very-pale-gray;
$btn-checkbox-border: $shell-pale-gray;
$btn-checkbox-border-hover: $shell-pale-gray;
$btn-checkbox-color: $shell-dark-gray;
$btn-checkbox-color-hover: $shell-dark-gray;

// Form
$placeholder-color: $shell-light-gray;
$border-input: $shell-pale-gray;
$border-input-hover: $shell-yellow;
$shadow-input-inset: $shell-very-pale-gray;

// Media points
$point-xs: 0;
$point-iphone-5: 360;
$point-sm: 576;
$point-md: 768;
$point-lg: 992;
$point-xl: 1200;

$point-scaling-10: 1900px;
$point-scaling-25: 1650px;
$point-scaling-cancel: 1350px;

$point-xs-min: $point-xs;
$point-iphone-5-min: $point-iphone-5 + px;
$point-sm-min: $point-sm + px;
$point-md-min: $point-md + px;
$point-lg-min: $point-lg + px;
$point-xl-min: $point-xl + px;

$point-xs-max: 0;
$point-iphone-5-max: ($point-iphone-5 - 1) + px;
$point-sm-max: ($point-sm - 1) + px;
$point-md-max: ($point-md - 1) + px;
$point-lg-max: ($point-lg - 1) + px;
$point-xl-max: ($point-xl - 1) + px;
