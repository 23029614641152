/* =================================
Section Shell Cafe
================================= */

.shell-cafe {
    &__prev {
        font-size: 1.5em;
        @media screen and (max-width: 767px) {
            font-size: 1em;
        }
        ul {
            li {
                margin-bottom: 1rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        a {
            color: $shell-red;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__section {
        &:nth-child(2n + 1) {
            .section-content {
                .text-block-bg {
                    width: 103%;
                    height: 150%;
                    right: 0;
                    top: 0;
                    background-image: url("../../img/ShellCafe/shell-cafe__coffe-text-bg.png");
                    background-position: right top;
                    @media screen and (max-width: $point-lg-max) {
                        width: 40%;
                        height: auto;
                        padding-top: 50%;
                        top: -1.5em;
                    }
                    @media screen and (max-width: $point-md-max) {
                        width: 50%;
                    }
                    @media screen and (max-width: $point-sm-max) {
                        width: 75%;
                    }
                }
                .text-block {
                    padding-right: 10%;
                }
            }
        }
        &:nth-child(2n) {
            .section-content {
                .text-block-bg {
                    width: 125%;
                    height: 150%;
                    left: 0;
                    top: 0;
                    background-image: url("../../img/ShellCafe/shell-cafe_hot-dogs_text_bg.png");
                    background-position: left top;
                    @media screen and (max-width: $point-lg-max) {
                        width: 40%;
                        height: auto;
                        padding-top: 30%;
                        top: -3.5em;
                    }
                    @media screen and (max-width: $point-md-max) {
                        width: 50%;
                    }
                    @media screen and (max-width: $point-sm-max) {
                        width: 75%;
                        padding-top: 40%;
                        top: -2.5em;
                    }
                }
                .text-block {
                    padding-left: 10%;
                }
            }
        }
    }

    .section-content {
        .img-wrap {
            position: relative;
            z-index: 1;
            //background-color: palegreen;
            @media screen and (max-width: $point-lg-max) {
                width: 100%;
                max-width: 350px;
                margin: 0 auto;
                margin-bottom: 2em;
            }
        }
        .text-block-bg {
            position: absolute;
            z-index: 0;
            width: 100%;
            height: 100%;
            top: 0;

            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;

            @media screen and (max-width: $point-sm-max) {
                background-size: contain;
            }

            //background-color: rgba(255,25,68,.25);
        }
        .text-block {
            position: relative;
            z-index: 2;
            padding: 8% 0;
            font-size: em(24);
            //font-weight: 700;
            color: $shell-very-dark-gray;

            @media screen and (max-width: $point-scaling-cancel) {
                font-size: em(20);
            }
            @media screen and (max-width: $point-lg-max) {
                padding-top: 0;
                padding-bottom: 0;
                font-size: em(18);
            }
            @media screen and (max-width: $point-md-max) {
                font-size: em(16);
            }
            p {
                margin: 0.5em;
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .btns-wrap {
        position: relative;
        z-index: 2;
        @media screen and (max-width: $point-md-max) {
            margin-top: 3em;
        }
    }
}

//.shell-cafe {
//
//    &.coffee {
//        .section-content {
//            .img-wrap {
//                padding-left: 8%;
//                padding-right: 25%;
//                @media screen and (max-width: $point-lg-max) {
//                    padding-left: 0;
//                    padding-right: 0;
//                }
//            }
//            .text-block-bg {
//                width: 103%;
//                height: 150%;
//                right: 0;
//                top: -5%;
//                background-image: url("../../img/ShellCafe/shell-cafe__coffe-text-bg.png");
//                background-position: right top;
//                @media screen and (max-width: $point-lg-max) {
//                    width: 50%;
//                    height: auto;
//                    padding-top: 50%;
//                    top: -1.5em;
//                }
//                @media screen and (max-width: $point-md-max) {
//                    width: 70%;
//                }
//                @media screen and (max-width: $point-sm-max) {
//                    width: 75%;
//                }
//            }
//            .text-block {
//                padding-right: 10%;
//            }
//        }
//    }
//
//    &.hot-dogs {
//        .section-content {
//            .img-wrap {}
//            .text-block-bg {
//                width: 128%;
//                height: 150%;
//                left: 0;
//                top: -5%;
//                background-image: url("../../img/ShellCafe/shell-cafe_hot-dogs_text_bg.png");
//                background-position: left top;
//                @media screen and (max-width: $point-lg-max) {
//                    width: 50%;
//                    height: auto;
//                    padding-top: 30%;
//                    top: -3.5em;
//                }
//                @media screen and (max-width: $point-md-max) {
//                    width: 60%;
//                }
//                @media screen and (max-width: $point-sm-max) {
//                    width: 75%;
//                    padding-top: 40%;
//                    top: -2.5em;
//                }
//            }
//            .text-block {
//                padding-left: 10%;
//            }
//        }
//    }
//
//    &.sandwiches {
//        .section-content {
//            .img-wrap {
//                padding-right: 15%;
//                @media screen and (max-width: $point-lg-max) {
//                    padding-right: 0;
//                }
//            }
//            .text-block-bg {
//                width: 102%;
//                height: 150%;
//                right: 0;
//                top: -5%;
//                background-image: url("../../img/ShellCafe/shell-cafe_sandwiches_text-bg.png");
//                background-position: right top;
//                @media screen and (max-width: $point-lg-max) {
//                    width: 50%;
//                    height: auto;
//                    padding-top: 30%;
//                    top: -3.5em;
//                }
//                @media screen and (max-width: $point-md-max) {
//                    width: 60%;
//                    padding-top: 40%;
//                }
//                @media screen and (max-width: $point-sm-max) {
//                    width: 75%;
//                    padding-top: 45%;
//                    top: -2em;
//                }
//            }
//            .text-block {
//                padding-top: 16%;
//                padding-bottom: 16%;
//                padding-right: 10%;
//                @media screen and (max-width: $point-xl-max) {
//                    padding-top: 12%;
//                    padding-bottom: 12%;
//                }
//                @media screen and (max-width: $point-lg-max) {
//                    padding-top: 3%;
//                    padding-bottom: 3%;
//                }
//                @media screen and (max-width: $point-sm-max) {
//                    padding-bottom: 0;
//                }
//            }
//        }
//    }
//
//    &.cafe {
//        .section-content {
//            .img-wrap {
//                padding: 5% 0;
//                @media screen and (max-width: $point-lg-max) {
//                    padding: 0;
//                }
//
//            }
//            .text-block-bg {
//                width: 96%;
//                height: 150%;
//                left: 0;
//                top: -5%;
//                background-image: url("../../img/ShellCafe/shell-cafe_cafe_text_bg.png");
//                background-position: left top;
//                @media screen and (max-width: $point-lg-max) {
//                    width: 45%;
//                    height: auto;
//                    padding-top: 30%;
//                    top: -7.5em;
//                }
//                @media screen and (max-width: $point-md-max) {
//                    width: 55%;
//                    padding-top: 40%;
//                    top: -5.5em;
//                }
//                @media screen and (max-width: $point-sm-max) {
//                    width: 65%;
//                    padding-top: 45%;
//                    top: -3em;
//                }
//            }
//            .text-block {
//                padding-left: 10%;
//            }
//        }
//    }
//
//    &.sweets {
//        .section-content {
//            .img-wrap {
//                padding: 6% 6% 6% 0;
//                @media screen and (max-width: $point-lg-max) {
//                    padding: 0;
//                }
//            }
//            .text-block-bg {
//                width: 100%;
//                height: 150%;
//                right: 0;
//                top: -5%;
//                background-image: url("../../img/ShellCafe/shell-cafe_sweets_text_bg.png");
//                background-position: right top;
//                @media screen and (max-width: $point-lg-max) {
//                    width: 50%;
//                    height: auto;
//                    padding-top: 30%;
//                    top: -4.5em;
//                }
//                @media screen and (max-width: $point-md-max) {
//                    width: 55%;
//                    padding-top: 40%;
//                    top: -3.5em;
//                }
//                @media screen and (max-width: $point-sm-max) {
//                    width: 65%;
//                    padding-top: 45%;
//                    top: -2em;
//                }
//            }
//            .text-block {
//                padding-right: 10%;
//            }
//        }
//    }
//
//}
