/* =================================
Breadcrumbs Styles
================================= */

.breadcrumbs {
    &__list {
        list-style: none;
        padding: 1em 0;
        margin: 0;
        @media screen and (max-width: $point-lg-max) {
            padding: .75em 0;
        }
    }
    &__item {
        position: relative;
        display: inline-block;
        padding: 0.5em 1em;
        font-size: em(18);
        @media screen and (max-width: $point-lg-max) {
            font-size: em(16);
        }
        @media screen and (max-width: $point-md-max) {
            font-size: em(14);
        }
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
            &::after {
                content: none;
            }
        }
        &::after {
            content: '>';
            display: block;
            width: auto;
            height: auto;
            position: absolute;
            top: 50%;
            right: 0;
            @include transform(translate(50%, -50%));
            color: $shell-mid-gray;
            @include transition(all, 0.2s, ease-in-out);
        }
        > a {
            color: $shell-mid-gray;
            &:hover {
                color: $shell-black;
            }
        }
        > span {
            font-size: em(17,18);
            color: $shell-black;
        }
    }
}