.magic-sphere {
    overflow: hidden;
    .container-fluid {
        @media screen and (max-width: 576px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__main {
        &-wrap {
            background-repeat: no-repeat;
            background-image: url("../../../img/promotions/magic-sphere/magic-sphere-banner.jpg");
            background-size: cover;
            background-position: center center;
            height: 710px;
            padding: 70px;
            background-color: #fbce07;
            @media screen and (max-width: 1680px) {
                background-position: 60% center;
            }
            @media screen and (max-width: 1400px) {
                height: 650px;
            }
            @media screen and (max-width: 1000px) {
                padding: 24px;
                height: 520px;
                background-image: url("../../../img/promotions/magic-sphere/banner-mobile.jpg");
                background-size: contain;
                background-position: center bottom;
            }
        }
        &-logo {
            margin-bottom: 120px;
            @media screen and (max-width: 1000px) {
                margin-bottom: 24px;
            }
            img {
                max-width: 360px;
                width: 100%;
                @media screen and (max-width: 1000px) {
                    max-width: 200px;
                }
            }
        }
        &-title {
            color: #333333;
            font-size: 75px;
            font-family: "ShellFutura", sans-serif;
            font-weight: bold;
            &.mobile {
                display: none;
            }
            @media screen and (max-width: 1400px) {
                font-size: 48px;
            }
            @media screen and (max-width: 1000px) {
                font-size: 30px;
                &.mobile {
                    display: block;
                }
                &.desktop {
                    display: none;
                }
            }
            @media screen and (max-width: 360px) {
                font-size: 24px;
            }
        }
    }
    &__conditions {
        &-flex {
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 1280px) {
                flex-direction: column;
                column-gap: 20px;
                row-gap: 20px;
            }
            .separate {
                color: #fff;
                font-family: "ShellFutura", sans-serif;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 24px;
                margin: 0 30px;
                @media screen and (max-width: 1440px) {
                    margin: 0 12px;
                }
            }
        }
        &-wrap {
            background-repeat: no-repeat;
            background-image: url("../../../img/promotions/magic-sphere/ball-bg.png");
            background-size: initial;
            background-position: right top;
            padding: 30px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            @media screen and (max-width: 1280px) {
                background-size: 150% auto;
                background-position: 40% top;
            }
            @media screen and (max-width: 1000px) {
                background-size: 200% auto;
                background-position: 40% top;
            }
            @media screen and (max-width: 768px) {
                background-image: url("../../../img/promotions/magic-sphere/condiitons-bg-mobile.png");
                background-size: 100% auto;
                background-position: center 25%;
                flex-wrap: wrap;
                padding: 24px;
            }
            @media screen and (max-width: 500px) {
                background-position: center top;
            }
            @media screen and (max-width: 390px) {
                background-size: 390px auto;
            }
        }
        &-ball {
            max-width: 42%;
            flex: 0 0 42%;
            padding-top: 150px;
            padding-left: 170px;
            position: relative;
            @media screen and (max-width: 1800px) {
                padding-left: 120px;
                padding-top: 135px;
            }
            @media screen and (max-width: 1680px) {
                max-width: 37%;
                flex: 0 0 37%;
            }
            @media screen and (max-width: 1280px) {
                padding-left: 50px;
            }
            @media screen and (max-width: 768px) {
                max-width: 100%;
                flex: 0 0 100%;
                padding-top: 70px;
                margin-bottom: 60px;
            }
            .ball-line-left {
                position: absolute;
                top: -60px;
                left: -50px;
                @media screen and (max-width: 1800px) {
                    top: -80px;
                    left: -70px;
                }
                @media screen and (max-width: 768px) {
                    width: 40%;
                }
                @media screen and (max-width: 576px) {
                    width: 30%;
                    top: -30px;
                }
            }
            .ball-stars {
                position: absolute;
                top: 0;
                left: 0;
                @media screen and (max-width: 1680px) {
                    max-width: 80%;
                }
                @media screen and (max-width: 576px) {
                    max-width: 80%;
                }
            }
            .ball-wrap {
                position: relative;
                z-index: 10;
                img {
                    animation: shake-ball 1.82s
                        cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
                    @media screen and (max-width: 1680px) {
                        max-width: 250px;
                    }
                    @media screen and (max-width: 1000px) {
                        max-width: 160px;
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 338px;
                    height: 343px;
                    top: -20px;
                    left: -20px;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    background-image: url("../../../img/promotions/magic-sphere/ball-border.png");

                    background-position: center;
                    @media screen and (max-width: 1680px) {
                        width: 288px;
                        height: 293px;
                    }
                    @media screen and (max-width: 1000px) {
                        width: 200px;
                        height: 205px;
                    }
                }
            }
            .ball-cloud {
                position: absolute;
                top: -15%;
                left: 0;
                @media screen and (min-width: 768px) {
                    display: none;
                }
            }
        }
        &-content {
            max-width: 58%;
            flex: 0 0 58%;
            @media screen and (max-width: 1680px) {
                max-width: 63%;
                flex: 0 0 63%;
            }
            @media screen and (max-width: 768px) {
                max-width: 100%;
                flex: 0 0 100%;
                position: relative;
            }
            .condition {
                display: flex;
                align-items: center;
                .image {
                    margin-right: 8px;
                    img {
                        height: 80px;
                        @media screen and (max-width: 1280px) {
                            height: 60px;
                        }
                    }
                }
                .content {
                    font-size: 24px;
                    text-transform: uppercase;
                    color: #fff;
                    font-weight: bold;
                    line-height: 40px;
                    font-family: "ShellFutura", sans-serif;
                    @media screen and (max-width: 1800px) {
                        font-size: 21px;
                        line-height: 36px;
                    }
                    @media screen and (max-width: 1680px) {
                        font-size: 18px;
                    }
                    .yellow {
                        color: $shell-yellow;
                    }
                    .large {
                        font-size: 36px;
                        @media screen and (max-width: 1800px) {
                            font-size: 32px;
                        }
                        @media screen and (max-width: 1680px) {
                            font-size: 27px;
                        }
                    }
                }
                &--main {
                    display: block;
                    text-align: center;
                    img {
                        max-width: 180px;
                        margin-left: 16px;
                        margin-top: -16px;
                        @media screen and (max-width: 1800px) {
                            max-width: 160px;
                        }
                        @media screen and (max-width: 1000px) {
                            display: block;
                            margin: 12px auto;
                        }
                    }
                }
            }
            .separate-line {
                width: 500px;
                height: 3px;
                margin: 40px auto 35px;
                background-image: url("../../../img/promotions/magic-sphere/separate-line.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: initial;
                @media screen and (max-width: 1280px) {
                    margin: 20px auto 25px;
                }
                @media screen and (max-width: 500px) {
                    width: 100%;
                    height: 2px;
                }
            }
            .large-text {
                font-size: 44px;
                text-transform: uppercase;
                color: #fff;
                margin-top: 24px;
                text-align: center;
                font-weight: bold;
                font-family: "ShellFutura", sans-serif;
                @media screen and (max-width: 1800px) {
                    font-size: 40px;
                }
                @media screen and (max-width: 1680px) {
                    font-size: 36px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 32px;
                }
            }
            .cloud-circle {
                position: absolute;
                bottom: 8%;
                z-index: 0;
                left: 12%;
                @media screen and (min-width: 768px) {
                    display: none;
                }
            }
            .circle {
                margin: 50px auto;
                width: 245px;
                height: 245px;
                background-color: $shell-yellow;
                border-radius: 150%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;

                @media screen and (max-width: 1280px) {
                    width: 220px;
                    height: 220px;
                    margin: 40px auto;
                }
                &-text {
                    text-align: center;
                    font-size: 27px;
                    color: #333333;
                    font-family: "ShellFutura", sans-serif;
                    font-weight: bold;
                    @media screen and (max-width: 1800px) {
                        font-size: 24px;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 21px;
                    }
                    &.large {
                        color: $shell-red;
                        animation: animPulseInviteTitle 1.2s ease-in-out
                            infinite;
                        display: block;
                        font-size: 60px;
                        @media screen and (max-width: 1800px) {
                            font-size: 48px;
                        }
                        @media screen and (max-width: 768px) {
                        }
                    }
                    &.small {
                        text-transform: uppercase;
                    }
                }
                &-star {
                    &-left {
                        position: absolute;
                        top: 4px;
                        left: -100px;
                        @media screen and (max-width: 414px) {
                            width: 30px;
                            left: -60px;
                        }
                    }
                    &-right {
                        position: absolute;
                        right: -100px;
                        top: -10px;
                        @media screen and (max-width: 414px) {
                            width: 30px;
                            right: -60px;
                        }
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 282px;
                    height: 286px;
                    top: -20px;
                    left: -20px;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    background-image: url("../../../img/promotions/magic-sphere/ball-border.png");
                    background-position: center;
                    @media screen and (max-width: 1280px) {
                        width: 252px;
                        height: 260px;
                    }
                }
            }
        }
        &-text {
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 24px;
            font-family: "ShellFutura", sans-serif;
            text-align: center;
            margin-top: 30px;
            @media screen and (max-width: 1800px) {
                font-size: 21px;
            }
            @media screen and (max-width: 1680px) {
                font-size: 18px;
            }
        }
    }
    &__gifts {
        margin-bottom: 80px;
        @media screen and (max-width: 768px) {
            margin-bottom: 40px;
        }
        &-title {
            text-align: center;
            margin-bottom: 60px;
            text-transform: uppercase;
            font-size: 36px;
            color: $shell-yellow;
            font-weight: bold;
            font-family: "ShellFutura", sans-serif;
            @media screen and (max-width: 1440px) {
                font-size: 32px;
            }
            @media screen and (max-width: 500px) {
                font-size: 20px;
                margin-bottom: 30px;
            }
        }
        &-slider {
            overflow: hidden;
            padding: 24px 0;
            position: relative;
            @media screen and (max-width: 768px) {
                padding: 12px 24px;
            }
            &-arrow {
                position: absolute;
                left: 0;
                top: 32%;
                transform: translateY(-50%);
                width: 20px;
                height: 34px;
                z-index: 10;
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                @media screen and (min-width: 1280px) {
                    display: none;
                }
                @media screen and (max-width: 768px) {
                    left: 12px;
                }
                @media screen and (max-width: 400px) {
                    width: 16px;
                }
                &--left {
                    background-image: url("../../../img/promotions/magic-sphere/arrow-left.png");
                }
                &--right {
                    right: 0;
                    left: auto;
                    background-image: url("../../../img/promotions/magic-sphere/arrow-right.png");
                    @media screen and (max-width: 768px) {
                        right: 12px;
                    }
                }
            }
        }
        &-wrap {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .item {
                text-align: center;
                position: relative;
                img {
                    max-width: 150px;
                    margin-bottom: 24px;
                    @media screen and (max-width: 1680px) {
                        max-width: 135px;
                    }
                    @media screen and (max-width: 768px) {
                        max-width: 120px;
                    }
                }
                p {
                    font-size: 24px;
                    text-align: center;
                    color: #333333;
                    font-weight: bold;
                    .color-red {
                        color: $shell-red;
                    }
                    @media screen and (max-width: 1680px) {
                        font-size: 21px;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                    }
                }
                &.procent {
                    &::after {
                        content: "%";
                        top: 4px;
                        right: 8px;
                        position: absolute;
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 32px;
                        color: $shell-red;
                        background-color: #fff;
                        border: 2px solid $shell-red;
                        border-radius: 150%;
                        @media screen and (max-width: 1680px) {
                            width: 40px;
                            height: 40px;
                            font-size: 27px;
                        }
                    }
                }
            }
        }
    }
    &__steps {
        margin-bottom: 80px;
        &-wrap {
            display: flex;

            align-items: flex-start;
            justify-content: space-between;
            @media screen and (max-width: 1024px) {
                flex-wrap: wrap;
            }
            @media screen and (max-width: 576px) {
                padding: 0 24px;
            }
        }
    }
    &__step {
        display: flex;
        align-items: flex-end;
        flex: 0 0 calc(100% / 3);
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        @media screen and (max-width: 1024px) {
            flex: 0 0 100%;
            margin-bottom: 32px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &--cloud {
            position: absolute;
            top: -12px;
            left: 16px;
            z-index: -1;
            @media screen and (max-width: 1024px) {
                left: 50%;
                transform: translateX(-50%);
            }
        }
        &-title {
            margin-top: 60px;
            max-width: 100%;
            font-size: 24px;
            line-height: 135%;
            font-weight: bold;
            flex: 0 0 100%;
            text-align: center;
            font-family: "ShellFutura", sans-serif;
            @media screen and (max-width: 1680px) {
                font-size: 21px;
            }
            @media screen and (max-width: 1440px) {
                font-size: 18px;
            }
        }
        .circle {
            width: 155px;
            height: 155px;
            font-size: 97px;
            font-family: "ShellFutura", sans-serif;
            font-weight: bold;
            color: #fff;
            padding: 12px 0 0 30px;
            border-radius: 150%;
            background-color: #fbce07;
            @media screen and (max-width: 1440px) {
                width: 135px;
                height: 135px;
                font-size: 92px;
                padding: 8px 0 0 20px;
            }
            @media screen and (max-width: 576px) {
                width: 110px;
                height: 110px;
                font-size: 72px;
            }
        }
        &--1 {
            .front {
                margin-left: -60px;
                margin-bottom: -24px;
                @media screen and (max-width: 1440px) {
                    margin-left: -80px;
                }
                @media screen and (max-width: 768px) {
                    margin-left: -45px;
                }
                .flex {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    img {
                        max-width: 125px;
                        width: 100%;
                        @media screen and (max-width: 1680px) {
                            max-width: 110px;
                        }
                        @media screen and (max-width: 1440px) {
                            max-width: 100px;
                        }
                        @media screen and (max-width: 768px) {
                            max-width: 80px;
                        }
                    }
                    .links {
                        margin-left: 24px;
                        @media screen and (max-width: 1680px) {
                            margin-left: 16px;
                        }
                        @media screen and (max-width: 768px) {
                            margin-left: 12px;
                        }
                        img {
                            display: block;
                            max-width: 180px;
                            @media screen and (max-width: 1680px) {
                                max-width: 150px;
                            }
                            @media screen and (max-width: 1440px) {
                                max-width: 140px;
                            }
                            @media screen and (max-width: 768px) {
                                max-width: 110px;
                            }
                            &:nth-child(1) {
                                margin-top: 8px;
                                margin-bottom: 12px;
                                @media screen and (max-width: 1680px) {
                                    margin-top: 4px;
                                    margin-bottom: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &--2 {
            .front {
                margin-left: -60px;
                margin-bottom: -24px;
                @media screen and (max-width: 768px) {
                    margin-left: -40px;
                }
            }
            .magic-sphere__step--cloud {
                left: 40px;
                @media screen and (max-width: 1024px) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        &--3 {
            .magic-sphere__step-title {
                @media screen and (max-width: 1440px) {
                    margin-top: 42px;
                }
            }
            .front {
                margin-left: -50px;
                margin-bottom: -24px;
                @media screen and (max-width: 768px) {
                    margin-left: -24px;
                }
            }
            .magic-sphere__step--cloud {
                left: 100px;
                @media screen and (max-width: 1024px) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
    &__how-get {
        margin-bottom: 80px;
        position: relative;
        &-cloud {
            position: absolute;
            bottom: -10%;
            left: 61%;
            transform: translateX(-50%);
            @media screen and (max-width: 576px) {
                max-width: 75%;
            }
        }
        &-title {
            text-align: center;
            margin-bottom: 12px;
            text-transform: uppercase;
            position: relative;
            font-size: 36px;
            color: $shell-yellow;
            font-weight: bold;
            font-family: "ShellFutura", sans-serif;
            @media screen and (max-width: 1440px) {
                font-size: 32px;
            }
            @media screen and (max-width: 500px) {
                font-size: 20px;
            }
            .star-image {
                position: absolute;
                left: -40px;
                top: -100px;
                @media screen and (max-width: 576px) {
                    max-width: 60%;
                    top: -50px;
                }
            }
        }
        &-subtitle {
            text-align: center;
            color: #333333;
            font-size: 24px;
            font-weight: bold;
            font-family: "ShellFutura", sans-serif;
            margin-bottom: 60px;
            &.mobile {
                display: none;
            }
            img {
                margin: 0 12px;
            }
            @media screen and (max-width: 1440px) {
                font-size: 18px;
            }
            @media screen and (max-width: 768px) {
                margin-bottom: 100px;
            }
            @media screen and (max-width: 450px) {
                margin-bottom: 425px;
            }
            @media screen and (max-width: 500px) {
                font-size: 16px;
                &.desktop {
                    display: none;
                }
                &.mobile {
                    display: block;
                }
            }
        }
        &-wrap {
            background-repeat: no-repeat;
            background-image: url("../../../img/promotions/magic-sphere/how-get-bg.jpg");
            background-size: 100% auto;
            background-position: center bottom;
            padding: 0 100px 0px 60px;
            @media screen and (max-width: 1440px) {
                padding: 0 50px;
            }
            @media screen and (max-width: 1280px) {
                background-size: 150% auto;
                background-position: 10% bottom;
            }
            @media screen and (max-width: 768px) {
                background-size: 250% auto;
                background-position: 10% bottom;
            }
            @media screen and (max-width: 450px) {
                background-image: url("../../../img/promotions/magic-sphere/how-get-bg-mobile.png");
                background-position: center bottom;
                background-size: 100% auto;
                padding: 0 24px;
            }
            @media screen and (max-width: 390px) {
                background-size: 390px auto;
            }
        }
        &-content {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            position: relative;
            .star-image-mobile {
                position: absolute;
                left: 15%;
                width: 80px;
                top: -120%;
                @media screen and (min-width: 768px) {
                    display: none;
                }
            }
            &--left {
                flex: 0 0 58%;
                max-width: 0 0 58%;
                padding-bottom: 95px;
                @media screen and (max-width: 1680px) {
                    padding-bottom: 75px;
                }
                @media screen and (max-width: 1440px) {
                    padding-bottom: 60px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding-bottom: 100px;
                }
                .title,
                .text {
                    text-align: center;
                    font-size: 24px;
                    font-weight: bold;
                    font-family: "ShellFutura", sans-serif;
                    line-height: 150%;
                    @media screen and (max-width: 1800px) {
                        font-size: 21px;
                    }
                    @media screen and (max-width: 1440px) {
                        font-size: 18px;
                    }
                }
                .title {
                    text-transform: uppercase;
                    color: $shell-yellow;
                    margin-bottom: 50px;
                    @media screen and (max-width: 1800px) {
                        max-width: 600px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    @media screen and (max-width: 1680px) {
                        margin-bottom: 35px;
                    }
                    @media screen and (max-width: 1440px) {
                        margin-bottom: 30px;
                    }
                }
                .text {
                    color: #fff;
                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                    }
                }
            }
            &--right {
                flex: 0 0 42%;
                max-width: 42%;
                text-align: right;
                img {
                    @media screen and (max-width: 1440px) {
                        width: 350px;
                    }
                }
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
    }
    &__winners-table {
        margin-top: 50px;
        padding-bottom: 80px;
        margin-bottom: 150px;
        @media screen and (max-width: $point-md-max) {
            margin-bottom: 20px;
        }
    }
    &__winners-table-title {
        margin-bottom: 70px;
        color: $shell-red;
        font-size: em(52);
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        @media screen and (max-width: $point-md-max) {
            font-size: em(34);
            margin-bottom: 35px;
        }
        .small-text {
            font-size: 0.65em;
        }
    }
    &__winners-table-wrap {
        position: relative;
        padding: 70px 5% 0;
        background-color: $shell-yellow;
        .star {
            position: absolute;
            filter: brightness(0) invert(1);
            @media screen and (max-width: 1024px) {
                display: none;
            }
            &--left {
                top: 145px;
                left: 20%;
                @media screen and (max-width: 1440px) {
                    left: 4%;
                }
            }
            &--right {
                right: 12%;
                top: 50px;
                @media screen and (max-width: 1440px) {
                    right: 4%;
                }
            }
        }
        @media screen and (max-width: $point-md-max) {
            padding: 35px 20px 0;
        }
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 150px;
            position: absolute;
            z-index: -1;
            top: 100%;
            left: 0;
            background: rgb(251, 206, 7);
            background: -moz-linear-gradient(
                180deg,
                rgba(251, 206, 7, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            background: -webkit-linear-gradient(
                180deg,
                rgba(251, 206, 7, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            background: linear-gradient(
                180deg,
                rgba(251, 206, 7, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbce07",endColorstr="#ffffff",GradientType=1);
            @media screen and (max-width: $point-md-max) {
                height: 50px;
                top: 99%;
                background: rgb(251, 206, 7);
                background: -moz-linear-gradient(
                    180deg,
                    rgba(251, 206, 7, 1) 75%,
                    rgba(255, 255, 255, 0) 100%
                );
                background: -webkit-linear-gradient(
                    180deg,
                    rgba(251, 206, 7, 1) 75%,
                    rgba(255, 255, 255, 0) 100%
                );
                background: linear-gradient(
                    180deg,
                    rgba(251, 206, 7, 1) 75%,
                    rgba(255, 255, 255, 0) 100%
                );
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbce07",endColorstr="#ffffff",GradientType=1);
            }
        }
        table {
            width: 100%;
            font-size: em(24);
            font-weight: 700;
            color: $shell-very-dark-gray;
            text-align: center;
            @media screen and (max-width: $point-scaling-cancel) {
                font-size: em(20);
            }
            @media screen and (max-width: $point-lg-max) {
                font-size: em(18);
            }
            &.showing {
                tbody {
                    tr {
                        &:nth-child(n + 8) {
                            display: table-row;
                        }
                        @media screen and (max-width: $point-md-max) {
                            &:nth-child(n + 3) {
                                display: block;
                            }
                        }
                    }
                }
            }
            thead {
                color: $shell-red;
                tr {
                    background-color: #ffffff;
                    border-bottom: 5px solid $shell-yellow;
                }
            }
            tbody {
                tr {
                    &:nth-child(odd) {
                        background-color: rgba(255, 255, 255, 0.4);
                    }
                    &:nth-child(even) {
                        @media screen and (max-width: $point-md-max) {
                            background-color: rgba(255, 255, 255, 0.4);
                        }
                    }
                    // &:nth-child(n + 8) {
                    //     display: none;
                    // }
                }
            }
            th {
                padding: 1em 0.5em;
            }
            td {
                padding: 1.25em 0.5em;
            }
            @media only screen and (max-width: $point-md-max),
                (min-device-width: $point-md-max) and (max-device-width: 1024px) {
                thead,
                tbody,
                th,
                td,
                tr {
                    display: block;
                }
                thead tr {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                }

                tr {
                    margin-bottom: 50px;
                }

                td {
                    /* Behave  like a "row" */
                    border: none;
                    position: relative;
                    padding: 1em 0;
                    height: auto !important;
                    padding-top: 0;
                }

                td:before {
                    position: inherit;
                    width: 100%;
                    display: block;
                    padding: 1em 0.5em;
                    color: $shell-red;
                    background-color: $white;
                    margin-bottom: 1em;
                    -webkit-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05);
                    -moz-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05);
                    box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05);
                }

                /*
                Label the data
                */
                td:nth-of-type(1):before {
                    content: "Дата";
                }
                td:nth-of-type(2):before {
                    content: "ПІБ";
                }
                td:nth-of-type(3):before {
                    content: "Телефон";
                }
                td:nth-of-type(4):before {
                    content: "Номер картки";
                }
                td:nth-of-type(5):before {
                    content: "Місце виграшу";
                }
            }
        }
    }
    .registration {
        padding-top: 20px;
        padding-bottom: 50px;
        @media screen and (max-width: $point-md-max) {
            padding-top: 50px;
            padding-bottom: 25px;
        }
        &__top-text {
            margin: 0;
            font-size: 30px;
            font-weight: 700;
            color: #404040;
            text-align: center;
            margin-bottom: 8px;
            @media screen and (max-width: $point-md-max) {
                font-size: 22px;
                padding-left: 16px;
                padding-right: 16px;
            }
            &.color-red {
                margin-bottom: 8px;
                color: $shell-red;
                &.mb-1em {
                    margin-bottom: 1em !important;
                }
            }
        }
        &__list {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 70px;
            @media screen and (max-width: $point-md-max) {
                flex-wrap: wrap;
                justify-content: center;
            }
            &_item {
                position: relative;
                z-index: 1;
                width: 24%;
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                    margin-bottom: 30px;
                }
            }
            &_icon {
                display: block;
                width: 41%;
                margin: 0 auto;
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                }
                > img {
                    width: 100%;
                }
            }
            &_descr {
                padding: 10% 10px 0 10px;
                font-size: 24px;
                font-weight: 700;
                color: #404040;
                text-align: center;
                @media screen and (max-width: $point-md-max) {
                    font-size: 4.25vw;
                    padding: 10% 5px 0 5px;
                }
                a {
                    display: inline-block;
                    color: $shell-red;
                }
                span {
                    display: inline-block;
                }
            }
        }
    }
}

@keyframes shake-ball {
    10%,
    90% {
        transform: translate3d(-2px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(3px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
