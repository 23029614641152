/* =================================
Styles Field Upload File
================================= */

.form-group.upload-file:not(.multiple) {

    input {
        position: absolute;
        width: 0;
        height: 0;
        top: 100%;
        left: 0;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;
        opacity: 0;
    }

    label {
        position: relative;
        display: inline-block;
        width: 100%;
        height: em(80,24);
        margin: 0;
        padding: 1.6em 1em .359em 1em;
        left: auto;
        top: auto;
        background-color: #fff;
        border: 1px solid $border-input;
        outline: none;
        @include border-radius(0);
        @include transform(none);

        font-size: em(24);
        color: $color-base;
        font-weight: 400;
        line-height: $line-height-base;

        -webkit-box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;
        -moz-box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;
        box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;

        &:hover {
            border-color: $border-input-hover;
        }

        .placeholder {
            position: absolute;
            width: auto;
            top: em(28,18);
            left: 1.35em;
            font-size: em(18,24);
            color: $placeholder-color;
            font-weight: 400;
            @include transition(all, .3s, ease-in-out);
        }
        .name {
            display: inline-block;
            font-size: 1em;
        }
    }

    &.in-focus {

    }

    &.active {
        > label {
            top: auto;
            .placeholder {
                top: em(12,18);
            }
            .name {

            }
        }
    }

}

.upload-multiple {

    .upload-file.multiple {

        &:last-child {
            label {
                cursor: pointer;
                &:hover {
                    color: $shell-red;
                }
            }
        }

        input {
            position: absolute;
            width: 0;
            height: 0;
            top: 100%;
            left: 0;
            padding: 0;
            margin: 0;
            border: none;
            outline: none;
            opacity: 0;
        }
        label {
            position: relative;
            display: inline-flex;
            align-items: center;
            width: auto;
            min-height: em(50,18);
            margin: 0;
            padding: .25em 1em .25em em(70,18);
            left: auto;
            top: auto;
            background-color: transparent;
            border: none;
            outline: none;
            @include border-radius(0);
            @include transform(none);
            @include transition(all, .2s, ease-in-out);

            font-size: em(18);
            color: $color-base;
            font-weight: 400;
            line-height: $line-height-base;

            .add,
            .field-close {
                position: absolute;
                top: 50%;
                left: 0;
                @include transform(translateY(-50%));
                display: inline-block;
                color: $shell-red;
                text-align: center;
            }

            .add {
                width: em(50,28);
                height: em(50,28);
                padding: .2em 0;
                background-color: $shell-yellow;
                font-size: em(28,18);
            }

            .field-close {
                width: em(50,20);
                height: em(50,20);
                padding: .75em 0;
                font-size: em(20,18);
                cursor: pointer;
            }

        }
    }

}