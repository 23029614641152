/* =================================
Section Information Important
================================= */

.information-important {
    padding-top: 0;
    @media screen and (max-width: $point-lg-max) {
        margin-top: 2em;
        padding-bottom: 0;
    }

    .section-content {
        position: relative;
        //margin-top: em(125);
        //padding-left: em(97);
        //padding-bottom: 9%;
        //
        //background-image: url("../../img/bg-information-important.jpg");
        //background-repeat: no-repeat;
        //background-position: right bottom;
        //background-size: 100% auto;
        //
        //@media screen and (max-width: 1500px) {
        //    background-size: 90% auto;
        //    background-position: right center;
        //    padding-bottom: 9%;
        //}
        //@media screen and (max-width: $point-xl-max) {
        //    padding-bottom: 3%;
        //    padding-left: 0;
        //}
        //@media screen and (max-width: $point-lg-max) {
        //    margin-top: 0;
        //    padding-left: em(30);
        //    background-image: none;
        //}
        //@media screen and (max-width: 479px) {
        //    margin-top: 0;
        //    padding-left: 0;
        //    background-image: none;
        //}

        .img-wrap {
            width: 100%;
            > img {
                width: 100%;
            }
        }

        .text-content {
            position: absolute;
            max-width: 60%;
            top: 24%;
            left: 6%;
            @media screen and (max-width: $point-xl-max) {
                top: 20%;
                left: 4%;
            }
            @media screen and (max-width: $point-md-max) {
                max-width: 100%;
                top: 0;
                left: 0;
                padding: 20px 25px;
            }
        }

        .title-text {
            margin-bottom: .5em;
            font-size: 2.5vw;
            font-weight: 700;
            color: $shell-red;
            text-transform: uppercase;
            @media screen and (max-width: $point-xl-max) {
                font-size: 2.9vw;
            }
            @media screen and (max-width: $point-md-max) {
                font-size: em(30);
            }
            @media screen and (max-width: $point-sm-max) {
                font-size: em(25);
            }
            .color-blue {
                color: $shell-blue;
            }
        }
        .descr-text {
            font-size: 1.25vw;
            color: $shell-very-dark-gray;
            @media screen and (max-width: $point-xl-max) {
                font-size: 1.4vw;
            }
            @media screen and (max-width: $point-md-max) {
                font-size: em(14);
            }
            @media screen and (max-width: $point-sm-max) {
                font-size: em(12);
            }
            .big-text {
                font-size: 1.5em;
                font-weight: 700;
            }
            p {
                @media screen and (max-width: $point-lg-max) {
                    margin-bottom: 0.75em;
                }
            }
            span {
                display: inline-block;
            }
        }
    }

}
