/* =================================
Section tabs-how-to-register
================================= */

.tabs-how-to-register {

    .tabs-bootstrap {
        &__nav {
            .nav-link {
                max-width: 20%;
                @media screen and (max-width: $point-lg-max) {
                    max-width: 40%;
                }
            }
        }
    }

}