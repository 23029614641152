/* =================================
Section Personal Liter Wallet
================================= */

.liter-wallet {

    .personal-cabinet__table-wrap {
        .table-content {
            table {
                //table-layout: fixed;
                td {
                    &:first-child {
                        text-align: left;
                        white-space: nowrap;
                        width: 20%;
                    }
                    &:nth-child(2) {
                        width: 20%;
                    }
                }
            }
        }
    }

}

.fuel-blocks {
    padding-top: em(30);
    @media screen and (max-width: $point-lg-max) {
        padding-bottom: 1em;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin-left: em(-6);
        margin-right: em(-6);
    }

    &__item {
        width: 16.66%;
        margin-bottom: em(30);
        padding: 0 em(6);
        @media screen and (max-width: $point-xl-max) {
            width: 33.33%;
        }
        @media screen and (max-width: $point-lg-max) {
            margin-bottom: 1em;
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }

        &.active {
            .fuel-blocks__btn {
                .btn-content {
                    color: $color-base;
                    > img {
                        &.img-static {
                            opacity: 0;
                        }
                        &.img-hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &__btn {
        position: relative;
        width: 100%;
        padding-top: 35%;
        border: 1px solid $shell-pale-gray;
        text-align: center;

        -webkit-box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;
        -moz-box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;
        box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;

        .btn-content {
            display: inline-block;
            position: absolute;
            width: 100%;
            height: auto;
            top: 50%;
            left: 0;
            @include transform(translateY(-50%));

            font-size: em(18);
            font-weight: 700;
            color: $shell-pale-gray;

            > img {
                width: 55%;
                height: auto;
                opacity: 1;
                &.img-hover {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    @include transform(translate(-50%,-50%));
                    opacity: 0;
                }
            }
        }
    }

    &__descr {
        .descr-liters {
            margin-top: 1em;
            margin-bottom: 0;
            color: $shell-red;
            font-size: em(20);
            font-weight: 700;
            text-align: center;
            @media screen and (max-width: $point-lg-max) {
                margin-top: .5em;
            }
            .unit {
                font-size: em(16,20);
            }
        }
    }

}

.table-fuel-pay {


}