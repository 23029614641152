/* =================================
Section Personal Promotion Ferrari Toy Cars
================================= */

.personal-cabinet {

    .bmw-m {
        &__header {
            padding: 20px 12px 24px 50px;
            background: #203667;
            background: radial-gradient(circle at center center, rgba(75,152,202,1) 0%, rgba(31,54,103,1) 100%);
            position: relative;
            overflow: hidden;
            &::before {
                content: "";
                position: absolute;
                bottom: -50px;
                left: 40%;
                width: 145px;
                height: 145px;
                background-image: url("../../../img/promotions/bmw-m/remote--lk.png");
                background-repeat: no-repeat;
                background-size: 100% auto;
                background-position: center;
                @media screen and (max-width: 576px) {
                    content: none;
                }
            }
            .title {
                font-size: 30px;
                font-weight: bold;
                color: $white;
                text-transform: uppercase;
                margin-bottom: 10px;
            }
            .subtitle {
                margin-bottom: 16px;
                color: $shell-yellow;
                font-size: 20px;
                text-transform: uppercase;
                font-weight: bold;
            }
            &-wrap {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }
            &--left {
                max-width: 40%;
                flex: 0 0 40%;
            }
            &--right {
                max-width: 60%;
                flex: 0 0 60%;
                padding-left: 40px;
                p {
                    font-size: 8px;
                    color: $white;
                }
            }
            .bluetooth {
                @media screen and (max-width: 1400px) {
                    max-width: 180px;
                    img {
                        width: 100%;
                    }
                }
            }
            @media screen and (max-width: 576px) {
                display: none;
            }
            &--mob {
                display: none;
                @media screen and (max-width: 576px) {
                    display: block;
                }
                img {
                    width: 100%;
                }
            }
        }
        &__table-descr {
            width: 100%;
            margin-top: 40px;
            margin-bottom: 30px;
            font-size: em(18);
            font-weight: 700;
            color: $shell-very-dark-gray;
            position: relative;
            line-height: 145%;
            @media screen and (max-width: $point-md-max) {
                width: 100%;
                text-align: center;
                font-size: em(16);
            }
            @media screen and (max-width: 768px) {
                margin-top: 20px;
                margin-bottom: 16px;
            }
            p {
                margin: 0;
            }
            .color-red {
                color: $shell-red;
            }
            .color-blue {
                color: $shell-light-blue;
            }
            .cars {
                width: 450px;
                position: absolute;
                top: -130px;
                right: 16px;
                @media screen and (max-width: 1680px) {
                    width: 320px;
                }
                @media screen and (max-width: 1400px) {
                    width: 300px;
                }
                @media screen and (max-width: 1280px) {
                    width: 220px;
                }
                @media screen and (max-width: 768px) {
                    width: 150px;
                    right: 16px;
                }
                @media screen and (max-width: 576px) {
                    display: none;
                }
            }
        }
        &__table-wrap {
            margin-bottom: 45px;
            @media screen and (max-width: $point-md-max) {
                display: none;
            }
            &_mob {
                display: none;
                @media screen and (max-width: $point-md-max) {
                    display: block;
                }
            }
            .blue-text {
                color: $shell-light-blue!important;
            }
            table {
                position: relative;
                font-size: 1em;
                -webkit-box-shadow: 0px 5px 0px 0px #ededed;
                -moz-box-shadow: 0px 5px 0px 0px #ededed;
                box-shadow: 0px 5px 0px 0px #ededed;
                th, td {
                    border: 1px solid $shell-light-blue;
                }
                th {
                    padding: 10px 10px;
                    background-color: #fff;
                    font-size: em(20);
                    font-weight: 400;
                    text-align: center;
                    @media screen and (max-width: $point-xl-max) {
                        font-size: em(18);
                    }
                    @media screen and (max-width: $point-md-max) {
                        padding: 15px;
                        font-size: em(18);
                        text-align: center;
                    }
                    &:first-child {
                        padding-left: 25px;
                        text-align: left;
                        min-width: 300px;
                        @media screen and (max-width: $point-md-max) {
                            text-align: center;
                        }
                    }
                    .big-text {
                        font-size: em(21,20);
                        font-weight: 700;
                        color: $shell-light-blue;
                    }
                    
                }
                td {
                    padding: 10px 10px;
                    text-align: center;
                    @media screen and (max-width: $point-md-max) {
                        padding: 15px;
                    }
                    @media screen and (max-width: 576px) {
                        font-size: 14px;
                        &:nth-child(1) {
                            text-align: left;
                        }
                    }
                    &.bg-red {
                        padding-top: 20px;
                        padding-bottom: 20px;
                        padding-left: 25px;
                        text-align: left;
                    }
                    &.bg-blue {
                        padding-left: 25px;
                        text-align: left;
                        border-color: #fff;
                        border-left: 1px solid $shell-light-blue;
                        border-right: 1px solid $shell-light-blue;
                        &--last {
                            border-bottom: 1px solid $shell-light-blue;
                        }
                    }
                    .big-text {
                        font-size: em(24);
                        color: $shell-red;
                        font-weight: 700;
                        @media screen and (max-width: 576px) {
                            font-size: em(20);
                        }
                    }
                    &.bg-grey {
                        background-color: #ececec;
                    }
                    &.bg-light-blue {
                        background-color: #c8e7f7;
                    }
                }
                .bg-red {
                    background-color: $shell-red;
                    color: $white;
                }
                .bg-red-light {
                    background-color: rgba(247,146,148,.66);
                }
                .bg-blue {
                    background-color: #3f98c5;
                    color: $white;
                }
                .bg-blue-light {
                    background-color: rgba(198,233,246,1);
                }
                .bg-yellow-light {
                    background-color: #fbe680;
                }
                .custom-td {
                    text-align: left;
                    padding-left: 16px;
                    
                    > img {
                        max-width: 75%;
                        margin-bottom: 5px;
                        @media screen and (max-width: $point-md-max) {
                            width: 60%;
                            max-width: 230px;
                        }
                    }
                    .fs-16 {
                        font-size: 1em;
                    }
                    .fs-21 {
                        font-size: em(21);
                        @media screen and (max-width: $point-md-max) {
                            font-size: em(18);
                        }
                    }
                    @media screen and (max-width: $point-md-max) {
                        text-align: center;
                    }
                }
                .content-list {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    > li {
                        position: relative;
                        padding: 15px 15px;
                        text-align: center;
                        border-bottom: 1px solid $shell-very-dark-gray;
                        &:first-child {
                            padding-top: 10px;
                            &::before {
                                content: none;
                            }
                        }
                        &:last-child {
                            padding-bottom: 10px;
                            border-bottom: none;
                        }
                        &::before {
                            content: 'або';
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 3em;
                            height: 1.5em;
                            position: absolute;
                            top: 0;
                            left: 50%;
                            @include transform(translate(-50%,-50%));
                            background-color: $shell-red;
                            font-weight: 500;
                            color: $white;
                            font-size: em(17);
                            @include border-radius(50%);
                        }
                    }
                }
            }
        }
        &__ban-img {
            margin-bottom: 20px;
            @media screen and (max-width: $point-md-max) {
                margin-bottom: 40px;
            }
            .img-wrap {
                > img {
                    &.deskt-tabl {
                        @media screen and (max-width: $point-md-max) {
                            display: none;
                        }
                    }
                    &.mob {
                        display: none;
                        @media screen and (max-width: $point-md-max) {
                            display: inline-block;
                        }
                    }
                }
            }
        }
        &__btn-wrap {
            text-align: center;
        }
    }

}