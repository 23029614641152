.input-inset-label {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    font-size: 1em;
    //@media screen and (max-width: $point-lg-max) {
    //    font-size: em(16);
    //}
    > label {
        position: absolute;
        left: em(24,18);
        top: 50%;
        @include transform(translateY(-50%));
        font-size: em(18);
        line-height: 1;
        color: $placeholder-color;
        cursor: text;
        @include transition(all, .2s, ease-in-out);

        @media screen and (max-width: $point-lg-max) {
            font-size: 1em;
        }
    }
    input[type=text],
    input[type=password],
    input[type=email] {
        //font-size: 1em;
        padding: 1.6em 1em .359em 1em;
        &:focus {
            + label {
                top: .75em;
                @include transform(none);
            }
        }
    }
    textarea {
        padding: 1.75em 1em .359em 1em;
        ~ label {
            width: calc(100% - 16px);
            left: 1px;
            top: 1px;
            padding: 1.75em 1em .5em em(24,18);
            //padding: .5em 1em .35em em(18,18);
            margin-bottom: 0;
            @include transform(none);
            background-color: #ffffff;

            -webkit-box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;
            -moz-box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;
            box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;
        }
        &:focus {
            ~ label {
                padding-top: .8em;
                @include transform(none);
            }
        }
    }
    &.in-focus {

    }
    &.active {
        > label {
            top: .75em;
            @include transform(none);
        }
        textarea ~ label {
            top: 1px;
            padding-top: .8em;
        }
    }
}