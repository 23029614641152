/* =================================
Page Promotion Fortune
================================= */

.fortune {
    overflow: hidden;

    .container-fluid {
        @media screen and (max-width: $point-md-max) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .red-text {
        color: $shell-red;
    }

    .num-big {
        font-size: 1.25em;
    }

    &__head {
        display: flex;
        background-color: $shell-yellow;
        @media screen and (max-width: $point-md-max) {
            flex-direction: column;
        }
    }
    &__head-text {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 55%;
        @media screen and (max-width: $point-md-max) {
            width: 100%;
            padding: 20px;
        }
        .head-logo {
            padding-left: 9%;
            @media screen and (max-width: $point-md-max) {
                padding-left: 0;
                margin-bottom: 20px;
            }
            > img {
                width: 24%;
                @media screen and (max-width: $point-md-max) {
                    width: 30%;
                }
            }
        }
        .title {
            display: flex;
            flex-direction: column;
            padding-left: 9%;
            font-size: 2.35vw;
            font-weight: 700;
            text-transform: uppercase;
            color: $shell-red;
            @media screen and (max-width: $point-md-max) {
                padding-left: 0;
                margin-bottom: 20px;
                font-size: 6vw;
            }
            .white-text {
                font-size: 2.75em;
                color: $white;
            }
        }
        .footer-logo {
            width: 69%;
            @media screen and (max-width: $point-md-max) {
                width: 75%;
                margin-left: -20px;
            }
            > img {
                width: 100%;
            }
        }
    }
    &__head-img {
        width: 45%;
        @media screen and (max-width: $point-md-max) {
            width: 100%;
        }
        > img {
            width: 100%;
        }
    }

    &__steps {
        background-color: $shell-red;
        padding-bottom: 60px;
        @media screen and (max-width: $point-md-max) {
            padding-bottom: 40%;
        }
    }
    &__steps-head {
        position: relative;
        margin-bottom: 40px;
        padding-top: 75px;
        padding-bottom: 40px;
        overflow: hidden;
        @media screen and (max-width: $point-md-max) {
            padding-top: 40px;
            padding-bottom: 16px;
        }
        &::before {
            content: '';
            display: block;
            width: 50%;
            height: 96px;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 55%;
            background-image: url("../../../img/promotions/fortune/points-line.png");
            background-repeat: repeat-x;
            background-position: top left;
            background-size: auto 100%;
            @media screen and (max-width: $point-md-max) {
                width: 100%;
                height: 38px;
                left: 25%;
            }
        }
        &::after {
            content: '';
            display: block;
            width: 50%;
            height: 96px;
            position: absolute;
            z-index: 0;
            bottom: 0;
            right: 55%;
            background-image: url("../../../img/promotions/fortune/points-line.png");
            background-repeat: no-repeat;
            background-position: top right;
            background-size: auto 100%;
            @media screen and (max-width: $point-md-max) {
                width: 100%;
                height: 38px;
                right: 25%;
            }
        }
        .text-block {
            position: relative;
            z-index: 1;
            width: 75%;
            padding: 30px 5% 30px 5%;
            background-color: $shell-yellow;
            font-size: 1.6vw;
            font-weight: 700;
            text-transform: uppercase;
            color: $shell-very-dark-gray;
            line-height: 1.5;
            @include border-radii(0, 10px, 10px, 0);

            transform: translateX(-50%);
            opacity: 0;
            transition: all .8s;

            @media screen and (max-width: $point-md-max) {
                width: calc(100% - 20px);
                padding: 20px;
                font-size: em(18);
                text-align: left !important;
            }
            &_animated {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
    &__steps-body {
        padding-left: 15%;
        background-image: url("../../../img/promotions/fortune/filling-hose.png");
        background-repeat: no-repeat;
        background-size: 16% auto;
        background-position: 2% 30px;
        color: $white;
        font-weight: 700;
        @media screen and (max-width: $point-md-max) {
            padding: 0 20px;
            background-size: 90px auto;
            background-position: 1% 50px;
        }
    }
    &__step {
        position: relative;
        margin-bottom: 50px;
        padding-left: 7vw;
        padding-right: 11vw;

        transform: translateX(-50%);
        opacity: 0;
        transition: all .8s;

        @media screen and (max-width: $point-md-max) {
            padding-left: 80px;
            padding-right: 0;
        }
        &_animated {
            transform: translateX(0);
            opacity: 1;
        }
        .step-pointer {
            position: absolute;
            top: 0;
            left: 0;
            padding-right: 1.5em;
            font-size: 2.5vw;
            @media screen and (max-width: $point-md-max) {
                font-size: em(28);
            }
            &::after {
                content: '';
                display: block;
                width: 1.5em;
                height: 0.85em;
                position: absolute;
                top: 50%;
                right: 0;
                @include transform(translateY(-50%));
                //background-color: pink;
                background-image: url("../../../img/promotions/fortune/hand.png");
                background-repeat: no-repeat;
                background-position: right center;
                background-size: contain;
            }
        }
        .step-content {
            .pr-10px {
                padding-right: 10px;
            }
            .pl-5px {
                padding-left: 5px;
            }
        }
        .step-title {
            margin-bottom: 1em;
            font-size: 1.6vw;
            font-weight: 700;
            line-height: 1.5;
            text-transform: uppercase;
            @media screen and (max-width: 1400px) {
                font-size: 1.75vw;
            }
            @media screen and (max-width: $point-md-max) {
                font-size: em(18);
            }
        }
        .cafe-img {
            text-align: center;
            @media screen and (max-width: $point-md-max) {
                text-align: left;
            }
            > img {
                width: 85%;
                @media screen and (max-width: $point-md-max) {
                    width: 100%;
                    max-width: 250px;
                }
            }
        }
        .app-images {
            display: flex;
            @media screen and (max-width: $point-md-max) {
                width: calc(100% + 80px);
                margin-left: -80px;
                max-width: 400px;
            }
        }
        .app-btns {
            width: 30%;
            margin-right: 15%;
            @media screen and (max-width: $point-md-max) {
                width: 50%;
                margin-right: 10%;
            }
            > a {
                display: inline-block;
                width: 100%;
                margin-bottom: 20px;
                font-size: 0;
                > img {
                    width: 100%;
                }
            }
        }
        .qr {
            width: 20%;
            @media screen and (max-width: $point-md-max) {
                width: 35%;
            }
            > img {
                width: 100%;
            }
        }
    }
    &__steps-footer {
        padding-left: 5%;
        font-size: 2.35vw;
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.5;
        @media screen and (max-width: $point-md-max) {
            font-size: em(26);
            text-align: center;
        }
    }

    &__casino {
        padding-top: 60px;
        padding-bottom: 60px;
        background-color: $shell-yellow;
        @media screen and (max-width: $point-md-max) {
            padding: 40px 0;
        }
    }
    &__casino-head {
        @media screen and (max-width: $point-md-max) {
            margin-top: -41%;
            overflow: hidden;
        }
    }
    &__casino-head-text {
        padding-left: 10%;
        font-weight: 700;
        text-transform: uppercase;
        color: $shell-very-dark-gray;
        @media screen and (max-width: $point-md-max) {
            text-align: center;
            padding: 0 20px;
        }
        .fs-45 {
            font-size: 2.35vw;
            @media screen and (max-width: $point-md-max) {
                font-size: 9vw;
            }
        }
        .fs-30 {
            font-size: 1.5vw;
            @media screen and (max-width: $point-md-max) {
                font-size: 5.25vw;
            }
        }
        .num {
            font-size: 1.5em;
            @media screen and (max-width: $point-md-max) {
                font-size: 1.75em;
            }
        }
        p {
            @media screen and (max-width: $point-md-max) {
                margin-bottom: 0;
            }
        }
    }
    &__casino-table {
        position: relative;
        margin-top: -41%;
        @media screen and (max-width: $point-md-max) {
            margin-left: -30px;
            margin-right: -30px;
            margin-bottom: 40px;
            margin-top: 0;
        }
        &.is-spinning {
            .img-anim {
                transition: transform 8s cubic-bezier(0.1, -0.01, 0, 1);
            }
        }
        .img-fixed,
        .img-anim {
            > img {
                width: 100%;
            }
        }
        .img-fixed {
            position: relative;
            z-index: 1;
        }
        .img-anim {
            position: absolute;
            z-index: 0;
            width: 62%;
            left: 19%;
            bottom: 6%;
            transform: rotate(calc(var(--rotate, 0) * 1deg));
        }
    }
    &__gifts {
        position: relative;
        overflow: hidden;
        margin-top: 25px;
        padding-top: 25px;
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 96px;
            position: absolute;
            z-index: 0;
            top: 0;
            right: 10%;
            background-image: url("../../../img/promotions/fortune/points-line.png");
            background-repeat: repeat-x;
            background-position: top right;
            background-size: auto 100%;
        }
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 96px;
            position: absolute;
            z-index: 0;
            bottom: 11em;
            left: 10%;
            background-image: url("../../../img/promotions/fortune/points-line.png");
            background-repeat: no-repeat;
            background-position: top left;
            background-size: auto 100%;
        }
        @media screen and (max-width: $point-md-max) {
            &::before,
            &::after {
                content: none;
            }
        }
    }
    &__gifts-list {
        position: relative;
        z-index: 1;
        display: flex;
        flex-wrap: wrap;
        padding: 0 5%;
        @media screen and (max-width: $point-md-max) {
            padding: 0 20px;
        }
    }
    &__gifts-item {
        width: 24%;
        margin-bottom: 50px;
        text-align: center;

        transform: translateX(-50%);
        opacity: 0;
        transition: all .8s;

        @media screen and (max-width: $point-md-max) {
            width: 50%;
            margin-bottom: 40px;
        }
        &:nth-child(even) {
            @media screen and (max-width: $point-md-max) {
                position: relative;
                &::before {
                    content: '';
                    display: block;
                    width: 250%;
                    height: 38px;
                    position: absolute;
                    z-index: 0;
                    top: -6px;
                    right: -25%;
                    background-image: url("../../../img/promotions/fortune/points-line.png");
                    background-repeat: repeat-x;
                    background-position: top right;
                    background-size: auto 100%;
                }
            }
        }
        &_animated {
            transform: translateX(0);
            opacity: 1;
        }
        > * {
            @media screen and (max-width: $point-md-max) {
                position: relative;
                z-index: 1;
            }
        }
        .img-wrap {
            margin-bottom: 25px;
            > img {
                width: auto;
                height: 105px;
                @media screen and (max-width: 1400px) {
                    height: 95px;
                }
                @media screen and (max-width: $point-lg-max) {
                    height: 60px;
                }
            }
        }
        .text, p {
            margin: 0;
            padding: 0 1em;
            font-size: em(18);
            font-weight: 700;
            color: $shell-red;
            text-transform: uppercase;
            @media screen and (max-width: $point-scaling-cancel) {
                font-size: em(16);
            }
            @media screen and (max-width: $point-lg-max) {
                font-size: em(14);
            }
        }
        > img {
            height: 2em;
            width: auto;
            @media screen and (max-width: $point-md-max) {
                height: 1.5em;
            }
        }
    }
    &__super-gift {
        position: relative;
        padding-left: 25%;
        padding-bottom: 55px;
        margin-bottom: 50px;
        @media screen and (max-width: $point-md-max) {
            padding-left: 20px;
            padding-bottom: 25px;
            margin-bottom: 30px;
        }
        &::after {
            content: '';
            display: block;
            width: 50%;
            height: 96px;
            position: absolute;
            z-index: 0;
            bottom: 0;
            left: 10%;
            background-image: url("../../../img/promotions/fortune/points-line.png");
            background-repeat: no-repeat;
            background-position: top left;
            background-size: auto 100%;
            @media screen and (max-width: $point-md-max) {
                width: 100%;
                height: 38px;
                right: 25%;
                left: auto;
                background-position: top right;
            }
        }
        .super-gift-content {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 25px 9%;
            background-color: $shell-red;
            color: $white;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1.1;
            @include border-radii(10px, 0, 0, 10px);

            transform: translateX(50%);
            opacity: 0;
            transition: all .8s;

            @media screen and (max-width: $point-md-max) {
                line-height: 1.25;
                flex-direction: column;
                padding: 15px 9%;
            }
            &_animated {
                transform: translateX(0);
                opacity: 1;
            }
            p {
                margin: 0;
            }
            .left-content {
                font-size: 4vw;
                @media screen and (max-width: 1500px) {
                    font-size: 4.5vw;
                }
                @media screen and (max-width: $point-md-max) {
                    font-size: 13vw;
                    padding-right: .25em;
                }
                .small-text {
                    font-size: .34em;
                }
            }
            .right-content {
                display: flex;
                align-items: center;
                font-size: 6.75vw;
                @media screen and (max-width: 1500px) {
                    font-size: 7.5vw;
                }
                @media screen and (max-width: $point-md-max) {
                    font-size: 16vw;
                }
                .small-text {
                    font-size: .25em;
                    transform-origin: center center;
                    @include transform(rotate(-90deg));
                }
            }
        }
    }
    &__casino-footer {
        padding: 0 5%;
        font-size: 1.6vw;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.5;
        color: $shell-very-dark-gray;
        text-align: center;
        @media screen and (max-width: $point-md-max) {
            font-size: em(18);
        }
        p {
            margin-bottom: 0;
        }
        img {
            height: .8em;
            vertical-align: baseline;
        }
    }

    .registration {
        padding-top: 80px;
        padding-bottom: 50px;
        @media screen and (max-width: $point-md-max) {
            padding-top: 25px;
            padding-bottom: 25px;
        }
        &__top-text {
            margin: 0;
            font-size: 1.9vw;
            font-weight: 700;
            color: $ferrari-cars-grey;
            text-align: center;
            @media screen and (max-width: $point-md-max) {
                font-size: 5.85vw;
                padding-left: 20px;
                padding-right: 20px;
            }
            &.color-red {
                margin-bottom: 1em;
                color: $shell-red;
            }
        }
        &__list {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 70px;
            @media screen and (max-width: $point-md-max) {
                flex-wrap: wrap;
                justify-content: center;
            }
            &_item {
                position: relative;
                z-index: 1;
                width: 20%;
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                    margin-bottom: 30px;
                }
            }
            &_icon {
                display: block;
                width: 41%;
                margin: 0 auto;
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                }
                > img {
                    width: 100%;
                }
            }
            &_descr {
                padding: 10% 30px 0 30px;
                font-size: 1.25vw;
                font-weight: 700;
                color: $ferrari-cars-grey;
                text-align: center;
                @media screen and (max-width: $point-md-max) {
                    font-size: 4.25vw;
                    padding: 10% 5px 0 5px;
                }
                a {
                    display: inline-block;
                    color: $shell-red;
                }
                span {
                    display: inline-block;
                }
            }
        }
    }

    &__winners-table {
        padding-bottom: 50px;
    }
    &__winners-table-title {
        margin-bottom: 70px;
        color: $shell-red;
        font-size: em(52);
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        @media screen and (max-width: $point-md-max) {
            font-size: em(34);
            margin-bottom: 35px;
        }
        .small-text {
            font-size: .65em;
        }
    }
    &__winners-table-wrap {
        position: relative;
        padding: 70px 5% 0;
        background-color: $shell-yellow;
        @media screen and (max-width: $point-md-max) {
            padding: 35px 20px 0;
        }
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 500px;
            position: absolute;
            z-index: -1;
            top: 100%;
            left: 0;
            background: rgb(251,206,7);
            background: -moz-linear-gradient(180deg, rgba(251,206,7,1) 0%, rgba(255,255,255,0) 100%);
            background: -webkit-linear-gradient(180deg, rgba(251,206,7,1) 0%, rgba(255,255,255,0) 100%);
            background: linear-gradient(180deg, rgba(251,206,7,1) 0%, rgba(255,255,255,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbce07",endColorstr="#ffffff",GradientType=1);
            @media screen and (max-width: $point-md-max) {
                height: 200px;
                background: rgb(251,206,7);
                background: -moz-linear-gradient(180deg, rgba(251,206,7,1) 75%, rgba(255,255,255,0) 100%);
                background: -webkit-linear-gradient(180deg, rgba(251,206,7,1) 75%, rgba(255,255,255,0) 100%);
                background: linear-gradient(180deg, rgba(251,206,7,1) 75%, rgba(255,255,255,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbce07",endColorstr="#ffffff",GradientType=1);
            }
        }
        table {
            width: 100%;
            font-size: em(24);
            font-weight: 700;
            color: $shell-very-dark-gray;
            text-align: center;
            @media screen and (max-width: $point-scaling-cancel) {
                font-size: em(20);
            }
            @media screen and (max-width: $point-lg-max) {
                font-size: em(18);
            }
            &.showing {
                tbody {
                    tr {
                        &:nth-child(n + 8) {
                            display: table-row;
                        }
                        @media screen and (max-width: $point-md-max) {
                            &:nth-child(n + 3) {
                                display: block;
                            }
                        }
                    }
                }
            }
            thead {
                color: $shell-red;
                tr {
                    background-color: #ffffff;
                    border-bottom: 5px solid $shell-yellow;
                }
            }
            tbody {
                tr {
                    &:nth-child(odd) {
                        background-color: rgba(255,255,255,.4);
                    }
                    &:nth-child(even) {
                        @media screen and (max-width: $point-md-max) {
                            background-color: rgba(255,255,255,.4);
                        }
                    }
                    &:nth-child(n + 8) {
                        display: none;
                    }
                    @media screen and (max-width: $point-md-max) {
                        &:nth-child(n + 3) {
                            display: none;
                        }
                    }
                }
            }
            th {
                padding: 1em .5em;
            }
            td {
                padding: 1.25em .5em;
            }
            @media only screen and (max-width: $point-md-max),
            (min-device-width: $point-md-max) and (max-device-width: 1024px) {
                thead, tbody, th, td, tr {
                    display: block;
                }
                thead tr {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                }

                tr {
                    margin-bottom: 50px;
                }

                td {
                    /* Behave  like a "row" */
                    border: none;
                    position: relative;
                    padding: 1em 0;
                    height: auto !important;
                    padding-top: 0;
                }

                td:before {
                    position: inherit;
                    width: 100%;
                    display: block;
                    padding: 1em .5em;
                    color: $shell-red;
                    background-color: $white;
                    margin-bottom: 1em;
                    -webkit-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05);
                    -moz-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05);
                    box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05);
                }

                /*
                Label the data
                */
                td:nth-of-type(1):before { content: "Дата"; }
                td:nth-of-type(2):before { content: "Ім’я"; }
                td:nth-of-type(3):before { content: "Номер картки"; }
                td:nth-of-type(4):before { content: "Регіон"; }
            }
        }
    }
    &__winners-table-search {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        @media screen and (max-width: $point-scaling-cancel) {
            font-size: em(14);
        }
        @media screen and (max-width: $point-xl-max) {
            flex-direction: column;
        }
        @media screen and (max-width: $point-md-max) {
            margin-bottom: 60px;
            font-size: em(13);
        }
        .title-text {
            margin-bottom: 0;
            margin-right: 30px;
            width: 30%;
            font-size: em(24);
            font-weight: 700;
            color: $shell-very-dark-gray;
            @media screen and (max-width: $point-xl-max) {
                width: 100%;
                margin-bottom: 1em;
                margin-right: 0;
                text-align: center;
            }
        }
        form {
            display: flex;
            width: 70%;
            @media screen and (max-width: $point-xl-max) {
                width: 100%;
            }
            @media screen and (max-width: $point-md-max) {
                flex-direction: column;
            }
            .form-group {
                width: 70%;
                margin-right: 30px;
                margin-bottom: 0;
                @media screen and (max-width: $point-md-max) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 20px;
                }
                input {
                    @media screen and (max-width: $point-md-max) {
                        @include placeholder {
                            font-size: 14px;
                        }
                    }
                }
            }
            .btns-wrap {
                width: 30%;
                margin-bottom: 0;
                @media screen and (max-width: $point-md-max) {
                    width: 100%;
                }
                .btn {
                    background-color: $shell-red;
                    border: none;
                    font-weight: 700;
                    color: $white;
                }
            }
        }
    }
    &__winners-empty {
        margin: 80px 0;
        font-size: em(24);
        font-weight: 700;
        color: $shell-very-dark-gray;
        text-align: center;
        @media screen and (max-width: $point-md-max) {
            margin: 50px 0 120px;
            font-size: em(20);
            text-align: center;
        }
    }
    &__winners-search-result {
        position: relative;
        margin: 50px 0;
        min-height: 1.35em;
        padding-right: 12em;
        color: $shell-red;
        font-size: em(24);
        font-weight: 700;
        @media screen and (max-width: $point-scaling-cancel) {
            font-size: em(20);
        }
        @media screen and (max-width: $point-lg-max) {
            font-size: em(18);
        }
        @media screen and (max-width: $point-md-max) {
            margin-top: 0;
            text-align: left;
            padding-right: 0;
        }
        .search-result-text {
            @media screen and (max-width: $point-md-max) {
                margin-bottom: 15px;
            }
        }
        .back-link {
            position: absolute;
            top: 50%;
            right: 0;
            @include transform(translateY(-50%));
            display: inline-flex;
            align-items: center;
            @media screen and (max-width: $point-md-max) {
                position: static;
                @include transform(none);
            }
            .icon {
                padding-right: .5em;
            }
        }
    }

    &__more-table {
        padding: 60px 0;
        text-align: center;
        @media screen and (max-width: $point-md-max) {
            padding: 0 0 25px 0;
        }
        &.less {
            a {
                > span {
                    &.more {
                        display: none;
                    }
                    &.less {
                        display: inline;
                    }
                }
                > img {
                    @include transform(rotate(-180deg));
                }
            }
        }
        a {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            > span {
                display: inline-block;
                margin-bottom: 1em;
                font-size: em(24);
                font-weight: 700;
                color: $shell-red;
                @media screen and (max-width: $point-scaling-cancel) {
                    font-size: em(20);
                }
                @media screen and (max-width: $point-lg-max) {
                    font-size: em(18);
                }
                &.more {
                    display: inline;
                }
                &.less {
                    display: none;
                }
            }
            > img {
                width: em(90);
                @include transition(all, .2s, ease-in-out);
            }
        }
    }

}

.fuels-block {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 20em;
    height: 5em;
    margin-bottom: 25px;
    padding: .75em;
    background-color: $shell-yellow;
    border: 2px solid $white;
    @include border-radius(10px);
    font-size: 1vw;
    @media screen and (max-width: $point-md-max) {
        font-size: 13px;
    }
    &__v-pover {
        justify-content: space-between;
        > img {
            width: 38%;
            height: auto;
        }
    }
    &__text {
        justify-content: center;
        p {
            margin: 0;
            padding: 0;
            font-size: 1.5em;
            color: #38383c;
        }
        img {
            height: 1.5em;
            width: auto;
            padding: 0 .25em;
        }
    }
}