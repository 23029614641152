/* =================================
Footer
================================= */

.main-footer {
    position: relative;
    margin-top: em(-97,17);
    padding: em(25) 0;
    -webkit-box-shadow: 0px -10px 40px 0px rgba(0,0,0,0.08);
    -moz-box-shadow: 0px -10px 40px 0px rgba(0,0,0,0.08);
    box-shadow: 0px -10px 40px 0px rgba(0,0,0,0.08);

    font-size: em(17);

    @media screen and (max-width: $point-scaling-10) {
        font-size: em(16);
    }

    @media screen and (max-width: $point-scaling-25) {
        font-size: em(15);
    }
    @media screen and (max-width: 1400px) {
        font-size: 1em;
    }
    @media screen and (max-width: $point-xl-max) {
        font-size: em(14);
    }
    @media screen and (max-width: $point-lg-max) {
        margin-top: em(-463,17);
        padding: 1.5em 0;
        font-size: em(17);
    }

    &__line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: $point-lg-max) {
            display: block;
            text-align: center;
        }
    }

    &__block {
        &_phone {
            order: 5;
        }
        &_contact {
            order: 4;
        }
        &_confidentiality {
            order: 2;
        }
        &_stores {
            order: 3;
        }
        &_social {
            order: 6;
            .main-footer__item {
                margin-right: 0;
            }
        }
        &_copyright {
            order: 1;
        }
    }

    &__item {
        margin-right: 2vw;
        white-space: nowrap;
        @media screen and (max-width: $point-lg-max) {
            margin-right: 0;
            margin-bottom: 1.5em;
        }
        .phone {
            > a {
                font-size: em(20);
            }
        }
        .stores {
            display: flex;
            align-items: center;
            @media screen and (max-width: $point-lg-max) {
                flex-direction: column;
                padding: 1em 0;
            }
            > a {
                position: relative;
                display: inline-block;
                height: em(40);
                margin-right: 4em;
                @media screen and (max-width: 1400px) {
                    margin-right: 2em;
                }
                @media screen and (max-width: $point-lg-max) {
                    width: 150px;
                    padding: .25em 0;
                    margin-right: 0;
                    margin-bottom: 1em;
                    border: 1px solid $shell-pale-gray;
                    text-align: center;
                }
                &:hover {
                    img {
                        &.img-static {
                            opacity: 0;
                        }
                        &.img-hover {
                            opacity: 1;
                        }
                    }
                }
                &:last-child {
                    margin-right: 0;
                    @media screen and (max-width: $point-lg-max) {
                        margin-bottom: 0;
                    }
                }
                img {
                    width: auto;
                    height: 100%;
                    @include transition(all, .2s, ease-in-out);
                    &.img-static {
                        opacity: 1;
                        @media screen and (max-width: $point-lg-max) {
                            display: none;
                        }
                    }
                    &.img-hover {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        @media screen and (max-width: $point-lg-max) {
                            position: static;
                            opacity: 1;
                            width: auto;
                        }
                    }
                }
            }
        }
        .social {
            justify-content: center;
        }
    }

    .link {
        color: $color-base;
        &:hover {
            color: $shell-red;
            text-decoration: none;
        }
    }

}