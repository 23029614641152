/* =================================
Personal Menu
================================= */

.personal {

    &__menu {
        //@media screen and (max-width: 1500px) {
        //    font-size: em(14);
        //}
        @media screen and (max-width: $point-lg-max) {
            font-size: 1em;
        }
    }

    &__nav {
        padding: 0;
        list-style: none;
    }

    &__nav-item {
        position: relative;
        margin-bottom: 1em;
        @media screen and (max-width: $point-lg-max) {
            margin-bottom: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }

        &.open-sub-nav {
            > .personal__sub-nav {
                max-height: 1000px;
            }
        }

        .btn-open-sub-nav {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: absolute;
            width: em(90,14);
            height: em(85,14);
            top: 0;
            right: 0;
            font-size: em(14);
            &.open {
                .icon {
                    @include transform(rotate(180deg));
                }
            }
            .icon {
                margin-left: .5em;
                @include transition(all, .3s, ease-in-out);
            }
        }
    }

    &__nav-link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 92%;
        height: em(85,21);
        padding: 0 em(30,21) 0 em(30,21);

        background-color: transparent;
        border: 1px solid $shell-pale-gray;
        -webkit-box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;
        -moz-box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;
        box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;
        @include transition(all, .3s, ease-in-out);

        font-size: em(21);

        @media screen and (max-width: 1500px) {
            padding: 0 em(20,21) 0 em(20,21);
        }
        @media screen and (max-width: $point-scaling-cancel) {
            font-size: em(18);
        }
        @media screen and (max-width: $point-lg-max) {
            height: em(70,21);
            border: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }

        &:hover,
        &.active {
            background-color: $shell-yellow;
            border-color: $shell-yellow;
            width: 100%;
            color: $shell-red;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            ~ .personal__sub-nav {
                width: 100%;
            }
        }

        .img-wrap {
            width: 2em;
            flex-basis: 2em;
            height: auto;
            margin-right: 1em;
            @media screen and (max-width: 1500px) {
                width: 1.5em;
                flex-basis: 1.5em;
            }
            > img {
                width: 100%;
            }
        }

        > span {
            flex-shrink: 1000;
        }
    }

    &__sub-nav {
        width: 92%;
        max-height: 0;
        padding-left: 3.75em;
        list-style: none;
        background-color: $shell-very-pale-gray;
        @include transition(all, .3s, ease-in-out);
        overflow: hidden;

        @media screen and (max-width: $point-lg-max) {
            padding-left: 4.5em;
            background-color: #fff;
        }

        .personal__nav-item {
            margin-bottom: 0;
            &:first-child {
                padding-top: 1em;
            }
            &:last-child {
                padding-bottom: 1em;
            }
        }

        .personal__nav-link {
            display: inline-block;
            width: auto;
            height: auto;
            padding:  0.3em 0;

            background-color: transparent;
            border: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;

            color: $shell-mid-gray;

            &:hover,
            &.active {
                background-color: transparent;
                color: $shell-red;
            }
        }

    }

}