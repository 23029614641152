/* =================================
Section Contacts
================================= */

.contacts {

    .form-wrap-col-2 {
        width: 100%;
        max-width: em(1040);
        margin: 0 auto;
    }

    .form-group {
        textarea[name="message"] {
            height: em(260,24);
        }
    }

}