.rules {
    &--shell-cafe {
        padding: 0 10px;
    }
    &__pdf {
        margin: 0 auto;
        padding-top: 42px;
        a {
            display: block;   
            text-align: center; 
            img {
                width: 100%;
                max-width: 75px;
            }
        }
        &-title {
            margin-top: 27px;
            text-align: center;
            font-size: em(24);
            color: $shell-red;
            a {
                text-decoration: underline;
            }
        }
    }
    &__content {
        padding-top: 30px;
        p {
            font-size: 1em;
            line-height: 1.4;
            font-weight: 400;
            margin: 0;
            text-align: justify;
        }
        a {
            color: $shell-red;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .rules {
        &__pdf {
            a {
                img {
                    max-width: 48px;
                }
            }
            &-title {
                font-size: 17px;
            }
        }
        &__content {
            p {
                text-align: justify;
            }
        }
    }
}

@media screen and (max-width: 414px)  {
    .rules {
        &__content {
            p {
                font-size: 14px;
            }
        }
    }
}