/* =================================
Page Shell-V-Power
================================= */

.shell-v-power {

    .top-section {
        padding-bottom: 1.5em;
        .img-wrap {
            > img {
                max-width: 100%;
            }
        }
    }

    .img-title {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        @media screen and (max-width: $point-lg-max) {
            margin-left: -30px;
            margin-right: -30px;
        }
        @media screen and (max-width: $point-sm-max) {
            margin-left: -15px;
            margin-right: -15px;
        }
        &.shell-v-power {
            background-image: url("../../img/Shell-V-Power/img-title_shell-v-power.png");
            @media screen and (max-width: $point-lg-max) {
                background-size: auto;
                background-position: top center;
            }
            @media screen and (max-width: $point-sm-max) {
                background-size: auto;
                background-position: 62% 0%;
            }
        }
        &.shell-v-power-disel {
            background-image: url("../../img/Shell-V-Power/img-title_shell-v-power-disel.jpg");
            @media screen and (max-width: $point-lg-max) {
                background-size: auto;
                background-position: top center;
            }
            @media screen and (max-width: $point-sm-max) {
                background-size: auto;
                background-position: 68% -50%;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: em(220);
            text-align: center;
            @media screen and (max-width: $point-sm-max) {
                min-height: em(208);
                padding: 0 15px;
            }
            .title {
                .sub-title-text {
                    @media screen and (max-width: $point-sm-max) {
                        margin-top: .5em;
                        font-size: em(19);
                    }
                }
                + .sub-title-text {
                    margin-top: .5em;
                    @media screen and (max-width: $point-sm-max) {
                        margin-top: 1em;
                    }
                }
            }
            .title-text {
                margin: 0;
                font-size: em(48);
                @media screen and (max-width: $point-lg-max) {
                    font-size: em(30);
                }
                @media screen and (max-width: $point-sm-max) {
                    font-size: em(26);
                }
            }
            .sub-title-text {
                margin: 0;
                font-size: em(30);
                @media screen and (max-width: $point-lg-max) {
                    font-size: em(24);
                }
                @media screen and (max-width: $point-sm-max) {
                    font-size: em(20);
                    line-height: 1.5;
                }
            }
        }
        &__footer {
            padding: .75em;
            font-size: em(30);
            font-weight: 700;
            line-height: 1.1;
            text-align: center;
            color: #ffffff;
            text-transform: uppercase;
            @media screen and (max-width: $point-lg-max) {
                font-size: em(24);
                line-height: 1.5;
            }
            @media screen and (max-width: $point-sm-max) {
                font-size: em(22);
                line-height: 1.75;
            }
            &_red {
                background-color: $shell-red;
            }
            &_blue {
                background-color: $shell-blue;
            }
            p {
                margin: 0;
            }
        }
    }

    .advantage {
        background-image: url("../../img/Shell-V-Power/shell-v-power-bg_01.png");
        background-repeat: no-repeat;
        background-position: top right;
        background-size: 90% 100%;
        @media screen and (max-width: 1400px) {
            padding-bottom: 3em;
        }
        @media screen and (max-width: $point-xl-max) {
            background-size: 90% auto;
        }
        @media screen and (max-width: $point-lg-max) {
            background-image: url("../../img/Shell-V-Power/shell-v-power-bg_01_mob.png");
            background-position: top left;
            background-size: 100% 100%;
        }
        .advantage-list {
            padding-top: 5em;
            .row {
                > div {
                    margin-bottom: em(50);
                }
            }
        }
        .advantage-item {
            height: 100%;
            padding: 0 1em;
            border-left: 1px solid $shell-yellow;
            &.h-auto {
                height: 45% !important;
                @media screen and (max-width: $point-lg-max) {
                    margin-bottom: em(50);
                    height: auto !important;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .img-wrap {
                position: relative;
                //background-color: pink;
                float: left;
                width: 20%;
                padding-top: 20%;
                margin-right: em(20);
                margin-bottom: em(10);
                > img {
                    position: absolute;
                    //width: auto;
                    //height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    top: 50%;
                    left: 50%;
                    @include transform(translate(-50%,-50%));
                }
            }
            .text {
                margin: 0;
                font-size: em(20);
                @media screen and (max-width: 1500px) {
                    font-size: em(18);
                }
                @media screen and (max-width: 1300px) {
                    font-size: 1em;
                }
                strong {
                    color: $shell-red;
                }
            }
        }
    }

    .video-section {
        background-image: url("../../img/Shell-V-Power/shell-v-power-bg_02.png");
        background-repeat: no-repeat;
        background-position: 100% 10%;
        background-size: 100% auto;

        .video-block {
            &__descr {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                height: 100%;
                padding: 2em 4% 2em 10%;
                background-color: $shell-yellow;
                .img-wrap {
                    width: em(230);
                    margin-bottom: 5vw;
                    @media screen and (max-width: $point-lg-max) {
                        width: em(144);
                    }
                    @media screen and (max-width: $point-sm-max) {
                        width: em(112);
                    }
                }
                .text-block {
                    font-size: em(36);
                    font-weight: 700;
                    @media screen and (max-width: $point-lg-max) {
                        font-size: em(24);
                    }
                    @media screen and (max-width: $point-sm-max) {
                        font-size: em(18);
                    }
                    .fs-50 {
                        font-size: em(50,36);
                        line-height: 1;
                        @media screen and (max-width: $point-lg-max) {
                            font-size: em(30,24);
                        }
                    }
                    p {
                        @media screen and (max-width: $point-sm-max) {
                            margin: .5em 0;
                        }
                        &:last-child,
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                    .color-red {
                        @media screen and (max-width: $point-sm-max) {
                            line-height: 1;
                        }
                    }
                }
            }
            &__video-wrap {
                position: relative;
                width: 100%;
                min-height: 100%;
                padding-top: 70%;
                &.video-active {
                    iframe {
                        z-index: 2;
                    }
                    .preview-img {
                        z-index: 1;
                    }
                }
                iframe {
                    position: absolute;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
                .preview-img {
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    cursor: pointer;
                    > img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        @include transform(translate(-50%,-50%));
                    }
                }
            }
        }

        .advantage {
            background-image: none;
            padding-bottom: 0;
            .advantage-list {
                padding-top: 0;
            }
            .advantage-item {
                .text {
                    strong {
                        color: $shell-blue;
                    }
                }
            }
        }

    }

    .footer-text {
        text-align: center;
        .text-block {
            width: 100%;
            max-width: 1205px;
            margin: 0 auto;
            text-align: justify;
        }
    }

}

.shell-v-power-static {
    padding-bottom: 50px;
    .banner-content {
        padding-bottom: 60px;
        @media screen and (max-width: $point-lg-max) {
            margin-bottom: 30px;
            padding: 0;
        }
    }
    h1 {
        margin-bottom: 80px;
        font-size: 48px;
        @media screen and (max-width: $point-lg-max) {
            margin-bottom: 45px;
            font-size: 32px;
        }
    }
    .advantage-item{
        &--static {
            align-items: center;
            padding: 56px 0;
            @media screen and (max-width: $point-lg-max) {
                flex-direction: column-reverse;
                padding: 56px 0 0;
            }
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                @media screen and (max-width: $point-lg-max) {
                    padding-bottom: 15px;
                }
            }
            &:nth-child(2n) {
                @media screen and (min-width: $point-lg-max) {
                    flex-direction: row-reverse;
                }
            }
            .text-block {
                @media screen and (max-width: $point-lg-max) {
                    margin-bottom: 35px;
                }
            }

            &.power-racing-100 {
                @media screen and (max-width: $point-lg-max) {
                    padding: 30px 0;
                }
            }
        }
        
        &__title {
            font-size: 36px;
            @media screen and (max-width: $point-lg-max) {
                font-size: 28px;
                text-align: center;
                padding: 30px 40px 10px;
                &--racing {
                    font-size: 27px;
                    padding: 0px 20px 10px;
                }
            }
        }
        
        &__text {
            font-size: 30px;
            text-align: justify;
            @media screen and (max-width: 1800px) {
                font-size: 28px;
            }
            @media screen and (max-width: 1620px) {
                font-size: 24px;
            }
            @media screen and (max-width: $point-lg-max) {
                text-align: center;
            }
        }
    }
    .video-section {
        background: none;
        .video-block {
            &>.row {
                &>div {
                    min-height: 550px;
                    @media screen and (max-width: $point-lg-max) {
                        min-height: 320px;
                    }
                }
                .video-container {
                    aspect-ratio: 16/9;
                }
            }
            .video-block__video-wrap {
                padding: 0;
                position: relative;
                overflow: hidden;
                background: #000;
                #icon-play {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    box-shadow: 0px 0px 30px 1px #fff;
                    border-radius: 50%;
                    cursor: pointer;
                    @media screen and (max-width: $point-lg-max) {
                        width: 80px;
                    }
                }
                &.video-active #icon-play {
                    display: none;
                }
                video {
                    &[poster] {
                        width: auto;
                        height: 550px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                &.video-active video {
                    width: 100%;
                }
            }
            &__descr {
                background: #dd1d21;
                padding: 2em 7%;
                .img-wrap {
                    margin-bottom: 70px;
                    @media screen and (max-width: $point-lg-max) {
                        margin-bottom: 25px;
                    }
                    img {
                        width: 15.5em;
                        @media screen and (max-width: $point-lg-max) {
                            width: 12.5em;
                        }
                        &.logo-white {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(99%)
                                saturate(14%) hue-rotate(234deg) brightness(103%)
                                contrast(100%);
                        }    
                    }
                    &.img-container {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        img {
                            max-width: 50%;
                            @media screen and (max-width: $point-lg-max) {
                                width: 48%;
                                max-width: 15.5em;
                            }
                        }
                    }
                }
                .text-block {
                    text-transform: uppercase;
                    color: #fff;
                    line-height: 1.35;
                    @media screen and (max-width: $point-lg-max) {
                        font-size: 1.75rem;
                        text-align: left !important;
                    }
                    @media screen and (max-width: 420px) {
                        font-size: 1.25rem;
                        text-align: left !important;
                    }
                }
            }
        }
    }
    .advantage--static {
        margin-bottom: 6rem;
        @media screen and (max-width: $point-lg-max) {
            margin-bottom: 4.5rem;
        }
        .advantage-list {
            &>.row {
                padding: 0 15px;
            }
            .advantage-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                @media screen and (min-width: $point-lg-max) {
                    border-left: 1px solid #fbce07;
                    &:last-child {
                        border-right: 1px solid #fbce07;
                    }
                }
                @media screen and (max-width: $point-lg-max) {
                    padding: 60px 20px 40px;
                    &::after, &:first-child::before {
                        content: "";
                        width: 100%;
                        max-width: 270px;
                        height: 1px;
                        background: #fbce07;
                        position: absolute;
                        bottom: 0;
                    }
                    &:first-child::before {
                        top: 0;
                    }
                }
                .img-wrap {
                    max-width: 70px;
                    margin-bottom: 1.25rem;
                    @media screen and (max-width: $point-lg-max) {
                        max-width: 85px;
                    }
                }
                .text {
                    font-size: 1.9rem;
                    text-align: center;
                    @media screen and (max-width: $point-lg-max) {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
    .footer-text {
        .text-block {
            max-width: none;
            font-size: 1.125rem;
            line-height: 1.65;
            @media screen and (max-width: $point-lg-max) {
                text-align: left !important;
                font-size: 1rem;
                line-height: 1.25;
            }

            p {
                margin-bottom: 10px;
            }
        }
        @media screen and (max-width: $point-lg-max) {
            padding: 1rem 0 0;
        }
    }
}