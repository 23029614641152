.form-group {
    position: relative;
    width: 100%;
    margin-bottom: 20px;

    &.mb-2em {
        margin-bottom: 2em;
    }
    &.mb-3em {
        margin-bottom: 3em;
    }

    &.editable {
        padding-right: 3em;
        .icon {
            position: absolute;
            top: 50%;
            right: 0;
            @include transform(translateY(-50%));
            font-size: 1.5em;
            color: $border-input;
        }
    }

    &__radio {
        &_line {
            display: flex;
            align-items: flex-start;
            height: em(80);
            @media screen and (max-width: $point-sm-max) {
                height: auto;
            }
            .form-group__title {
                display: block;
                margin-right: 2em;
                padding-top: .25em;
                font-size: em(18);
                color: $placeholder-color;
                margin-bottom: 1em;
                text-align: left;
                @media screen and (min-width: 1600px) and (max-width: 1900px) {
                    margin-right: .5em;
                }
                @media screen and (max-width: $point-sm-max) {
                    font-size: 1em;
                    margin-right: 1em;
                }

            }
            &.editable {
                padding: 1.45em 3em .1em 0;
                .icon {
                    position: static;
                    @include transform(none);
                    margin-left: .75em;
                    vertical-align: text-top;
                    &-absolute {
                        position: absolute;
                        top: 35%;
                    }
                }
            }
        }
        &_flex {
            width: calc(100% - 20px);
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @media screen and (max-width:576px) {
                flex-wrap: wrap;
            }
        }
        &-sex {
            &.editable {
                padding: 0 3em 0 0;
            }
        }
        &-item {
            width: 50%;
            padding-right: 20px;
            @media screen and (min-width: 1600px) and (max-width: 1900px) {
                padding-right: 10px !important;
            }
            @media screen and (max-width: $point-sm-max) {
                margin-bottom: 5px;
            }
            label {
                margin-bottom: 0!important;
                display: flex!important;
                align-items: center;
                height: 100%;
                @media screen and (min-width: 1600px) and (max-width: 1900px) {
                    padding-left: 30px !important;
                }
                span {
                    top: 6px;
                }
            }
            input[type=radio] + label {
                //padding-top: 0!important;
                //padding-bottom: 12px!important;
                //font-size: 1.05em;
            }
            @media screen and (max-width:576px) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }

    &__title {
        margin-bottom: 0;
        font-size: em(24);
        @media screen and (max-width: 576px) {
            font-size: em(20);
        }
    }

    input {
        display: inline-block;
        width: 100%;
        padding-left: 1em;
        padding-right: 1em;

        background-color: #fff;
        border: 1px solid $border-input;
        outline: none;
        @include border-radius(0);

        font-family: $font-ShellFutura;
        font-size: em(18);
        color: $color-base;
        font-weight: 400;

        -webkit-box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;
        -moz-box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;
        box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;

        @include placeholder{
            color: $placeholder-color;
            font-weight: 400;
            @include transition(all, .3s, ease-in-out);
        };

        &:hover,
        &:focus{
            border-color: $border-input-hover;
        }

        &[type="text"],
        &[type="password"],
        &[type="email"],
        &[type="file"] {
            position: relative;
            padding: 0.9583333333em 1em 1em 1em;

            font-size: em(24);

            @include transition(all, .3s, ease-in-out);

            @media screen and (max-width: $point-lg-max) {
                font-size: em(22);
            }
        }
        &[type="radio"] {
            position: absolute;
            width: 0;
            height: 0;
            left: 0;
            top: 0;
            visibility: hidden;
            overflow: hidden;
            &:checked {
                + label {
                    color: $color-base;
                    > span {
                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }
            + label {
                position: relative;
                display: inline-block;
                margin-right: 2em;
                margin-bottom: 20px;
                padding-left: 35px;
                padding-top: 0.35em;
                padding-bottom: 0.35em;
                font-size: em(18);
                color: $shell-light-gray;
                @media screen and (max-width: $point-lg-max) {
                    font-size: em(16);
                }
                > span {
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    top: .3em;
                    left: 0;
                    border: 1px solid $border-input;
                    @include border-radius(50%);
                    &::before {
                        content: '';
                        display: block;
                        width: 50%;
                        height: 50%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        @include border-radius(50%);
                        @include transform(translate(-50%,-50%));
                        @include transition(all, .2s, ease-in-out);
                        background-color: $shell-yellow;
                        opacity: 0;
                    }
                }
            }
        }
        &:last-of-type {
            + label {
                margin-right: 0;
            }
        }
        &.error {
            border-color: $shell-red;
        }
    }

    textarea {
        display: inline-block;
        width: 100%;
        min-height: 6em;
        padding: 1em;

        background-color: #fff;
        border: 1px solid $border-input;
        outline: none;
        @include border-radius(0);

        font-family: $font-ShellFutura;
        font-size: em(24);
        color: $color-base;
        font-weight: 400;

        -webkit-box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;
        -moz-box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;
        box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;

        resize: none;

        @include placeholder{
            color: $placeholder-color;
            font-weight: 400;
            @include transition(all, .3s, ease-in-out);
        };

        &.error {
            border-color: $shell-red;
        }
    }

    &__checkbox {
        display: flex;
        align-items: center;
        font-size: em(18);
        input[type=checkbox] {
            position: absolute;
            z-index: -1;
            opacity: 0;
            cursor: pointer;
            &+label {
                position: relative;
                padding: 2em;
                user-select: none;
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    @include transform(translateY(-50%));
                    display: inline-block;
                    width: 1.2em;
                    height: 1.2em;
                    border: 1px solid #bababa;
                    margin-right: 0.5em;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 50% 50%;
                }
                > a {
                    text-decoration: underline;
                }
            }
            &:checked+label::before {
                border-color: #fbce07;
                background-color:#fbce07;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
            }
        }
        @media screen and (max-width:576px) {
            label {
                align-items: flex-start!important;
            }
        }
    }
    @media screen and (max-width:640px) {
        display: block;
        .form-group__checkbox {
            margin-bottom: 60px;
            text-align: left;
            align-items: flex-start;
        }
    }

    &__shellsmart-club {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: $point-md-max) {
            display: block;
            text-align: center;
        }
        .form-group__checkbox {
            @media screen and (max-width: $point-md-max) {
                margin-bottom: 30px;
                text-align: left;
            }
        }
    }

    .alert {
        position: absolute;
        width: 100%;
        padding: 0 0 0 2em;
        margin: 0;
        top: 100%;
        left: 0;
        background-color: transparent;
        border: none;
        font-size: 12px;
        color: $shell-red;
        text-align: left;
    }

    span.error {
        position: absolute;
        width: 100%;
        padding: 0 0 0 2em;
        margin: 0;
        top: 100%;
        left: 0;
        background-color: transparent;
        border: none;
        font-size: 12px;
        color: $shell-red;
        text-align: left;
    }

}

.form-sub-text {
    margin-bottom: 40px;
    font-size: em(24);
    @media screen and (max-width: $point-lg-max) {
        font-size: em(20);
    }
}

.form-text {
    font-size: em(18);
    @media screen and (max-width: $point-lg-max) {
        font-size: 1em;
    }
    a {
        color: $shell-red;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.upload-file {
    input {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
        opacity: 0;
        //visibility: hidden;
        border: none;
        outline: none;
    }
    &.input-inset-label input:focus {
        + label {
            font-size: 1em;
        }
    }
    > label, > a {
        position: static;
        display: inline-block;
        vertical-align: top;
        width: 100%;
        height: auto;
        background-color: $shell-red;
        border: none;
        outline: none;
        padding: em(18) 30px;
        font-family: $font-ShellFutura;
        font-size: 1em;
        text-align: left;
        font-weight: 300;
    }
    &.media {
        float: left;
        width: calc(25% - 30px);
        margin-right: 30px;
        margin-bottom: 30px;
        @media screen and (max-width: $point-lg-max) {
            width: calc(33.3% - 30px);
        }
        @media screen and (max-width: $point-md-max) {
            width: calc(50% - 30px);
        }
        @media screen and (max-width: $point-sm-max) {
            float: none;
            width: 240px;
            margin-left: auto;
            margin-right: auto;
        }
        input {
            &:disabled {
                + label {
                    cursor: default;
                }
            }
        }
        > label, > a {
            position: relative;
            padding: 100% 0 0 0;
            cursor: pointer;
            span.locking {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
            span.add {
                position: absolute;
                display: block;
                width: 90px;
                height: 90px;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%,-50%));
                border: 2px solid $placeholder-color;
                @include border-radius(50%);
                font-size: 3em;
                line-height: 85px;
                font-weight: 200;
                color: $placeholder-color;
                text-align: center;
            }
            span.field-close, a.field-close {
                position: absolute;
                display: block;
                width: 30px;
                height: 30px;
                top: 5px;
                right: 5px;
                border: 1px solid $shell-blue;
                @include border-radius(50%);
                font-size: 18px;
                line-height: 25px;
                color: #ffffff;
                text-align: center;
                cursor: pointer;
                @include transform(none);
            }
            > img,
            > video {
                position: absolute;
                max-width: 100%;
                max-height: 100%;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
            }
            &.load {
                min-height: auto;
                &::before {
                    background-color: $shell-yellow;
                }
                .loader {
                    top: 50%;
                    left: 50%;
                    @include transform(translate(-50%,-50%));
                }
            }
        }
        a{
            display: block;
            background-color: $shell-yellow;
        }
        .invalid-feedback {
            padding-left: 0;
        }
    }
    &.doc {
        width: 100%;
        margin-bottom: 30px;
        input {
            &:disabled {
                + label {
                    cursor: default;
                    font-size: em(20);
                    font-weight: 300;
                    color: $color-base;
                    > span.remove {
                        color: $placeholder-color;
                    }
                }
            }
        }
        > label, > a {
            padding-right: 1.5em;
            font-size: em(18);
            font-weight: 200;
            color: $placeholder-color;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            @media screen and (max-width: $point-lg-max) {
                font-size: em(16);
            }
            span.locking {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
            span.add,
            span.field-close,
            a.field-close {
                position: absolute;
                z-index: 1;
                display: block;
                width: 1em;
                height: 1em;
                //padding-top: .35em;
                top: 50%;
                right: .5em;
                @include transform(translateY(-50%));
                @include border-radius(50%);
                background-color: $shell-yellow;
                font-size: 1.5em;
                line-height: 1em;
                cursor: pointer;
                text-decoration: none;
                text-align: center;
            }
        }
        a {
            display: block;
            background-color: $shell-yellow;
        }
    }
}
