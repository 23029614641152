/* =================================
Styles Popup Navigator Route
================================= */

.popup {

    &__navigator-route {
        .custom-scroll {
            width: 100%;
            max-width: 730px;
            margin:  0 auto;
        }
        .popup__content {
            padding: 3em 4em;
            max-width: 700px;
            border: 1px solid $border-input;
            -webkit-box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;
            -moz-box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;
            box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;
            @media screen and (max-width: $point-md-max) {
                padding: 1.5em 1em;
            }
        }
        .start-point {
            .form-group {
                input {
                    padding-right: em(80,24);
                }
                .create-first-point {
                    position: absolute;
                    width: em(78);
                    height: calc(100% - 2px);
                    top: 50%;
                    right: 1px;
                    padding: 1.25em 0;
                    @include transform(translateY(-50%));
                    cursor: pointer;
                    text-align: center;
                    > img {
                        width: 45%;
                        height: auto;
                    }
                }
            }
        }
        .field-wrap {
            position: relative;
            margin-bottom: 20px;
            padding-left: em(50);
            padding-right: em(50);
            @media screen and (max-width: $point-scaling-cancel) {
                font-size: em(13);
            }
            @media screen and (max-width: $point-md-max) {
                padding-left: em(30);
                padding-right: em(30);
            }
            .form-group {
                margin-bottom: 0;
            }
        }
        .first-icon,
        .remove-point {
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
            font-size: 2em;
            @media screen and (max-width: $point-md-max) {
                font-size: 1.5em;
            }
        }
        .first-icon {
            left: 0;
            color: $shell-very-dark-gray;
        }
        .remove-point {
            right: 0;
            color: $shell-red;
            font-size: 1.5em;
            line-height: 1;
            cursor: pointer;
        }
        .add-point {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: em(20);
            line-height: 1;
            vertical-align: middle;
            cursor: pointer;
            @media screen and (max-width: $point-lg-max) {
                font-size: em(18);
            }
            .icon-plus {
                display: flex;
                align-items: center;
                justify-content: center;
                width: em(50,34);
                height: em(50,34);
                margin-right: .5em;
                background-color: $shell-yellow;
                font-size: em(34,20);
                color: $shell-red;
                text-align: center;
            }
            .text-label {
                color: $shell-very-dark-gray;
            }
        }
        .btns-wrap {
            .btn {
                height: em(73,22);
                padding-top: 5px;
                padding-bottom: 5px;
                line-height: 1.1;
            }
        }
    }

}