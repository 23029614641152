.championship {
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 992px) {
        .container-fluid {
            padding-left: 0;
            padding-right: 0;
        }
        .rules {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    @media screen and (max-width: 576px)  {
        .rules {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    &__top-section {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../../img/promotions/championship/top-section.jpg');
        height: 575px;
        position: relative;
        @media screen and (max-width: 1440px)  {
            height: 435px;
        }
        @media screen and (max-width: 1200px)  {
            height: 430px;
        }
        @media screen and (max-width: 992px) {
            height: 560px;
        }
        @media screen and (max-width: 768px) {
            height: 680px;
        }
        @media screen and (max-width: 576px) {
            height: 525px;
        }
        @media screen and (max-width: 425px) {
            height: 450px;
        }
        @media screen and (max-width: 375px) {
            height: 390px;
        }
        &-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        &-titles {
            margin-top: 35px;
            margin-left: 45px;
            @media screen and (max-width: 1200px) {
                margin-top: 25px;
                margin-left: 25px;
            }
            @media screen and (max-width: 768px) {
                margin-left: 25px;
            }
            @media screen and (max-width: 576px) {
                margin-left: 15px;
                margin-top: 15px;
            }
        }
        &-title {
            font-size: 3.25em;
            font-weight: 700;
            text-transform: uppercase;
            color: #404040;
            position: relative;
            &--black {
                margin-bottom: 25px;
            }
            &--red {
                color: #dd1d21;
                padding-right: 150px;
            }
            @media screen and (max-width: 1440px) {
                font-size: 2.5em;
            }
            @media screen and (max-width: 1350px) {
                font-size: 32px;
            }
            @media screen and (max-width: 1200px) {
                font-size: 28px;
            }
            @media screen and (max-width: 992px) {
                font-size: 36px;
            }
            @media screen and (max-width: 576px) {
                font-size: 28px;
            }
            @media screen and (max-width: 425px) {
                font-size: 24px;
                &--red {
                    padding-right: 15px;
                }
            }
            @media screen and (max-width: 375px) {
                font-size: 22px;
            }
        }
        &-image {
            max-width: 52%;
            min-width: 790px;
            position: absolute;
            right: 90px;
            top: 30px;
            background-image: url('../../../img/promotions/championship/family-bg.png');
            background-repeat: no-repeat;
            @media screen and (max-width: 1800px) {
                right: 20px;
            }
            @media screen and (max-width: 1680px) {
                max-width: 50%;
                right: 10px;
            }
            @media screen and (max-width: 1440px) {
                max-width: 40%;
                min-width: 600px;
                background-size: 100%;
                right: 10px;
            }
            @media screen and (max-width: 1200px) {
                min-width: 560px
            }
            img {
                width: 100%;
                margin-top: -7px;
                margin-left: 10px;
            }
            @media screen and (max-width: 992px) {
                right: 0;
                top: auto;
                bottom: 35px;
            }
            @media screen and (max-width: 768px) {
                bottom: 80px;
            }
            @media screen and (max-width: 576px) {
                min-width: 420px;
                bottom: 30px;
            }
            @media screen and (max-width: 425px)  {
                max-width: 100%;
                min-width: 100%;
                bottom: -15px;
            }
            @media screen and (max-width: 375px) {
                bottom: -10px;
            }
        }
    }
    &__video {
        height: 1080px;
        @media screen and (max-width: 1200px)  {
            height: 950px;
        }
        @media screen and (max-width: 768px) {
            height: 680px;
            margin-bottom: 75px;
        }
        @media screen and (max-width: 576px) {
            height: 550px;
            margin-bottom: 30px;
        }
        @media screen and (max-width: 425px) {
            height: 350px;
        }
        &-wrapper {
            position: relative;
            padding-top: 160px;
            padding-bottom: 170px;
            height: 100%;
            @media screen and (max-width: 425px) {
                padding: 10px 0;
            }
        }
        &-bg {
            background-image: url('../../../img/promotions/championship/grass.png');
            background-repeat: no-repeat;
            background-position: top;
            background-size: 110% calc(100% + 245px);
            width: 100%;
            height: calc(100% + 245px);
            position: absolute;
            z-index: 1;
            top: -245px;
            left: 0;
            @media screen and (max-width: 576px) {
                background-size: 105% calc(100% + 50px);
                height: calc(100% + 145px);
                top: -145px;
            }
            @media screen and (max-width: 425px) {
                background-size: 105% calc(100% + 70px);
                height: calc(100% + 150px);
                top: -70px;
            }
            @media screen and (max-width: 375px) {
                background-size: 230% 100%;
            }
        }
        &-wrap {
            z-index: 10;
            iframe {
                width: 1060px;
                z-index: 10;
                height: 615px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 200px; 
                @media screen and (max-width: 1440px) {
                    width: 800px;
                    height: 550px;
                }
                @media screen and (max-width: 1200px) {
                    width: 700px;
                    height: 480px;
                    top: 150px;
                }
                @media screen and (max-width: 768px)  {
                    width: 550px;
                    height: 410px;
                    top: 110px;
                }
                @media screen and (max-width: 576px) {
                    width: 400px;
                    height: 300px;
                    top: 60px;
                }
                @media screen and (max-width: 425px) {
                    width: 320px;
                    height: 200px;
                    top: 85px;
                }
                @media screen and (max-width: 375px) {
                    width: 300px;
                    height: 190px;
                    top: 75px;
                }
            }
            &-bg {
                background-image: url('../../../img/promotions/championship/video-bg.png');
                width: 1200px;
                position: absolute;
                left: 50%;
                z-index: 9;
                top: 100px;
                background-size: 100%;
                transform: translateX(-50%);
                height: 830px;
                background-repeat: no-repeat;
                @media screen and (max-width: 1440px) {
                    width: 1000px;
                    height: 700px;
                    top: 150px;
                }
                @media screen and (max-width: 1200px) {
                    width: 900px;
                    height: 680px;
                    top: 120px;
                }
                @media screen and (max-width: 768px)  {
                    width: 650px;
                    height: 500px;
                    top: 80px;
                }
                @media screen and (max-width: 576px) {
                    width: 510px;
                    height: 380px;
                    top: 40px;
                }
                @media screen and (max-width: 425px) {
                    width: 400px;
                    height: 320px;
                    top: 55px;
                }
                @media screen and (max-width: 375px) {
                    width: 350px;
                    height: 240px;
                    top: 45px;
                }
            }
        }
        &-preview {
            width: 1060px;
            z-index: 11;
            top: 200px;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            height: 615px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media screen and (max-width: 1440px) {
                width: 800px;
                height: 550px;
            }
            @media screen and (max-width: 1200px) {
                width: 700px;
                height: 480px;
                top: 150px;
            }
            @media screen and (max-width: 768px)  {
                width: 550px;
                height: 410px;
                top: 110px;
            }
            @media screen and (max-width: 576px) {
                width: 400px;
                height: 300px;
                top: 60px;
            }
            @media screen and (max-width: 425px) {
                width: 320px;
                height: 200px;
                top: 85px;
            }
            @media screen and (max-width: 375px) {
                width: 300px;
                height: 190px;
                top: 75px;
            }
            &::after {
                content: '';
                position: absolute;
                background-image: url('../../../img/promotions/championship/play.png');
                background-size: 100%;
                width: 125px;
                height: 125px;
                @media screen and (max-width: 576px) {
                    width: 42px;
                    height: 42px;
                }
            }
        }
    }
    &__details {
        background-color: #fbce07;
        padding: 50px 0;
        position: relative;
        @media screen and (max-width: 992px) {
            padding: 25px 0;
        }
        &--bang {
            width: 389px;
            height: 389px;
            background-image: url('../../../img/promotions/championship/details-ball--bang.png');
            background-repeat: no-repeat;
            background-size: 100%;
            position: absolute;
            top: -32px;
            left: 150px;
            z-index: 12;
            transform: scale(0.01);
            transition: all .5s;
            &-animated {
                transform: scale(1);
            }
            @media screen and (max-width: 1680px) {
                width: 350px;
                height: 350px;
                left: 50px;
                top: 85px;
            }
            @media screen and (max-width: 1080px) {
                width: 300px;
                left: 40px;
                top: 105px;
            }
            @media screen and (max-width: 992px) {
                top: 180px;
                left: 55px;
            }
            @media screen and (max-width: 768px) {
                width: 220px;
                top: 270px;
                left: 40px;
            }
            @media screen and (max-width: 576px) {
                top: 155px;
                z-index: 0;
                left: 20px;
            }
            @media screen and (max-width: 425px) {
                width: 80px;
                top: 258px;
                left: 15px;
                width: 120px;
                height: 120px;
            }
        }
        &::before {
            content: '';
            top: -150px;
            z-index: 10;
            left: 0;
            width: 100%;
            height: 350px;
            background-image: url('../../../img/promotions/championship/details-top.png');
            background-size: 100% ;
            background-repeat: no-repeat;
            position: absolute;
            @media screen and (max-width: 640px) {
                top: -100px;
                width: 600px;
                height: 125px;
            }
            @media screen and (max-width: 425px) {
                top: -40px;
            }
        }
        &::after {
            content: '';
            position: absolute;
            top: -5%;
            z-index: 11;
            left: -70px;
            background-image: url('../../../img/promotions/championship/details-ball.png');
            background-size: 100%;
            background-repeat: no-repeat;
            height: 855px;
            width: 390px;
            @media screen and (max-width: 1680px) {
                left: -120px;
            }
            @media screen and (max-width: 1680px) {
                width: 320px;
                height: 750px;
                left: -106px;
                top: 1%;
            }
            @media screen and (max-width: 1080px)  {
                width: 280px;
                top: 2%;
            }
            @media screen and (max-width: 992px) {
                left: -90px;
                top: 5%;
            }
            @media screen and (max-width: 768px) {
                width: 220px;
                height: 500px;
                left: -75px;
                top: 8%;
            }
            @media screen and (max-width: 576px) {
                top: 5%;
            }
            @media screen and (max-width: 425px) {
                width: 120px;
                height: 270px;
                left: -40px;
                top: 13%;
            }
        }
        &-content {
            text-align: right;
        }
        &-arrow-right--red {
            position: absolute;
            top: 100px;
            left: 50%;
            transform: translateX(-5%) rotate(0);
            background-image: url('../../../img/promotions/championship/arrow-right--red.png');
            background-repeat: no-repeat;
            background-size: 100%;
            width: 215px;
            height: 154px;
            z-index: 10;
            animation: championshipArrowRight 2s ease-in-out infinite;
            @media screen and (max-width: 1200px) {
                width: 180px;
                height: 132px;
            }
            @media screen and (max-width: 992px) {
                width: 170px;
                height: 125px;
                transform: translateX(-5%) rotate(20deg);
            }
            @media screen and (max-width: 768px) {
                width: 120px;
                height: 90px;
                top: 150px;
            }
            @media screen and (max-width: 576px) {
                width: 100px;
                height: 75px;
                top: 120px;
            }
            @media screen and (max-width: 425px) {
                left: 23%;
                width: 80px;
                height: 60px;
                top: 10px;
            }
            @media screen and (max-width: 375px) {
                left: 20%;
            }
        }
        &-arrow-left--red {
            position: absolute;
            top: 230px;
            right: 300px;
            background-image: url('../../../img/promotions/championship/arrow-left--red.png');
            background-repeat: no-repeat;
            background-size: 100%;
            width: 215px;
            height: 154px;
            z-index: 10;
            animation: championshipArrowLeft 2s ease-in-out infinite;
            @media screen and (max-width: 1500px) {
                right: 50px;
            }
            @media screen and (max-width: 1400px) {
                right: 150px;
            }
            @media screen and (max-width: 1200px) {
                width: 180px;
                height: 132px;
            }
            @media screen and (max-width: 992px) {
                width: 175px;
                height: 125px;
                top: 110px;
                transform: translateX(0) translateY(0) rotate(-25deg);
                right: 100px;
            }
            @media screen and (max-width: 768px) {
                width: 120px;
                height: 90px;
                top: 120px;
                right: 70px;
            }
            @media screen and (max-width: 576px) {
                width: 100px;
                height: 75px;
                top: 40px;
                right: 75px;
            }
            @media screen and (max-width: 425px) {
                top: 40px;
                right: 105px;
                width: 60px;
                height: 45px;
            }
            @media screen and (max-width: 375px) {
                right: 37%;
                top: 5px;
                width: 80px;
                height: 60px;
            }
        }
        &-arrow-right--white  {
            position: absolute;
            top: 0px;
            left: -270px;
            background-image: url('../../../img/promotions/championship/arrow-right--white.png');
            background-repeat: no-repeat;
            background-size: 100%;
            width: 215px;
            height: 154px;
            transform: translateX(-5%) rotate(0);
            z-index: 10;
            animation: championshipArrowRight 2s ease-in-out infinite;
            @media screen and (max-width: 1200px) {
                width: 180px;
                height: 132px;
            }
            @media screen and (max-width: 992px) {
                width: 175px;
                height: 125px;
                left: -150px;
                top: -85px;
                animation: championshipArrowRightWhite 2s ease-in-out infinite;
            }
            @media screen and (max-width: 768px) {
                width: 120px;
                height: 90px;
                top: -100px;
                left: 0px;
            }
            @media screen and (max-width: 425px) {
                width: 70px;
                height: 50px;
                top: -50px;
                left: 10px;
            }
        }
        &-title {
            color: #dd1d21;
            font-size: 64px;
            font-weight: 700;
            text-transform: uppercase;
            padding-right: 100px;
            -webkit-text-stroke: 2px #fff;
            margin-bottom: 80px;
            z-index: 10;
            position: relative;
            span {
                font-size: 82px;
                text-transform: lowercase;
                color: #fbce07;
                span {
                    font-size: 48px;
                    text-transform: uppercase;
                }
            }
            @media screen and (max-width: 1750px) {
                padding-right: 50px;
            }
            @media screen and (max-width: 1600px) {
                padding-right: 0;
            }
            @media screen and (max-width: 1400px) {
                font-size: 54px;
            }
            @media screen and (max-width: 992px) {
                font-size: 50px;
                padding-right: 8px;
                span {
                    font-size: 74px;
                    span {
                        font-size: 44px;
                    }
                }
            }
            @media screen and (max-width: 768px) {
                font-size: 44px;
                -webkit-text-stroke: 1px #fff;
                &--black {
                    padding-left: 40px;
                }
            }
            @media screen and (max-width: 576px) {
                font-size: 32px;
                padding-right: 15px;
                span {
                    font-size: 44px;
                    span {
                        font-size: 28px;
                    }
                }
            }
            @media screen and (max-width: 375px) {
                font-size: 28px;
                padding-right: 20px;
                span {
                    font-size: 42px;
                    span {
                        font-size: 24px;
                    }
                }
            }
            @media screen and (max-width: 340px) {
                padding-right: 10px;
                font-size: 26px;
                span {
                    font-size: 40px;
                    span {
                        font-size: 22px;
                    }
                }
            }
            
            &::before {
                position: absolute;
                content: '';
                right: 87px;
                top: -210px;
                z-index: -1;
                width: 470px;
                height: 450px;
                background-size: 100%;
                background-image: url('../../../img/promotions/championship/price-bg.png');
                background-repeat: no-repeat;
                @media screen and (max-width: 1750px) {
                    right: 37px;
                }
                @media screen and (max-width: 1600px) {
                    right: -20px;
                }
                @media screen and (max-width: 992px) {
                    width: 420px;
                    right: 2px;
                    top: -185px;
                }
                @media screen and (max-width: 576px) {
                    width: 275px;
                    height: 270px;
                    right: 15px;
                    top: -130px;
                }
                @media screen and (max-width: 425px)  {
                    width: 245px;
                    height: 245px;
                    top: -110px;
                    &--black {
                        padding-left: 40px;
                    }
                }
                @media screen and (max-width: 375px)  {
                    width: 235px;
                    height: 235px;
                    top: -100px;
                    right: 12px;
                    &--black {
                        padding-left: 40px;
                    }
                }
            }
            &--black {
                color: #404040;
                text-align: left;
                padding-left: 210px;
                padding-right: 0;
                text-shadow: none;
                margin-bottom: 35px;
                -webkit-text-stroke: 0;
                &::before {
                    display: none;
                }
                @media screen and (max-width: 1400px) {
                    padding-left: 150px;
                }
                @media screen and (max-width: 1200px) {
                    padding-left: 80px;
                }
                
            }
        }
        &-subtitles {
            padding-right: 100px;
            z-index: 11;
            position: inherit;
            &-bag {
                position: relative;
                &::after {
                    position: absolute;
                    content: '';
                    width: 220px;
                    height: 300px;
                    bottom: -190px;
                    right: -210px;
                    background-image: url('../../../img/promotions/championship/bag.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    @media screen and (max-width: 1400px) {
                        right: -160px;
                        z-index: -1;
                    }
                    @media screen and (max-width: 992px) {
                        right: 0;
                        width: 180px;
                        bottom: auto;
                    }
                    @media screen and (max-width: 576px) {
                        width: 120px;
                        height: 180px;
                    }
                    @media screen and (max-width: 425px) {
                        width: 75px;
                        height: 120px;
                    }
                    @media screen and (max-width: 375px) {
                        width: 80px;
                        height: 120px;
                    }
                }
            }
            @media screen and (max-width: 1400px) {
                padding-right: 50px;
            }
            @media screen and (max-width: 992px) {
                padding-right: 30px;
            }
            @media screen and (max-width: 576px) {
                padding-right: 15px;
            }
        }
        &-subtitle {
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 12px;
            color: #404040;
            &--red {
                color: #dd1d21;
                text-transform: uppercase;
            }
            span {
                font-size: 60px;
            }
            @media screen and (max-width: 576px) {
                font-size: 22px;
                span {
                    font-size: 32px;
                }
            }
            @media screen and (max-width: 375px) {
                margin-bottom: 5px;
            }
        }
        &-ball {
            padding-right: 150px;
            img {
                width: 210px;
                height: 210px;
                @media screen and (max-width: 1400px) {
                    width: 150px;
                    height: 150px;
                }
            }
            @media screen and (max-width: 992px) {
                position: relative;
                img {
                    width: 130px;
                    height: 130px;
                    position: absolute;
                    top: -300px;
                    right: 260px;
                }
            }
            @media screen and (max-width: 576px) {
                img {
                    width: 100px;
                    height: 100px;
                    right: 180px;
                    top: -270px;
                }
            }
            @media screen and (max-width: 425px) {
                img {
                    width: 65px;
                    right: 145px;
                    height: 65px;
                }
            }
            @media screen and (max-width: 375px) {
                img {
                    width: 60px;
                    height: 60px;
                    right: 150px;
                }
            }
        }
        &-how-get {
            align-items: flex-end;
            margin-top: 80px;
            padding-bottom: 450px;
            position: relative;
            @media screen and (max-width: 576px) {
                padding-bottom: 350px;
            }
            &::before {
                content: '';
                position: absolute;
                left: 5px;
                top: -30px;
                width: 1100px;
                height: 710px;
                background-size: 100%;
                background-image: url('../../../img/promotions/championship/how-get.png');
                background-repeat: no-repeat;
                z-index: 10;
                @media screen and (max-width: 1400px) {
                    width: 900px;
                    height: 600px;
                    left: -50px;
                    top: -10px;
                }
                @media screen and (max-width: 992px) {
                    left: -80px;
                }
                @media screen and (max-width: 768px) {
                    width: 700px;
                    height: 450px;
                    top: 20px;
                }
                @media screen and (max-width: 576px) {
                    width: 120%;
                    height: 500px;
                }
                @media screen and (max-width: 425px) {
                    height: 280px;
                    width: 100%;
                    left: -30px;
                    top: 60px;
                }
            }
            @media screen and (max-width: 992px) {
                margin-top: 200px;
                padding-top: 100px;
            }
            @media screen and (max-width: 425px)  {
                margin-top: 85px;
                padding-bottom: 200px;
            }
        }
        &-refuel {
            padding-bottom: 300px;
            position: relative;
            @media screen and (max-width: 992px) {
                padding-left: 100px;
                padding-bottom: 480px;
            }
            @media screen and (max-width: 768px)  {
                padding-left: 50px;
            }
            @media screen and (max-width: 576px) {
                padding-bottom: 360px;
            }
            @media screen and (max-width: 375px) {
                padding-left: 0;
                margin-left: 0;
                margin-right: 0;
            }
            &--wrap {
                z-index: 10;
                @media screen and (max-width: 1600px) {
                    
                }
            }
            &-header {
                padding-right: 85px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 30px;
                position: relative;
                @media screen and (max-width: 1300px) {
                    padding-right: 25px;
                }
                @media screen and (max-width: 768px) {
                    flex-wrap: wrap;
                }
                @media screen and (max-width: 425px) {
                    padding-right: 30px;
                    padding-left: 30px;
                }
            }
            &-title {
                font-size: 48px;
                color: #404040;
                -webkit-text-stroke: 2px #fff;
                font-weight: 700;
                text-transform: uppercase;
                @media screen and (max-width: 1200px) {
                    font-size: 42px;
                }
                @media screen and (max-width: 1080px) {
                    font-size: 36px;
                }
                @media screen and (max-width: 768px) {
                    width: 100%;
                    flex: 0 0 100%;
                    text-align: left;
                    margin-bottom: 10px;
                    -webkit-text-stroke: 1px #fff;
                }
                @media screen and (max-width: 425px) {
                    font-size: 32px;
                }
                &--footer {
                    margin-bottom: 50px;
                    animation: championFooterTitle 1.2s ease-in-out infinite;
                    @media screen and (max-width: 1600px) {
                        font-size: 38px;
                    }
                    @media screen and (max-width: 1300px) {
                        font-size: 36px;
                    }
                    @media screen and (max-width: 768px) {
                        text-align: center;
                        -webkit-text-stroke: 1px #fff;
                    }
                    @media screen and (max-width: 576px) {
                        font-size: 26px;
                    }
                }
                
            }
            &-shell {
                font-size: 26px;
                color: #fff;
                font-weight: 700;
                text-align: center;
                display: grid;
                @media screen and (max-width: 1600px) {
                    font-size: 24px;
                    img {
                        width: 200px;
                    }
                }
                @media screen and (max-width: 1300px) {
                    font-size: 20px;
                }
                @media screen and (max-width: 1200px) {
                    img {
                        width: 180px;
                        font-size: 18px;
                    }
                }
                @media screen and (max-width: 576px) {
                    img {
                        width: 120px;
                    }
                    font-size: 12px;
                }
            }
            &-from {
                font-size: 36px;
                color: #fff;
                font-weight: 700;
                @media screen and (max-width: 1600px) {
                    font-size: 32px;
                }
                @media screen and (max-width: 1080px) {
                    font-size: 28px;
                }
                @media screen and (max-width: 576px) {
                    font-size: 18px;
                }
            }
            &-count {
                font-size: 104px;
                -webkit-text-stroke: 2px #fff;
                font-weight: 700;
                color: #404040;
                position: relative;
                span {
                    font-size: 60px;
                    text-transform: lowercase;
                }
                @media screen and (max-width: 1600px) {
                    font-size: 90px;
                    span {
                        font-size: 52px;
                    }
                }
                @media screen and (max-width: 1080px) {
                    font-size: 84px;
                    -webkit-text-stroke: 1px #fff;
                    span {
                        font-size: 48px;
                    }
                }
                @media screen and (max-width: 576px) {
                    font-size: 42px;
                    span {
                        font-size: 22px;
                    }
                }
                &::after {
                    position: absolute;
                    content: '';
                    top: -90px;
                    right: -75px;
                    width: 224px;
                    height: 330px;
                    transform: scale(0.01);
                    transition: all .2s;
                    background-image: url('../../../img/promotions/championship/refuel-bang.png');
                    background-repeat: no-repeat;
                    background-size: 100%;
                    @media screen and (max-width: 768px)  {
                        width: 150px;
                        height: 240px;
                        top: -55px;
                    }
                    @media screen and (max-width: 576px) {
                        width: 88px;
                        height: 130px;
                        top: -35px;
                        right: -40px;
                    }
                }
                &--animated {
                    &::after {
                        transform: scale(1);
                    }
                }
            }
            &-content {
                width: 100%;
                margin-bottom: 40px;
                padding-left: 20px;
                position: relative;
                @media screen and (max-width: 1400px) {
                    margin-left: -50px;
                }
                @media screen and (max-width: 992px) {
                    position: inherit;
                }
                @media screen and (max-width: 768px) {
                    margin-left: 25px;
                }
                @media screen and (max-width: 576px) {
                    margin-left: 0;
                }
                &::before {
                    content: '';
                    position: absolute;
                    right: -80px;
                    top: 50px;
                    background-image: url('../../../img/promotions/championship/refuel-ball.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    width: 400px;
                    height: 800px;
                    z-index: -1;
                    @media screen and (max-width: 1500px)  {
                        width: 320px;
                        top: 195px;
                    }
                    @media screen and (max-width: 1400px) {
                        right: -150px;
                    }
                    @media screen and (max-width: 992px) {
                        top: 650px;
                        width: 250px;
                        height: 500px;
                    }
                    @media screen and (max-width: 768px) {
                        right: -55px;
                        width: 200px;
                    }
                    @media screen and (max-width: 576px) {
                        top: 430px;
                        right: -35px;
                        width: 180px;
                    }
                }
            }
            &-item {
                display: flex;
                align-items: center;
                @media screen and (max-width: 576px) {
                    margin-bottom: 20px;
                }
                &--number {
                    font-size: 112px;
                    font-weight: 700;
                    color: #404040;
                    -webkit-text-stroke: 2px #fff;
                    width: 75px;
                    @media screen and (max-width: 1080px) {
                        font-size: 103px;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 93px;
                        -webkit-text-stroke: 1px #fff;
                    }
                    @media screen and (max-width: 576px) {
                        font-size: 42px;
                        width: 35px;
                    }
                }
                &--mark {
                    font-size: 55px;
                    color: #fff;
                    margin: 0 30px;
                    @media screen and (max-width: 768px) {
                        font-size: 48px;
                    }
                    @media screen and (max-width: 576px) {
                        font-size: 20px;
                        margin: 0 20px;
                    }
                }
                &--icon {
                    img {
                        width: 110px;
                    }
                    @media screen and (max-width: 1080px) {
                        img {
                            width: 95px;
                        }
                    }
                    @media screen and (max-width: 768px) {
                        img {
                            width: 80px;
                        }
                    }
                    @media screen and (max-width: 576px) {
                        img {
                            width: 42px;
                        }
                    }
                }
                &--cost {
                    color: #404040;
                    font-size: 74px;
                    font-weight: 700;
                    -webkit-text-stroke: 2px #fff;
                    span {
                        font-size: 34px;
                    }
                    @media screen and (max-width: 1080px) {
                        font-size: 64px;
                        span {
                            font-size: 28px;
                        }
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 58px;
                        -webkit-text-stroke: 1px #fff;
                        span {
                            font-size: 28px;
                        }
                    }
                    @media screen and (max-width: 576px) {
                        font-size: 30px;
                        span {
                            font-size: 15px;
                            -webkit-text-stroke: 0.5px #fff;
                        }
                    }
                }
            }
            &-footer {
                text-align: left;
                max-width: 900px;
                margin-left: -100px;
                position: relative;
                @media screen and (max-width: 1600px) {
                    max-width: 700px;
                    margin-left: -50px;
                }
                @media screen and (max-width: 1300px) {
                    max-width: 600px;
                    margin-left: 0px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 100%;
                    margin-left: auto;
                    margin-right: 50px;
                }
                @media screen and (max-width: 375px) {
                    margin-right: auto;
                }
            }
            &-btn {
                text-align: center;
                @media screen and (max-width: 375px) {
                    margin: 0 24px;
                }
            }
            &::after {
                content: '';
                position: absolute;
                top: -350px;
                background-image: url('../../../img/promotions/championship/refuel-bg.png');
                background-repeat: no-repeat;
                background-size: 100%;
                z-index: 1;
                right: 15px;
                width: 1520px;
                height: 1660px;
                @media screen and (max-width: 1800px) {
                    width: 1400px;
                    height: 1600px;
                }
                @media screen and (max-width: 1600px)  {
                    width: 1300px;
                }
                @media screen and (max-width: 1500px) {
                    top: -300px;
                }
                @media screen and (max-width: 1300px) {
                    width: 1100px;
                    height: 1260px;
                    top: -100px;
                }
                @media screen and (max-width: 1080px) {
                    top: -205px;
                    width: 1050px;
                }
                @media screen and (max-width: 992px) {
                    width: 1300px;
                    height: 1500px;
                    top: -280px;
                    right: -200px;
                }
                @media screen and (max-width: 576px) {
                    width: 1120px;
                    height: 1350px;
                    top: -220px;
                    right: -300px;
                }
                @media screen and (max-width: 480px) {
                    top: -285px;
                }
                @media screen and (max-width: 425px) {
                    width: 990px;
                }
                @media screen and (max-width: 375px) {
                    width: 952px;
                    top: -195px;
                    right: -305px;
                }
            }
        }
    }
    &__contacts {
        z-index: 10;
        position: relative;
        @media screen and (max-width: 768px) {
            padding-left: 15px;
            padding-right: 15px;
        }
        &::before {
            position: absolute;
            content: '';
            top: -253px;
            left: 0;
            z-index: -1;
            height: 254px;
            width: 1520px;
            background-image: url('../../../img/promotions/championship/contacts-top.png');
            background-size: 100%;
            background-repeat: no-repeat;
            @media screen and (max-width: 992px) {
                left: -230px;
            }
            @media screen and (max-width: 576px) {
                width: 200%;
                left: -120px;
                top: -160px;
            }
            @media screen and (max-width: 480px) {
                height: 400px;
                width: 400%;
                left: -100%;
            }
            @media screen and (max-width: 375px) {
                width: 300%;
                left: -80%;
                top: -175px;
            }
        }
        &::after {
            content: '';
            position: absolute;
            right: -70px;
            top: -50%;
            z-index: -1;
            background-image: url('../../../img/promotions/championship/bag-refuel.png');
            background-repeat: no-repeat;
            background-size: 100%;
            width: 300px;
            height: 435px;
            @media screen and (max-width: 1200px) {
                width: 250px;
                top: -60%;
            }
            @media screen and (max-width: 992px) {
                top: -30%;
                width: 180px;
                height: 300px;
                right: 0px;
            }
            @media screen and (max-width: 576px) {
                width: 110px;
                top: -20%;
                height: 250px;
            }
        }
        &-title {
            text-align: center;
            color: #404040;
            font-size: 48px;
            font-weight: 700;
            padding-top: 20px;
            padding-left: 40px;
            padding-right: 40px;
            padding-bottom: 130px;
            position: relative;
            span {
                display: block;
                padding-left: 4em;
                padding-right: 4em;
                color: #dd1d21;
            }
            @media screen and (max-width: 1440px) {
                padding-top: 50px;
                font-size: 42px;
                span {
                    padding-left: 2em;
                    padding-right: 2em;
                }
            }
            @media screen and (max-width: 1200px) {
                font-size: 36px;
            }
            @media screen and (max-width: 992px) {
                padding-top: 0;
                padding-left: 20px;
                padding-right: 20px;
            }
            @media screen and (max-width: 768px) {
                font-size: 32px;
            }
            @media screen and (max-width: 576px)  {
                font-size: 24px;
            }
            @media screen and (max-width: 375px) {
                font-size: 20px;
            }
            &::after {
                content: '';
                position: absolute;
                width: 500px;
                height: 18px;
                bottom: 55px;
                left: 50%;
                transform: translateX(-50%);
                background-image: url('../../../img/promotions/championship/line.png');
                background-repeat: no-repeat;
                background-size: 100%;
                @media screen and (max-width: 576px) {
                    width: 300px;
                    height: 10px;
                }
            }
        }
        &-subtitle {
            text-align: center;
            font-size: 36px;
            color: #404040;
            font-weight: 700;
            span {
                display: block;
                color: #dd1d21;
                span {
                    display: inline;
                    @media screen and (max-width: 425px) {
                        display: block;
                    }
                }
            }
            @media screen and (max-width: 768px) {
                font-size: 28px;
            }
            @media screen and (max-width: 576px)  {
                font-size: 20px;
            }
            @media screen and (max-width: 375px) {
                font-size: 18px;
            }
        }
        &-list {
            margin-top: 110px;
            margin-bottom: 40px;
            ul {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;
                padding: 0;
                list-style-type: none;
                @media screen and (max-width: 992px) {
                    justify-content: space-evenly;
                }
            }
            @media screen and (max-width: 576px) {
                margin-top: 70px;
            }
        }
        &-item {
            text-align: center;
            flex: 0 0 20%;
            max-width: 20%;
            padding: 0 30px;
            
            &:nth-child(1) {
                padding-left: 0;
                .championship__contacts-item-icon {
                    &::before {
                        top: -4px;
                        left: -2px;
                    }
                    &::after {
                        top: 1px;
                        left: -2px;
                    }
                }
            }
            &:nth-child(2) {
                .championship__contacts-item-icon {
                    &::before {
                        top: 1px;
                        left: 1px;
                    }
                    &::after {
                        top: -2px;
                        left: -1px;
                    }
                }
            }
            &:nth-child(3) {
                .championship__contacts-item-icon {
                    &::before {
                        top: -3px;
                        left: -1px;
                    }
                    &::after {
                        top: 2px;
                        left: -3px;
                    }
                }
            }
            &:nth-child(4) {
                .championship__contacts-item-icon {
                    &::before {
                        top: 0px;
                        left: -1px;
                    }
                    &::after {
                        top: -4px;
                        left: -2px;
                    }
                }
            }
            &:last-child {
                padding-right: 0;
                .championship__contacts-item-icon {
                    &::before {
                        top: 1px;
                        left: 2px;
                    }
                    &::after {
                        top: -1px;
                        left: -1px;
                    }
                }
            }
            &-icon {
                width: 135px;
                height: 135px;
                background-color: #ffd300;
                display: flex;
                align-items: center;
                margin: 0 auto;
                margin-bottom: 30px;
                justify-content: center;
                border-radius: 150%;
                position: relative;
                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    width: 140px;
                    height: 140px;
                    border: 1px solid #000;
                    border-radius: 150%;
                    top: 0;
                    z-index: -1;
                    left: 0;
                }
                @media screen and (max-width: 1440px) {
                    width: 120px;
                    height: 120px;
                    &::before,
                    &::after {
                        width: 125px;
                        height: 125px;
                    }
                }
                @media screen and (max-width: 1200px) {
                    width: 100px;
                    height: 100px;
                    &::before,
                    &::after {
                        width: 105px;
                        height: 105px;
                    }
                    img {
                        width: 42px;
                    }
                }
                @media screen and (max-width: 576px) {
                    width: 75px;
                    height: 75px;
                    &::before,
                    &::after {
                        width: 80px;
                        height: 80px;
                    }
                    img {
                        width: 32px;
                    }
                }
            }
            &-title {
                font-size: 26px;
                color: #404040;
                font-weight: 700;
                span {
                    color: #dd1d21;
                    &.block {
                        display: block;
                    }
                }
                @media screen and (max-width: 1680px) {
                    font-size: 24px;
                }
                @media screen and (max-width: 1680px) {
                    font-size: 22px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 20px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 18px;
                    span {
                        margin-top: 5px;
                    }
                }
                @media screen and (max-width: 992px) {
                    font-size: 20px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 18px;
                }
                @media screen and (max-width: 576px) {
                    font-size: 16px;
                }
                @media screen and (max-width: 375px) {
                    font-size: 14px;
                }
            }
            @media screen and (max-width: 1200px) {
                padding: 0 15px;
            }
            @media screen and (max-width: 992px) {
                max-width: 32%;
                flex: 0 0 32%;
                margin-bottom: 25px;
            }
            @media screen and (max-width: 768px) {
                max-width: 50%;
                flex: 0 0 50%;
            }
        }
    }
}