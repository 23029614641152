$fill: $bg-burger;
$burgerSize: 30px;

.burger {
    margin: 0;
    display: inline-block;
    width: $burgerSize * 1.2;
    height: $burgerSize;

    span, span:before, span:after {
        display: block;
        width: 100%;
        height: $burgerSize / 10;
        background: $fill;
        transition: all 0.5s;
        backface-visibility: hidden;
    }

    span {
        position: relative;
        margin: ($burgerSize * 2 / 5) 0 0;
    }

    span:before, span:after {
        position: absolute;
        content: "";
    }

    span:before {
        top: $burgerSize * -2 / 5;
    }

    span:after {
        top: $burgerSize * 2 / 5;
    }
}

.burger1.selected {
    span:before {
        transform: translate(0px, ($burgerSize * 2 / 5)) rotate(-45deg);
    }
    span:after {
        transform: translate(0px, -($burgerSize * 2 / 5)) rotate(45deg);
    }
    span {
        background: transparent;
    }
}

.burger2.selected {
    span:before {
        transform: translate(0px, ($burgerSize * 2 / 5)) rotate(-90deg);
    }
    span:after {
        transform: translate(0px, -($burgerSize * 2 / 5)) rotate(-90deg);
    }
    span {
        transform: rotate(45deg);
    }
}

.burger3.selected {
    span:before {
        transform: translate(0px, ($burgerSize * 2 / 5)) rotate(90deg);
    }
    span:after {
        transform: translate(0px, -($burgerSize * 2 / 5)) rotate(90deg);
    }
    span {
        transform: rotate(-45deg);
    }
}

.burger4:hover {
    span:before {
        transform: translate(0px, ($burgerSize * 2 / 5)) rotate(90deg);
    }
    span:after {
        transform: translate(0px, -($burgerSize * 2 / 5)) rotate(90deg);
    }
}

.burger4.selected {
    span:before {
        transform: translate(0px, ($burgerSize * 2 / 5)) rotate(90deg);
    }
    span:after {
        transform: translate(0px, -($burgerSize * 2 / 5)) rotate(90deg);
    }
    span {
        transform: rotate(-45deg);
    }
}

.burger5:hover {
    span {
        transform: scale(0.5);
    }
    span:before {
        transform: translate(($burgerSize * 1 / 5), 0px) rotate(45deg);
    }
    span:after {
        transform: translate(($burgerSize * 1 / 5), 0px) rotate(-45deg);
    }
}

.burger5.selected {
    span:before {
        transform: translate(0px, ($burgerSize * 2 / 5)) rotate(90deg);
    }
    span:after {
        transform: translate(0px, -($burgerSize * 2 / 5)) rotate(90deg);
    }
    span {
        transform: rotate(-45deg);
    }

    &:hover {
        span {
            transform: scale(0.5);
        }
        span:before {
            transform: translate(-($burgerSize * 1 / 5), 0px) rotate(-45deg);
        }
        span:after {
            transform: translate(-($burgerSize * 1 / 5), 0px) rotate(45deg);
        }
    }
}




.slick-panorama {
    > div {
        img, svg {
            height: 300px;
            width: auto;
        }
    }
}