.ferrari-promo {
    position: relative;
    overflow: hidden;
    max-width: 100vw;
    .container-fluid {
        @media screen and (max-width: 768px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__main {
        margin-bottom: 50px;
        @media screen and (max-width: 576px) {
            margin-bottom: 24px;
        }
        &-wrap {
            position: relative;
            background: url("../../../img/promotions/ferrari-promo/main-bg.jpg"),
                $shell-red;
            background-repeat: no-repeat;
            background-size: cover;
            padding-top: 120px;
            padding-bottom: 32px;
            @media screen and (max-width: 576px) {
                padding-top: 100px;
                padding-bottom: 32px;
                padding-left: 0;
                padding-right: 0;
            }
        }
        &-titles {
            @media screen and (max-width: 576px) {
                padding-left: 24px;
                padding-right: 24px;
            }
            h1 {
                color: $white;
                text-align: center;
                font-size: 60px;
                text-transform: uppercase;
                font-weight: bold;
                @media screen and (max-width: 1440px) {
                    font-size: 48px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 42px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 30px;
                }
                @media screen and (max-width: 576px) {
                    font-size: 24px;
                }
            }
            h2 {
                font-weight: bold;
                font-size: 36px;
                color: $shell-yellow;
                text-transform: uppercase;
                text-align: center;
                @media screen and (max-width: 1440px) {
                    font-size: 32px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 30px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 24px;
                }
                @media screen and (max-width: 576px) {
                    font-size: 16px;
                }
            }
        }
        &-cars {
            max-width: 1280px;
            margin: 80px auto 100px;
            padding-bottom: 0px;
            transition: all 1s;
            @media screen and (max-width: 1680px) {
                padding: 0 24px;
            }
            @media screen and (max-width: 576px) {
                margin: 40px auto 80px;
                padding: 0 4px;
            }
            img {
                width: 100%;
                filter: drop-shadow(-4px 3px 4px rgba(#000, 0.75));
            }
            &--desktop {
                display: block;
                @media screen and (max-width: 576px) {
                    display: none;
                }
            }
            &--mobile {
                display: none;
                @media screen and (max-width: 576px) {
                    display: block;
                }
            }
        }
        &-v-power {
            position: absolute;
            left: 50px;
            top: 40px;
            max-width: 165px;
            @media screen and (max-width: 1440px) {
                max-width: 140px;
            }
            @media screen and (max-width: 576px) {
                max-width: 100px;
                left: 16px;
                top: 24px;
            }
            img {
                width: 100%;
            }
        }
        &-logo {
            max-width: 150px;
            position: absolute;
            width: 100%;
            top: 45px;
            right: 35px;
            @media screen and (max-width: 576px) {
                top: 24px;
                max-width: 100px;
                right: 24px;
            }
            img {
                width: 100%;
            }
        }
        .go-well {
            text-align: right;
            @media screen and (max-width: 1600px) {
                img {
                    max-width: 35%;
                }
            }
            @media screen and (max-width: 576px) {
                img {
                    max-width: 70%;
                }
            }
        }
        &-bluetooth {
            bottom: 32px;
            left: 70px;
            position: absolute;
            @media screen and (max-width: 576px) {
                left: 16px;
                bottom: 110px;
                img {
                    max-width: 150px;
                }
            }
            @media screen and (max-width: 576px) {
                bottom: 90px;
            }
        }
        &-text-small {
            position: absolute;
            left: 360px;
            width: 210px;
            font-size: 7px;
            color: #fff;
            text-align: justify;
            bottom: 130px;
            @media screen and (max-width: 1440px) {
                left: 330px;
            }
            @media screen and (max-width: 1280px) {
                bottom: 100px;
                left: 300px;
            }
            @media screen and (max-width: 768px) {
                bottom: 80px;
                left: 200px;
            }
            @media screen and (max-width: 576px) {
                bottom: 75px;
            }
            @media screen and (max-width: 425px) {
                left: 170px;
            }
            @media screen and (max-width: 390px) {
                left: 155px;
                width: 180px;
            }
        }
    }

    &__condition {
        padding-bottom: 70px;
        position: relative;
        @media screen and (max-width: 576px) {
            padding-bottom: 90px;
        }
        &::after {
            content: "";
            position: absolute;
            width: 100vw;
            height: 90px;
            z-index: -1;
            background-color: #efefef;
            bottom: -2px;
            left: 0;
        }
        &--before {
            position: absolute;
            z-index: 1;
            top: -15px;
            left: 15px;
            max-width: 224px;
            img {
                width: 100%;
            }
            @media screen and (max-width: 576px) {
                max-width: 75px;
            }
        }
        &-image {
            &--desktop {
                @media screen and (max-width: 576px) {
                    display: none;
                }
            }
            &--mobile {
                display: none;
                @media screen and (max-width: 576px) {
                    display: block;
                }
            }
        }
        &-wrap {
            position: relative;
        }
        &-title {
            padding-left: 60px;
            font-size: 44px;
            color: #fff;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 12px;
            span {
                font-size: 28px;
                @media screen and (max-width: 1600px) {
                    font-size: 24px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 21px;
                }
                @media screen and (max-width: 1200px) {
                    font-size: 14px;
                }
            }
            @media screen and (max-width: 1600px) {
                font-size: 36px;
            }
            @media screen and (max-width: 1440px) {
                font-size: 32px;
            }
            @media screen and (max-width: 1200px) {
                font-size: 27px;
                padding-left: 32px;
            }
            @media screen and (max-width: 992px) {
                font-size: 21px;
                padding-left: 16px;
            }
            @media screen and (max-width: 576px) {
                padding-left: 0;
            }
        }
        &-subtitle {
            font-size: 36px;
            font-weight: bold;
            text-transform: uppercase;
            color: #facd07;
            @media screen and (max-width: 1600px) {
                font-size: 30px;
            }
            @media screen and (max-width: 1440px) {
                font-size: 27px;
            }
            @media screen and (max-width: 1200px) {
                font-size: 24px;
            }
            @media screen and (max-width: 1200px) {
                font-size: 16px;
            }
        }
        &-content {
            width: 100%;
            padding: 60px 250px 50px 120px;
            background: url("../../../img/promotions/ferrari-promo/condition/bg.png");
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            @media screen and (max-width: 992px) {
                padding: 40px 100px 40px 60px;
            }
            @media screen and (max-width: 576px) {
                padding: 16px 32% 16px 0;
                background-size: auto 100%;
                background: none;
                background-position: -10vw center;
                &::before {
                    content: "";
                    position: absolute;
                    height: 150px;
                    width: calc(125vw);
                    background: url("../../../img/promotions/ferrari-promo/condition/bg--mobile.png");
                    background-repeat: no-repeat;
                    background-size: 100%;
                    background-position: center;
                    z-index: -1;
                    top: 0;
                    left: -100px;
                }
            }
            @media screen and (max-width: 375px) {
                padding: 20px 24% 16px 0;
            }
        }
        &-shell {
            position: absolute;
            bottom: -70px;
            right: 150px;
            @media screen and (max-width: 1680px) {
                right: 90px;
            }
            @media screen and (max-width: 576px) {
                right: 0;
                bottom: -90px;
            }
            .parallelogram {
                background-color: #facd07;
                padding: 35px 40px;
                transform: skewX(-45deg);
                @media screen and (max-width: 1680px) {
                    padding: 24px 32px;
                }
                @media screen and (max-width: 992px) {
                    padding: 20px;
                    transform: skewX(-30deg);
                }
                &__content {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    @media screen and (max-width: 992px) {
                        display: block;
                    }
                }
                .text {
                    color: $shell-red;
                    font-size: 30px;
                    text-transform: uppercase;
                    font-weight: bold;
                    transform: skewX(45deg);

                    line-height: 80%;
                    @media screen and (max-width: 1600px) {
                        font-size: 27px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 24px;
                    }
                    @media screen and (max-width: 992px) {
                        font-size: 16px;
                        margin-bottom: 12px;
                        transform: skewX(30deg);
                    }
                }
                .logo {
                    margin-left: 12px;
                    max-width: 215px;
                    transform: skewX(45deg);
                    @media screen and (max-width: 1600px) {
                        max-width: 180px;
                    }
                    @media screen and (max-width: 1200px) {
                        max-width: 160px;
                    }
                    @media screen and (max-width: 992px) {
                        max-width: 120px;
                        transform: skewX(30deg);
                    }
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__cars {
        background: #efefef;
        position: relative;
        @media screen and (max-width: 576px) {
            padding-top: 60px;
        }
        &--before {
            position: absolute;
            top: 0px;
            right: 10%;
            max-width: 224px;
            img {
                width: 100%;
            }
            @media screen and (max-width: 576px) {
                right: -12px;
            }
        }
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            background: url("../../../img/promotions/ferrari-promo/cars-corner.png");
            background-repeat: no-repeat;
            background-size: 100%;
            width: 155px;
            height: 140px;
            @media screen and (max-width: 1440px) {
                right: -24px;
            }
            @media screen and (max-width: 992px) {
                display: none;
            }
        }
        &-image {
            &--desktop {
                @media screen and (max-width: 576px) {
                    display: none;
                }
            }
            &--mobile {
                display: none;
                @media screen and (max-width: 576px) {
                    display: block;
                }
            }
        }
        &-item {
            display: flex;
            width: 100%;
            min-height: 224px;
            @media screen and (max-width: 576px) {
                min-height: auto;
            }
            .block {
                width: 75px;
                height: 12px;
                background-color: #000;
                margin-top: 8px;
                margin-bottom: 4px;
                transform: skewX(-45deg);
                @media screen and (max-width: 1440px) {
                    width: 70px;
                    margin-top: 11px;
                }
                @media screen and (max-width: 1280px) {
                    margin-top: 16px;
                }
            }
            .image {
                z-index: 10;
                @media screen and (max-width: 576px) {
                    position: absolute;
                }
            }
            .image,
            .content {
                max-width: 50%;
                flex: 0 0 50%;
                @media screen and (max-width: 576px) {
                    max-width: 60%;
                    flex: 0 0 60%;
                }
                img {
                    position: relative;
                    z-index: 1;
                    filter: drop-shadow(-4px 3px 4px rgba(#000, 0.65));
                }
                .title {
                    font-size: 24px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: $shell-red;
                    @media screen and (max-width: 1600px) {
                        font-size: 21px;
                    }
                    @media screen and (max-width: 1280px) {
                        font-size: 18px;
                    }
                    @media screen and (max-width: 576px) {
                        margin-bottom: 8px;
                        font-size: 16px;
                    }
                }

                .text {
                    font-size: 16px;
                    font-weight: 300;
                    @media screen and (max-width: 576px) {
                        font-size: 14px;
                    }
                }
            }
            &--f1 {
                @media screen and (max-width: 576px) {
                    justify-content: flex-end;
                }
                .image {
                    position: relative;
                    max-width: 45%;
                    flex: 0 0 45%;
                    @media screen and (max-width: 1600px) {
                        img {
                            width: 100%;
                        }
                    }
                    @media screen and (max-width: 576px) {
                        max-width: 40%;
                        flex: 0 0 40%;
                        position: relative;
                        img {
                            width: 260px;
                            position: absolute;
                            left: 24px;
                            top: -52px;
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        bottom: -20px;
                        right: 80px;
                        height: 160px;
                        background: #facd07;
                        transform: skewX(-45deg);
                        width: 100vw;
                        @media screen and (max-width: 576px) {
                            height: 100%;
                            bottom: 0;
                            right: 0;
                            transform: skewX(-30deg);
                        }
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 300px;
                        height: 1px;
                        background-color: #000;
                        top: 164px;
                        right: 79px;
                        @media screen and (max-width: 576px) {
                            content: none;
                        }
                    }
                }
                .block {
                    margin-left: auto;
                    margin-right: 0;
                    @media screen and (max-width: 576px) {
                        display: none;
                    }
                }
                .content {
                    max-width: none;
                    flex: 0 0 auto;
                    padding-top: 45px;
                    position: relative;
                    @media screen and (max-width: 576px) {
                        max-width: 60%;
                        flex: 0 0 60%;
                        padding-top: 24px;
                        z-index: 11;
                        padding-bottom: 24px;
                        padding-left: 16px;
                        border-bottom: 1px solid #000;
                        border-left: 1px solid #000;
                    }
                    .line {
                        position: absolute;
                        width: calc(100% - 74px);
                        display: block;
                        height: 1px;
                        background-color: #000;
                        top: 84px;
                        left: -1px;
                        @media screen and (max-width: 576px) {
                            display: none;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            left: 0;
                            height: 80px;
                            width: 1px;
                            background-color: #000;
                            left: -39px;
                            top: 0;
                            transform: skewX(-45deg);
                            @media screen and (max-width: 576px) {
                                content: none;
                            }
                        }
                    }
                }
            }
            &--330 {
                justify-content: flex-end;
                margin-top: -40px;
                @media screen and (max-width: 1200px) {
                    justify-content: space-between;
                }
                @media screen and (max-width: 576px) {
                    justify-content: flex-end;
                    margin-top: 0;
                }
                .image {
                    position: relative;
                    max-width: 45%;
                    flex: 0 0 45%;
                    @media screen and (max-width: 768px) {
                        img {
                            right: 160px;
                        }
                    }
                    @media screen and (max-width: 576px) {
                        max-width: 35%;
                        flex: 0 0 35%;
                        order: 2;
                        position: relative;
                        img {
                            width: 230px;
                            position: absolute;
                            right: 32px;
                            top: 12px;
                            z-index: 11;
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 26px;
                        right: 70px;
                        height: 140px;
                        background: #dbdbdb;
                        transform: skewX(-45deg);
                        width: 640px;
                        @media screen and (max-width: 576px) {
                            bottom: 0;
                            left: -200px;
                            transform: skewX(-30deg);
                            height: 100%;
                            z-index: -1;
                        }
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 100px;
                        height: 1px;
                        background-color: #000;
                        top: 138px;
                        right: 79px;
                        @media screen and (max-width: 1200px) {
                            width: 60px;
                            right: -105px;
                        }
                        @media screen and (max-width: 1100px) {
                            display: none;
                        }
                        @media screen and (max-width: 576px) {
                            content: none;
                        }
                    }
                    img {
                        margin-bottom: 24px;
                    }
                }
                .block {
                    margin-left: auto;
                    margin-right: 0;
                    @media screen and (max-width: 576px) {
                        display: none;
                    }
                }
                .content {
                    max-width: none;
                    flex: 0 0 auto;
                    padding-top: 19px;
                    position: relative;
                    @media screen and (max-width: 576px) {
                        max-width: 60%;
                        flex: 0 0 60%;
                        margin-top: 24px;
                        z-index: 10;
                        padding-bottom: 24px;
                        margin-left: 24px;
                        padding-right: 16px;
                        border-bottom: 1px solid #000;
                        order: 1;
                        &::after {
                            content: "";
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            height: 88px;
                            width: 1px;
                            background-color: #000;
                        }
                    }
                    .line {
                        position: absolute;
                        width: calc(100% - 74px);
                        display: block;
                        height: 1px;
                        background-color: #000;
                        top: 58px;
                        left: -1px;
                        @media screen and (max-width: 576px) {
                            display: none;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            left: 0;
                            height: 80px;
                            width: 1px;
                            background-color: #000;
                            left: -39px;
                            top: 0;
                            transform: skewX(-45deg);
                            @media screen and (max-width: 576px) {
                                content: none;
                            }
                        }
                    }
                }
            }
            &--daytona {
                justify-content: flex-end;
                margin-top: -10px;
                @media screen and (max-width: 576px) {
                    margin-top: 0;
                    padding-top: 75px;
                }
                .image {
                    position: relative;
                    max-width: 45%;
                    flex: 0 0 45%;
                    text-align: right;
                    @media screen and (max-width: 576px) {
                        max-width: 40%;
                        flex: 0 0 40%;
                        position: relative;
                        order: 1;
                        img {
                            width: 260px;
                            position: absolute;
                            left: 24px;
                            top: -52px;
                            @media screen and (max-width: 576px) {
                                width: 180px;
                                left: 72px;
                            }
                        }
                    }
                    img {
                        margin-left: auto;
                        margin-right: 0;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 7px;
                        left: 80px;
                        height: 160px;
                        background: #facd07;
                        transform: skewX(45deg);
                        width: 100vw;
                        @media screen and (max-width: 576px) {
                            transform: skewX(-30deg);
                            height: 100%;
                            bottom: 0;
                            left: auto;
                            right: 8px;
                        }
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 300px;
                        height: 1px;
                        background-color: #000;
                        top: 137px;
                        left: 79px;
                        @media screen and (max-width: 1440px) {
                            width: 180px;
                        }
                        @media screen and (max-width: 576px) {
                            content: none;
                        }
                    }
                }
                .block {
                    margin-left: 0;
                    margin-right: auto;
                    transform: skewX(45deg);
                    @media screen and (max-width: 576px) {
                        display: none;
                    }
                }
                .content {
                    max-width: none;
                    flex: 0 0 auto;
                    padding-top: 19px;
                    position: relative;
                    z-index: 10;
                    @media screen and (max-width: 576px) {
                        max-width: 60%;
                        flex: 0 0 60%;
                        padding-top: 5px;
                        z-index: 11;
                        padding-bottom: 24px;
                        padding-left: 24px;
                        border-bottom: 1px solid #000;
                        border-left: 1px solid #000;
                        order: 2;
                    }
                    .line {
                        position: absolute;
                        width: calc(100% - 80px);
                        display: block;
                        height: 1px;
                        background-color: #000;
                        top: 58px;
                        right: -1px;
                        @media screen and (max-width: 576px) {
                            display: none;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            height: 80px;
                            width: 1px;
                            background-color: #000;
                            right: -39px;
                            top: 0;
                            transform: skewX(45deg);
                            @media screen and (max-width: 576px) {
                                content: none;
                            }
                        }
                    }
                }
            }
            &--gtb {
                margin-top: -65px;
                min-height: 198px;
                @media screen and (max-width: 576px) {
                    margin-top: 0;
                    min-height: auto;
                }
                .image {
                    position: relative;
                    max-width: 45%;
                    flex: 0 0 45%;
                    @media screen and (max-width: 576px) {
                        max-width: 40%;
                        flex: 0 0 40%;
                        position: relative;
                        img {
                            width: 260px;
                            position: absolute;
                            left: 24px;
                            top: -52px;
                            @media screen and (max-width: 576px) {
                                width: 220px;
                                left: -24px;
                                top: 10px;
                            }
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        right: 69px;
                        height: 140px;
                        background: #dbdbdb;
                        transform: skewX(45deg);
                        width: 640px;
                        @media screen and (max-width: 1680px) {
                            width: 617px;
                        }
                        @media screen and (max-width: 1600px) {
                            width: 99%;
                        }
                        @media screen and (max-width: 576px) {
                            transform: skewX(-30deg);
                            height: 100%;
                            left: -120%;
                            width: 100vw;
                        }
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 100px;
                        height: 1px;
                        background-color: #000;
                        top: 138px;
                        left: -51px;
                        @media screen and (max-width: 576px) {
                            content: none;
                        }
                    }
                }
                .block {
                    margin-left: 0;
                    margin-right: auto;
                    transform: skewX(45deg);
                    @media screen and (max-width: 576px) {
                        display: none;
                    }
                }
                .content {
                    max-width: none;
                    flex: 0 0 auto;
                    padding-top: 19px;
                    position: relative;
                    z-index: 11;
                    margin-right: 130px;
                    @media screen and (max-width: 1680px) {
                        flex: 0 0 35%;
                        max-width: 35%;
                    }
                    @media screen and (max-width: 576px) {
                        max-width: 60%;
                        flex: 0 0 60%;
                        padding-top: 32px;
                        z-index: 11;
                        padding-bottom: 24px;
                        padding-left: 24px;
                        margin-right: 0;
                    }
                    .line {
                        position: absolute;
                        width: calc(100% - 80px);
                        display: block;
                        height: 1px;
                        background-color: #000;
                        top: 59px;
                        right: -1px;
                        @media screen and (max-width: 576px) {
                            display: none;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            height: 80px;
                            width: 1px;
                            background-color: #000;
                            right: -39px;
                            top: 0;
                            transform: skewX(45deg);
                            @media screen and (max-width: 576px) {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &__new {
        padding: 70px 10%;
        background: linear-gradient(to right, #bc1214, #930405);
        position: relative;
        overflow-y: none;
        @media screen and (max-width: 1200px) {
            padding: 50px 10%;
        }
        @media screen and (max-width: 576px) {
            padding: 50px 15px;
        }
        &-lines {
            position: absolute;
            &--left,
            &--right {
                left: -50px;
                top: -2px;
                height: calc(100% + 4px);
                img {
                    height: 100%;
                    width: auto;
                }
                @media screen and (max-width: 1680px) {
                    left: -100px;
                }
                @media screen and (max-width: 1600px) {
                    left: -150px;
                }
                @media screen and (max-width: 992px) {
                    display: none;
                }
            }
            &--right {
                left: auto;
                right: -50px;
                @media screen and (max-width: 1680px) {
                    right: -100px;
                }
                @media screen and (max-width: 1600px) {
                    right: -150px;
                }
            }
        }
        &-wrap {
            .title {
                text-transform: uppercase;
                text-align: center;
                font-size: 60px;
                font-weight: bold;
                color: $white;
                @media screen and (max-width: 1600px) {
                    font-size: 48px;
                }
                @media screen and (max-width: 1200px) {
                    font-size: 42px;
                }
                @media screen and (max-width: 576px) {
                    font-size: 36px;
                }
                @media screen and (max-width: 391px) {
                    font-size: 32px;
                }
                span {
                    font-size: 48px;
                    display: block;
                    margin-bottom: 16px;
                    @media screen and (max-width: 1600px) {
                        font-size: 32px;
                    }
                    @media screen and (max-width: 576px) {
                        font-size: 27px;
                    }
                    @media screen and (max-width: 391px) {
                        font-size: 24px;
                    }
                }
            }
        }
    }

    &__terms {
        padding: 50px 0;
        background-color: #efefef;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            background: url("../../../img/promotions/ferrari-promo/terms-corner.png");
            width: 238px;
            height: 268px;
            background-size: 100%;
            background-repeat: no-repeat;
            @media screen and (max-width: 1440px) {
                left: -42px;
            }
            @media screen and (max-width: 1200px) {
                left: -64px;
            }
            @media screen and (max-width: 992px) {
                display: none;
            }
        }
        &-wrap {
            .title {
                font-size: 42px;
                font-weight: bold;
                text-transform: uppercase;
                color: #38383c;
                @media screen and (max-width: 992px) {
                    font-size: 20px;
                }
                .small {
                    font-weight: bold;
                    font-size: 34px;
                    @media screen and (max-width: 992px) {
                        font-size: 16px;
                    }
                }
                .red {
                    text-transform: none;
                    font-size: 50px;
                    color: $shell-red;
                    @media screen and (max-width: 992px) {
                        font-size: 24px;
                    }
                }
            }
        }
        &-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 50px;
            position: relative;
            @media screen and (max-width: 992px) {
                align-items: flex-start;
            }
            @media screen and (max-width: 768px) {
                align-items: center;
                margin-top: 32px;
            }
            @media screen and (max-width: 576px) {
                align-items: flex-start;
            }
            .icon {
                position: relative;
                padding-right: 50px;
                @media screen and (max-width: 1200px) {
                    padding-right: 24px;
                }
                @media screen and (max-width: 576px) {
                    &--fuel {
                        position: absolute;
                        top: 24px;
                        left: 0;
                    }
                }
                img {
                    z-index: 10;
                    position: relative;
                    @media screen and (max-width: 1440px) {
                        max-width: 125px;
                    }
                    @media screen and (max-width: 1200px) {
                        max-width: 100px;
                    }
                    @media screen and (max-width: 992px) {
                        height: 70px;
                        max-width: none;
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    height: 150px;
                    width: 700px;
                    right: -125px;
                    top: -8px;
                    background-position: right center;
                    background-image: url("../../../img/promotions/ferrari-promo/fuels-line.png");
                    @media screen and (max-width: 992px) {
                        height: 90px;
                        right: -85px;
                    }
                    @media screen and (max-width: 768px) {
                        right: -60px;
                        background-image: url("../../../img/promotions/ferrari-promo/fuels-line--mob.png");
                    }
                }
            }
            .content {
                font-size: 42px;
                font-weight: bold;
                text-transform: uppercase;
                color: #38383c;
                margin-left: 160px;
                @media screen and (max-width: 1680px) {
                    margin-left: 140px;
                    font-size: 36px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 32px;
                }
                @media screen and (max-width: 992px) {
                    margin-left: 85px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 24px;
                }
                @media screen and (max-width: 576px) {
                    &--fuel {
                        margin-left: 0;
                        padding-right: 16px;
                    }
                }
                .small {
                    font-weight: bold;
                    font-size: 34px;
                    @media screen and (max-width: 1680px) {
                        font-size: 30px;
                    }
                    @media screen and (max-width: 1440px) {
                        font-size: 27px;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                    }
                }
                .red {
                    text-transform: none;
                    font-size: 50px;
                    color: $shell-red;
                    @media screen and (max-width: 1680px) {
                        font-size: 42px;
                    }
                    @media screen and (max-width: 1440px) {
                        font-size: 32px;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 24px;
                    }
                }
                .image {
                    height: 150px;
                    border-right: 2px solid #9c9c9e;
                    padding: 0 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: 1680px) {
                        padding: 0 24px;
                    }
                    @media screen and (max-width: 1600px) {
                        img {
                            max-width: 125px;
                        }
                    }
                    @media screen and (max-width: 1440px) {
                        img {
                            max-width: 110px;
                        }
                    }
                    @media screen and (max-width: 1200px) {
                        img {
                            max-width: 100px;
                        }
                    }
                    @media screen and (max-width: 992px) {
                        height: auto;
                        padding: 10px 20px;
                    }
                    @media screen and (max-width: 768px) {
                        margin: 12px 0;
                    }
                    @media screen and (max-width: 576px) {
                        flex: 0 0 50%;
                        max-width: 50%;
                        border-left: 2px solid #9c9c9e;
                        height: 100px;
                        &:first-of-type {
                            margin-left: 50%;
                            border-left: none;
                        }
                        &:nth-child(3) {
                            border-left: none;
                        }
                        &:last-of-type {
                            border-left: none;
                        }
                    }
                }
            }
            &--shops {
                @media screen and (max-width: 768px) {
                    align-items: center;
                }
                .icon {
                    padding-left: 80px;
                    @media screen and (max-width: 992px) {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    &__promotions {
        padding: 90px 0 50px 0;
        background: url("../../../img/promotions/ferrari-promo/table-bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        @media screen and (max-width: 1440px) {
            padding: 70px 0 50px 0;
        }
        @media screen and (max-width: 1200px) {
            padding: 50px 0 30px 0;
        }
        &-large {
            text-align: center;
            font-size: 60px;
            font-weight: bold;
            text-transform: uppercase;
            color: $white;
            margin-bottom: 80px;
            @media screen and (max-width: 1680px) {
                font-size: 52px;
            }
            @media screen and (max-width: 1440px) {
                font-size: 44px;
                margin-bottom: 60px;
            }
            @media screen and (max-width: 1200px) {
                margin-bottom: 40px;
            }
            @media screen and (max-width: 992px) {
                font-size: 30px;
            }
        }
        &-title {
            margin-bottom: 24px;
            color: $white;
            font-size: 18px;
            font-weight: bold;
            @media screen and (max-width: 992px) {
                font-size: 16px;
            }
        }
        &-table-wrap {
            margin-bottom: 24px;
            @media screen and (max-width: 768px) {
                overflow-x: auto;
                width: 100%;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                thead {
                    tr {
                        td {
                            background-color: #ed1d24;
                            color: #ffffff;
                            text-align: center;
                            padding: 16px 4px;
                            font-weight: 600;
                            font-size: 18px;
                            border: 2px solid $white;
                            @media screen and (max-width: 1440px) {
                                font-size: 16px;
                            }
                            @media screen and (max-width: 1280px) {
                                font-size: 14px;
                            }
                            @media screen and (max-width: 992px) {
                                font-size: 12px;
                            }
                            @media screen and (max-width: 768px) {
                                min-width: 160px;
                            }
                            &:nth-child(1) {
                                font-size: 20px;
                                @media screen and (max-width: 1440px) {
                                    font-size: 18px;
                                }
                                @media screen and (max-width: 1280px) {
                                    font-size: 16px;
                                }
                            }
                            &:nth-child(5) {
                                border-right: 1px solid #fff;
                            }
                            .large {
                                font-size: 32px;
                                margin-right: 4px;
                                @media screen and (max-width: 1440px) {
                                    font-size: 24px;
                                }
                                @media screen and (max-width: 1280px) {
                                    font-size: 20px;
                                }
                                @media screen and (max-width: 992px) {
                                    font-size: 18px;
                                }
                            }
                            div {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            position: relative;
                            color: #fff;
                            padding: 6px 4px;
                            text-align: center;
                            font-size: 18px;
                            border: 2px solid $white;
                            @media screen and (max-width: 1280px) {
                                font-size: 14px;
                            }
                            @media screen and (max-width: 992px) {
                                font-size: 12px;
                            }
                            .medium {
                                font-size: 24px;
                                font-weight: 600;
                                @media screen and (max-width: 1440px) {
                                    font-size: 18px;
                                }
                                @media screen and (max-width: 1280px) {
                                    font-size: 16px;
                                }
                                @media screen and (max-width: 992px) {
                                    font-size: 14px;
                                }
                            }
                            .large {
                                display: block;
                                font-size: 32px;
                                font-weight: bold;
                                @media screen and (max-width: 1440px) {
                                    font-size: 24px;
                                }
                                @media screen and (max-width: 1280px) {
                                    font-size: 20px;
                                }
                                @media screen and (max-width: 992px) {
                                    font-size: 18px;
                                }
                            }
                            &.image {
                                animation: gasStationSurprise 1.2s ease-in-out
                                    infinite;
                                transition: all 2.5s;
                                img {
                                    position: absolute;
                                    top: 0;
                                    width: calc(100% + 24px);
                                    height: 100%;
                                    left: -24px;
                                    @media screen and (max-width: 992px) {
                                        height: 60px;
                                    }
                                    @media screen and (max-width: 768px) {
                                        height: 120px;
                                        left: auto;
                                        right: -150px;
                                    }
                                }
                            }
                        }
                        &:nth-child(1) {
                            td {
                                color: $shell-yellow;
                            }
                        }
                    }
                }
            }
        }
        &-cars {
            position: relative;
            z-index: 10;
            width: 100%;
            img {
                width: 100%;
            }
            &--desktop {
                display: block;
                @media screen and (max-width: 576px) {
                    display: none;
                }
            }
            &--mobile {
                display: none;
                @media screen and (max-width: 576px) {
                    display: block;
                }
            }
        }
        &-text {
            margin-top: 24px;
            font-size: 14px;
            color: #fff;
            @media screen and (max-width: 576px) {
                font-size: 12px;
            }
        }
    }

    &__video {
        height: 800px;
        position: relative;
        @media screen and (max-width: 1440px) {
            height: 750px;
        }
        @media screen and (max-width: 1200px) {
            height: 710px;
        }
        @media screen and (max-width: 768px) {
            height: 575px;
            margin-bottom: 0;
        }
        @media screen and (max-width: 576px) {
            height: 380px;
        }
        @media screen and (max-width: 425px) {
            height: 275px;
        }
        &-wrap {
            z-index: 10;
            iframe,
            video {
                width: 1000px;
                z-index: 10;
                height: 615px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 100px;
                @media screen and (max-width: 1440px) {
                    width: 800px;
                    height: 550px;
                }
                @media screen and (max-width: 1200px) {
                    width: 700px;
                    height: 480px;
                    top: 80px;
                }
                @media screen and (max-width: 768px) {
                    width: 550px;
                    height: 410px;
                }
                @media screen and (max-width: 576px) {
                    width: 400px;
                    height: 300px;
                    top: 40px;
                }
                @media screen and (max-width: 425px) {
                    width: 320px;
                    height: 200px;
                }
                @media screen and (max-width: 375px) {
                    width: 300px;
                    height: 190px;
                }
            }
        }
        &-preview {
            width: 1060px;
            z-index: 11;
            top: 100px;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            height: 615px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media screen and (max-width: 1440px) {
                width: 800px;
                height: 550px;
            }
            @media screen and (max-width: 1200px) {
                width: 700px;
                height: 480px;
                top: 80px;
            }
            @media screen and (max-width: 768px) {
                width: 550px;
                height: 410px;
            }
            @media screen and (max-width: 576px) {
                width: 400px;
                height: 300px;
                top: 40px;
            }
            @media screen and (max-width: 425px) {
                width: 320px;
                height: 200px;
            }
            @media screen and (max-width: 375px) {
                width: 300px;
                height: 190px;
            }
            &::after {
                content: "";
                position: absolute;
                background-image: url("../../../img/promotions/championship/play.png");
                background-size: 100%;
                width: 125px;
                height: 125px;
                @media screen and (max-width: 576px) {
                    width: 42px;
                    height: 42px;
                }
            }
        }
        &-line {
            background-image: url("../../../img/promotions/ferrari-promo/video-line-top.png");
            background-position: center;
            background-repeat: repeat-x;
            background-size: 100vw;
            position: absolute;
            &--top {
                top: 0;
                left: 0;
                width: 100%;
                height: 27px;
                background-position: center top;
            }
            &--bottom {
                bottom: 0;
                left: 0;
                width: 100%;
                height: 27px;
                background-position: center bottom;
            }
        }
    }

    .registration {
        padding-top: 80px;
        padding-bottom: 50px;
        border-top: 30px solid $shell-red;
        @media screen and (max-width: $point-md-max) {
            padding-top: 25px;
            padding-bottom: 25px;
        }
        &__top-text {
            margin: 0;
            font-size: 36px;
            font-weight: 700;
            color: $ferrari-cars-grey;
            text-align: center;
            @media screen and (max-width: $point-md-max) {
                font-size: 22px;
                padding-left: 16px;
                padding-right: 16px;
            }
            &.color-red {
                margin-bottom: 1em;
                color: $shell-red;
            }
        }
        &__list {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 70px;
            @media screen and (max-width: $point-md-max) {
                flex-wrap: wrap;
                justify-content: center;
            }
            &_item {
                position: relative;
                z-index: 1;
                width: 24%;
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                    margin-bottom: 30px;
                }
            }
            &_icon {
                display: block;
                width: 41%;
                margin: 0 auto;
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                }
                > img {
                    width: 100%;
                }
            }
            &_descr {
                padding: 10% 10px 0 10px;
                font-size: 24px;
                font-weight: 700;
                color: $ferrari-cars-grey;
                text-align: center;
                @media screen and (max-width: $point-md-max) {
                    font-size: 4.25vw;
                    padding: 10% 5px 0 5px;
                }
                a {
                    display: inline-block;
                    color: $shell-red;
                }
                span {
                    display: inline-block;
                }
            }
        }
    }
}
