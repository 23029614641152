/* =================================
Style Shell Autocomplete
================================= */

.ui-autocomplete {
    padding: .5em 1em 0 em(24,18);
    border: 1px solid $shell-pale-gray;
    background-color: #ffffff;
    font-size: em(18);
    list-style: none;
    .ui-menu-item {
        &:last-child {
            .ui-menu-item-wrapper {
                border-bottom: none;
            }
        }
        .ui-menu-item-wrapper {
            padding: .5em .75em .5em 0;
            border-bottom: 1px solid $shell-pale-gray;
            cursor: pointer;
            @include transition(all, .2s, ease-in-out);
            &.active,
            &.ui-state-focus,
            &.ui-state-active {
                color: $shell-red;
                background-color: $shell-very-pale-gray;
            }
        }
    }
}

.ui-helper-hidden-accessible {
    display: none;
}