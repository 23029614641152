.bootstrap-select {
    position: relative;
    width: 100%;
    font-size: 1em;
    &:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
        width: 100%;
    }
    > select {
        &.is-invalid {
            ~ .btn {
                -webkit-box-shadow: 0px 0px 1px 1px $shell-red;
                -moz-box-shadow: 0px 0px 1px 1px $shell-red;
                box-shadow: 0px 0px 1px 1px $shell-red;
            }
        }
    }
    &.show {
        .btn {
            &::after {
                @include transform(rotate(180deg))
            }
        }
    }
    .btn {
        &:focus {
            outline: none !important;
        }
        &-rect {
            padding: 1.6em 1em 0.359em 1em;
            border: 1px solid $border-input;
            outline: none;
            background-color: #ffffff;
            font-size: em(24);
            line-height: inherit;
            @media screen and (max-width: $point-lg-max) {
                font-size: em(22);
            }
            &:hover {
                border-color: $shell-yellow
            }
        }
        &-round {
            @media screen and (max-width: $point-lg-max) {
                font-size: em(14,18);
            }
            &::after {
                border-top: 0.75em solid $shell-black;
                border-right: 0.5em solid transparent;
                border-bottom: 0;
                border-left: 0.5em solid transparent;
                @include transition(all, .2s, ease-in-out);
            }
        }
        &.bs-placeholder {
            .filter-option-inner-inner {
                opacity: 0;
            }
        }
        &.dropdown-toggle {
            &::after {
                position: absolute;
                width: 20px;
                height: 11px;
                top: 50%;
                right: 1em;
                margin: -5px 0 0 0;
                padding: 0;
                border: none;
                background-image: url("../../img/fonticons/arrow-down-bold-grey.svg");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                @include transition(all, .1s, ease-in-out);
            }
        }
    }
    &.show {
        .btn {
            &.dropdown-toggle {
                &::after {
                    @include transform(rotate(180deg));
                }
            }
        }
    }
    .dropdown-menu {
        max-width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        border-color: $shell-light-gray;
        z-index: 1001;
        @include border-radius(0);
        font-size: 1em;
        .bs-searchbox {
            padding-top: 0;
            padding-bottom: 0;
            height: em(60);
            margin-bottom: .5em;
        }
        .dropdown-item {
            padding: 0 1em;
            &.active,
            &:active {
                background-color: $shell-very-pale-gray;
                font-weight: 400;
                color: $shell-red;
            }
            &:focus {
                outline: none;
            }
            &:hover {
                color: $shell-red;
                background-color: #ffffff;
            }
            > span.text {
                font-weight: 400;
                white-space: pre-wrap;
                word-break: break-word;
                font-size: em(18);
                line-height: 1.2;
                height: 54px;
                display: table-cell;
                vertical-align: middle;
                @media screen and (max-width: $point-lg-max) {

                }
            }
        }
    }
    > label {
        position: absolute;
        left: 30px;
        top: em(22,18);
        font-size: em(18);
        line-height: 1;
        color: $placeholder-color;
        cursor: text;
        font-weight: 200;
        @include transition(all, .2s, ease-in-out);
    }
}
