
// CSS ACCORDION

// Accordion base layout
%accordion-base-layout {

	font-size: 1em;

	.accordion__item {
		margin-bottom: 10px;
	}

	.accordion__head {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-height: 4.6em;
		padding: 1em 3em;
		background-color: $shell-yellow;
		cursor: pointer;
		font-size: em(24);
		color: $shell-very-dark-gray;
        text-decoration: none;
		@include transition(all, .2s, ease-in-out);

        @media screen and (max-width: $point-lg-max) {
            position: relative;
            font-size: em(22);
        }
		@media screen and (max-width: $point-md-max) {
			padding: 1em 3em 1em 1em;
			font-size: em(18);
		}

		&:hover, &:focus {

		}

		> * {
			margin: 0;
			padding: 0;
			font-size: inherit;
			color: inherit;
			font-weight: inherit;
			line-height: inherit;
		}

		.title {
			font-weight: 400;
		}

		.icon {
			position: absolute;
			top: 50%;
			right: 2em;
			@include transform(translateY(-50%));
			font-size: .75em;
			color: #ffffff;
			@include transition(all, .2s, ease-in-out);
			@media screen and (max-width: $point-md-max) {
				right: 1.5em;
			}
		}

	}

	.accordion__body {
		display: none;
		padding: 2em 4.5em;
		@media screen and (max-width: $point-lg-max) {
			padding: 2em 4.125em;
		}
		@media screen and (max-width: $point-md-max) {
			padding: 1.5em 1em;
		}
		.text-block {
			font-size: em(20);
			color: $shell-very-dark-gray;
			@media screen and (max-width: $point-md-max) {
				font-size: 1em;
			}
			> * {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

}

// Theme - White-Gray
%theme-white-grey {

	.accordion__item {

		&.open {
			> .accordion__head {
				.icon {
					@include transform(translateY(-50%) rotate(180deg));
				}
			}
		}

	}

	.accordion__head {
		//background-color: $bg-accordion-head-hover;
	}

}

.accordion {
	@extend %accordion-base-layout;
	@extend %theme-white-grey;

	.accordion__body {
		.f-text-block {
			> * {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
