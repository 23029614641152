/* =================================
Page Promotion EveryJourney
================================= */

.everyjourney {

    .main {
        background-image: url('../../../img/promotions/everyjourney/main-bg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-color: #ffdd0b;
        display: flex;
        align-items: center;
        min-height: 720px;
        &__logo {
            img {
                width: 160px;
            }
        }
        &__header {
            padding-top: em(115,44);
            font-size: em(44);
            font-weight: 700;
            color: $shell-very-dark-gray;
            text-transform: uppercase;
        }
    }
    .stock {
        background-image: url('../../../img/promotions/everyjourney/stock-bg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        padding-top: 290px;
        //margin-top: -255px;
        margin-top: -17%;
        background-position: 0 0;
        &__header {
            font-size: em(60);
            font-weight: 700;
            text-transform: uppercase;
            color: $shell-red;
            text-align: center;
        }
        &__content {
            display: flex;
            align-items: flex-start;
            padding-top: 65px;
            padding-bottom: 75px;
            &-line {
                width: 5px;
                height: 90px;
                background-color: $white;
                transform: rotate(30deg);
                margin: 0 40px;
                border-radius: 20px;
            }
            &-icons {
                display: flex;
                align-items: center;
                img {
                    width: em(150);
                    &:last-child {
                        width: em(180);
                    }
                }

            }
            &-text {
                padding-left: 30px;
                padding-bottom: 70px;
                text-transform: uppercase;
                color: $shell-very-dark-gray;
                font-weight: 700;
                &--top {
                    font-size: em(40);
                }
                &--bottom {
                    font-size: em(36);
                    span {
                        font-size: em(100,36);
                    }
                }
            }

        }

        &__slider {
            &-gallery {
                margin-top: 22px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                overflow: hidden;
                list-style: none;
                padding: 0;
                padding-top: 24px;
                padding-bottom: 24px;
                z-index: 1;
                .swiper-scrollbar {
                    display: none;
                }
                .swiper-wrapper {
                    align-items: flex-end;
                    .swiper-slide {
                        text-align: center;
                        height: 245px;
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        img {
                            width: 100%;
                            max-width: 95px;
                            transition: all .3s;
                        }
                    }
                    .swiper-slide-active {
                        align-items: center;
                        img {
                            position: absolute;
                            width: calc(100% + 40px);
                            max-width: calc(100% + 40px);
                        }
                    }
                }
            }
            &-arrow {
                position: absolute;
                bottom: 100px;
                left: -20px;
                z-index: 9999;
                cursor: pointer;
                img {
                    height: 45px;
                }
                &--left {
                    img {
                        transform: rotate(90deg);
                    }
                }
                &--right {
                    right: -20px;
                    left: auto;
                    img {
                        transform: rotate(-90deg);
                    }
                }
            }
        }
        &__slider-titles {
            margin-top: 45px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            overflow: hidden;
            list-style: none;
            padding: 0;
            z-index: 1;
            .swiper-scrollbar {
                display: none;
            }
            .swiper-wrapper {
                .swiper-slide {
                    text-align: center;
                    font-size: em(30);
                    color: $red;
                    opacity: 0!important;
                    &-active {
                        opacity: 1!important;
                    }
                }
            }
        }
        &__block {

            &:first-child {
                margin-right: 17px;
            }
            &:last-child {
                margin-left: 17px;
            }
            &-arrow {
                display: flex;
                justify-content: center;
                img {
                    width: 60px;
                    animation: mainArrowAnimation 2s linear infinite;
                }
            }
            &-icon {
                display: flex;
                justify-content: center;
                margin: 20px 0 30px 0;
            }
            &-title {
                font-size: em(48);
                color: $shell-red;
                text-align: center;
                text-transform: uppercase;
                padding: 0 30px;
                margin-bottom: 36px;
                @media screen and (max-width: 1666px) {
                    font-size: em(40);
                }
                @media screen and (max-width: 1300px) {
                    font-size: em(30);
                }
                span {
                    font-size: 30px;
                }
            }
            &-content {
                background-color: $shell-yellow;
                padding: 65px 65px 100px 65px;
                height: em(609);
                @media screen and (max-width: 1450px) {
                    height: 550px;
                }
                @media screen and (max-width: 1200px) {
                    height: 480px;
                }
                @media screen and (max-width: 1024px) {
                    height: 380px;
                }
                @media screen and (max-width: 768px) {
                    height: auto;
                }
                &-slider {
                    position: relative;
                }
                &--left {
                    padding-bottom: 72px;
                }
                &-title {
                    font-size: em(28);
                    color: $shell-very-dark-gray;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    img {
                        margin-left: 12px;
                        width: 24px;
                        transform: rotate(-90deg)
                    }
                    a {
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        color: inherit;
                    }
                }
                &-wrapper {
                    background-color: $shell-red;
                    margin-top: 65px;
                    padding: 45px 60px;
                    position: relative;
                    h3 {
                        color: $white;
                        font-size: em(30);
                        text-transform: uppercase;
                        text-align: center;
                        margin: 0;
                        margin-bottom: 65px;
                    }
                }
            }
            &-fingers {
                position: absolute;
                bottom: -80px;
                right: -40px;
                width: 200px;
            }
            &--first {
                .stock__block-content {
                    background-image: url('../../../img/promotions/everyjourney/bg--left.jpg');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    background-position: center;
                }
            }
            &--second {
                .stock__block-content {
                    background-image: url('../../../img/promotions/everyjourney/bg--right.jpg');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    background-position: center;
                }
            }
        }
        &__fuel-logo {
            display: flex;
            align-items: flex-start;
            img {
                max-width: em(155);
                margin-right: em(55);
            }
        }
        &__btn {
            margin-top: 50px;
            display: flex;
            justify-content: center;
        }
    }
    .smart-card {
        margin-top: 95px;
        &__header {
            padding: em(40,30) 0;
            text-align: center;
            width: 100%;
            background-color: $shell-yellow;
            color: $shell-very-dark-gray;
            font-size: em(30);
            &--red {
                width: 100%;
                font-size: em(60);
                padding-bottom: em(90,60);
                color: $shell-red;
                text-align: center;
            }
        }
        &__content {
            display: flex;
            border: 1px solid #ccc;
            padding: 80px 0;
            flex-wrap: wrap;
            width: 100%;
        }
        &__contacts {
            &-item {
                margin-bottom: 30px;
            }
            &-link {
                display: flex;
                align-items: center;
                img {
                    margin-right: em(50,30);
                    width: em(53,30);
                    height: em(53,30);
                }
                color: $shell-very-dark-gray;
                font-size: em(30);
                text-decoration: none;
                .sms-number {
                    padding-bottom: 4px;
                    color: $shell-very-dark-gray;
                    margin-left: 0;
                    span {
                        color: $shell-red;
                        margin-left: 10px;
                    }
                }
                span {
                    color: $shell-red;
                    margin-left: 10px;
                }
            }
        }
        &__image {
            img {
                width: 100%;
                max-width: 480px;
                padding-left: 90px;
            }
        }
    }

    .btn-default {
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        &__results {
            background-color: $shell-red;
            padding: em(35,30) em(80,35);
            display: flex;
            align-items: center;
            font-size: em(30);
            transition: all .2s;
            color: $white;
            text-decoration: none;
            text-transform: uppercase;
            border: 1px solid #cccccc;
            span {
                font-size: em(18,30);
                margin-top: 9px;
                margin-right: 10px;
            }
            img {
                transform: rotate(-90deg);
                width: 26px;
                margin-left: 10px;
            }
            &:hover {
                color: $shell-yellow;
            }
            &--code-page {
                padding: 36px 90px;
                border: none;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .btn-default {
            &__results {
                font-size: 28px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .btn-default {
            &__results {
                width: 100%;
                text-align: center;
                font-size: 20px;
                padding: 15px 36px;
                display: flex;
                justify-content: center;
                span {
                    font-size: 14px;
                    margin-top: 4px;
                    margin-right: 6px;
                }
            }
        }
    }
    @media screen and (max-width: 568px) {
        .btn-default {
            &__results {
                width: 100%;
                text-align: center;
                font-size: 18px;
                padding: 12px 32px;
                display: flex;
                justify-content: center;
                span {
                    font-size: 11px;
                    margin-top: 3px;
                    margin-right: 5px;
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .btn-default {
            &__results {
                font-size: 16px;
                padding: 12px;
            }
        }
    }
    @media screen and (max-width: 390px) {
        .btn-default {
            &__results {
                font-size: 14px;
                padding: 12px 6px;
                span {
                    font-size: 8px;
                    margin-top: 6px;
                }
                img {
                    width: 18px;
                    margin-left: 8px;
                }
            }
        }
    }
    @media screen and (max-width: 360px) {
        .btn-default {
            &__results {
                font-size: 12px;
                padding: 6px;
                span {
                    font-size: 8px;
                    margin-top: 4px;
                }
            }
        }
    }

}

@import "media-everyjourney";