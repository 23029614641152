/* =================================
Section Personal Edit User Data
================================= */

.edit-user-data {
    @media screen and (max-width: 1500px) {
        .col-lg-6 {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .auth-forms__title {
        margin-bottom: 15px;
        font-size: em(32);
        .alert.alert-success {
            margin-bottom: 0;
            padding: em(18,32) 0;
            border: none;
            background-color: transparent;
            @include border-radius(0);
            color: $shell-very-dark-gray;
        }
    }
    .form-group {
        input[name="reg-card"] {
            &:disabled {
                -webkit-text-fill-color: $color-base;
                -webkit-opacity:1;
                opacity: 1;
                color: $color-base;
                background: #ffffff;
            }
        }
    }
}