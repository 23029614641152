/* =================================
Section Personal Balls Validity
================================= */

.balls-validity {

    @media screen and (max-width: $point-lg-max) {
        font-size: em(14);
    }

    &__table {

        .title-text {
            margin-bottom: 5px;
            padding: em(30,24) em(24,24);
            border: 1px solid $shell-pale-gray;
            font-size: em(24);
            font-weight: 700;
            color: $shell-red;
            text-align: center;

            -webkit-box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;
            -moz-box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;
            box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;
        }

        table {
            width: 100%;
            margin-bottom: 3em;
            table-layout: fixed;
            border-bottom: 1px solid $shell-pale-gray;
            font-size: em(24);
            font-weight: 700;
            text-align: center;
            tr {
                &:nth-child(2n + 1) {
                    background-color: $shell-very-pale-gray;
                }
            }
            th, td {
                padding: 1em;
            }
            th {
                font-weight: 400;
                background-color: $shell-yellow;
            }
        }

    }

}