/* =================================
Section Promotion Big
================================= */

.title-image {
    &__content {
        position: relative;
    }

    &__label {
        position: absolute;
        top: 2.5em;
        right: 0;
        @media screen and (max-width: $point-lg-max) {
            top: 1.5em;
            right: -30px;
        }
        @media screen and (max-width: $point-sm-max) {
            right: -15px;
        }
        .national {
            position: relative;
            width: em(190, 18);
            padding: 0.5em 0.5em 0.5em 2em;
            font-size: em(18);
            text-align: center;
            color: $shell-red;

            background-image: url("../../img/promotion-big-label-arrow.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            @media screen and (max-width: $point-lg-max) {
                font-size: em(12);
            }
            &::after {
                content: "";
                display: block;
                width: 6%;
                height: 123%;
                position: absolute;
                top: 0;
                left: 100%;
                background-image: url("../../img/promotion-big-label-arrow-tail.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
                @media screen and (max-width: $point-lg-max) {
                    content: none;
                }
            }
        }
    }

    &__text-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        padding: 2em 6% 6vh;

        @media screen and (max-width: 1500px) {
            padding: 2em 6% 4vh;
        }

        @media screen and (max-width: $point-lg-max) {
            padding: 5em 0 2em;
        }
    }

    &__title {
        &.w-50 {
            @media screen and (max-width: $point-lg-max) {
                width: 100% !important;
            }
        }
        &.w-55 {
            width: 55% !important;
            @media screen and (max-width: $point-lg-max) {
                width: 100% !important;
            }
        }
        &.w-60 {
            width: 60%;
            @media screen and (max-width: $point-lg-max) {
                width: 100%;
            }
        }
        &.w-70 {
            width: 70%;
            @media screen and (max-width: $point-lg-max) {
                width: 100%;
            }
        }
        .title-text {
            font-size: 2.5vw;
            font-weight: 700;
            color: $shell-red;
            @media screen and (max-width: $point-lg-max) {
                font-size: em(23);
                line-height: 1.35;
            }
            @media screen and (max-width: $point-iphone-5-max) {
                font-size: em(20);
            }
            @media screen and (min-width: 1920px) {
                font-size: 48px;
            }
            span {
                font-size: 3.75vw;
                padding-top: 24px;
                @media screen and (max-width: $point-lg-max) {
                    font-size: em(23);
                    line-height: 1.35;
                    padding-top: 0;
                }
                @media screen and (max-width: $point-iphone-5-max) {
                    font-size: em(20);
                }
            }
        }
        .sub-title {
            margin-top: 2vh;
            font-size: 1.25vw;
            font-weight: 700;
            color: $shell-very-dark-gray;
            @media screen and (max-width: $point-lg-max) {
                font-size: em(16);
            }
            span {
                font-size: 1.75vw;
                @media screen and (max-width: $point-lg-max) {
                    font-size: em(16);
                }
            }
        }
    }

    &__link {
        .btn {
            margin-bottom: 2em;
            @media screen and (max-width: $point-lg-max) {
                margin-bottom: 1em;
                font-size: em(18);
            }
        }
        .text-bottom {
            margin-bottom: 0;
            font-size: em(24);
            @media screen and (max-width: $point-lg-max) {
                font-size: em(18);
            }
        }
    }

    &__title-bottom {
        text-align: center;
        @media screen and (max-width: $point-xl-max) {
            font-size: em(12);
        }
        @media screen and (max-width: $point-lg-max) {
            font-size: em(10);
        }
        .title-text {
            margin: 1em 0 0.8em;
            font-size: em(48);
            font-weight: 700;
            color: $shell-red;
        }
        .time {
            font-size: em(24);
            font-weight: 700;
            color: $shell-red;
        }
    }

    &__buttons {
        margin-top: 4em;
        text-align: center;
        @media screen and (max-width: $point-md-max) {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 2em;
        }
        .btn {
            margin-right: 4em;
            @media screen and (max-width: $point-md-max) {
                margin-right: 0;
                margin-bottom: 1em;
            }
            &:last-child {
                margin-right: 0;
                @media screen and (max-width: $point-md-max) {
                    margin-bottom: 0;
                }
            }
        }
    }
}
