@keyframes registerArrow {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes starAnimationLargeLeft {
    0% {
        transform: translateX(-185%) translateY(0);
    }
    50% {
        transform: translateX(-185%) translateY(10px);
    }
    100% {
        transform: translateX(-185%) translateY(0);
    }
}
@keyframes starAnimationLargeRight {
    0% {
        transform: translateX(84%) translateY(0);
    }
    50% {
        transform: translateX(84%) translateY(10px);
    }
    100% {
        transform: translateX(84%) translateY(0);
    }
}

@keyframes starAnimationMediumLeft {
    0% {
        transform: translateX(-350%) translateY(0);
    }
    50% {
        transform: translateX(-350%) translateY(-10px);
    }
    100% {
        transform: translateX(-350%) translateY(0);
    }
}
@keyframes starAnimationMediumRight {
    0% {
        transform: translateX(250%) translateY(0);
    }
    50% {
        transform: translateX(250%) translateY(-10px);
    }
    100% {
        transform: translateX(250%) translateY(0);
    }
}
@keyframes starAnimationSmallLeft {
    0% {
        transform: translateX(-545%) translateY(0);
    }
    50% {
        transform: translateX(-545%) translateY(10px);
    }
    100% {
        transform: translateX(-545%) translateY(0);
    }
}
@keyframes starAnimationSmallRight {
    0% {
        transform: translateX(445%) translateY(0);
    }
    50% {
        transform: translateX(445%) translateY(10px);
    }
    100% {
        transform: translateX(445%) translateY(0);
    }
}

@keyframes mainArrowAnimation {
    0% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(-10px);
    }
    50% {
        transform: translateY(0);
    }
    75% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes championshipArrowRight {
    0% {
        transform: translateX(0) translateY(0) rotate(0);
    }
    50% {
        transform: translateX(-20px) translateY(-20px) rotate(0);
    }
    100% {
        transform: translateX(0) translateY(0) rotate(0);
    }
}

@keyframes championshipArrowLeft {
    0% {
        transform: translateX(0) translateY(0);
    }
    50% {
        transform: translateX(20px) translateY(-20px);
    }
    100% {
        transform: translateX(0) translateY(0);
    }
}

@keyframes championshipCountBang {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes championFooterTitle {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes gasStationSurprise {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes bmwImage {
    0% {
        transform: scale(0.75) translateY(-300px);
        opacity: 0.5;
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}


@media screen and (max-width: 992px) {
    @keyframes championshipArrowRight {
        0% {
            transform: translateX(0) translateY(0) rotate(20deg);
        }
        50% {
            transform: translateX(-10px) translateY(-20px) rotate(20deg);
        }
        100% {
            transform: translateX(0) translateY(0) rotate(20deg);
        }
    }
    @keyframes championshipArrowLeft {
        0% {
            transform: translateX(0) translateY(0) rotate(-25deg);
        }
        50% {
            transform: translateX(20px) translateY(-20px) rotate(-25deg);
        }
        100% {
            transform: translateX(0) translateY(0) rotate(-25deg);
        }
    }
    @keyframes championshipArrowRightWhite {
        0% {
            transform: translateX(0) translateY(0) rotate(0);
        }
        50% {
            transform: translateX(-20px) translateY(-20px) rotate(0);
        }
        100% {
            transform: translateX(0) translateY(0) rotate(0);
        }
    }
}

@media screen and (max-width: 768px) {
    @keyframes starAnimationLargeLeft {
        0% {
            transform: translateX(-205%) translateY(0);
        }
        50% {
            transform: translateX(-205%) translateY(10px);
        }
        100% {
            transform: translateX(-205%) translateY(0);
        }
    }
    @keyframes starAnimationLargeRight {
        0% {
            transform: translateX(104%) translateY(0);
        }
        50% {
            transform: translateX(104%) translateY(10px);
        }
        100% {
            transform: translateX(104%) translateY(0);
        }
    }
    @keyframes starAnimationSmallLeft {
        0% {
            transform: translateX(-555%) translateY(0);
        }
        50% {
            transform: translateX(-555%) translateY(10px);
        }
        100% {
            transform: translateX(-555%) translateY(0);
        }
    }
    @keyframes starAnimationSmallRight {
        0% {
            transform: translateX(455%) translateY(0);
        }
        50% {
            transform: translateX(455%) translateY(10px);
        }
        100% {
            transform: translateX(455%) translateY(0);
        }
    }
    @keyframes gasStationSurprise {
        0% {
            transform: scale(1) translateX(-50%);
        }
        50% {
            transform: scale(1.15) translateX(-50%);
        }
        100% {
            transform: scale(1) translateX(-50%);
        }
    }
}

@media screen and (max-width: 400px) {
    @keyframes starAnimationLargeLeft {
        0% {
            transform: translateX(-175%) translateY(0);
        }
        50% {
            transform: translateX(-175%) translateY(10px);
        }
        100% {
            transform: translateX(-175%) translateY(0);
        }
    }
    @keyframes starAnimationLargeRight {
        0% {
            transform: translateX(74%) translateY(0);
        }
        50% {
            transform: translateX(74%) translateY(10px);
        }
        100% {
            transform: translateX(74%) translateY(0);
        }
    }
    @keyframes starAnimationSmallLeft {
        0% {
            transform: translateX(-425%) translateY(0);
        }
        50% {
            transform: translateX(-425%) translateY(10px);
        }
        100% {
            transform: translateX(-425%) translateY(0);
        }
    }
    @keyframes starAnimationSmallRight {
        0% {
            transform: translateX(325%) translateY(0);
        }
        50% {
            transform: translateX(325%) translateY(10px);
        }
        100% {
            transform: translateX(325%) translateY(0);
        }
    }
    @keyframes starAnimationMediumLeft {
        0% {
            transform: translateX(-280%) translateY(0);
        }
        50% {
            transform: translateX(-280%) translateY(-10px);
        }
        100% {
            transform: translateX(-280%) translateY(0);
        }
    }
    @keyframes starAnimationMediumRight {
        0% {
            transform: translateX(180%) translateY(0);
        }
        50% {
            transform: translateX(180%) translateY(-10px);
        }
        100% {
            transform: translateX(180%) translateY(0);
        }
    }
}
