.bmw-m {
    position: relative;
    overflow: hidden;
    max-width: 100vw;
    .container-fluid {
        @media screen and (max-width: 768px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__cars {
        &-animation {
            opacity: 1 !important;
            transform: translateY(0) scale(1) !important;
        }
    }
    &__info {
        &-image {
            img {
                width: 100%;
            }
            &--desktop {
                @media screen and (max-width: 576px) {
                    display: none;
                }
            }
            &--mobile {
                @media screen and (min-width: 576px) {
                    display: none;
                }
            }
        }
    }
    &__main {
        &-wrap {
            position: relative;
            background: #203667;
            background: radial-gradient(
                circle at center bottom,
                rgba(75, 152, 202, 1) 0%,
                rgba(31, 54, 103, 1) 75%,
                rgba(31, 54, 103, 1) 100%
            );
            padding-top: 120px;
            padding-bottom: 200px;
            @media screen and (max-width: 576px) {
                padding-top: 80px;
                padding-bottom: 240px;
            }
        }
        &-titles {
            h1 {
                color: $white;
                text-align: center;
                font-size: 72px;
                font-weight: bold;
                @media screen and (max-width: 1440px) {
                    font-size: 60px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 48px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 30px;
                }
            }
            h2 {
                font-weight: bold;
                font-size: 48px;
                color: $shell-yellow;
                text-align: center;
                @media screen and (max-width: 1440px) {
                    font-size: 36px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 30px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 24px;
                }
            }
        }
        &-cars {
            max-width: 900px;
            margin: 40px auto 0;
            padding-bottom: 0px;
            opacity: 0;
            transform: translateY(-120px) scale(0.5);
            transition: all 1s;
            @media screen and (max-width: 1440px) {
                max-width: 720px;
            }
            @media screen and (max-width: 1280px) {
                max-width: 600px;
            }
            @media screen and (max-width: 992px) {
                max-width: 400px;
            }
            @media screen and (max-width: 576px) {
                margin: 20px auto 0;
                max-width: 325px;
            }
            img {
                width: 100%;
            }
        }
        &-v-power {
            position: absolute;
            left: 50px;
            top: 40px;
            max-width: 165px;
            @media screen and (max-width: 1440px) {
                max-width: 140px;
            }
            @media screen and (max-width: 576px) {
                max-width: 100px;
                left: 16px;
                top: 24px;
            }
            img {
                width: 100%;
            }
        }
        &-logo {
            max-width: 325px;
            position: absolute;
            top: 35px;
            right: 35px;
            @media screen and (max-width: $point-scaling-cancel) {
                max-width: 275px;
            }
            @media screen and (max-width: 576px) {
                max-width: 180px;
                right: 16px;
                top: 10px;
            }
            img {
                width: 100%;
            }
            p {
                font-weight: 400;
                color: #fff;
                padding-left: 112px;
                font-size: 14.2px;
                margin-top: 12px;
                span {
                    font-weight: 600;
                }
                @media screen and (max-width: 1350px) {
                    padding-left: 96px;
                    font-size: 13.2px;
                }
                @media screen and (max-width: 576px) {
                    padding-left: 62px;
                    font-size: 8px;
                    margin-top: 8px;
                }
            }
        }
        .go-well {
            text-align: right;
            @media screen and (max-width: 1600px) {
                img {
                    max-width: 35%;
                }
            }
            @media screen and (max-width: 576px) {
                img {
                    max-width: 70%;
                }
            }
        }
        &-bluetooth {
            top: 520px;
            left: 50px;
            position: absolute;
            @media screen and (max-width: 1440px) {
                max-width: 150px;
                top: 480px;
            }
            @media screen and (max-width: 1280px) {
                top: 450px;
            }
            @media screen and (max-width: 992px) {
                left: 300px;
                top: 365px;
            }
            @media screen and (max-width: 768px) {
                max-width: 125px;
                left: 200px;
                height: 100px;
                top: 400px;
                img {
                    width: 100%;
                }
            }
            @media screen and (max-width: 576px) {
                height: 35px;
                top: 360px;
            }
            @media screen and (max-width: 425px) {
                left: 170px;
            }
            @media screen and (max-width: 390px) {
                left: 155px;
            }
        }
        &-remote {
            position: absolute;
            bottom: 0;
            left: 50px;
            z-index: 11;
            @media screen and (max-width: 992px) {
                bottom: 50px;
            }
            @media screen and (max-width: 768px) {
                left: 120px;
            }
            @media screen and (max-width: 450px) {
                left: 90px;
                bottom: 60px;
            }
            @media screen and (max-width: 390px) {
                bottom: 75px;
                left: 80px;
            }
            .circle {
                &--yellow {
                    box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.95);
                    width: 285px;
                    height: 285px;
                    border-radius: 150%;
                    background-color: $shell-yellow;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    animation: gasStationSurprise 1.2s ease-in-out infinite;
                    transition: all 2.5s;
                    @media screen and (max-width: 1440px) {
                        width: 220px;
                        height: 220px;
                    }
                    @media screen and (max-width: 1280px) {
                        width: 190px;
                        height: 190px;
                    }
                    @media screen and (max-width: 768px) {
                        width: 125px;
                        height: 125px;
                    }
                    @media screen and (max-width: 390px) {
                        width: 120px;
                        height: 120px;
                    }
                }
                &--red {
                    width: 250px;
                    height: 250px;
                    border: 2px solid #fff;
                    border-radius: 150%;
                    background-color: $shell-red;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    overflow: hidden;
                    @media screen and (max-width: 1440px) {
                        width: 195px;
                        height: 195px;
                    }
                    @media screen and (max-width: 1280px) {
                        width: 160px;
                        height: 160px;
                    }
                    @media screen and (max-width: 768px) {
                        width: 110px;
                        height: 110px;
                    }
                    img {
                        margin-left: -60px;
                        margin-bottom: -50px;
                        width: 100%;
                    }
                }
            }
        }
        &-text-small {
            position: absolute;
            left: 360px;
            width: 210px;
            font-size: 7px;
            color: #fff;
            text-align: justify;
            bottom: 130px;
            @media screen and (max-width: 1440px) {
                left: 330px;
            }
            @media screen and (max-width: 1280px) {
                bottom: 100px;
                left: 300px;
            }
            @media screen and (max-width: 768px) {
                bottom: 80px;
                left: 200px;
            }
            @media screen and (max-width: 576px) {
                bottom: 75px;
            }
            @media screen and (max-width: 425px) {
                left: 170px;
            }
            @media screen and (max-width: 390px) {
                left: 155px;
                width: 180px;
            }
        }
    }
    .registration {
        padding-top: 80px;
        padding-bottom: 50px;
        @media screen and (max-width: $point-md-max) {
            padding-top: 25px;
            padding-bottom: 25px;
        }
        &__top-text {
            margin: 0;
            font-size: 36px;
            font-weight: 700;
            color: $ferrari-cars-grey;
            text-align: center;
            @media screen and (max-width: $point-md-max) {
                font-size: 22px;
                padding-left: 16px;
                padding-right: 16px;
            }
            &.color-red {
                margin-bottom: 1em;
                color: $shell-red;
            }
        }
        &__list {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 70px;
            @media screen and (max-width: $point-md-max) {
                flex-wrap: wrap;
                justify-content: center;
            }
            &_item {
                position: relative;
                z-index: 1;
                width: 24%;
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                    margin-bottom: 30px;
                }
            }
            &_icon {
                display: block;
                width: 41%;
                margin: 0 auto;
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                }
                > img {
                    width: 100%;
                }
            }
            &_descr {
                padding: 10% 10px 0 10px;
                font-size: 24px;
                font-weight: 700;
                color: $ferrari-cars-grey;
                text-align: center;
                @media screen and (max-width: $point-md-max) {
                    font-size: 4.25vw;
                    padding: 10% 5px 0 5px;
                }
                a {
                    display: inline-block;
                    color: $shell-red;
                }
                span {
                    display: inline-block;
                }
            }
        }
    }
    &__winners-table {
        padding-bottom: 50px;
        margin-bottom: 100px;
        @media screen and (max-width: $point-md-max) {
            margin-bottom: 20px;
        }
    }
    &__winners-table-title {
        margin-bottom: 70px;
        color: $shell-red;
        font-size: em(52);
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        @media screen and (max-width: $point-md-max) {
            font-size: em(34);
            margin-bottom: 35px;
        }
        .small-text {
            font-size: 0.65em;
        }
    }
    &__winners-table-wrap {
        position: relative;
        padding: 70px 5% 0;
        background-color: $shell-yellow;
        @media screen and (max-width: $point-md-max) {
            padding: 35px 20px 0;
        }
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 150px;
            position: absolute;
            z-index: -1;
            top: 100%;
            left: 0;
            background: rgb(251, 206, 7);
            background: -moz-linear-gradient(
                180deg,
                rgba(251, 206, 7, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            background: -webkit-linear-gradient(
                180deg,
                rgba(251, 206, 7, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            background: linear-gradient(
                180deg,
                rgba(251, 206, 7, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbce07",endColorstr="#ffffff",GradientType=1);
            @media screen and (max-width: $point-md-max) {
                height: 50px;
                top: 99%;
                background: rgb(251, 206, 7);
                background: -moz-linear-gradient(
                    180deg,
                    rgba(251, 206, 7, 1) 75%,
                    rgba(255, 255, 255, 0) 100%
                );
                background: -webkit-linear-gradient(
                    180deg,
                    rgba(251, 206, 7, 1) 75%,
                    rgba(255, 255, 255, 0) 100%
                );
                background: linear-gradient(
                    180deg,
                    rgba(251, 206, 7, 1) 75%,
                    rgba(255, 255, 255, 0) 100%
                );
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbce07",endColorstr="#ffffff",GradientType=1);
            }
        }
        table {
            width: 100%;
            font-size: em(24);
            font-weight: 700;
            color: $shell-very-dark-gray;
            text-align: center;
            @media screen and (max-width: $point-scaling-cancel) {
                font-size: em(20);
            }
            @media screen and (max-width: $point-lg-max) {
                font-size: em(18);
            }
            &.showing {
                tbody {
                    tr {
                        &:nth-child(n + 8) {
                            display: table-row;
                        }
                        @media screen and (max-width: $point-md-max) {
                            &:nth-child(n + 3) {
                                display: block;
                            }
                        }
                    }
                }
            }
            thead {
                color: $shell-red;
                tr {
                    background-color: #ffffff;
                    border-bottom: 5px solid $shell-yellow;
                }
            }
            tbody {
                tr {
                    &:nth-child(odd) {
                        background-color: rgba(255, 255, 255, 0.4);
                    }
                    &:nth-child(even) {
                        @media screen and (max-width: $point-md-max) {
                            background-color: rgba(255, 255, 255, 0.4);
                        }
                    }
                    &:nth-child(n + 8) {
                        display: none;
                    }
                    @media screen and (max-width: $point-md-max) {
                        &:nth-child(n + 3) {
                            display: none;
                        }
                    }
                }
            }
            th {
                padding: 1em 0.5em;
            }
            td {
                padding: 1.25em 0.5em;
            }
            @media only screen and (max-width: $point-md-max),
                (min-device-width: $point-md-max) and (max-device-width: 1024px) {
                thead,
                tbody,
                th,
                td,
                tr {
                    display: block;
                }
                thead tr {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                }

                tr {
                    margin-bottom: 50px;
                }

                td {
                    /* Behave  like a "row" */
                    border: none;
                    position: relative;
                    padding: 1em 0;
                    height: auto !important;
                    padding-top: 0;
                }

                td:before {
                    position: inherit;
                    width: 100%;
                    display: block;
                    padding: 1em 0.5em;
                    color: $shell-red;
                    background-color: $white;
                    margin-bottom: 1em;
                    -webkit-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05);
                    -moz-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05);
                    box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05);
                }

                /*
                Label the data
                */
                td:nth-of-type(1):before {
                    content: "Дата";
                }
                td:nth-of-type(2):before {
                    content: "Ім’я";
                }
                td:nth-of-type(3):before {
                    content: "Номер картки";
                }
                td:nth-of-type(4):before {
                    content: "Регіон";
                }
            }
        }
    }
    &__winners-empty {
        margin: 80px 0;
        font-size: em(24);
        font-weight: 700;
        color: $shell-very-dark-gray;
        text-align: center;
        @media screen and (max-width: $point-md-max) {
            margin: 50px 0 120px;
            font-size: em(20);
            text-align: center;
        }
    }
    &__collection {
        position: relative;
        z-index: 10;
        margin-top: -100px;
        &-wrap {
            padding-top: 120px;
            position: relative;
        }
        &-titles {
            padding-left: 16px;
            padding-right: 16px;
            h2 {
                color: $shell-red;
                text-align: center;
                font-size: 36px;
                font-weight: bold;
                text-transform: uppercase;
                @media screen and (max-width: $point-scaling-cancel) {
                    font-size: 32px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 24px;
                }
                @media screen and (max-width: 576px) {
                    padding: 0 24px;
                }
                @media screen and (max-width: 370px) {
                    font-size: 21px;
                }
            }
            h3 {
                font-weight: bold;
                font-size: 30px;
                color: $white;
                text-align: center;
                text-transform: lowercase;
                @media screen and (max-width: $point-scaling-cancel) {
                    font-size: 24px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 19px;
                }
                @media screen and (max-width: 370px) {
                    font-size: 17px;
                }
            }
        }
        &-cars {
            max-width: 500px;
            margin: 30px auto 0;
            padding-bottom: 20px;
            opacity: 0;
            transform: translateY(-120px) scale(0.5);
            transition: all 1s;
            @media screen and (max-width: 992px) {
                max-width: 350px;
            }
            @media screen and (max-width: 576px) {
                margin: 12px auto 0;
            }
            img {
                width: 100%;
            }
        }
        &-bg-arrow {
            position: absolute;
            top: -12px;
            left: -1.5px;
            width: calc(100% + 3px);
            z-index: -1;
            height: 515px;
            background-image: url("../../../img/promotions/bmw-m/yellow-arrow-top.png");
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            @media screen and (max-width: 1680px) {
                top: -70px;
            }
            @media screen and (max-width: 768px) {
                width: 125%;
                height: 400px;
                left: 50%;
                transform: translateX(-50%);
                top: -10px;
            }
            @media screen and (max-width: 576px) {
                width: 225%;
                top: 0px;
            }
            @media screen and (max-width: 450px) {
                top: -10px;
            }
            @media screen and (max-width: 390px) {
                top: -20px;
            }
        }
    }
    &__conditions {
        padding: 75px 0 50px;
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
        @media screen and (max-width: 1680px) {
            padding: 30px 0 50px;
        }
        @media screen and (max-width: 576px) {
            padding: 50px 0 40px;
            font-size: 22px;
        }
        &-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            @media screen and (max-width: 992px) {
                display: block;
            }
            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 400px;
                height: 300px;
                background-image: url("../../../img/promotions/bmw-m/gas-station.png");
                background-size: auto;
                background-repeat: no-repeat;
                background-position: center;
                @media screen and (max-width: 1680px) {
                    width: 300px;
                    height: 225px;
                    background-size: 100% auto;
                    bottom: 40px;
                }
                @media screen and (max-width: 1440px) {
                    width: 250px;
                    bottom: 50px;
                }
                @media screen and (max-width: 992px) {
                    bottom: auto;
                    top: -52px;
                    left: 22%;
                }
                @media screen and (max-width: 576px) {
                    left: 12%;
                }
                @media screen and (max-width: 450px) {
                    width: 225px;
                    height: 240px;
                    left: 4%;
                    background-image: url("../../../img/promotions/bmw-m/gas-station--mobile.png");
                }
                @media screen and (max-width: 390px) {
                    width: 212px;
                    height: 220px;
                    left: 2%;
                }
            }
        }
        &-gas,
        &-shop {
            flex: 0 0 41%;
            max-width: 41%;
            @media screen and (max-width: 992px) {
                flex: 0 0 100%;
                max-width: none;
                margin: 24px auto;
            }
        }
        .or {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            @media screen and (max-width: 992px) {
                margin: 32px auto;
            }
        }
        &-gas {
            margin-left: 290px;
            padding-top: 45px;
            flex: 0 0 41%;
            max-width: 290px;
            @media screen and (max-width: 1680px) {
                margin-left: 235px;
            }
            @media screen and (max-width: 1440px) {
                margin-left: 200px;
            }
            @media screen and (max-width: 1100px) {
                margin-left: 170px;
            }
            @media screen and (max-width: 992px) {
                margin-left: 45%;
                padding-top: 24px;
                max-width: none;
            }
            @media screen and (max-width: 450px) {
                margin-left: 38%;
                transform: scale(0.9);
            }
            .row {
                margin-bottom: 24px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                &:last-child {
                    margin-bottom: 0;
                }
                @media screen and (max-width: 1440px) {
                    justify-content: flex-start;
                }
                @media screen and (max-width: 992px) {
                    margin-bottom: 12px;
                }
            }
            .title {
                font-size: 30px;
                font-weight: bold;
                @media screen and (max-width: 1440px) {
                    font-size: 24px;
                    margin-right: 24px;
                }
                @media screen and (max-width: 450px) {
                    font-size: 22px;
                }

                .red {
                    color: $shell-red;
                }
            }
            .small {
                font-weight: bold;
                font-size: 24px;
                text-transform: lowercase;
                margin: 0 12px;
                @media screen and (max-width: 1440px) {
                    font-size: 20px;
                }
                @media screen and (max-width: 450px) {
                    font-size: 18px;
                    margin: 0 6px;
                }
            }
            .image {
                @media screen and (max-width: 1440px) {
                    max-width: 120px;
                    margin-right: 24px;
                    img {
                        width: 100%;
                    }
                }
                @media screen and (max-width: 450px) {
                    max-width: 100px;
                    margin-right: 16px;
                }
            }
        }
        &-shop {
            display: flex;
            align-items: center;
            @media screen and (max-width: 992px) {
                justify-content: center;
                margin-top: 100px;
            }
            .title {
                font-size: 30px;
                display: block;
                @media screen and (max-width: 450px) {
                    font-size: 22px;
                }
            }
            .text {
                display: block;
                font-size: 24px;
                @media screen and (max-width: 450px) {
                    font-size: 18px;
                }
                .small {
                    font-weight: bold;
                    font-size: 24px;
                    text-transform: lowercase;
                    @media screen and (max-width: 1440px) {
                        font-size: 20px;
                    }
                    @media screen and (max-width: 450px) {
                        font-size: 18px;
                    }
                }
                .red {
                    color: $shell-red;
                    font-size: 36px;
                    @media screen and (max-width: 450px) {
                        font-size: 22px;
                    }
                }
            }
            .shop {
                @media screen and (max-width: 1440px) {
                    img {
                        max-width: 280px;
                    }
                }
                @media screen and (max-width: 1280px) {
                    img {
                        max-width: 220px;
                    }
                }
                @media screen and (max-width: 450px) {
                    img {
                        max-width: 160px;
                    }
                }
            }
        }
    }
    &__more-gas {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: -2px;
            left: 50%;
            transform: translateX(-50%);
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 80px 200px 0 200px;
            border-color: #fff transparent transparent transparent;
        }
        &-wrap {
            padding: 90px 100px 75px;
            position: relative;
            background-color: $shell-yellow;
            @media screen and (max-width: 1280px) {
                padding: 90px 50px 75px;
            }
            @media screen and (max-width: 576px) {
                padding: 100px 24px 75px;
            }
        }
        &-title {
            &--mobile {
                display: none;
            }
            h2 {
                color: $shell-red;
                font-weight: bold;
                font-size: 60px;
                text-transform: uppercase;
                @media screen and (max-width: 1680px) {
                    font-size: 48px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 36px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 29px;
                }
            }
            @media screen and (max-width: 992px) {
                padding-right: 200px;
            }
            @media screen and (max-width: 576px) {
                padding-right: 0;
                text-align: center;
                font-size: 28px;
                h2 {
                    text-align: center;
                }
                &--desktop {
                    display: none;
                }
                &--mobile {
                    display: block;
                }
            }
            @media screen and (max-width: 370px) {
                h2 {
                    font-size: 27px;
                }
            }
        }
        &-circle {
            position: absolute;
            bottom: -50px;
            right: 50px;
            z-index: 11;
            @media screen and (max-width: 1280px) {
                bottom: 0;
            }
            @media screen and (max-width: 768px) {
                right: -70px;
                bottom: -10px;
            }
            @media screen and (max-width: 576px) {
                right: 50%;
                left: 50%;
                transform: translateX(-50%);
                bottom: -75px;
            }
            .circle {
                &--yellow {
                    box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.95);
                    width: 315px;
                    height: 315px;
                    border-radius: 150%;
                    background-color: $shell-yellow;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    animation: gasStationSurprise 1.2s ease-in-out infinite;
                    transition: all 2.5s;
                    @media screen and (max-width: 1680px) {
                        width: 260px;
                        height: 260px;
                    }
                    @media screen and (max-width: 1280px) {
                        width: 220px;
                        height: 220px;
                    }
                    @media screen and (max-width: 576px) {
                        width: 140px;
                        height: 140px;
                    }
                }
                &--red {
                    width: 280px;
                    height: 280px;
                    border: 2px solid #fff;
                    border-radius: 150%;
                    background-color: $shell-red;
                    justify-content: center;
                    align-items: flex-start;
                    flex-direction: column;
                    display: flex;
                    overflow: hidden;
                    padding: 10px 25px;
                    @media screen and (max-width: 1680px) {
                        width: 225px;
                        height: 225px;
                    }
                    @media screen and (max-width: 1280px) {
                        width: 185px;
                        height: 185px;
                    }
                    @media screen and (max-width: 576px) {
                        width: 120px;
                        height: 120px;
                        padding: 10px 16px;
                    }
                    img {
                        margin-left: 0;
                        margin-bottom: 0;
                        width: 100%;
                        max-width: 200px;
                        @media screen and (max-width: 1680px) {
                            max-width: 180px;
                        }
                    }
                    span {
                        font-size: 27px;
                        text-align: left;
                        text-transform: uppercase;
                        display: block;
                        margin-bottom: 10px;
                        font-weight: bold;
                        color: $white;
                        @media screen and (max-width: 1680px) {
                            font-size: 24px;
                        }
                        @media screen and (max-width: 1280px) {
                            font-size: 18px;
                        }
                        @media screen and (max-width: 576px) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
    &__promotions {
        &-wrap {
            position: relative;
            background: #203667;
            background: radial-gradient(
                circle at center center,
                rgba(75, 152, 202, 1) 0%,
                rgba(31, 54, 103, 1) 100%
            );
            padding: 60px 100px 35px;
            @media screen and (max-width: 992px) {
                padding: 60px 50px 35px;
            }
            @media screen and (max-width: 576px) {
                padding-top: 120px;
                padding-bottom: 16px;
            }
            @media screen and (max-width: 390px) {
                padding-left: 16px;
                padding-right: 16px;
            }
            &::after {
                content: "";
                position: absolute;
                bottom: -10px;
                left: -1px;
                width: calc(100% + 1px);
                z-index: 0;
                height: 260px;
                background-image: url("../../../img/promotions/bmw-m/arrow-yellow.png");
                background-size: 100% auto;
                background-repeat: no-repeat;
                background-position: center;
                @media screen and (max-width: 1680px) {
                    height: 230px;
                    bottom: -10px;
                }
                @media screen and (max-width: 1400px) {
                    bottom: -20px;
                    height: 200px;
                }
                @media screen and (max-width: 992px) {
                    height: 180px;
                    bottom: -30px;
                }
                @media screen and (max-width: 992px) {
                    width: 150%;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -10px;
                }
                @media screen and (max-width: 576px) {
                    bottom: -24px;
                }
                @media screen and (max-width: 450px) {
                    bottom: -40px;
                    width: 160%;
                }
            }
        }
        &-title {
            margin-bottom: 24px;
            color: $white;
            font-size: 18px;
            font-weight: bold;
        }
        &-table-wrap {
            @media screen and (max-width: 768px) {
                overflow-x: auto;
                width: 100%;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                thead {
                    tr {
                        td {
                            background-color: $white;
                            color: #00b7f3;
                            text-align: center;
                            padding: 16px 4px;
                            font-weight: 600;
                            font-size: 18px;
                            border: 2px solid $white;
                            border-right: 2px solid #00b7f3;
                            @media screen and (max-width: 1440px) {
                                font-size: 16px;
                            }
                            @media screen and (max-width: 1280px) {
                                font-size: 14px;
                            }
                            @media screen and (max-width: 992px) {
                                font-size: 12px;
                            }
                            @media screen and (max-width: 768px) {
                                min-width: 160px;
                            }
                            &:nth-child(1) {
                                font-size: 20px;
                                @media screen and (max-width: 1440px) {
                                    font-size: 18px;
                                }
                                @media screen and (max-width: 1280px) {
                                    font-size: 16px;
                                }
                            }
                            &:nth-child(5) {
                                border-right: 1px solid #fff;
                            }
                            .large {
                                font-size: 32px;
                                margin-right: 4px;
                                @media screen and (max-width: 1440px) {
                                    font-size: 24px;
                                }
                                @media screen and (max-width: 1280px) {
                                    font-size: 20px;
                                }
                                @media screen and (max-width: 992px) {
                                    font-size: 18px;
                                }
                            }
                            div {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            position: relative;
                            color: #fff;
                            padding: 6px 4px;
                            text-align: center;
                            font-size: 18px;
                            border: 2px solid $white;
                            @media screen and (max-width: 1280px) {
                                font-size: 14px;
                            }
                            @media screen and (max-width: 992px) {
                                font-size: 12px;
                            }
                            .medium {
                                font-size: 24px;
                                font-weight: 600;
                                @media screen and (max-width: 1440px) {
                                    font-size: 18px;
                                }
                                @media screen and (max-width: 1280px) {
                                    font-size: 16px;
                                }
                                @media screen and (max-width: 992px) {
                                    font-size: 14px;
                                }
                            }
                            .large {
                                display: block;
                                font-size: 32px;
                                font-weight: bold;
                                @media screen and (max-width: 1440px) {
                                    font-size: 24px;
                                }
                                @media screen and (max-width: 1280px) {
                                    font-size: 20px;
                                }
                                @media screen and (max-width: 992px) {
                                    font-size: 18px;
                                }
                            }
                            &.image {
                                animation: gasStationSurprise 1.2s ease-in-out
                                    infinite;
                                transition: all 2.5s;
                                img {
                                    position: absolute;
                                    top: 0;
                                    width: calc(100% + 24px);
                                    height: 100%;
                                    left: -24px;
                                    @media screen and (max-width: 992px) {
                                        height: 60px;
                                    }
                                    @media screen and (max-width: 768px) {
                                        height: 45px;
                                        left: auto;
                                        right: -150px;
                                    }
                                }
                            }
                        }
                        &:nth-child(1) {
                            td {
                                color: $shell-yellow;
                            }
                        }
                    }
                }
            }
        }
        &-cars {
            max-width: 1000px;
            margin: 70px auto 40px;
            position: relative;
            z-index: 10;
            padding-bottom: 0px;
            opacity: 0;
            transform: translateY(-120px) scale(0.5);
            transition: all 1s;
            @media screen and (max-width: 1680px) {
                max-width: 800px;
            }
            @media screen and (max-width: 1280px) {
                max-width: 640px;
            }
            @media screen and (max-width: 576px) {
                margin: 50px auto 24px;
            }
            img {
                width: 100%;
            }
        }
        &-text {
            margin-top: 24px;
            font-size: 14px;
            color: #fff;
            @media screen and (max-width: 576px) {
                font-size: 12px;
            }
        }
    }
    &__video {
        margin-bottom: 100px;
        @media screen and (max-width: 576px) {
            margin-bottom: 80px;
        }
        &-wrap {
            background-color: $shell-yellow;
            text-align: center;
            padding-bottom: 110px;
            position: relative;
            @media screen and (max-width: 1440px) {
                padding-left: 50px;
                padding-right: 50px;
            }
            @media screen and (max-width: 576px) {
                padding-bottom: 50px;
                padding-left: 16px;
                padding-right: 16px;
            }
            &::after {
                content: "";
                position: absolute;
                bottom: -80px;
                left: 50%;
                transform: translateX(-50%);
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 80px 200px 0 200px;
                border-color: $shell-yellow transparent transparent transparent;
                @media screen and (max-width: 1280px) {
                    bottom: -75px;
                }
            }
            video {
                margin: 0 auto;
                width: 1130px;
                height: 620px;
                object-fit: cover;
                object-position: center;
                @media screen and (max-width: 1440px) {
                    width: 100%;
                }
                @media screen and (max-width: 992px) {
                    height: 400px;
                }
                @media screen and (max-width: 576px) {
                    height: 220px;
                }
            }
        }
    }
}
