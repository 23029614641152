.promotion {
    &--lk {
        padding-bottom: 200px!important;
        @media screen and (max-width: 576px) {
            padding-bottom: 100px!important;
        }
    }
    &__header {
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 1.875em;
        background-color: #ffd300;
        padding: 32px 10px;
        color: #333333;
        @media screen and (max-width: 1400px)  {
            font-size: 1.5em;
        }
        @media screen and (max-width: 576px) {
            font-size: 1.2em;
            padding: 24px 10px;
        }
    }
    &__subheader {
        font-size: 1.125em;
        text-align: center;
        font-weight: 700;
        padding: 48px 10px 8px 10px;
        &--shell-cafe {
            font-size: 24px;
            text-align: center;
        }
        span {
            color: #e30613;
        }
        @media screen and (max-width: 1400px) {
            font-size: 1em;
        }
        @media screen and (max-width: 576px) {
            text-align: left;
            padding: 32px 0px 0px 0px;
            &--shell-cafe {
                font-size: 20px;
                text-align: center;
            }
        }
    }
    &-table {
        tr {
            &:nth-child(2n) {
                background-color: #fff!important;
            }
            th,
            td {
                border: 1px solid #d9d9d9;
                .red {
                    font-weight: 700;
                    color: #e30613;
                }
            }
            td {
                padding: 20px 65px!important;
                height: 110px!important;
                font-size: 1.1666666667em;
                @media screen and (max-width: 576px) {
                    padding: 16px!important;
                    width: 50%!important;
                    text-align: left;
                }
            }
            td.small-col {
                font-size: 1em;
            }
            @media screen and (max-width: 576px) {
                display: block;
                border-bottom: 6px solid #ededed;
            }
        }
        &--row-2 {
            border-right: 1px solid #d9d9d9;
            padding: 0 50px!important;
            min-width: 290px;
            text-align: left;
            img {
                max-width: 120px;
                margin: 5px 0;
                display: block;
            }
            @media screen and (max-width: 576px) {
                display: block;
                text-align: center;
                padding: 20px!important;
                height: auto!important;
                img {
                    margin: 5px auto;
                }
            }
        }
        &--grey {
            background-color: #f7f7f7!important;
        }
        &--top {
            padding: 20px!important;
            color: #666666;
        }
        &--scroll {
            @media screen and (max-width: 576px) {
                overflow-x: hidden!important;
            }
        }
        &--desktop {
            display: table-cell;
            @media screen and (max-width: 576px) {
                display: none;
            }
        }
        &--mobile {
            display: none;
            @media screen and (max-width: 576px) {
                display: table-cell;
            }
        }
        &--value {
            color: #000;
        }
        &--shell-cafe {
            margin-bottom: 40px;
            .table-wrap {
                width: 100%;
                padding: 0;
            }
            table {
                width: 100%;

            }
            .promotion-table--scroll {
                overflow-x: auto!important;
            }
            .promotion-table {
                tr {
                    display: table-row;
                }
            }
            thead {
                tr {
                    th {
                        color: #999999;
                        padding: 30px 0;
                        background-color: $white;
                        font-size: 24px;
                        border-top: 1px solid #999999;
                        border-bottom: 1px solid #999999;
                        vertical-align: middle;
                        text-align: center;
                        font-weight: 400;
                        border: none;
                        &:first-child {
                            border-right: none;
                            border-left: 1px solid #999;
                        }
                        &:last-child {
                            border-left: none;
                            border-right: 1px solid #999;
                        }
                        &:nth-child(3) {
                            width: 300px;
                        }
                    }
                }
            }
            tbody {
                vertical-align: middle;
                tr {
                    vertical-align: middle;
                    &:first-child {
                        border-top: 7px solid #f2f2f2;
                    }
                    td {
                        height: 100px;
                        background-color: $white;
                        text-align: center;
                        font-size: 18px;
                        vertical-align: middle;
                        padding: 20px;
                        font-weight: 400;
                        font-family: 'ShellFutura';
                        color: #000;
                        @media screen and (max-width: 576px) {
                            font-size: 16px;
                        }
                        img {
                            width: 100%;
                            max-width: 120px;
                        }
                        .btn-default {
                            border: none;
                            background: none;
                            outline: none;
                            cursor: pointer;
                            &__table-youtube {
                                width: 50px;
                                height: 37px;
                                cursor: pointer;
                                background-image: url('../../../img/promotions/shell-cafe/youtube.png');
                                &:hover {
                                    background-image: url('../../../img/promotions/shell-cafe/youtube-hover.png');
                                }
                            }
                        }
                    }
                    &:nth-child(2n+1) {
                        td {
                            background-color: #f7f7f7;
                        }
                    }
                }
            }
        }
    }
    &__btn-wrap {
        display: flex;
        justify-content: center;
        position: relative;
        padding-top: 400px;
        &--shell-cafe {
            padding-top: 40px;
        }
        @media screen and (max-width: 576px) {
            padding-top: 320px;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            background-image: url('../../../img/promotions/championship/lk-promotion.png');
            background-repeat: no-repeat;
            background-size: 100%;
            width: 340px;
            height: 340px;
            z-index: -1;
            @media screen and (max-width: 576px) {
                top: 0px;
                left: 50%;
                transform: translateX(-50%);
            }
            @media screen and (max-width: 375px) {
                width: 250px;
                height: 250px;
            }
        }
        &--shell-cafe {
            &::after {
                background-image: url('../../../img/promotions/shell-cafe/lk-shell-cafe.png');
                top: 20px;
                @media screen and (max-width: 1440px) {
                    width: 300px;
                    height: 300px;
                    top: 120px;
                }
                @media screen and (max-width: 576px) {
                    top: 0;
                }
            }
        }
    }
}