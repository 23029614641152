/* =================================
Tabs Bootstrap Styles Custom
================================= */

.tabs-bootstrap {

    &__nav {
        margin-bottom: em(60);
        @media screen and (max-width: $point-lg-max) {
            margin-bottom: 2.5em;
        }
        .nav-tabs {
            display: flex;
            flex-wrap: nowrap;
            align-items: stretch;
            justify-content: space-between;
            border: none;
            border-bottom: 4px solid $shell-yellow;
            &.justify-content-around {
                .nav-link {
                    &:first-child {
                        padding-left: .5em;
                    }
                    &:last-child {
                        padding-right: .5em;
                    }
                }
            }
        }
        .nav-link {
            position: relative;
            display: flex;
            align-items: center;
            border: none;
            margin: 0;
            padding: 0 .5em 2em .5em;
            background-color: transparent;
            font-size: em(20);
            color: $shell-very-dark-gray;
            @include transition(opacity, 0.2s, ease-in-out);
            @media screen and (max-width: $point-lg-max) {
                padding-bottom: .5em;
                max-width: 50%;
                text-align: center;
                align-items: flex-start;
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                position: absolute;
                top: 100%;
                left: 0;
                background-color: $shell-red;
                opacity: 0;
                @include transition(opacity, 0.2s, ease-in-out);
            }
            &:hover,
            &.active {
                color: $shell-red;
                &::after {
                    opacity: 1;
                }
            }
            &.active {
                .tab-name {
                    @media screen and (max-width: $point-lg-max) {
                        opacity: 1;
                        visibility: visible;
                        display: block;
                    }
                }
            }
            > span {
                position: relative;
                display: inline-block;
                width: 100%;
                padding-left: 2.5em;
                @media screen and (max-width: $point-lg-max) {
                    padding-left: 0;
                }
            }
            .icon {
                position: absolute;
                top: 50%;
                left: 0;
                @include transform(translateY(-50%));
                font-size: 2em;
                color: inherit;
                @media screen and (max-width: $point-lg-max) {
                    position: static;
                    display: block;
                    margin-bottom: 3px;
                    @include transform(none);
                    font-size: 1.75em;
                }
                @media screen and (max-width: $point-iphone-5-max) {
                    font-size: 1.5em;
                }
            }
            .tab-name {
                @media screen and (max-width: $point-lg-max) {
                    display: none;
                    width: 100%;
                    //display: inline-block;
                    font-size: em(14,20);
                    //position: absolute;
                    //white-space: nowrap;
                    //top: 100%;
                    //left: 50%;
                    //transform: translateX(-50%);
                    opacity: 0;
                    visibility: hidden;
                }
                @media screen and (max-width: $point-iphone-5-max) {
                    font-size: em(13,20);
                }
            }
        }
    }

    &__content {

    }

    &__mob-title {
        display: none;
        margin-bottom: 2em;
        @media screen and (max-width: $point-lg-max) {
            display: block;
        }
        .title-text {
            font-size: 1em;
            color: $shell-red;
        }
    }

}