.loader {
    position: relative;
    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        background-color: rgba(255,255,255,0.9);
    }
    &::after {
        content: '';
        display: block;
        width: 100px;
        height: 100px;
        position: absolute;
        z-index: 1001;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%,-50%));
        background-image: url("../../img/loader.gif");
        background-repeat: no-repeat;
        background-position: center;
    }
}
body.loader {
    position: fixed;
    width: 100%;
    height: 100%;
}
