/* =================================
Start General Enjoy Your Profit
================================= */

body {
    font-family: $font-ShellFutura;
    font-size: $font-size-base + px;
    line-height: $line-height-base;
    font-weight: 400;
    color: $color-base;
    -webkit-font-smoothing:antialiased;
    -webkit-text-stroke:1px transparent;
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

// General containers

.logged-in {
    .page-height {
        @media screen and (max-width: $point-lg-max) {
            padding-top: em(140);
        }
        @media screen and (max-width: $point-sm-max) {
            padding-top: em(125);
        }
        @media screen and (max-width: $point-iphone-5-max) {
            padding-top: em(108);
        }
    }
}

.page-height {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    padding-top: em(152);
    padding-bottom: em(100);
    @media screen and (max-width: $point-scaling-10) {
        padding-top: em(144);
    }
    @media screen and (max-width: $point-scaling-25) {
        padding-top: em(134);
    }
    @media screen and (max-width: 1400px) {
        padding-bottom: em(90);
    }
    @media screen and (max-width: $point-xl-max) {
        padding-top: em(142);
        padding-bottom: em(78);
    }
    @media screen and (max-width: $point-lg-max) {
        padding-top: em(88);
        padding-bottom: em(463);
    }
    @media screen and (max-width: $point-sm-max) {
        padding-top: em(78);
    }
    @media screen and (max-width: $point-iphone-5-max) {
        padding-top: em(68);
    }
}

.container-fluid {
    max-width: 1920px;
    margin: 0 auto;
    padding-left: 10%;
    padding-right: 10%;
    @media screen and (max-width: 1500px) {
        padding-left: 7%;
        padding-right: 7%;
    }
    @media screen and (max-width: $point-xl-max) {
        padding-left: 5%;
        padding-right: 5%;
    }
    @media screen and (max-width: $point-lg-max) {
        padding-left: 30px;
        padding-right: 30px;
    }
    @media screen and (max-width: $point-sm-max) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.container {
    @media screen and (min-width: $point-xl-min) {
        max-width: 1026px;
    }
}

.main-content {
    padding-bottom: 80px;
    @media screen and (max-width: $point-lg-max) {
        padding-bottom: 50px;
    }
    section {
        &:first-child,
        &:first-of-type {
            padding-top: 0;
        }
        &:last-child,
        &:last-of-type {
            padding-bottom: 0;
        }
    }
}

.full-screen-lg {
    @media screen and (max-width: $point-lg-max) {
        margin-left: -30px;
        margin-right: -30px;
    }
    @media screen and (max-width: $point-sm-max) {
        margin-left: -15px;
        margin-right: -15px;
    }
}

section {
    padding: 3em 0;
    @media screen and (max-width: $point-lg-max) {
        padding: 2.5em 0;
    }
}

// Padding

.pt-1em { padding-top: 1em; }
.pt-2em { padding-top: 2em; }
.pt-3em { padding-top: 3em; }
.pt-3em-lg-0 {
    padding-top: 3em;
    @media screen and (max-width: $point-lg-max) {
        padding-top: 0;
    }
}

.pb-1em { padding-bottom: 1em; }
.pb-2em { padding-bottom: 2em; }
.pb-3em { padding-bottom: 3em; }
.pb-lg-0em {
    @media screen and (max-width: $point-lg-max) {
        padding-bottom: 0;
    }
}

.pl-1em { padding-left: 1em; }

.pr-lg-15 {
    @media screen and (max-width: $point-lg-max) {
        padding-right: 15px !important;
    }
}
.pl-lg-15 {
    @media screen and (max-width: $point-lg-max) {
        padding-left: 15px !important;
    }
}

// Margin

.mb-1em { margin-bottom: 1em; }
.mb-2em { margin-bottom: 2em; }
.mb-3em { margin-bottom: 3em; }
.mb-4em { margin-bottom: 4em; }
.mb-6em { margin-bottom: 6em; }
.mb-8em { margin-bottom: 8em; }
.mb-lg-1em {
    @media screen and (max-width: $point-lg-max) {
        margin-bottom: 1em;
    }
}
.mb-lg-2em {
    @media screen and (max-width: $point-lg-max) {
        margin-bottom: 2em;
    }
}
.mb-lg-5em {
    @media screen and (max-width: $point-lg-max) {
        margin-bottom: 5em;
    }
}

.mt-1em { margin-top: 1em; }
.mt-2em { margin-top: 2em; }
.mt-3em { margin-top: 3em; }
.mt-4em { margin-top: 4em; }

// Other

.hidden {
    display: none !important;
}

.separator {
    width: 100%;
    height: 1px;
    background-color: $shell-pale-gray;
}

.img-wrap {
    > img {
        width: 100%;
        height: auto;
    }
}