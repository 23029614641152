.referral-program {
    &__main {
        margin-bottom: 50px;
        @media screen and (max-width: 576px) {
            margin-bottom: 24px;
        }
        &-wrap {
            position: relative;
            background: url("../../../img/promotions/referral/banner.jpg"),
                $shell-red;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            padding-top: 120px;
            padding-bottom: 160px;
            padding-left: 100px;
            padding-right: 100px;
            @media screen and (max-width: 992px) {
                padding: 36px 16px 150px;
            }
            @media screen and (max-width: 576px) {
                padding-top: 32px;
                padding-bottom: 32px;
                padding-left: 0;
                padding-right: 0;
                min-height: 540px;
                background: url("../../../img/promotions/referral/banner--mobile.jpg"),
                    $shell-red;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
        }
        &-titles {
            max-width: 700px;
            @media screen and (max-width: 1440px) {
                max-width: 60%;
            }
            @media screen and (max-width: 1280px) {
                max-width: 100%;
            }
            @media screen and (max-width: 576px) {
                padding-left: 24px;
                padding-right: 24px;
            }
            h1 {
                color: $white;
                text-align: left;
                font-size: 56px;
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 24px;
                @media screen and (max-width: 1440px) {
                    font-size: 42px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 42px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 30px;
                }
                @media screen and (max-width: 576px) {
                    font-size: 24px;
                    margin-bottom: 16px;
                }
            }
            h2 {
                font-weight: 300;
                font-size: 48px;
                color: $white;
                text-transform: uppercase;
                text-align: left;
                @media screen and (max-width: 1440px) {
                    font-size: 30px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 30px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 24px;
                }
                @media screen and (max-width: 576px) {
                    font-size: 20px;
                    margin-bottom: 24px;
                }
            }
        }
        &-price {
            color: $shell-red;
            font-weight: bold;
            font-size: 36px;
            text-transform: uppercase;
            line-height: 175%;
            position: relative;
            max-width: 600px;
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 1440px) {
                justify-content: flex-end;
                margin-top: 40px;
                font-size: 32px;
                max-width: 500px;
            }
            @media screen and (max-width: 1280px) {
                justify-content: flex-start;
                padding-left: 16px;
            }
            @media screen and (max-width: 992px) {
                font-size: 16px;
                margin-left: 24px;
                padding-left: 0;
            }
            @media screen and (max-width: 576px) {
                margin-left: 42px;
            }
            .text-center {
                display: inline;
                position: relative;
                z-index: 1;
            }
            span {
                font-size: 80px;
                @media screen and (max-width: 1440px) {
                    font-size: 72px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 36px;
                }
            }
            &::after {
                z-index: 0;
                content: "";
                position: absolute;
                width: 295px;
                height: 212px;
                top: -21px;
                right: -65px;
                transform: rotate(-15deg);
                background-color: #fdd105;
                border-radius: 150%;
                animation: animPulseMainCircle 1.2s ease-in-out infinite;
                @media screen and (max-width: 1440px) {
                    top: -20px;
                    right: -50px;
                    width: 250px;
                    height: 190px;
                }
                @media screen and (max-width: 1280px) {
                    left: 0;
                    right: auto;
                }
                @media screen and (max-width: 992px) {
                    width: 137px;
                    height: 99px;
                    top: -16px;
                    left: -21px;
                }
            }
        }
        &-shell {
            height: 75px;
            right: 0;
            bottom: 40px;
            position: absolute;
            @media screen and (max-width: 992px) {
                height: 48px;
                bottom: 24px;
            }
            img {
                height: 100%;
            }
        }
    }
    &__details {
        &-wrap {
            padding-bottom: 32px;
            background: url("../../../img/promotions/referral/details-bg.jpg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;
            overflow: hidden;
            @media screen and (max-width: 992px) {
                padding-left: 16px;
                padding-right: 16px;
            }
            @media screen and (max-width: 576px) {
                background: url("../../../img/promotions/referral/detail-bg--mobile.jpg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                padding-bottom: 16px;
            }
            &::after {
                content: none;
                position: absolute;
                top: 0%;
                right: -12%;
                width: 100%;
                height: 100%;
                background: url("../../../img/promotions/referral/dots-yellow.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                transform: rotate(20deg);
                z-index: 0;
            }
        }
        &-text {
            font-weight: bold;
            position: relative;
            z-index: 10;
            span {
                font-weight: 400;
            }
            .break {
                @media screen and (max-width: 768px) {
                    display: block;
                }
            }
            &.fz-30 {
                font-size: 30px;
                @media screen and (max-width: 992px) {
                    font-size: 24px;
                }
            }
            &.fz-24 {
                font-size: 24px;
                @media screen and (max-width: 992px) {
                    font-size: 16px;
                }
            }
            &.fz-36 {
                font-size: 36px;
                @media screen and (max-width: 992px) {
                    font-size: 27px;
                }
            }
            &.fz-14 {
                font-size: 14px;
                @media screen and (max-width: 992px) {
                    font-size: 12px;
                }
            }
            &.black {
                color: #333333;
            }
            &.red {
                color: $shell-red;
            }
            &.title {
                text-transform: uppercase;
                padding: 65px 0 140px;
                @media screen and (max-width: 992px) {
                    padding: 32px 0 90px;
                }
                @media screen and (max-width: 576px) {
                    padding: 16px 0 90px;
                }
            }
            &.mb-24 {
                margin-bottom: 24px;
                @media screen and (max-width: 576px) {
                    margin-bottom: 16px;
                }
            }
            &.mb-40 {
                margin-bottom: 40px;
                @media screen and (max-width: 576px) {
                    margin-bottom: 16px;
                }
            }
            &.mb-50 {
                margin-bottom: 50px;
                @media screen and (max-width: 992px) {
                    margin-bottom: 30px;
                }
                @media screen and (max-width: 576px) {
                    margin-bottom: 16px;
                }
            }
        }
        &-fuels {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 60px;
            position: relative;
            z-index: 10;
            @media screen and (max-width: 1280px) {
                flex-wrap: wrap;
                row-gap: 100px;
            }
            @media screen and (max-width: 992px) {
                display: block;
                margin-bottom: 24px;
            }
            .circle {
                width: 170px;
                height: 170px;
                font-size: 20px;
                color: #fff;
                background-image: url("../../../img/promotions/referral/ellips.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 100%;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: -90px;
                font-weight: bold;
                text-align: center;
                animation: animPulseFuelCircle 1.2s ease-in-out infinite;
                span {
                    font-size: 36px;
                }
                div {
                    line-height: 145%;
                }
                @media screen and (max-width: 576px) {
                    font-size: 18px;
                    width: 160px;
                    height: 160px;
                }
            }
            .item {
                height: 185px;
                width: 340px;
                margin: 0 16px;
                background-color: #fff;
                border-radius: 10px;
                border: 1px solid #fdd105;
                display: flex;
                align-items: flex-end;
                padding-bottom: 24px;
                padding-right: 24px;
                padding-left: 24px;
                position: relative;
                @media screen and (max-width: 1440px) {
                    height: 175px;
                }
                @media screen and (max-width: 1280px) {
                    width: 40%;
                }
                @media screen and (max-width: 992px) {
                    margin: 0 auto 100px;
                    width: 340px;
                    height: 180px;
                    padding-bottom: 32px;
                    &:last-of-type {
                        margin-bottom: 24px;
                    }
                }
                @media screen and (max-width: 576px) {
                    width: 100%;
                    height: 150px;
                    padding-bottom: 16px;
                }
                .flex {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    width: 100%;
                    img {
                        max-width: 120px;
                        margin: 6px 6px;
                        @media screen and (max-width: 390px) {
                            max-width: 100px;
                        }
                    }
                    &--between {
                        // justify-content: space-between;
                        flex-wrap: wrap;
                        img {
                            margin-bottom: 0;
                        }
                        @media screen and (max-width: 1800px) {
                            justify-content: space-between;
                            img {
                                margin: 0;
                                max-width: 48%;
                            }
                        }
                    }
                }
                &--95 {
                    .flex {
                        img {
                            margin-bottom: 16px;
                        }
                    }
                }
                &--gas {
                    .flex {
                        img {
                            margin-bottom: 16px;
                            max-width: 145px;
                        }
                    }
                }
            }
        }
    }
    &__invite {
        &-wrap {
            background: url("../../../img/promotions/referral/invite-bg.jpg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            padding: 60px 120px 10px;
            position: relative;
            overflow: hidden;
            @media screen and (max-width: 992px) {
                padding: 50px 32px 320px;
            }
            @media screen and (max-width: 576px) {
                padding: 40px 16px 320px;
                background: url("../../../img/promotions/referral/invite-bg--mobile.jpg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
            &::before {
                content: "";
                position: absolute;
                background-image: url("../../../img/promotions/referral/hand-with-phone.png");
                background-repeat: no-repeat;
                background-size: 100%;
                width: 430px;
                height: 450px;
                bottom: -42px;
                right: 30px;
                background-position: center;
                @media screen and (max-width: 1440px) {
                    width: 300px;
                    height: 320px;
                    right: 16px;
                    bottom: -32px;
                }
                @media screen and (max-width: 1024px) {
                    width: 250px;
                    height: 280px;
                }
                @media screen and (max-width: 992px) {
                    width: 300px;
                    height: 310px;
                    right: auto;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            &::after {
                content: none;
                position: absolute;
                top: 10%;
                left: -25%;
                width: 100%;
                height: 100%;
                background: url("../../../img/promotions/referral/dots-grey.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                transform: rotate(20deg);
                z-index: 0;
            }
            .title {
                font-size: 36px;
                color: $shell-red;
                text-transform: uppercase;
                font-weight: bold;
                text-align: center;
                margin-bottom: 50px;
                position: relative;
                z-index: 10;
                animation: animPulseInviteTitle 1.2s ease-in-out infinite;
                @media screen and (max-width: 992px) {
                    font-size: 30px;
                    margin-bottom: 40px;
                }
                @media screen and (max-width: 992px) {
                    margin-bottom: 20px;
                }
            }
            .text {
                font-size: 30px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 50px;
                position: relative;
                z-index: 10;
                @media screen and (max-width: 992px) {
                    font-size: 24px;
                    margin-bottom: 20px;
                }
                @media screen and (max-width: 992px) {
                    margin-bottom: 10px;
                }
                span {
                    color: $shell-red;
                }
            }
            .arrow {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 10;
                margin: 24px auto;
                img {
                    width: 60px;
                }
                @media screen and (max-width: 992px) {
                    margin: 24px auto;
                }
            }
            .flex {
                display: flex;
                justify-content: center;
                position: relative;
                z-index: 10;
                align-items: center;
                @media screen and (max-width: 576px) {
                    display: block;
                }
                .item {
                    margin: 0 24px;
                    display: block;
                    @media screen and (max-width: 992px) {
                        margin: 24px auto;
                        text-align: center;
                    }
                    img {
                        height: 75px;
                        width: auto;
                        @media screen and (max-width: 992px) {
                            height: 70px;
                        }
                    }
                }
            }
        }
    }
    &__procent {
        &-wrap {
            height: 310px;
            background: url("../../../img/promotions/referral/procent.jpg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            padding-top: 70px;
            @media screen and (max-width: 1200px) {
                height: auto;
                padding: 40px 24px;
            }
            @media screen and (max-width: 576px) {
                background: url("../../../img/promotions/referral/procent--mobile.jpg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
            .title {
                font-size: 36px;
                color: $shell-red;
                font-weight: bold;
                text-transform: uppercase;
                text-align: center;
                margin-bottom: 50px;
                line-height: 135%;
                @media screen and (max-width: 1200px) {
                    margin-bottom: 24px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 27px;
                    margin-bottom: 16px;
                }
            }
            .subtitle {
                text-align: center;
                font-weight: bold;
                font-size: 20px;
                color: #333333;
                @media screen and (max-width: 992px) {
                    font-size: 18px;
                }
            }
        }
    }
    .rules {
        .short-text {
            margin-top: 24px;
        }

        ul {
            margin: 10px 0;
            padding-left: 20px;
        }
        @media screen and (max-width: 576px) {
            p,
            ul,
            li {
                font-size: 12px !important;
            }
        }
    }
}

@keyframes animPulseMainCircle {
    0% {
        transform: scale(1) rotate(-15deg);
    }
    50% {
        transform: scale(1.1) rotate(-15deg);
    }
    100% {
        transform: scale(1) rotate(-15deg);
    }
}

@keyframes animPulseFuelCircle {
    0% {
        transform: scale(1) translateX(-50%);
    }
    50% {
        transform: scale(1.1) translateX(-50%);
    }
    100% {
        transform: scale(1) translateX(-50%);
    }
}

@keyframes animPulseInviteTitle {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
