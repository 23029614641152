/* =================================
Counter Styles
================================= */

.counter {
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: auto;
    min-width: em(200);
    height: em(80);
    background-color: #fff;
    border: 1px solid $border-input;

    @media screen and (max-width: $point-lg-max) {
        height: em(73);
    }

    &:hover {
        border-color: $shell-yellow
    }

    &.w-100 {
        width: 100%;
    }

    &.disabled-counter {
        input {
            color: $shell-pale-gray;
        }
    }

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: none;
        outline: none;
        background-color: #ffffff;
        font-size: inherit;
        color: $shell-very-dark-gray;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
        text-align: center;
        @include border-radius(0);
        //&:disabled {
        //    color: $shell-pale-gray;
        //}
    }

    &__btn {
        width: 2.5em;
        padding: 1em 0;
        font-size: em(24);
        color: $shell-very-dark-gray;
        cursor: pointer;
        text-align: center;
        @media screen and (max-width: $point-lg-max) {
            font-size: em(22);
        }
    }

    &__value {
        position: relative;
        display: inline-block;
        width: auto;
        max-width: calc(100% - 5em);
        padding: 1em .125em;
        font-size: em(24);
        white-space: nowrap;
        @media screen and (max-width: $point-lg-max) {
            font-size: em(22);
        }
    }

}
