.shell-cafe {
    @media screen and (max-width: 576px) {
        .container-fluid {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__main {
        background: linear-gradient(to right, #04382e, #001914);
        padding: 50px 60px;
        position: relative;
        margin-bottom: 320px;
        @media screen and (max-width: 1800px) {
            margin-bottom: 250px;
        }
        @media screen and (max-width: 1440px) {
            margin-bottom: 250px;
        }
        @media screen and (max-width: 768px) {
            padding: 25px 30px;
        }
        @media screen and (max-width: 648px) {
            margin-bottom: 200px;
        }
        @media screen and (max-width: 576px) {
            padding: 60px 30px 25px 30px;
            margin-bottom: 120px;
        }
        @media screen and (max-width: 375px) {
            padding: 60px 20px 25px 20px;
        }
        &-header {
            color: #fff;
            margin-bottom: 70px;
            font-size: 72px;
            font-family: "ShellHand";
            font-weight: 500;
            @media screen and (max-width: 1200px) {
                font-size: 56px;
            }
            @media screen and (max-width: 992px) {
                font-size: 48px;
                img {
                    max-width: 120px;
                    width: 100%;
                }
            }
            @media screen and (max-width: 768px) {
                font-size: 42px;
                img {
                    max-width: 100px;
                }
            }
            @media screen and (max-width: 576px) {
                display: flex;
                align-items: center;
                margin-bottom: 150px;
                img {
                    max-width: 80px;
                    margin-right: 10px;
                }
            }
            @media screen and (max-width: 375px) {
                font-size: 36px;
                img {
                    max-width: 75px;
                }
            }
        }
        &-content {
            font-size: 36px;
            position: relative;
            z-index: 10;
            color: #fff;
            @media screen and (max-width: 1800px) {
                max-width: 50%;
            }
            @media screen and (max-width: 1200px) {
                font-size: 32px;
            }
            @media screen and (max-width: 992px) {
                font-size: 24px;
            }
            @media screen and (max-width: 768px) {
                padding-bottom: 70px;
            }
            span {
                font-weight: 700;
                text-transform: uppercase;
            }
            &-text {
                z-index: 10;
                position: relative;
                @media screen and (max-width: 768px) {
                    font-size: 0;
                }
            }
        }
        &-circle {
            width: 1200px;
            height: 430px;
            background-image: url("../../../img/promotions/shell-cafe/shellcafe-main-bg.png");
            background-repeat: no-repeat;
            background-size: 100%;
            position: absolute;
            z-index: -1;
            left: -60px;
            top: -60px;
            @media screen and (max-width: 1440px) {
                max-width: 1000px;
                top: -10px;
                left: -61px;
            }
            @media screen and (max-width: 1200px) {
                max-width: 800px;
            }
            @media screen and (max-width: 1024px) {
                max-width: 700px;
                top: 10px;
            }
            @media screen and (max-width: 768px) {
                max-width: 680px;
                top: -35px;
                left: -30px;
            }
            @media screen and (max-width: 768px) {
                max-width: 600px;
                top: -35px;
                left: -30px;
            }
            @media screen and (max-width: 670px) {
                max-width: 520px;
                top: -35px;
                left: -30px;
            }
            @media screen and (max-width: 648px) {
                max-width: 500px;
            }
            @media screen and (max-width: 576px) {
                max-width: 800px;
                top: -100px;
            }
            @media screen and (max-width: 375px) {
                left: -100px;
            }
        }
        &-line-g {
            content: "";
            position: absolute;
            background-image: url("../../../img//promotions//shell-cafe/main-g-bg.png");
            background-repeat: no-repeat;
            background-size: 100%;
            min-width: 100%;
            height: 400px;
            left: 0;
            top: 100%;
            z-index: -2;
            @media screen and (max-width: 1800px) {
                top: 90%;
            }
            @media screen and (max-width: 1680px) {
                top: 100%;
            }
            @media screen and (max-width: 576px) {
                display: none;
            }
            @media screen and (max-width: 375px) {
                left: -100px;
            }
        }
        &-image {
            max-width: 750px;
            width: 100%;
            position: absolute;
            right: 0;
            top: 150px;
            z-index: 20;
            @media screen and (max-width: 1800px) {
                max-width: 650px;
            }
            @media screen and (max-width: 1440px) {
                max-width: 620px;
                top: 230px;
            }
            @media screen and (max-width: 1200px) {
                max-width: 500px;
            }
            @media screen and (max-width: 1024px) {
                max-width: 450px;
            }
            @media screen and (max-width: 992px) {
                max-width: 400px;
            }
            @media screen and (max-width: 768px) {
                max-width: 400px;
                top: 105px;
            }
            @media screen and (max-width: 576px) {
                top: 155px;
            }
            @media screen and (max-width: 520px) {
                top: 200px;
            }
            @media screen and (max-width: 450px) {
                max-width: 350px;
                top: 225px;
            }
            @media screen and (max-width: 375px) {
                top: 210px;
            }
            img {
                width: 100%;
            }
        }
    }
    &__term {
        position: relative;
        &::before {
            content: "";
            background-image: url("../../../img/promotions/shell-cafe/term-bg.png");
            position: absolute;
            background-size: 100%;
            width: 100%;
            height: 500px;
            top: -190px;
            z-index: -1;
            left: 0;
            background-repeat: no-repeat;
            @media screen and (max-width: 1875px) {
                background-size: 110%;
                height: 600px;
            }
            @media screen and (max-width: 1680px) {
                background-size: 120%;
                height: 650px;
                top: -184px;
            }
            @media screen and (max-width: 1440px) {
                background-size: 135%;
            }
            @media screen and (max-width: 1200px) {
                background-size: 150%;
            }
            @media screen and (max-width: 992px) {
                background-size: 235%;
            }
            @media screen and (max-width: 900px) {
                background-size: 235% 820px;
                top: -240px;
            }
            @media screen and (max-width: 576px) {
                height: 1100px;
                background-size: 300% 1250px;
                top: -350px;
                background-position: 0 -100px;
            }
        }
        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 700;
            color: #5a574b;
            padding: 0 250px;
            padding-bottom: 12px;
            margin-bottom: 50px;
            @media screen and (max-width: 1750px) {
                padding: 0 150px 12px 150px;
            }
            @media screen and (max-width: 1440px) {
                padding: 0 100px 12px 100px;
            }
            @media screen and (max-width: 1200px) {
                padding: 0 10px 12px 10px;
            }
            @media screen and (max-width: 992px) {
                justify-content: center;
            }
        }
    }
    &__title {
        font-size: 30px;
        font-weight: 700;
        color: #5a574b;
        text-transform: uppercase;
        @media screen and (max-width: 1600px) {
            font-size: 27px;
        }
        @media screen and (max-width: 576px) {
            font-size: 20px;
        }
        span {
            font-size: 36px;
            color: #dd1d21;
            @media screen and (max-width: 1600px) {
                font-size: 32px;
            }
            @media screen and (max-width: 576px) {
                font-size: 24px;
            }
        }
    }
    &__buy,
    &__gas {
        display: flex;
        align-items: center;
        @media screen and (max-width: 992px) {
            flex: 0 0 100%;
            max-width: 100%;
            justify-content: center;
            margin: 20px;
        }
        @media screen and (max-width: 576px) {
            display: block;
            text-align: center;
        }
    }
    &__icon {
        margin-right: 20px;
        @media screen and (max-width: 576px) {
            margin-bottom: 10px;
            img {
                max-width: 110px;
            }
        }
    }
    &__body {
        background-color: #005e5c;
        padding-top: 65px;
        padding-left: 50px;
        padding-right: 50px;
    }
    &__gift {
        position: relative;
        margin-top: 120px;
        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            background-image: url("../../../img/promotions/shell-cafe/gift-top.png");
            background-size: calc(100% + 190px);
            background-repeat: no-repeat;
            height: 300px;
            z-index: -1;
            top: -115px;
            left: 0;
        }
        &::before {
            @media screen and (max-width: 1875px) {
                background-size: calc(100% + 320px);
                height: 350px;
            }
            @media screen and (max-width: 1600px) {
                background-size: calc(110% + 320px);
                height: 350px;
            }
        }
        &::after {
            transform: scale(-1, 1) scale(1, -1);
            bottom: -185px;
            top: auto;
            left: auto;
            right: -1px;
            background-size: 110% 115%;
            width: calc(100% + 1px);
            @media screen and (max-width: 576px) {
                background-size: 235% 70%;
            }
        }
        &-header {
            background-color: #005e5c;
        }
        &-title {
            font-size: 60px;
            text-align: center;
            color: #fff;
            @media screen and (max-width: 576px) {
                font-size: 36px;
            }
            &--shellhand {
                font-family: "ShellHand";
                font-weight: 500;
                margin-bottom: 20px;
            }
            &--yellow {
                font-size: 48px;
                color: #fbce07;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 16px;
                animation: championFooterTitle 1.2s ease-in-out infinite;
                @media screen and (max-width: 576px) {
                    font-size: 32px;
                }
            }
            &--small {
                font-size: 21px;
                text-transform: uppercase;
                font-weight: 700;
                @media screen and (max-width: 576px) {
                    font-size: 14px;
                }
            }
        }
        &-image-wrap {
            position: relative;
        }
        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 50px;
            @media screen and (max-width: 1600px) {
                align-items: flex-start;
            }
        }
        &-item {
            max-width: 24%;
            flex: 0 0 24%;
            position: relative;
            opacity: 0;
            transform: translateX(-100px);
            transition: all 1s;
            @media screen and (max-width: 992px) {
                max-width: 48%;
                flex: 0 0 48%;
                margin-bottom: 20px;
            }
            @media screen and (max-width: 375px) {
                max-width: 100%;
                flex: 0 0 100%;
                margin-bottom: 20px;
            }
            &-bg {
                width: 290px;
                height: 185px;
                background-color: #fff2d4;
                border-radius: 150%;
                transform: rotate(-30deg);
                margin-top: 10px;
                margin-left: 42px;
                box-shadow: 10px 10px rgba(#003c3b, 0.5);
                @media screen and (max-width: 1680px) {
                    margin-left: 20px;
                }
                @media screen and (max-width: 1600px) {
                    width: 250px;
                    height: 155px;
                }
                @media screen and (max-width: 1280px) {
                    width: 190px;
                    height: 115px;
                }
                @media screen and (max-width: 992px) {
                    margin-left: 105px;
                }
                @media screen and (max-width: 768px) {
                    margin-left: 60px;
                }
                @media screen and (max-width: 576px) {
                    width: 135px;
                    height: 94px;
                    margin-left: 40px;
                }
                @media screen and (max-width: 480px) {
                    margin-left: 27px;
                }
                @media screen and (max-width: 425px) {
                    margin-left: 15px;
                }
                @media screen and (max-width: 375px) {
                    margin-left: 65px;
                }
                @media screen and (max-width: 340px) {
                    margin-left: 47px;
                }
            }
            &-image {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                @media screen and (max-width: 1600px) {
                    img {
                        max-height: 150px;
                    }
                }
                @media screen and (max-width: 1280px) {
                    img {
                        max-height: 120px;
                    }
                }
                @media screen and (max-width: 576px) {
                    img {
                        max-height: 100px;
                    }
                }
            }
            &-title {
                text-align: center;
                color: #fff;
                font-size: 21px;
                text-transform: uppercase;
                font-weight: 700;
                margin-top: 40px;
                @media screen and (max-width: 576px) {
                    font-size: 14px;
                }
            }
            &--animated {
                opacity: 1;
                transform: translateX(0);
            }
        }
        &-large {
            text-align: center;
            position: relative;
            height: 550px;
            @media screen and (max-width: 768px) {
                height: 420px;
            }
            @media screen and (max-width: 576px) {
                height: 350px;
            }
            &--line {
                position: absolute;
                width: calc(100% + 100px);
                height: 600px;
                background-size: 101%;
                background-image: url("../../../img/promotions/shell-cafe/gift-yellow-line.png");
                background-repeat: no-repeat;
                top: -70px;
                z-index: 10;
                left: -50px;
                @media screen and (max-width: 1280px) {
                    top: 40px;
                }

                @media screen and (max-width: 576px) {
                    top: -190px;
                    background-size: 300% 500px;
                    width: calc(100% + 300px);
                    transform: rotate(-15deg);
                    left: -100px;
                }
                &-white {
                    background-image: url("../../../img/promotions/shell-cafe/gift-white-line.png");
                    top: 120px;
                    height: 400px;
                    @media screen and (max-width: 1280px) {
                        top: 230px;
                    }
                    @media screen and (max-width: 768px) {
                        top: 0px;
                        background-size: 200% 400px;
                        width: calc(100% + 300px);
                        transform: rotate(-15deg);
                        left: -100px;
                    }
                }
            }
            &--bg {
                width: 630px;
                height: 415px;
                background-color: #fff2d4;
                border-radius: 150%;
                box-shadow: 10px 10px rgba(#003c3b, 0.5);
                margin: 0 auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%) rotate(-25deg);
                z-index: 11;
                margin-top: 50px;
                @media screen and (max-width: 992px) {
                    width: 520px;
                    height: 365px;
                }
                @media screen and (max-width: 768px) {
                    width: 360px;
                    height: 265px;
                }
                @media screen and (max-width: 576px) {
                    width: 320px;
                    height: 218px;
                    margin-top: 35px;
                }
            }

            &--image {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 12;
                transform: translateX(-100%) translateY(-50%);
                opacity: 0;
                transition: all 1s;
                img {
                    max-width: 450px;
                    width: 100%;
                    @media screen and (max-width: 576px) {
                        min-width: 250px;
                    }
                }
            }
            &--animated {
                .shell-cafe__gift-large--image {
                    opacity: 1;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
        }
    }
    &__btn {
        text-align: center;
        padding: 50px 0 0 0;
        margin-bottom: 150px;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 576px) {
            padding: 75px 0 0 0;
        }
        a {
            font-size: 24px;
            color: #fff;
            font-weight: bold;
            background-color: #dd1d21;
            padding: 25px;
            border: 2px solid #fff;
            max-width: 470px;
            display: block;
            transition: all 0.2s;
            width: 100%;
            animation: championFooterTitle 1.2s ease-in-out infinite;
            @media screen and (max-width: 576px) {
                font-size: 16px;
                max-width: 300px;
                padding: 16px;
            }
            &:hover {
                border: 2px solid #dd1d21;
            }
        }
    }
    &__contacts {
        margin-top: 220px;
        position: relative;
        background-color: #eeeeee;
        @media screen and (max-width: 1680px) {
            margin-top: 200px;
        }
        @media screen and (max-width: 1600px) {
            padding-top: 50px;
            margin-top: 180px;
        }
        @media screen and (max-width: 1600px) {
            margin-top: 130px;
        }
        @media screen and (max-width: 576px) {
            margin-top: 90px;
            padding-bottom: 100px;
        }
        &::before {
            content: "";
            z-index: -1;
            position: absolute;
            width: calc(100% + 1px);
            height: 500px;
            background-image: url("../../../img/promotions/shell-cafe/shell-cafe-contacts-top.png");
            background-size: 101%;
            background-repeat: no-repeat;
            top: -290px;
            left: 0;
            @media screen and (max-width: 1800px) {
                top: -275px;
            }
            @media screen and (max-width: 1680px) {
                top: -260px;
            }
            @media screen and (max-width: 1600px) {
                top: -190px;
            }
            @media screen and (max-width: 1300px) {
                top: -190px;
            }
            @media screen and (max-width: 1024px) {
                top: -175px;
            }
            @media screen and (max-width: 768px) {
                top: -130px;
            }
            @media screen and (max-width: 640px) {
                top: -115px;
            }
            @media screen and (max-width: 576px) {
                top: -80px;
            }
            @media screen and (max-width: 400px) {
                top: -65px;
            }
            @media screen and (max-width: 325px) {
                top: -50px;
            }
        }
        &--hide-winners {
            padding-bottom: 100px;
            border-bottom-left-radius: 250px;
            border-bottom-right-radius: 250px;
            @media screen and (max-width: 576px) {
                padding-bottom: 50px;
                border-bottom-left-radius: 150px;
                border-bottom-right-radius: 150px;
            }
        }
        &-title {
            font-size: 42px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 1024px) {
                font-size: 36px;
            }
            @media screen and (max-width: 576px) {
                font-size: 28px;
            }
            span {
                color: #dd1d21;
                font-weight: 700;
                display: block;
            }
        }
        &-subtitle {
            font-size: 28px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 60px;
            @media screen and (max-width: 1024px) {
                font-size: 24px;
            }
            @media screen and (max-width: 576px) {
                font-size: 21px;
            }
            span {
                color: #dd1d21;
                display: block;
            }
        }
        &-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0 80px;
            @media screen and (max-width: 1024px) {
                justify-content: center;
                padding: 0 10px;
            }
        }
        &-image-wrap {
            position: relative;
        }
        &-item {
            @media screen and (max-width: 1024px) {
                max-width: 32%;
                flex: 0 0 32%;
                margin-bottom: 24px;
            }
            @media screen and (max-width: 370px) {
                max-width: 48%;
                flex: 0 0 48%;
                margin-bottom: 24px;
            }
            &--bg {
                width: 150px;
                height: 110px;
                background-color: #fbce07;
                box-shadow: 8px 8px #ccc;
                border-radius: 150%;
                transform: rotate(-30deg);
                margin: 0 auto;
                margin-top: 15px;
                @media screen and (max-width: 1440px) {
                    width: 120px;
                    height: 90px;
                }
                @media screen and (max-width: 576px) {
                    width: 60px;
                    height: 42px;
                }
            }
            &--icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                @media screen and (max-width: 1440px) {
                    img {
                        height: 45px;
                    }
                }
                @media screen and (max-width: 576px) {
                    img {
                        height: 24px;
                    }
                }
            }
            &--title {
                margin-top: 50px;
                font-weight: 700;
                font-size: 21px;
                text-align: center;
                @media screen and (max-width: 1440px) {
                    font-size: 18px;
                    margin-top: 40px;
                }
                @media screen and (max-width: 576px) {
                    font-size: 12px;
                    margin-top: 20px;
                }
                span {
                    display: block;
                    color: #dd1d21;
                }
            }
            &:last-child {
                .shell-cafe__contacts-item--title {
                    span {
                        display: inline;
                    }
                }
            }
        }
    }
    &__winners {
        position: relative;
        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: -90px;
            height: 570px;
            width: 100%;
            background-image: url("../../../img/promotions/shell-cafe/winners-header-bg.png");
            background-size: 101%;
            background-repeat: no-repeat;
            z-index: 1;
            @media screen and (max-width: 1680px) {
                background-position: center;
                background-size: 140%;
            }
            @media screen and (max-width: 768px) {
                background-size: 230%;
            }
            @media screen and (max-width: 576px) {
                background-size: 180% 120%;
                top: -230px;
            }
        }
        &--hide {
            display: none;
        }
        &-header {
            padding-top: 200px;
            margin-bottom: 200px;
            text-align: center;
            position: relative;
            height: 400px;
            background-color: #eeeeee;
            @media screen and (max-width: 1280px) {
                height: 350px;
                margin-bottom: 290px;
            }
            @media screen and (max-width: 576px) {
                background-color: #fff;
                margin-bottom: 20px;
            }
            @media screen and (max-width: 350px) {
                margin-bottom: 50px;
            }
        }
        &-title {
            font-size: 48px;
            text-transform: uppercase;
            color: #dd1d21;
            font-weight: 700;
            margin-bottom: 30px;
            position: absolute;
            left: 50%;
            top: 190px;
            width: 100%;
            z-index: 20;
            transform: translateX(-50%);
            @media screen and (max-width: 576px) {
                font-size: 40px;
                top: 50px;
            }
        }
        &-subtitle {
            color: #dd1d21;
            font-size: 30px;
            width: 100%;
            margin-bottom: 24px;
            z-index: 20;
            text-transform: uppercase;
            font-weight: 700;
            left: 50%;
            top: 265px;
            transform: translateX(-50%);
            position: absolute;
            @media screen and (max-width: 576px) {
                font-size: 20px;
                top: 125px;
            }
            @media screen and (max-width: 520px) {
                top: 155px;
            }
        }
        &-icon {
            top: 365px;
            width: 190px;
            height: 190px;
            background-color: #fff;
            display: flex;
            left: 50%;
            z-index: 20;
            transform: translateX(-50%);
            align-items: center;
            justify-content: center;
            border-radius: 150%;
            box-shadow: 0px 8px #cbcbcb;
            margin: 0 auto;
            position: absolute;
            img {
                width: 100%;
                max-width: 120px;
            }
            @media screen and (max-width: 992px) {
                top: 330px;
            }
            @media screen and (max-width: 768px) {
                top: 370px;
            }
            @media screen and (max-width: 576px) {
                width: 130px;
                height: 130px;
                top: 220px;
                img {
                    max-width: 80px;
                }
            }
            @media screen and (max-width: 350px) {
                top: 235px;
            }
        }
        &-table {
            margin-bottom: 40px;
            .table-wrap {
                width: 100%;
                padding: 0 50px;
                @media screen and (max-width: 1024px) {
                    overflow-x: scroll;
                }
                @media screen and (max-width: 760px) {
                    overflow-x: hidden;
                }
            }
            table {
                width: 100%;
            }
            thead {
                tr {
                    th {
                        color: #999999;
                        padding: 30px 0;
                        background-color: $white;
                        font-size: 24px;
                        border-top: 1px solid #999999;
                        border-bottom: 1px solid #999999;
                        vertical-align: middle;
                        text-align: center;
                        font-weight: 400;
                        min-width: 150px;
                        @media screen and (max-width: 1280px) {
                            font-size: 18px;
                        }
                        &:first-child {
                            border-right: none;
                            border-left: 1px solid #999;
                        }
                        &:last-child {
                            border-left: none;
                            border-right: 1px solid #999;
                        }
                    }
                }
            }
            tbody {
                vertical-align: middle;
                tr {
                    vertical-align: middle;
                    &:first-child {
                        border-top: 7px solid #f2f2f2;
                    }
                    @media only screen and (max-width: 760px) {
                        &:first-child {
                            border-top: 1px solid #eee;
                        }
                    }
                    td {
                        height: 100px;
                        background-color: $white;
                        text-align: center;
                        font-size: 24px;
                        vertical-align: middle;
                        @media screen and (max-width: 1280px) {
                            font-size: 18px;
                        }
                        @media only screen and (max-width: 760px) {
                            background-color: #f7f7f7;
                        }
                        img {
                            width: 100%;
                            max-width: 120px;
                        }
                        .btn-default {
                            border: none;
                            background: none;
                            outline: none;
                            cursor: pointer;
                            &__table-youtube {
                                width: 50px;
                                height: 37px;
                                cursor: pointer;
                                background-image: url("../../../img/promotions/shell-cafe/youtube.png");
                                &:hover {
                                    background-image: url("../../../img/promotions/shell-cafe/youtube-hover.png");
                                }
                            }
                        }
                    }
                    &:nth-child(2n + 1) {
                        td {
                            background-color: #f7f7f7;
                        }
                    }
                }
            }
            @media only screen and (max-width: 760px),
                (min-device-width: 768px) and (max-device-width: 1024px) {
                /* Force table to not be like tables anymore */
                thead,
                tbody,
                th,
                td,
                tr {
                    display: block;
                }

                /* Hide table headers (but not display: none;, for accessibility) */
                thead tr {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                }

                tr {
                    border: 1px solid #ccc;
                    margin-bottom: 50px;
                }

                td {
                    /* Behave  like a "row" */
                    border: none;
                    border-bottom: 1px solid #eee;
                    position: relative;
                    padding: 10px 0;
                    font-size: 18px;
                    height: auto !important;
                    padding-top: 0;
                    background-color: #f7f7f7;
                }

                td:before {
                    position: inherit;
                    width: 100%;
                    display: block;
                    padding: 16px 2px;
                    color: #999999;
                    background-color: $white;
                    vertical-align: middle;
                    text-align: center;
                    font-weight: 400;
                    margin-bottom: 10px;
                    font-size: 18px;
                    border-bottom: 5px solid #eaeaea;
                }

                /*
                    Label the data
                    */
                td:nth-of-type(1):before {
                    content: "Період розіграшу";
                }
                td:nth-of-type(2):before {
                    content: "Номер картки";
                }
                td:nth-of-type(3):before {
                    content: "#транзакції";
                }
                td:nth-of-type(4):before {
                    content: "Ім'я";
                }
                td:nth-of-type(5):before {
                    content: "Регіон";
                }
                td:nth-of-type(6):before {
                    content: "Відео розіграшу";
                }
            }
        }
    }
    &--time-to-win {
        overflow: hidden;
        .image-block {
            @media screen and (max-width: 991px) {
                margin-left: 0;
                margin-right: 0;
            }
        }
        .shell-cafe {
            &__row {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                background-image: url("../../../img/promotions/shell-cafe-v2/conditions-bg.png");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: top center;
                height: 400px;
                @media screen and (max-width: 1680px) {
                    height: 310px;
                }
                @media screen and (max-width: 1200px) {
                    height: auto;
                }
                @media screen and (max-width: 1200px) {
                    display: block;
                    padding-top: 80px;
                    padding-bottom: 160px;
                }
                @media screen and (max-width: 768px) {
                    padding-bottom: 200px;
                }
                .separator {
                    width: auto;
                    height: auto;
                    background-color: transparent;
                    text-transform: lowercase;
                    font-size: 36px;
                    font-weight: bold;
                    color: #404040;
                    margin-top: -50px;
                    margin-left: 50px;
                    margin-right: 50px;
                    @media screen and (max-width: 1300px) {
                        margin-left: 24px;
                        margin-right: 24px;
                    }
                    @media screen and (max-width: 1200px) {
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 24px;
                        margin-bottom: 24px;
                        text-align: center;
                        font-size: 30px;
                    }
                }
            }
            &__condition {
                display: flex;
                align-items: center;
                font-size: 30px;
                text-transform: uppercase;
                max-width: auto;
                color: #404040;
                flex: 0 0 auto;
                font-weight: bold;
                margin-top: -50px;
                @media screen and (max-width: 1200px) {
                    margin-top: 0;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    text-align: center;
                }
                img {
                    margin-right: 24px;
                    @media screen and (max-width: 1400px) {
                        max-height: 80px;
                    }
                    @media screen and (max-width: 1200px) {
                        margin-right: 0;
                        margin-bottom: 32px;
                    }
                }

                .red {
                    font-size: 36px;
                    color: $shell-red;
                    @media screen and (max-width: 1680px) {
                        font-size: 30px;
                    }
                }
                @media screen and (max-width: 1680px) {
                    font-size: 26px;
                }
                @media screen and (max-width: 576px) {
                    &.max-w-sm {
                        .title {
                            max-width: 280px;
                            margin: 0 auto;
                        }
                    }
                }
            }
            &__gifts {
                margin-top: 100px;
                @media screen and (max-width: 1440px) {
                    margin-top: 80px;
                }
                @media screen and (max-width: 768px) {
                    position: relative;
                    z-index: 10;
                    margin-top: 0;
                }
                @media screen and (max-width: 768px) {
                    margin-top: -50px;
                }

                &-wrap {
                    background-color: #005853;
                    padding: 0 40px;
                    padding-bottom: 70px;
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        top: -33%;
                        z-index: -1;
                        left: 0;
                        width: 100%;
                        height: 275px;
                        background-image: url("../../../img/promotions/shell-cafe-v2/gifts--top.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100%;
                        @media screen and (max-width: 1200px) {
                            top: -20%;
                        }
                        @media screen and (max-width: 1100px) {
                            top: -15%;
                        }
                        @media screen and (max-width: 576px) {
                            top: -12%;
                            z-index: 1;
                        }
                        @media screen and (max-width: 425px) {
                            height: 220px;
                            width: 200%;
                        }
                        @media screen and (max-width: 365px) {
                            height: 310px;
                        }
                    }
                }
                &-items {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    @media screen and (max-width: 1200px) {
                        flex-wrap: wrap;
                        justify-content: center;
                    }
                    @media screen and (max-width: 576px) {
                        column-gap: 24px;
                    }
                }
                &-title {
                    padding-top: 24px;
                    margin-bottom: 50px;
                    font-size: 60px;
                    color: $white;
                    text-align: center;
                    position: relative;
                    z-index: 10;
                    font-family: "ShellHand";
                    font-weight: 500;
                    @media screen and (max-width: 1440px) {
                        font-size: 52px;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 48px;
                    }
                }
            }
            &__gift {
                max-width: calc(100% / 5);
                flex: 0 0 calc(100% / 5);
                background: transparent;
                text-align: center;
                margin-top: 24px;
                .mb-block {
                    @media screen and (max-width: 576px) {
                        display: block;
                    }
                }
                &::before,
                &::after {
                    content: none;
                }

                img {
                    max-height: 210px;
                    height: 100%;
                    margin: 0 auto;
                    margin-bottom: 30px;
                    @media screen and (max-width: 1680px) {
                        max-height: 190px;
                    }
                    @media screen and (max-width: 1440px) {
                        max-height: 165px;
                    }
                    @media screen and (max-width: 576px) {
                        max-height: 132px;
                    }
                }
                &-title {
                    text-align: center;
                    font-size: 28px;
                    font-weight: bold;
                    color: #fce9bf;
                    @media screen and (max-width: 1680px) {
                        font-size: 24px;
                    }
                    @media screen and (max-width: 1440px) {
                        font-size: 22px;
                    }
                    span {
                        color: #fbce07;
                    }
                    @media screen and (max-width: 576px) {
                        font-size: 18px;
                    }
                }
                @media screen and (max-width: 1200px) {
                    max-width: calc(100% / 2.5);
                    flex: 0 0 calc(100% / 2.5);
                }
                @media screen and (max-width: 576px) {
                    max-width: calc(100% / 2.2);
                    flex: 0 0 calc(100% / 2.2);
                }
            }
            &__exclusive {
                position: relative;
                margin-bottom: 90px;
                @media screen and (max-width: 768px) {
                    margin-bottom: 50px;
                }
                &-title {
                    font-size: 48px;
                    color: $white;
                    text-align: center;
                    margin-bottom: 50px;
                    font-family: "ShellHand";
                    font-weight: 500;
                    @media screen and (max-width: 1440px) {
                        font-size: 42px;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 36px;
                    }
                }
                &-wrap {
                    position: relative;
                    &::before {
                        content: "";
                        background-color: #005853;
                        height: 50%;
                        width: 100%;
                        position: absolute;
                        top: -2px;
                        left: 0;
                        z-index: -1;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: 45%;
                        z-index: -1;
                        left: 0;
                        width: 100%;
                        height: 230px;
                        background-image: url("../../../img/promotions/shell-cafe-v2/gifts--bottom.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100%;
                        @media screen and (max-width: 1400px) {
                            top: 40%;
                        }
                        @media screen and (max-width: 992px) {
                            top: 32%;
                        }
                        @media screen and (max-width: 576px) {
                            height: 150px;
                            width: 200%;
                            left: -100%;
                        }
                    }
                }
                &-seeds {
                    position: absolute;
                    top: 24%;
                    left: 30%;
                    transform: translateX(-50%);
                    @media screen and (max-width: 1440px) {
                        img {
                            max-width: 150px;
                        }
                    }
                    @media screen and (max-width: 992px) {
                        left: 25%;
                    }
                    @media screen and (max-width: 768px) {
                        left: 16%;
                        img {
                            max-width: 120px;
                        }
                    }
                    @media screen and (max-width: 576px) {
                        top: 30%;
                        img {
                            max-width: 80px;
                        }
                    }
                }
                &-sun {
                    position: absolute;
                    font-size: 20px;
                    text-align: center;
                    top: 45%;
                    right: 24%;
                    color: #404040;
                    transform: translateX(-50%);
                    font-weight: bold;
                    @media screen and (max-width: 1440px) {
                        font-size: 18px;
                    }
                    @media screen and (max-width: 992px) {
                        right: 16%;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 12px;
                        right: 8%;
                    }
                    @media screen and (max-width: 576px) {
                        right: 15%;
                    }
                    .red {
                        font-size: 40px;
                        color: $shell-red;
                        @media screen and (max-width: 1440px) {
                            font-size: 32px;
                        }
                        @media screen and (max-width: 768px) {
                            font-size: 22px;
                        }
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        background-image: url("../../../img/promotions/shell-cafe-v2/goods/sun.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 310px;
                        width: 310px;
                        height: 310px;
                        top: -85px;
                        left: -90px;
                        z-index: -1;
                        animation: rotatingSun 20s linear infinite;
                        @media screen and (max-width: 1440px) {
                            top: -60px;
                            left: -70px;
                            width: 250px;
                            height: 250px;
                            background-size: 250px;
                        }
                        @media screen and (max-width: 768px) {
                            background-size: 180px;
                            width: 180px;
                            height: 180px;
                            top: -45px;
                            left: -55px;
                        }
                        @media screen and (max-width: 768px) {
                            background-size: 170px;
                            width: 170px;
                            height: 170px;
                            top: -42px;
                            left: -47px;
                        }
                    }
                }
                &-cup {
                    text-align: center;
                    .cup-image {
                        margin: 0 auto;
                        @media screen and (max-width: 1440px) {
                            max-width: 320px;
                        }
                        @media screen and (max-width: 576px) {
                            max-width: 200px;
                            margin-left: -24px;
                        }
                        @media screen and (max-width: 425px) {
                            margin-left: -60px;
                        }
                        @media screen and (max-width: 360px) {
                            margin-left: -50px;
                        }
                    }
                }
            }
        }
        .registration {
            padding-top: 80px;
            padding-bottom: 50px;
            box-shadow: 0px -10px 7px rgba(0, 0, 0, 0.1);
            @media screen and (max-width: $point-md-max) {
                padding-top: 50px;
                padding-bottom: 25px;
            }
            &__top-text {
                margin: 0;
                font-size: 36px;
                font-weight: 700;
                color: #404040;
                text-align: center;
                margin-bottom: 8px;
                @media screen and (max-width: $point-md-max) {
                    font-size: 22px;
                    padding-left: 16px;
                    padding-right: 16px;
                }
                &.color-red {
                    margin-bottom: 8px;
                    color: $shell-red;
                    &.mb-1em {
                        margin-bottom: 1em !important;
                    }
                }
            }
            &__list {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                padding-top: 70px;
                @media screen and (max-width: $point-md-max) {
                    flex-wrap: wrap;
                    justify-content: center;
                }
                &_item {
                    position: relative;
                    z-index: 1;
                    width: 24%;
                    @media screen and (max-width: $point-md-max) {
                        width: 50%;
                        margin-bottom: 30px;
                    }
                }
                &_icon {
                    display: block;
                    width: 41%;
                    margin: 0 auto;
                    @media screen and (max-width: $point-md-max) {
                        width: 50%;
                    }
                    > img {
                        width: 100%;
                    }
                }
                &_descr {
                    padding: 10% 10px 0 10px;
                    font-size: 24px;
                    font-weight: 700;
                    color: #404040;
                    text-align: center;
                    @media screen and (max-width: $point-md-max) {
                        font-size: 4.25vw;
                        padding: 10% 5px 0 5px;
                    }
                    a {
                        display: inline-block;
                        color: $shell-red;
                    }
                    span {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

@keyframes rotatingSun {
    0% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(1.1);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}
