/* =================================
Section Basket Certificate
================================= */

.basket-certificate {

    &.empty {
        .basket-empty {
            display: block;
        }
        .basket-not-empty {
            display: none;
        }
    }

    .section-title {
        position: relative;
        margin-bottom: em(20);
        padding-right: em(160);
        .product-count {
            position: absolute;
            bottom: 1em;
            right: 0;
            font-size: em(24);
            color: $shell-very-dark-gray;
            @media screen and (max-width: $point-md-max) {
                bottom: .75em;
                font-size: em(28);
            }
            > span {
                padding-left: .25em;
                font-size: 1.5em;
                font-weight: 700;
                color: $shell-red;
            }
        }
    }

    &__back-buy {
        margin-bottom: em(60);
        text-align: right;
        @media screen and (max-width: $point-md-max) {
            margin-bottom: em(50);
        }
        .link {
            font-size: em(24);
            color: $shell-very-dark-gray;
            text-decoration: underline;
            @media screen and (max-width: $point-lg-max) {
                font-size: em(18);
            }
            &:hover {
                text-decoration: none;
            }
        }
    }

    &__form-wrap {
        width: 100%;
        max-width: em(1040);
        margin: 0 auto;
        .form-title {
            margin-bottom: 2em;
            padding-bottom: .8em;
            font-size: em(24);
            color: $shell-very-dark-gray;
            border-bottom: 1px solid $shell-pale-gray;
            @media screen and (max-width: $point-lg-max) {
                font-size: em(18);
            }
        }
        .btns-switcher {
            &.mb-4em {
                margin-bottom: 4em;
            }
        }
        .form-group {
            input[readonly],
            input:disabled {
                &:hover,
                &:focus{
                    border-color: $border-input;
                }
            }
        }
    }

    .certificate-list {
        .certificate-item {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: em(80);
            padding-right: em(55);

            @media screen and (max-width: $point-lg-max) {
                flex-wrap: wrap;
                justify-content: flex-start;
                padding-right: 0;
            }
            @media screen and (max-width: $point-md-max) {
                margin-bottom: em(60);
            }

            &:last-child {
                margin-bottom: 0;
            }

            .img-wrap {
                width: auto;
                height: em(80);
                margin-right: em(15);
                @media screen and (max-width: $point-lg-max) {
                    margin-right: em(25);
                    margin-bottom: 2em;
                }
                @media screen and (max-width: $point-md-max) {
                    height: em(65);
                }
                @media screen and (max-width: $point-iphone-5-max) {
                    height: em(55);
                }
                > img {
                    width: auto;
                    height: 100%;
                }
            }

            .denomination {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: em(80);
                margin-right: em(15);
                @media screen and (max-width: $point-xl-max) {
                    width: em(130);
                }
                @media screen and (max-width: $point-lg-max) {
                    width: auto;
                    margin-right: 0;
                    margin-bottom: 2em;
                }
                @media screen and (max-width: $point-md-max) {
                    height: em(65);
                }
                @media screen and (max-width: $point-iphone-5-max) {
                    font-size: em(14);
                }
                > * {
                    font-size: em(18);
                }
                > p {
                    margin-bottom: 0;
                }
                .num {
                    font-size: em(24,18);
                }
            }

            .price-type {
                min-width: 35%;
                margin-right: em(15);
                @media screen and (max-width: $point-xl-max) {
                    min-width: 40%;
                }
                @media screen and (max-width: $point-lg-max) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 2em;
                }
                .form-group {
                    margin-bottom: 0;
                }
                .bootstrap-select {
                    .btn {
                        &-rect {
                            padding: em(42,19) 1em em(12,19) 1em;
                            font-size: em(19);
                            color: $shell-red;
                            @media screen and (max-width: $point-lg-max) {
                                padding: em(38,19) 1em em(9,19) 1em;
                            }
                        }
                    }
                    .dropdown-menu {
                        .dropdown-item {
                            &.active.selected {
                                display: none;
                            }
                        }
                    }
                }
            }

            .remove-certificate {
                position: absolute;
                width: auto;
                height: 100%;
                top: 0;
                right: 0;
                padding: .8em 0;
                font-size: em(30);
                color: $shell-red;
                cursor: pointer;
                @media screen and (max-width: $point-lg-max) {
                    height: em(73,30);
                    top: auto;
                    bottom: 0;
                }
            }

            .alert{
                position: absolute;
                top: 100%;
                background: none;
                border: 0;
                padding-left: 0;
                padding-right: 0;

                &.alert-danger{
                    color: #f00;
                }
            }

        }
    }

    .price-sum {
        margin-bottom: 3em;
        .sum-item {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin-bottom: 1em;
            font-size: em(24);
            @media screen and (max-width: $point-lg-max) {
                font-size: em(20);
            }
            > span {
                width: calc(100% - 7.0833333333em);
                text-align: right;
            }
            > span.color-red {
                display: inline-block;
                padding-left: 20px;
                width: em(170,24);
                text-align: left;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .basket-empty {
        display: none;
    }

}
