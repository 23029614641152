/* =================================
Page Promotion Covid-19
================================= */

.covid-19 {
    .yellow-block {
        .bg-yellow {
            background-color: #f4d12f;
            .text-block {
                @media screen and (max-width: $point-md-max) {
                    padding-left: 1em;
                    padding-right: 1em;
                    text-align: center !important;
                }
            }
        }
    }
    .questions-and-answers {
        .questions-and-answers-wrap {
            //width: 100%;
            //max-width: 725px;
            //margin: 0 auto;
        }
        .title-block {
            margin-bottom: 40px;
            font-size: em(26);
            font-weight: 700;
            text-align: center;
            color: $shell-very-dark-gray;
        }
        .questions-and-answers-item {
            padding: 30px 0;
            border-top: 2px solid $shell-yellow;
            &:last-child {
                border-bottom: 2px solid $shell-yellow;
            }
            .question {
                margin-bottom: 20px;
                font-size: em(24);
                font-weight: 700;
                color: $shell-red;
                line-height: 1.5;
                @media screen and (max-width: $point-lg-max) {
                    font-size: em(18);
                }
            }
            .answer {
                font-size: em(20);
                @media screen and (max-width: $point-lg-max) {
                    font-size: em(16);
                }
                a {
                    color: $shell-red;
                }
            }
        }
    }
    .text-block {
        text-align: justify;
    }
}