.gas-station-winner {
    overflow-x: hidden;
    &__main {
        margin-bottom: 20px;
        position: relative;
        @media screen and (max-width: 768px) {
            margin-bottom: 70px;
        }
        &-wrap {
            display: flex;
            align-items: initial;
            justify-content: center;
            position: relative;
            flex-wrap: wrap;
            &::after {
                content: "";
                position: absolute;
                width: 105%;
                height: 480px;
                bottom: -42%;
                left: -2.5%;
                background-image: url("../../../img/promotions/gas-station-winner/white-back.png");
                background-repeat: no-repeat;
                background-size: 100% auto;
                background-position: center bottom;
                @media screen and (max-width: 1680px) {
                    bottom: -36%;
                }
                @media screen and (max-width: 1440px) {
                    bottom: -41%;
                }
                @media screen and (max-width: 992px) {
                    bottom: -36%;
                }
                @media screen and (max-width: 768px) {
                    bottom: -8%;
                    height: 100px;
                    background-position: center;
                    width: calc(100%);
                    left: 0;
                    background-image: url("../../../img/promotions/gas-station-winner/banner-part_mob.png");
                }
                @media screen and (max-width: 576px) {
                    bottom: -10%;
                }
                @media screen and (max-width: 425px) {
                    bottom: -11%;
                }
                @media screen and (max-width: 375px) {
                    bottom: -12%;
                }
            }
        }
        &-image,
        &-title {
            max-width: 50%;
            flex: 0 0 50%;
            @media screen and (max-width: 768px) {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
        &-image {
            background: linear-gradient(to bottom left, #78c1d6, #fff);
            &-logo {
                padding-top: 45px;
                padding-left: 50px;
                margin-bottom: 100px;
                @media screen and (max-width: 1600px) {
                    margin-bottom: 80px;
                }
                @media screen and (max-width: 768px) {
                    margin-bottom: 40px;
                    padding-left: 24px;
                    padding-top: 30px;
                }
            }
            &-gas {
                img {
                    max-width: 90%;
                }
            }
        }
        &-title {
            background-color: #fbce07;
            padding-top: 150px;
            padding-bottom: 100px;
            @media screen and (max-width: 1600px) {
                padding-top: 120px;
            }
            @media screen and (max-width: 1200px) {
                padding-top: 70px;
            }
            @media screen and (max-width: 576px) {
                padding-top: 30px;
                padding-bottom: 20px;
            }
            h1 {
                margin-top: 0;
                text-align: center;
                color: $shell-red;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 88px;
                margin-bottom: 100px;
                @media screen and (max-width: 1600px) {
                    font-size: 72px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 64px;
                }
                @media screen and (max-width: 1200px) {
                    margin-bottom: 80px;
                }
                @media screen and (max-width: 992px) {
                    margin-bottom: 70px;
                    font-size: 52px;
                }
                @media screen and (max-width: 576px) {
                    font-size: 48px;
                    text-align: center;
                    margin-bottom: 30px;
                }
                @media screen and (max-width: 360px) {
                    font-size: 42px;
                }
                span {
                    letter-spacing: 4px;
                }
            }
            .go-well {
                text-align: right;
                @media screen and (max-width: 1600px) {
                    img {
                        max-width: 85%;
                    }
                }
                @media screen and (max-width: 576px) {
                    img {
                        max-width: 60%;
                    }
                }
            }
        }
    }
    &__about {
        margin-bottom: 50px;
        padding-bottom: 125px;
        padding-top: 125px;
        z-index: 2;
        position: relative;
        @media screen and (max-width: 1440px) {
            padding-top: 75px;
            padding-bottom: 75px;
        }
        @media screen and (max-width: 768px) {
            background-image: url("../../../img/promotions/gas-station-winner/back_mob.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin-bottom: 10px;
        }
        @media screen and (max-width: 768px) {
            padding: 10px 0 105px 0;
            margin-top: -70px;
        }
        @media screen and (max-width: 576px) {
            padding: 10px 0 60px 0;
            margin-top: -70px;
        }
        @media screen and (max-width: 425px) {
            margin-top: 90px;
            padding: 10px 0 50px 0;
            background-size: 200%;
            background-position: center -90px;
        }
        @media screen and (max-width: 415px) {
            background-size: 220%;
            background-position: center -85px;
        }
        @media screen and (max-width: 390px) {
            background-size: 235%;
            background-position: center -120px;
        }
        @media screen and (max-width: 376px) {
            background-size: 245%;
            background-position: center -175px;
        }
        @media screen and (max-width: 350px) {
            background-size: 270%;
            background-position: center -150px;
        }
        @media screen and (max-width: 340px) {
            background-size: 305%;
            background-position: center -170px;
        }
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -3;
            background-image: url("../../../img/promotions/gas-station-winner/yellow-back.png");
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: center top;
            min-width: 1920px;
            @media screen and (max-width: 1440px) {
                left: -15%;
            }
            @media screen and (max-width: 1280px) {
                left: -25%;
            }
            @media screen and (max-width: 768px) {
                display: none;
            }
            @media screen and (max-width: 425px) {
                display: block;
                background-image: url("../../../img/promotions/gas-station-winner/back_mob_part_top.png");
                min-width: 678px;
                left: 0;
                top: -172px;
            }
        }
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;
            z-index: -2;
            background-image: url("../../../img/promotions/gas-station-winner/red-back.png");
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: center bottom;
            min-width: 1920px;
            @media screen and (max-width: 1440px) {
                left: -15%;
                bottom: -60px;
            }
            @media screen and (max-width: 1280px) {
                left: -25%;
            }
            @media screen and (max-width: 992px) {
                left: -55%;
            }
            @media screen and (max-width: 800px) {
                left: -75%;
            }
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
        &-title {
            font-size: 48px;
            text-align: center;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 50px;
            color: #333333;
            @media screen and (max-width: 1440px) {
                font-size: 42px;
            }
            @media screen and (max-width: 1280px) {
                font-size: 36px;
            }
            @media screen and (max-width: 768px) {
                margin-top: 100px;
            }
            @media screen and (max-width: 425px) {
                font-size: 30px;
                margin-bottom: 20px;
            }
            @media screen and (max-width: 425px) {
                margin-top: 30px;
            }
            @media screen and (max-width: 390px) {
                margin-top: -20px;
            }
        }
        &-content {
            padding-left: 80px;
            padding-right: 50px;
            @media screen and (max-width: 992px) {
                padding-left: 20px;
                padding-right: 20px;
            }
            @media screen and (max-width: 576px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            @media screen and (max-width: 576px) {
                align-items: flex-start;
            }
            .image {
                width: 200px;
                flex: 0 0 200px;
                max-width: 200px;
                @media screen and (max-width: 1280px) {
                    flex: 0 0 150px;
                    max-width: 150px;
                }
                @media screen and (max-width: 576px) {
                    flex: 0 0 120px;
                    max-width: 120px;
                }
                img {
                    width: 100%;
                }
            }
            .text {
                font-weight: bold;
                font-size: 36px;
                color: #fff;
                text-transform: uppercase;
                .red {
                    color: $shell-red;
                }
                .large {
                    font-size: 72px;
                    @media screen and (max-width: 1680px) {
                        font-size: 64px;
                    }
                    @media screen and (max-width: 992px) {
                        font-size: 48px;
                    }
                    @media screen and (max-width: 425px) {
                        font-size: 42px;
                    }
                }
                .yellow {
                    color: #fbce07;
                }
                @media screen and (max-width: 1680px) {
                    font-size: 30px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 22px;
                }
                @media screen and (max-width: 425px) {
                    font-size: 19px;
                }
            }
            .stores {
                display: flex;
                align-items: center;
                padding-left: 80px;
                max-width: 50%;
                flex: 0 0 50%;
                @media screen and (max-width: 1680px) {
                    padding-left: 60px;
                }
                @media screen and (max-width: 1280px) {
                    padding-left: 30px;
                }
                @media screen and (max-width: 992px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    justify-content: center;
                    margin-left: 0;
                }
                @media screen and (max-width: 768px) {
                    flex-wrap: wrap;
                }
                .item {
                    max-width: 280px;
                    margin-right: 40px;
                    &:last-child {
                        margin-right: 0;
                    }
                    img {
                        width: 90%;
                    }
                    @media screen and (max-width: 768px) {
                        max-width: 200px;
                        margin-right: 0;
                        margin-bottom: 20px;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
            &--1 {
                .image {
                    width: 185px;
                    margin-right: 10px;
                    @media screen and (max-width: 576px) {
                        width: 125px;
                    }
                }
                .text {
                    color: #333333;
                    margin-top: -10px;
                    @media screen and (max-width: 768px) {
                        margin-top: 0;
                    }
                    @media screen and (max-width: 576px) {
                        padding-right: 35px;
                    }
                    @media screen and (max-width: 425px) {
                        padding-left: 0;
                        padding-top: 40px;
                    }
                    @media screen and (max-width: 425px) {
                        padding-right: 20px;
                    }
                }
                @media screen and (max-width: 768px) {
                    padding-right: 205px;
                    margin-bottom: 50px;
                }
                @media screen and (max-width: 576px) {
                    padding-right: 0;
                    margin-bottom: 25px;
                }
                @media screen and (max-width: 425px) {
                    margin-bottom: 50px;
                }
            }
            &--2 {
                margin-bottom: 40px;
                padding-left: 220px;
                .image {
                    margin-right: 50px;
                }
                .text {
                    color: #fff;
                }
                @media screen and (max-width: 1680px) {
                    padding-left: 180px;
                }
                @media screen and (max-width: 1280px) {
                    padding-left: 50px;
                }
                @media screen and (max-width: 992px) {
                    padding-left: 0;
                    .image {
                        margin-right: 20px;
                    }
                }
                @media screen and (max-width: 768px) {
                    padding-left: 0px;
                    .text {
                        padding-top: 25px;
                    }
                }
            }
            &--3 {
                .text {
                    max-width: 50%;
                    flex: 0 0 50%;
                    @media screen and (max-width: 1680px) {
                        font-size: 30px;
                    }
                    @media screen and (max-width: 992px) {
                        text-align: center;
                        max-width: 100%;
                        flex: 0 0 100%;
                        margin-bottom: 24px;
                        font-size: 22px;
                    }
                }
                @media screen and (max-width: 992px) {
                    display: block;
                }
            }
        }
        &-gas-gun {
            position: absolute;
            width: 100%;
            height: 100%;
            right: 0;
            top: 7.5%;
            z-index: -1;
            img {
                width: 100%;
            }
            &--mobile {
                display: none;
            }
            @media screen and (max-width: 1680px) {
                top: 11.5%;
            }
            @media screen and (max-width: 1440px) {
            }
            @media screen and (max-width: 1200px) {
                right: -4%;
                width: 104%;
            }
            @media screen and (max-width: 992px) {
                top: 15%;
            }
            @media screen and (max-width: 768px) {
                &--desktop {
                    display: none;
                }
                &--mobile {
                    display: block;
                    right: 0;
                    width: 100%;
                    top: 24%;
                }
            }
            @media screen and (max-width: 425px) {
                &--mobile {
                    top: 15%;
                }
            }
            @media screen and (max-width: 390px) {
                &--mobile {
                    top: 7%;
                }
            }
        }
    }
    &__winners-table {
        padding-bottom: 50px;
        margin-bottom: 100px;
        @media screen and (max-width: $point-md-max) {
            margin-bottom: 20px;
        }
    }
    &__winners-table-title {
        margin-bottom: 70px;
        color: $shell-red;
        font-size: em(52);
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        @media screen and (max-width: $point-md-max) {
            font-size: em(34);
            margin-bottom: 35px;
        }
        .small-text {
            font-size: 0.65em;
        }
    }
    &__winners-table-wrap {
        position: relative;
        padding: 70px 5% 0;
        background-color: $shell-yellow;
        @media screen and (max-width: $point-md-max) {
            padding: 35px 20px 0;
        }
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 150px;
            position: absolute;
            z-index: -1;
            top: 100%;
            left: 0;
            background: rgb(251, 206, 7);
            background: -moz-linear-gradient(
                180deg,
                rgba(251, 206, 7, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            background: -webkit-linear-gradient(
                180deg,
                rgba(251, 206, 7, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            background: linear-gradient(
                180deg,
                rgba(251, 206, 7, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbce07",endColorstr="#ffffff",GradientType=1);
            @media screen and (max-width: $point-md-max) {
                height: 50px;
                top: 99%;
                background: rgb(251, 206, 7);
                background: -moz-linear-gradient(
                    180deg,
                    rgba(251, 206, 7, 1) 75%,
                    rgba(255, 255, 255, 0) 100%
                );
                background: -webkit-linear-gradient(
                    180deg,
                    rgba(251, 206, 7, 1) 75%,
                    rgba(255, 255, 255, 0) 100%
                );
                background: linear-gradient(
                    180deg,
                    rgba(251, 206, 7, 1) 75%,
                    rgba(255, 255, 255, 0) 100%
                );
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbce07",endColorstr="#ffffff",GradientType=1);
            }
        }
        table {
            width: 100%;
            font-size: em(24);
            font-weight: 700;
            color: $shell-very-dark-gray;
            text-align: center;
            @media screen and (max-width: $point-scaling-cancel) {
                font-size: em(20);
            }
            @media screen and (max-width: $point-lg-max) {
                font-size: em(18);
            }
            &.showing {
                tbody {
                    tr {
                        &:nth-child(n + 8) {
                            display: table-row;
                        }
                        @media screen and (max-width: $point-md-max) {
                            &:nth-child(n + 3) {
                                display: block;
                            }
                        }
                    }
                }
            }
            thead {
                color: $shell-red;
                tr {
                    background-color: #ffffff;
                    border-bottom: 5px solid $shell-yellow;
                }
            }
            tbody {
                tr {
                    &:nth-child(odd) {
                        background-color: rgba(255, 255, 255, 0.4);
                    }
                    &:nth-child(even) {
                        @media screen and (max-width: $point-md-max) {
                            background-color: rgba(255, 255, 255, 0.4);
                        }
                    }
                    &:nth-child(n + 8) {
                        display: none;
                    }
                    @media screen and (max-width: $point-md-max) {
                        &:nth-child(n + 3) {
                            display: none;
                        }
                    }
                }
            }
            th {
                padding: 1em 0.5em;
            }
            td {
                padding: 1.25em 0.5em;
            }
            @media only screen and (max-width: $point-md-max),
                (min-device-width: $point-md-max) and (max-device-width: 1024px) {
                thead,
                tbody,
                th,
                td,
                tr {
                    display: block;
                }
                thead tr {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                }

                tr {
                    margin-bottom: 50px;
                }

                td {
                    /* Behave  like a "row" */
                    border: none;
                    position: relative;
                    padding: 1em 0;
                    height: auto !important;
                    padding-top: 0;
                }

                td:before {
                    position: inherit;
                    width: 100%;
                    display: block;
                    padding: 1em 0.5em;
                    color: $shell-red;
                    background-color: $white;
                    margin-bottom: 1em;
                    -webkit-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05);
                    -moz-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05);
                    box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05);
                }

                /*
                Label the data
                */
                td:nth-of-type(1):before {
                    content: "Дата";
                }
                td:nth-of-type(2):before {
                    content: "ПІБ";
                }
                td:nth-of-type(3):before {
                    content: "Телефон";
                }
                td:nth-of-type(4):before {
                    content: "Номер картки";
                }
                td:nth-of-type(5):before {
                    content: "Місце виграшу";
                }
            }
        }
    }
    &__winners-empty {
        margin: 80px 0;
        font-size: em(24);
        font-weight: 700;
        color: $shell-very-dark-gray;
        text-align: center;
        @media screen and (max-width: $point-md-max) {
            margin: 50px 0 120px;
            font-size: em(20);
            text-align: center;
        }
    }
    .registration {
        padding-top: 80px;
        padding-bottom: 50px;
        @media screen and (max-width: $point-md-max) {
            padding-top: 25px;
            padding-bottom: 25px;
        }
        &__top-text {
            margin: 0;
            font-size: 36px;
            font-weight: 700;
            color: $ferrari-cars-grey;
            text-align: center;
            @media screen and (max-width: $point-md-max) {
                font-size: 22px;
                padding-left: 0px;
                padding-right: 0px;
            }
            &.color-red {
                margin-bottom: 1em;
                color: $shell-red;
            }
        }
        &__list {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 70px;
            @media screen and (max-width: $point-md-max) {
                flex-wrap: wrap;
                justify-content: center;
            }
            &_item {
                position: relative;
                z-index: 1;
                width: 24%;
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                    margin-bottom: 30px;
                }
            }
            &_icon {
                display: block;
                width: 41%;
                margin: 0 auto;
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                }
                > img {
                    width: 100%;
                }
            }
            &_descr {
                padding: 10% 10px 0 10px;
                font-size: 24px;
                font-weight: 700;
                color: $ferrari-cars-grey;
                text-align: center;
                @media screen and (max-width: $point-md-max) {
                    font-size: 4.25vw;
                    padding: 10% 5px 0 5px;
                }
                a {
                    display: inline-block;
                    color: $shell-red;
                }
                span {
                    display: inline-block;
                }
            }
        }
    }
    &__superprise {
        padding-top: 70px;
        min-height: 650px;
        margin-bottom: 40px;
        position: relative;
        @media screen and (max-width: 1600px) {
            min-height: 600px;
        }
        @media screen and (max-width: 1400px) {
            min-height: 520px;
        }
        @media screen and (max-width: 576px) {
            margin-bottom: 0;
        }
        @media screen and (max-width: 425px) {
            padding-top: 40px;
        }
        &-bg-image {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            left: 0;
            top: 135px;
            img {
                width: 100%;
            }
            @media screen and (max-width: 992px) {
                top: 155px;
            }
            &--desktop {
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
            &--mobile {
                display: none;
                position: initial;
                @media screen and (max-width: 768px) {
                    display: block;
                }
            }
        }
        &-title {
            font-size: 72px;
            text-transform: uppercase;
            color: $shell-red;
            text-align: center;
            font-weight: bold;
            @media screen and (max-width: 1600px) {
                font-size: 64px;
                margin-bottom: 10px;
            }
            @media screen and (max-width: 768px) {
                font-size: 48px;
                margin-bottom: 40px;
            }
        }
        &-circle {
            border-radius: 150%;
            width: 285px;
            height: 285px;
            text-align: center;
            display: flex;
            position: absolute;
            z-index: 10;
            bottom: 20px;
            right: 20%;
            align-items: center;
            justify-content: center;
            animation: gasStationSurprise 1.2s ease-in-out infinite;
            @media screen and (max-width: 1600px) {
                width: 240px;
                height: 240px;
                bottom: 75px;
            }
            @media screen and (max-width: 1400px) {
                width: 210px;
                height: 210px;
                bottom: 20px;
            }
            @media screen and (max-width: 992px) {
                width: 185px;
                height: 185px;
                bottom: 40px;
            }
            @media screen and (max-width: 768px) {
                width: 240px;
                height: 240px;
                right: auto;
                left: 50%;
                transform: translateX(-50%);
                bottom: 20%;
            }
            @media screen and (max-width: 576px) {
                width: 185px;
                height: 185px;
            }
            @media screen and (max-width: 576px) {
                bottom: 15%;
            }
            @media screen and (max-width: 415px) {
                bottom: 10%;
            }
            @media screen and (max-width: 390px) {
                bottom: 8%;
            }
            &::before {
                content: "";
                width: calc(100% + 60px);
                height: calc(100% + 60px);
                top: -30px;
                left: -30px;
                position: absolute;
                z-index: -1;
                background-color: rgba(#f2f2f2, 0.6);
                border-radius: 150%;
                @media screen and (max-width: 1400px) {
                    width: calc(100% + 40px);
                    height: calc(100% + 40px);
                    top: -20px;
                    left: -20px;
                }
                @media screen and (max-width: 768px) {
                }
            }
            &-inner {
                display: block;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 30px;
                flex-wrap: wrap;
                text-align: center;
                box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);
                padding: 12px;
                width: 100%;
                border-radius: 150%;
                height: 100%;
                background: $shell-red;
                color: #fff;
                display: flex;
                align-content: center;
                justify-content: center;
                @media screen and (max-width: 1600px) {
                    font-size: 27px;
                }
                @media screen and (max-width: 1400px) {
                    font-size: 24px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 20px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 27px;
                }
                @media screen and (max-width: 576px) {
                    font-size: 20px;
                }
                span {
                    flex: 0 0 100%;
                    max-width: 100%;
                    display: block;
                    &.large {
                        font-size: 72px;
                        line-height: 85%;
                        @media screen and (max-width: 1600px) {
                            font-size: 64px;
                        }
                        @media screen and (max-width: 1400px) {
                            font-size: 54px;
                        }
                        @media screen and (max-width: 992px) {
                            font-size: 48px;
                        }
                        @media screen and (max-width: 768px) {
                            font-size: 64px;
                        }
                        @media screen and (max-width: 576px) {
                            font-size: 48px;
                        }
                    }
                    &.yellow {
                        font-size: 32px;
                        color: $shell-yellow;
                        @media screen and (max-width: 1600px) {
                            font-size: 27px;
                        }
                        @media screen and (max-width: 1400px) {
                            font-size: 24px;
                        }
                        @media screen and (max-width: 992px) {
                            font-size: 21px;
                        }
                        @media screen and (max-width: 768px) {
                            font-size: 27px;
                        }
                        @media screen and (max-width: 576px) {
                            font-size: 21px;
                        }
                    }
                }
            }
        }
    }
    &__gifts {
        position: relative;
        &-bg {
            position: absolute;
            top: 30%;
            left: -25%;
            width: 150%;
            height: 100%;
            img {
                width: 100%;
            }
            @media screen and (max-width: 1400px) {
                width: 200%;
                left: -50%;
            }
            @media screen and (max-width: 768px) {
                width: 400%;
                left: -200%;
                top: 5%;
            }
            &--desktop {
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
            &--mobile {
                display: none;
                @media screen and (max-width: 768px) {
                    display: block;
                    width: 100%;
                    left: 0;
                    top: 25%;
                }
            }
        }
        &-title {
            font-size: 48px;
            text-transform: uppercase;
            color: #333333;
            text-align: center;
            font-weight: bold;
            margin-bottom: 85px;
            @media screen and (max-width: 1400px) {
                margin-bottom: 40px;
            }
            @media screen and (max-width: 768px) {
                font-size: 30px;
            }
        }
        &-content {
            padding: 0 100px;
            position: relative;
            @media screen and (max-width: 768px) {
                padding: 0 35px;
            }
            @media screen and (max-width: 425px) {
                padding: 0 21px;
            }
        }
        &-slider {
            position: relative;
            margin-bottom: 50px;
            overflow: hidden;
            @media screen and (max-width: 1400px) {
                margin-bottom: 30px;
            }
            &-arrow {
                cursor: pointer;
                top: 100px;
                position: absolute;
                z-index: 20;
                @media screen and (max-width: 992px) {
                    top: 75px;
                }
                @media screen and (max-width: 576px) {
                    top: 65px;
                }
                @media screen and (max-width: 425px) {
                    top: 50px;
                }
                @media screen and (max-width: 376px) {
                    top: 45px;
                }
                img {
                    width: 32px;
                    @media screen and (max-width: 425px) {
                        width: 21px;
                    }
                }
                &--left {
                    left: 20px;
                    @media screen and (max-width: 768px) {
                        left: 0;
                    }
                }
                &--right {
                    left: auto;
                    right: 20px;
                    @media screen and (max-width: 768px) {
                        right: 0;
                    }
                }
            }
        }
        &-slide {
            padding: 0 15px;
            .title {
                text-align: center;
                font-size: 22px;
                font-weight: bold;
                @media screen and (max-width: 1400px) {
                    font-size: 20px;
                }
                @media screen and (max-width: 1024px) {
                    font-size: 18px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 17px;
                }
            }
            .image {
                img {
                    width: 100%;
                    max-width: 230px;
                    margin: 0 auto;
                }
            }
        }
    }
}
