.power_duo {
    max-width: 100%;
    overflow: hidden;
    .container-fluid {
        @media screen and (max-width: 576px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &-main {
        &__wrap {
            background-color: #fdc400;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            padding: 24px 0 30px 80px;
            @media screen and (max-width: 1440px) {
                padding: 24px 0 30px 60px;
            }
            @media screen and (max-width: 768px) {
                padding: 20px 24px 20px;
            }
            @media screen and (max-width: 576px) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                padding-bottom: 0;
            }
        }
        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media screen and (max-width: 768px) {
                display: block;
                &--logos {
                    display: flex;
                    margin-bottom: 30px;
                }
            }
        }
        &__shell {
            &-logo {
                width: 100%;
                max-width: 260px;
                img {
                    width: 100%;
                }
                @media screen and (max-width: 992px) {
                    max-width: 140px;
                }
                @media screen and (max-width: 576px) {
                    max-width: 126px;
                }
            }
            &-cafe {
                margin-right: 55px;
                width: 100%;
                max-width: 140px;
                img {
                    width: 100%;
                }
                @media screen and (max-width: 992px) {
                    max-width: 100px;
                }
                @media screen and (max-width: 768px) {
                    margin-right: 0;
                }
                @media screen and (max-width: 576px) {
                    max-width: 70px;
                }
            }
        }
        &__titles {
            h1 {
                color: #db1d21;
                font-size: 80px;
                font-weight: bold;
                margin-bottom: 50px;
                .break {
                    display: block;
                }
                @media screen and (max-width: 1900px) {
                    font-size: 72px;
                }
                @media screen and (max-width: 1720px) {
                    font-size: 64px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 54px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 40px;
                }
                @media screen and (max-width: 768px) {
                    margin-bottom: 20px;
                }
                @media screen and (max-width: 425px) {
                    font-size: 40px;
                }
                @media screen and (max-width: 400px) {
                    font-size: 36px;
                }
                @media screen and (max-width: 370px) {
                    font-size: 34px;
                }
            }
            h3 {
                font-weight: bold;
                font-size: 52px;
                color: #db1d21;
                .break {
                    display: block;
                }
                span {
                    color: #575756;
                }
                @media screen and (max-width: 1900px) {
                    font-size: 46px;
                }
                @media screen and (max-width: 1720px) {
                    font-size: 40px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 36px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 26px;
                }
                @media screen and (max-width: 425px) {
                    font-size: 22px;
                }
            }
        }
        &__image {
            max-width: 50%;
            flex: 0 0 50%;
            padding-top: 24px;
            @media screen and (max-width: 768px) {
                max-width: 100%;
            }
            img {
                width: 100%;
                &.mobile {
                    display: none;
                }
                @media screen and (max-width: 576px) {
                    &.desktop {
                        display: none;
                    }
                    &.mobile {
                        display: block;
                    }
                }
            }
        }
    }
    &-conditions {
        margin-top: 16px;
        margin-bottom: 60px;
        @media screen and (max-width: 576px) {
            margin-top: 10px;
            margin-bottom: 32px;
        }
        &__wrap {
            padding: 140px 90px;
            background-color: #fdc400;
            border-radius: 16px;
            @media screen and (max-width: 1440px) {
                padding: 100px 60px;
            }
            @media screen and (max-width: 768px) {
                border-radius: 0;
                padding: 74px 24px;
            }
        }
        &__item {
            background-color: #fff;
            position: relative;
            display: flex;
            border-radius: 16px;
            justify-content: space-between;
            margin-bottom: 120px;
            padding: 50px 40px;
            @media screen and (max-width: 768px) {
                flex-direction: row;
                flex-wrap: wrap;
                row-gap: 24px;
                padding: 20px;
                padding-top: 200px;
                padding-bottom: 40px;
                margin-bottom: 40px;
            }
            @media screen and (max-width: 400px) {
                padding-top: 150px;
            }
            .image {
                position: relative;
                flex: 0 0 45%;
                z-index: 2;
                .desktop-img{
                    display: block;
                }
                .mobile-img{
                    display: none;
                }
                @media screen and (max-width: 768px) {
                    flex: 0 0 100%;
                }
                @media screen and (max-width: 576px) {
                    .desktop-img{
                        display: none;
                        bottom: -10px;
                    }
                    .mobile-img{
                        display: block;
                    }
                }
                img {
                    position: absolute;
                    filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.25));
                    bottom: -40px;
                    left: 0;
                    z-index: 2;
                    width: 100%;
                    max-width: 475px;
                    @media screen and (max-width: 1720px) {
                        max-width: 400px;
                        padding-right: 24px;
                    }
                    @media screen and (max-width: 768px) {
                        bottom: 0;
                    }
                }
            }
            .content {
                flex: 0 0 55%;
                z-index: 2;
                @media screen and (max-width: 768px) {
                    flex: 0 0 100%;
                }
                .title {
                    font-size: 48px;
                    font-weight: bold;
                    color: #db1d21;
                    span {
                        color: #575756;
                    }
                    @media screen and (max-width: 1720px) {
                        font-size: 42px;
                    }
                    @media screen and (max-width: 1440px) {
                        font-size: 32px;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 30px;
                    }
                }
                .text {
                    font-size: 32px;
                    font-weight: bold;
                    color: #db1d21;
                    span {
                        color: #575756;
                    }
                    @media screen and (max-width: 1720px) {
                        font-size: 24px;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 20px;
                    }
                }
            }
            &::after {
                content: "";
                position: absolute;
                top: 4px;
                z-index: 1;
                left: 4px;
                width: calc(100% - 8px);
                height: calc(100% - 8px);
                border: 4px solid #fdc400;
                border-radius: 16px;
            }
            &--f1{
                @media screen and (max-width: 768px) {
                    padding-top: 250px;
                }
                @media screen and (max-width: 576px) {
                    padding: 18px;
                    padding-top: 380px;
                    row-gap: 0px;
                }
                .image {
                    flex: 0 0 55%;
                    @media screen and (max-width: 1800px) {
                        flex: 0 0 50%;
                    }
                    @media screen and (max-width: 1720px) {
                        flex: 0 0 50%;
                    }
                    @media screen and (max-width: 1600px) {
                        flex: 0 0 50%;
                    }
                    @media screen and (max-width: 1100px) {
                        flex: 0 0 50%;
                    }
                    @media screen and (max-width: 900px) {
                        flex: 0 0 50%;
                    }
                    @media screen and (max-width: 800px) {
                        flex: 0 0 50%;
                    }
                    @media screen and (max-width: 768px) {
                        flex: 0 0 100%;
                    }
                   
                    img {
                        left: -100px;
                        bottom: -80px;
                        max-width: 840px;
                        width: auto;
                        @media screen and (max-width: 1800px) {
                            max-width: 130%;
                            left: -100px;
                            bottom: -70px;
                        }
                        @media screen and (max-width: 1720px) {
                            max-width: 140%;
                            left: -100px;
                            bottom: -70px;
                        }
                        @media screen and (max-width: 1100px) {
                            max-width: 140%;
                            left: -75px;
                            bottom: -65px;
                        }
                        @media screen and (max-width: 900px) {
                            max-width: 150%;
                            left: -70px;
                            bottom: -70px;
                        }
                        @media screen and (max-width: 768px) {
                            max-width: 110%;
                            left: -10px;
                            bottom: -30px;
                        }
                        @media screen and (max-width: 576px) {
                            max-width: 130%;
                            bottom: 0;
                            left: 25px;
                        }
                        @media screen and (max-width: 485px) {
                            max-width: 130%;
                            bottom: 0;
                            left: -25px;
                        }
                    }
                }
                .content {
                    flex: 0 0 45%;
                    margin-left: 25px;
                    @media screen and (max-width: 1800px) {
                        flex: 0 0 45%;
                        margin-left: 50px;
                        .title{
                            font-size: 42px;
                        }
                    }
                    @media screen and (max-width: 1700px) {
                        .title{
                            font-size: 38px;
                        }
                    }
                    @media screen and (max-width: 1600px) {
                        flex: 0 0 49%;
                        margin-left: 40px;
                        .title{
                            font-size: 35px;
                        }
                    }
                    @media screen and (max-width: 1300px) {
                        flex: 0 0 48%;
                        margin-left: 30px;
                        .title{
                            font-size: 30px;
                        }
                    }
                    @media screen and (max-width: 1100px) {
                        flex: 0 0 50%;
                        .title{
                            font-size: 26px;
                        }
                    }
                    @media screen and (max-width: 900px) {
                        flex: 0 0 48%;
                        margin-left: 35px;
                        .title{
                            font-size: 24px;
                        }
                    }
                    @media screen and (max-width: 800px) {
                        flex: 0 0 50%;
                        margin-left: 32px;
                    }
                    @media screen and (max-width: 768px) {
                        flex: 0 0 100%;
                        margin-left: 0;
                    }
                }
            }
        }
        &__qr {
            &-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 30px;
                @media screen and (max-width: 768px) {
                    flex-wrap: wrap;
                    justify-content: space-between;
                    column-gap: 0;
                    align-items: initial;
                }
            }
            .qr {
                @media screen and (max-width: 768px) {
                    flex: 0 0 calc(44% - 8px);
                }
                img {
                    max-width: 160px;
                    width: 100%;
                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                }
            }
            .apps {
                @media screen and (max-width: 768px) {
                    flex: 0 0 calc(54% - 8px);
                }
                .title {
                    color: #575756;
                    font-weight: bold;
                    font-size: 24px;
                    margin-bottom: 42px;
                    .break {
                        display: block;
                    }
                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                }
                &-wrap {
                    display: flex;
                    align-items: center;
                    column-gap: 30px;
                    @media screen and (max-width: 768px) {
                        display: flex;
                        align-items: initial;
                        justify-content: space-between;
                        flex-direction: column;
                        height: 100%;
                    }
                }
                &_item {
                    a {
                        cursor: pointer;
                        img {
                            width: 200px;
                            @media screen and (max-width: 768px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            &-title-mobile {
                color: #575756;
                font-weight: bold;
                font-size: 20px;
                text-align: center;
                margin-bottom: 42px;
                flex: 0 0 100%;
                .break {
                    display: block;
                }
                @media screen and (min-width: 768px) {
                    display: none;
                }
            }
        }
    }
    .registration {
        padding-top: 20px;
        @media screen and (max-width: $point-md-max) {
            padding-top: 50px;
            padding-bottom: 25px;
        }
        &__top-text {
            margin: 0;
            font-size: 30px;
            font-weight: 700;
            color: #404040;
            text-align: center;
            margin-bottom: 8px;
            @media screen and (max-width: $point-md-max) {
                font-size: 22px;
                padding-left: 16px;
                padding-right: 16px;
            }
            @media screen and (max-width: 600px) {
                .desktop-br {
                    display: none;
                }
            }
            &.color-red {
                margin-bottom: 8px;
                color: $shell-red;
                &.mb-1em {
                    margin-bottom: 1em !important;
                }
            }
        }
        &__list {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 70px;
            @media screen and (max-width: 768px) {
                //(max-width: $point-md-max) {
                flex-wrap: wrap;
                justify-content: center;
            }
            &_item {
                position: relative;
                z-index: 1;
                width: 24%;
                @media screen and (max-width: 768px) {
                    //$point-md-max) {
                    width: 50%;
                    margin-bottom: 30px;
                }
            }
            &_icon {
                display: block;
                width: 41%;
                margin: 0 auto;
                @media screen and (max-width: 768px) {
                    //(max-width: $point-md-max) {
                    width: 50%;
                }
                > img {
                    width: 100%;
                }
            }
            &_descr {
                padding: 10% 10px 0 10px;
                font-size: 24px;
                font-weight: 700;
                color: #404040;
                text-align: center;
                @media screen and (max-width: 768px) {
                    //(max-width: $point-md-max) {
                    font-size: 4.25vw;
                    padding: 10% 5px 0 5px;
                }
                a {
                    display: inline-block;
                    color: $shell-red;
                }
                span {
                    display: inline-block;
                }
            }
        }
    }
    &-waiting {
        margin: 100px 0 24px 0;
        @media screen and (max-width: 576px) {
            margin: 24px 0;
        } 
        &__banner {
            img {
                width: 100%;
                &.desktop {
                    @media screen and (max-width: 576px) {
                        display: none;
                    }
                }
                &.mobile {
                    display: none;
                    @media screen and (max-width: 576px) {
                        display: block;
                    }
                }
            }
        }
    }
    .rules {
        @media screen and (max-width: 768px) {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}
