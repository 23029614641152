/* =================================
Page Buy Certificate Styles
================================= */

.buy-certificate {
    .image-block {
        position: relative;
    }
    .link-bodo {
        display: flex;
        justify-content: space-around;
        position: absolute;
        width: 28%;
        height: 23%;
        right: 0;
        bottom: 5%;
        @media screen and (max-width: $point-md-max) {
            width: 60%;
            height: 11%;
            right: auto;
            left: 0;
            bottom: 0;
        }
        > a {
            display: inline-block;
            width: 100%;
            height: 100%;
        }
    }
}