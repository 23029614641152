/* =================================
Section Personal Orders
================================= */

.orders {
    .personal-cabinet__table-wrap {
        padding-top: 0;
    }
}

.popup-table {
    &__orders {
        .popup-table__table-wrap {
            table {
                @media screen and (max-width: $point-md-max) {
                    font-size: em(13);
                }
                tr {
                    &:last-child {
                        border-top: 2px solid #ffffff;
                        background-color: #fff2b2;
                    }
                    td {
                        &:last-child {
                            p {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                td {
                    p {
                        margin-bottom: .5em;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .num {
                    font-size: em(24,18);
                    font-weight: 700;
                    color: $shell-red;
                    @media screen and (max-width: $point-lg-max) {
                        font-size: em(20,18);
                    }
                }
            }
        }
    }
}