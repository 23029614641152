
  $icon-arrow-down: "\ea01";

  $icon-arrow-down-bold: "\ea02";

  $icon-arrow-down-bold-grey: "\ea03";

  $icon-arrow-next: "\ea04";

  $icon-arrow-next-v1: "\ea05";

  $icon-arrow-prev: "\ea06";

  $icon-arrow-prev-v1: "\ea07";

  $icon-arrow-prev-v2: "\ea08";

  $icon-basket: "\ea09";

  $icon-call: "\ea0a";

  $icon-cancel: "\ea0b";

  $icon-clock: "\ea0c";

  $icon-close: "\ea0d";

  $icon-drop: "\ea0e";

  $icon-gas: "\ea0f";

  $icon-gas-station: "\ea10";

  $icon-information: "\ea11";

  $icon-intermediate-pin: "\ea12";

  $icon-left-and-right: "\ea13";

  $icon-location-pin: "\ea14";

  $icon-login: "\ea15";

  $icon-logout: "\ea16";

  $icon-magnifying-glass: "\ea17";

  $icon-menu: "\ea18";

  $icon-pen: "\ea19";

  $icon-phone-call: "\ea1a";

  $icon-quality: "\ea1b";

  $icon-sale: "\ea1c";

  $icon-shild: "\ea1d";

  $icon-tabs-how-to-register-certificate-www: "\ea1e";

  $icon-tabs-how-to-register-gas-station: "\ea1f";

  $icon-tabs-how-to-register-phone: "\ea20";

  $icon-tabs-how-to-register-phone-call: "\ea21";

  $icon-tabs-how-to-register-telegram: "\ea22";

  $icon-tabs-rewards-certificate: "\ea23";

  $icon-tabs-rewards-gas-station: "\ea24";

  $icon-tabs-rewards-price-tag: "\ea25";

  $icon-tabs-rewards-shopping-bag: "\ea26";

  $icon-user-profile: "\ea27";

  $icon-v-power: "\ea28";

  $icon-wall-clock: "\ea29";

  $icon-wallet: "\ea2a";

  $icon-wallet-red: "\ea2b";

  $icon-warning: "\ea2c";


@font-face {
  font-display: block;
  font-family: "icons";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/icons.eot?");
  src: url("../../fonts/icons.eot?#iefix") format("embedded-opentype"), url("../../fonts/icons.woff2?") format("woff2"), url("../../fonts/icons.woff?") format("woff"), url("../../fonts/icons.ttf?") format("truetype"), url("../../fonts/icons.svg?#icons") format("svg");
}

%icon {
display: inline-block;
  font-family: "icons";
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.icon {
  @extend %icon;
}

.icon-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.icon-xs {
  font-size: 0.75em;
}

.icon-sm {
  font-size: 0.875em;
}

.icon-1x {
  font-size: 1em;
}

.icon-2x {
  font-size: 2em;
}

.icon-3x {
  font-size: 3em;
}

.icon-4x {
  font-size: 4em;
}

.icon-5x {
  font-size: 5em;
}

.icon-6x {
  font-size: 6em;
}

.icon-7x {
  font-size: 7em;
}

.icon-8x {
  font-size: 8em;
}

.icon-9x {
  font-size: 9em;
}

.icon-10x {
  font-size: 10em;
}

.icon-fw {
  text-align: center;
  width: 1.25em;
}

.icon-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.icon-pull-left {
  float: left;
}

.icon-pull-right {
  float: right;
}

.icon.icon-pull-left {
  margin-right: 0.3em;
}

.icon.icon-pull-right {
  margin-left: 0.3em;
}


.icon-arrow-down::before {
    content: "\ea01";
}

.icon-arrow-down-bold::before {
    content: "\ea02";
}

.icon-arrow-down-bold-grey::before {
    content: "\ea03";
}

.icon-arrow-next::before {
    content: "\ea04";
}

.icon-arrow-next-v1::before {
    content: "\ea05";
}

.icon-arrow-prev::before {
    content: "\ea06";
}

.icon-arrow-prev-v1::before {
    content: "\ea07";
}

.icon-arrow-prev-v2::before {
    content: "\ea08";
}

.icon-basket::before {
    content: "\ea09";
}

.icon-call::before {
    content: "\ea0a";
}

.icon-cancel::before {
    content: "\ea0b";
}

.icon-clock::before {
    content: "\ea0c";
}

.icon-close::before {
    content: "\ea0d";
}

.icon-drop::before {
    content: "\ea0e";
}

.icon-gas::before {
    content: "\ea0f";
}

.icon-gas-station::before {
    content: "\ea10";
}

.icon-information::before {
    content: "\ea11";
}

.icon-intermediate-pin::before {
    content: "\ea12";
}

.icon-left-and-right::before {
    content: "\ea13";
}

.icon-location-pin::before {
    content: "\ea14";
}

.icon-login::before {
    content: "\ea15";
}

.icon-logout::before {
    content: "\ea16";
}

.icon-magnifying-glass::before {
    content: "\ea17";
}

.icon-menu::before {
    content: "\ea18";
}

.icon-pen::before {
    content: "\ea19";
}

.icon-phone-call::before {
    content: "\ea1a";
}

.icon-quality::before {
    content: "\ea1b";
}

.icon-sale::before {
    content: "\ea1c";
}

.icon-shild::before {
    content: "\ea1d";
}

.icon-tabs-how-to-register-certificate-www::before {
    content: "\ea1e";
}

.icon-tabs-how-to-register-gas-station::before {
    content: "\ea1f";
}

.icon-tabs-how-to-register-phone::before {
    content: "\ea20";
}

.icon-tabs-how-to-register-phone-call::before {
    content: "\ea21";
}

.icon-tabs-how-to-register-telegram::before {
    content: "\ea22";
}

.icon-tabs-rewards-certificate::before {
    content: "\ea23";
}

.icon-tabs-rewards-gas-station::before {
    content: "\ea24";
}

.icon-tabs-rewards-price-tag::before {
    content: "\ea25";
}

.icon-tabs-rewards-shopping-bag::before {
    content: "\ea26";
}

.icon-user-profile::before {
    content: "\ea27";
}

.icon-v-power::before {
    content: "\ea28";
}

.icon-wall-clock::before {
    content: "\ea29";
}

.icon-wallet::before {
    content: "\ea2a";
}

.icon-wallet-red::before {
    content: "\ea2b";
}

.icon-warning::before {
    content: "\ea2c";
}
