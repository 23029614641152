.popup-user-data {
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    padding-top: 160px;
    padding-bottom: 5vh;
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: rgba(255,255,255,.95);

    @media screen and (max-width: $point-lg-max) {
        padding-top: 130px;
    }

    .container-fluid {
        height: 100%;
    }

    &__wrapper {
        position: relative;
        height: 100%;
        padding-top: 10vh;
        padding-bottom: 2em;
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        width: 2.5em;
        height: 2.5em;
        top: 0;
        right: 0;
        font-size: em(32);
        color: $shell-red;
        cursor: pointer;
        @media screen and (max-width: $point-lg-max) {
            font-size: em(28);
        }
        .icon {
            @include transform(translateX(.25em))
        }
    }

    &__content {
        width: 100%;
        max-width: 820px;
        max-height: 100%;
        margin: 0 auto;
        overflow-y: auto;
        padding: 0 50px;
        background-color: #ffffff;
        text-align: center;
        &--confirmed {
            display: none;
            padding: 0 50px;
            p {
                margin-bottom: 10px!important;
            }
        }
        p {
            margin-bottom: 50px;
            font-size: 24px;
        }
    }
    @media screen and (max-width: 576px) {
        &__content {
            padding: 0 20px;
            p {
                font-size: 20px;
            }
            &--confirmed {
                padding: 0 20px;
            }
        }
    }
}