/* =================================
Social
================================= */

.social {
    display: flex;
    align-items: center;
    > a {
        position: relative;
        display: inline-block;
        margin-right: 1em;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            img {
                &.icon-static {
                    opacity: 0;
                }
                &.icon-hover {
                    opacity: 1;
                }
            }
        }
        img {
            @include transition(all, .2s, ease-in-out);
            &.icon-static {
                opacity: 1;
                @media screen and (max-width: $point-lg-max) {
                    display: none;
                }
            }
            &.icon-hover {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: 0;
                @media screen and (max-width: $point-lg-max) {
                    position: static;
                    opacity: 1;
                }
            }
        }
    }
}