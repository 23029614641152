/* =================================
Slick Slider Custom Style
================================= */

.slick-slider-custom {

    .slick-arrow {
        position: absolute;
        z-index: 1;
        top: 50%;
        @include transform(translateY(-50%));
        padding: 0.25em;

        border: none;
        outline: none;
        background-color: transparent;

        font-size: 3em;
        line-height: 1;
        color: #ffffff;

        &.slick-prev {
            left: 10px;
        }

        &.slick-next {
            right: 10px;
        }

    }

    .focus-slider-dots {
        position: absolute;
        left: 50%;
        bottom: 50px;
        @include transform(translateX(-50%));
        padding: 0;
        margin: 0;
        @media screen and (max-width: $point-xl-max) {
            bottom: 25px;
        }

        > li {
            display: inline-block;
            margin-right: 1.25em;
            &:last-child {
                margin-right: 0;
            }

            &.slick-active {
                button {
                    border: 3px solid $shell-yellow;
                    background-color: $shell-yellow;
                }
            }

            button {
                width: 18px;
                height: 18px;
                padding: 0;
                border: 3px solid #ffffff;
                outline: none;
                background-color: transparent;
                font-size: 0;
                @media screen and (max-width: $point-xl-max) {
                    width: 14px;
                    height: 14px;
                }
            }
        }

    }

}