/* =================================
Section Buy Certificate
================================= */

.buy-certificate {

    &__card-info {
        padding: 15% 14% 0 30%;
        background-image: url("../../img/Bodo-Certificate/bg_buy-certificate.png");
        background-repeat: no-repeat;
        background-size: 110% auto;
        background-position: top left;
        @media screen and (max-width: $point-lg-max) {
            margin-bottom: 4em;
        }
        @media screen and (max-width: $point-md-max) {
            margin-bottom: 3em;
            padding-left: 0;
            padding-right: 0;
        }
        .img-wrap {
            margin-bottom: 2em;
            @media screen and (max-width: $point-md-max) {
                padding-right: 20%;
                padding-left: 25%;
            }
        }
        .text-block {
            @media screen and (max-width: $point-md-max) {
                padding-left: 15%;
            }
            .link {
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &__form-wrap {
        @media screen and (max-width: $point-xl-max) {
            font-size: em(14);
        }
        @media screen and (max-width: $point-md-max) {
            font-size: em(12);
        }
        @media screen and (max-width: $point-sm-max) {
            font-size: em(10);
        }
        .form-group__checkbox {
            font-size: em(16);
            color: $shell-very-dark-gray;
            label a {
                display: inline-block;
            }
        }
        .btns-wrap {
            .btn {
                @include transform(translateX(-50%));
                @media screen and (max-width: $point-lg-max) {
                    @include transform(none);
                }
                &__big {
                    @media screen and (max-width: $point-lg-max) {
                        padding-top: .9em;
                        padding-bottom: .9em;
                    }
                }
            }
        }
        .btn {
            &__big {
                @media screen and (max-width: $point-lg-max) {
                    font-size: em(24);
                }
            }
        }
        .btns-switcher {
            &__label {
                @media screen and (max-width: $point-md-max) {
                    font-size: 16px;
                }
            }
            &__btn {
                label.btn {
                    min-height: em(86,24);
                    @media screen and (max-width: $point-lg-max) {
                        min-height: em(86,22);
                    }
                }
            }
        }
        .btns-switcher__label,
        .certificate-type,
        .counter-label,
        .form-group__checkbox {
            @media screen and (max-width: $point-md-max) {
                font-size: 16px;
            }
        }
        .row {
            @media screen and (max-width: $point-md-max) {
                margin-right: -5px;
                margin-left: -5px;
                .col-4 {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
        .counter.w-100 {
            @media screen and (max-width: $point-md-max) {
                min-width: auto;
            }
        }
    }

}