/* =================================
Section for promotions stock-rules
================================= */

.stock-rules {
    .title {
        margin-bottom: 50px;
        text-align: center;
        > a {
            position: relative;
            padding-left: 65px;
            font-size: em(20);
            font-weight: 700;
            text-decoration: underline;
            color: $shell-red;
            &::before {
                content: '';
                display: block;
                width: 45px;
                height: 45px;
                position: absolute;
                top: 50%;
                left: 0;
                @include transform(translateY(-50%));
                background-image: url("../../img/icon-rules-pdf.png");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }
    }
    .rules-text {
        font-size: em(13);
        text-align: justify;
        a {
            color: $shell-red;
            text-decoration: underline;
        }
    }
}