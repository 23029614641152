.overlay--youtube {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba($black, 0.8);
    display: flex;
    visibility: hidden;
    opacity: 0;
    align-items: center;
    justify-content: center;
}
.modal--youtube {
    width: 100%;
    max-width: 500px;
    height: 300px;
    &-open {
        visibility: visible;
        opacity: 1;
    }
    &-close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 48px;
        color: $white;
        cursor: pointer;
    }
    iframe {
        width: 100%;
        height: 100%;
    }
}