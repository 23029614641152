.custom-scroll {
    &::-webkit-scrollbar-button {
        //background-image:url('');
        background-repeat: no-repeat;
        width: 0px;
        height: 0px
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
        border: 1px solid $shell-pale-gray;
        -webkit-border-radius: 4px;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 4px;
        border-radius: 4px;
        background-color: $shell-yellow;
    }

    &::-webkit-scrollbar-thumb:hover{
        background-color: $shell-red;
    }

    &::-webkit-resizer{
        //background-image:url('');
        background-repeat:no-repeat;
        width:8px;
        height:0px
    }

    &::-webkit-scrollbar{
        width: 8px;
        height: 4px;
        padding-top: 10px;
    }
}

.table-wrap.custom-scroll {
    &::-webkit-scrollbar-button {
        //background-image:url('');
        background-repeat: no-repeat;
        width: 0px;
        height: 0px
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
        border: 1px solid $shell-pale-gray;
        -webkit-border-radius: 4px;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 4px;
        border-radius: 4px;
        background-color: $shell-yellow;
    }

    &::-webkit-scrollbar-thumb:hover{
        background-color: $shell-red;
    }

    &::-webkit-resizer{
        //background-image:url('');
        background-repeat:no-repeat;
        width:8px;
        height:0px
    }

    &::-webkit-scrollbar{
        width: 16px;
        height: 8px;
        padding-top: 10px;
    }
}
