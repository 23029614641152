.slider-offers {
    margin-bottom: 2.5em;

    .slider {
        white-space: nowrap;
        overflow: hidden;

        .slick-arrow {
            @media screen and (max-width: $point-xl-max) {
                font-size: 2.5em;
            }
            @media screen and (max-width: $point-lg-max) {
                font-size: 2em;
                top: auto;
                bottom: 25%;
                @include transform(none);
            }

            &.slick-prev {
                @media screen and (max-width: $point-xl-max) {
                    left: 0;
                }
                @media screen and (max-width: $point-lg-max) {
                    left: 30px;
                    padding-left: 0;
                }
            }

            &.slick-next {
                @media screen and (max-width: $point-xl-max) {
                    right: 0;
                }
                @media screen and (max-width: $point-xl-max) {
                    right: 30px;
                    padding-right: 0;
                }
            }
        }
    }

    .slide-item {
        //position: relative;
        //display: inline-block;
        //vertical-align: top;
        //width: 100%;
        //min-height: 58vh;
        //min-height: calc(var(--vh, 1vh) * 58);
        //white-space: normal;

        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 100%;
        white-space: normal;

        @media screen and (max-width: $point-xl-max) {
            //padding: 5vh 25px 0;
            //padding: calc(var(--vh, 1vh) * 5) 25px 0;
        }
        @media screen and (max-width: $point-lg-max) {
            //min-height: 70vh;
            //min-height: calc(var(--vh, 1vh) * 70);
        }
        //@media screen and (max-width: $point-md-max) {
        //    max-height: 70vh;
        //    max-height: calc(var(--vh, 1vh) * 70);
        //    overflow: hidden;
        //}

        .img-wrap {
            //position: absolute;
            //width: 100%;
            //height: 100%;
            //top: 0;
            //left: 0;

            width: 100%;
            height: auto;
            //@media screen and (max-width: $point-md-max) {
            //    max-height: 100%;
            //}
            > span {
                //display: inline-block;
                //width: 100%;
                //height: 100%;
                //background-repeat: no-repeat;
                //background-position: center;
                //background-size: cover;

                display: inline-block;
                width: 100%;
                height: auto;
                > img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .text-content {
            //position: relative;
            ////width: em(620);
            //width: 42%;
            //min-width: 620px;
            //padding: em(50);
            //background-color: rgba(255,255,255,0.75);

            position: absolute;
            width: 42%;
            min-width: 620px;
            max-height: 100%;
            padding: em(50);
            background-color: rgba(255, 255, 255, 0.9);

            @media screen and (max-width: $point-scaling-cancel) {
                font-size: em(13);
            }
            @media screen and (max-width: $point-xl-max) {
                //font-size: em(14);
                //padding: em(40,14);
                //min-width: 540px;

                padding: em(30, 14);
                min-width: 540px;
            }
            @media screen and (max-width: $point-lg-max) {
                width: calc(80% - 50px);
                padding: 25px;
                min-width: auto;
            }
            @media screen and (max-width: $point-md-max) {
                width: calc(100% - 50px);
                font-size: 1em;
                padding: 25px;
                min-width: auto;
            }
            @media screen and (max-width: $point-sm-max) {
                padding: 20px;
            }

            &.pos-top-left {
                //margin-left: em(100);
                //margin-top: em(75);
                //margin-bottom: em(75);

                left: em(100);
                top: em(75);

                @media screen and (max-width: $point-xl-max) {
                    //margin-left: em(60);
                    //margin-top: 0;
                    //margin-bottom: em(45);

                    left: em(60);
                    top: em(50);
                }
                @media screen and (max-width: $point-lg-max) {
                    //margin: 0;

                    left: 25px;
                    top: 40px;
                }
            }

            .title-text {
                margin-bottom: 1em;
                font-size: em(32);
                font-weight: 700;
                line-height: 1.5;
                color: $shell-red;
                @media screen and (max-width: $point-xl-max) {
                    font-size: em(28);
                }
                @media screen and (max-width: $point-sm-max) {
                    font-size: em(20);
                }
                span {
                    display: block;
                    font-weight: 400;
                    font-size: 24px;
                    @media screen and (max-width: $point-xl-max) {
                        font-size: 20px;
                    }
                    @media screen and (max-width: $point-sm-max) {
                        font-size: 16px;
                    }
                }
            }

            .btns-wrap {
                .btn {
                    &__red-yellow {
                        background-color: transparent;
                        @media screen and (max-width: $point-lg-max) {
                            background-color: $shell-yellow;
                            border-color: $shell-yellow;
                        }
                        &:hover {
                            background-color: $shell-yellow;
                        }
                    }
                }
            }
        }
    }
}
