/* =================================
Section tabs-rewards
================================= */

.tabs-rewards {
    overflow: hidden;

    .certificates-tab-content {
        position: relative;
        .img-wrap {
            position: absolute;
            width: 37%;
            height: auto;
            top: 0;
            left: 0;
            @media screen and (max-width: $point-md-max) {
                position: static;
                width: 100%;
            }
        }
        .title-tab {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 3em;
            padding-top: 10%;
            padding-bottom: 10%;
            margin-right: -5%;
            background-image: url("../../img/bg_certificates-tab-content.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: right center;
            @media screen and (max-width: $point-md-max) {
                position: static;
                width: auto;
                margin-left: -30px;
                margin-right: -30px;
                margin-bottom: 2em;
                padding-top: 20%;
                padding-bottom: 20%;
                background-image: url("../../img/bg_certificates-tab-content_mob.png");
                background-position: left center;
            }
            @media screen and (max-width: $point-sm-max) {
                margin-left: -15px;
                margin-right: -15px;
            }
            .title-text {
                width: 47%;
                margin-right: 12%;
                font-size: 2.5vw;
                font-weight: 700;
                line-height: 1.35;
                @media screen and (max-width: $point-md-max) {
                    width: 76%;
                    margin: 0 auto;
                    font-size: 5.5vw;
                }
            }
        }
    }

    .discounts-on-fuel {
        font-size: 1em;
        .bg {
            padding: 1.5em 5% 2em;
            background-color: #f9f9f9;
            @media screen and (max-width: $point-xl-max) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        &__title {
            text-align: center;
            .title {
                display: inline-block;
                position: relative;
                width: auto;
                max-width: 100%;
                margin: 0 auto;
                margin-bottom: 2em;
                &.title-1 {
                    padding-top: 1em;
                    padding-bottom: 1em;
                    padding-left: em(200);
                    @media screen and (max-width: $point-lg-max) {
                        padding-left: em(100);
                    }
                    .img-wrap {
                        width: em(156);
                        @media screen and (max-width: $point-lg-max) {
                            width: em(85);
                        }
                    }
                }
                &.title-2 {
                    padding-top: 1em;
                    padding-bottom: 1em;
                    padding-left: em(131);
                    @media screen and (max-width: $point-lg-max) {
                        padding-left: em(65);
                    }
                    .img-wrap {
                        width: em(87);
                        @media screen and (max-width: $point-lg-max) {
                            width: em(50);
                        }
                    }
                }
                .img-wrap {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    @include transform(translateY(-50%));
                }
                .title-text {
                    display: flex;
                    align-items: center;
                    min-height: 2.7em;
                    margin-bottom: 0;
                    font-size: em(24);
                    font-weight: 700;
                    text-transform: uppercase;
                    color: $shell-very-dark-gray;
                    @media screen and (max-width: $point-lg-max) {
                        font-size: em(18);
                        text-align: left;
                    }
                }
            }
        }
        &__table {
            margin-bottom: 2em;
            @media screen and (max-width: $point-scaling-10) {
                font-size: em(15);
            }
            @media screen and (max-width: $point-scaling-25) {
                font-size: em(14);
            }
            @media screen and (max-width: 1500px) {
                font-size: em(12);
            }
            @media screen and (max-width: $point-xl-max) {
                font-size: em(11);
            }
            @media screen and (max-width: $point-lg-max) {
                font-size: em(9);
            }
            .table-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 1.5em 0;
                border-top: 3px solid $shell-yellow;
                @media screen and (max-width: $point-md-max) {
                    padding: 2.5em 0;
                }
                &:last-child {
                    border-bottom: 3px solid $shell-yellow;
                }
            }
            .price-descr {
                display: flex;
                align-items: center;
                width: 73%;
                @media screen and (max-width: $point-md-max) {
                    display: block;
                    width: 50%;
                }
                .big-value {
                    width: 40%;
                    @media screen and (max-width: $point-md-max) {
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }
                .descr {
                    vertical-align: middle;
                    > * {
                        display: inline-block;
                        vertical-align: middle;
                        margin-bottom: 0;
                    }
                    .img-wrap {
                        width: em(250);
                        margin-right: 1em;
                        @media screen and (max-width: $point-md-max) {
                            width: em(190);
                            margin-bottom: 10px;
                        }
                    }
                    > p {
                        @media screen and (max-width: $point-md-max) {
                            width: 80%;
                        }
                    }
                }
            }
            .balls-wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 27%;
                max-width: 23em;
                @media screen and (max-width: $point-scaling-25) {
                    max-width: 20em;
                }
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                    max-width: none;
                }
                .prefix {
                    margin-right: 7%;
                    font-size: em(36);
                    text-transform: uppercase;
                    color: $shell-red;
                    font-weight: 700;
                }
                .big-value {
                    @media screen and (max-width: $point-md-max) {
                        flex-direction: column;
                        align-items: center;
                        width: 100px;
                        text-align: center;
                    }
                    .num {
                        padding-right: .25em;
                        @media screen and (max-width: $point-md-max) {
                            padding-right: 0;
                            padding-bottom: 10px;
                        }
                    }
                    .unit {
                        padding-bottom: .125em;
                        line-height: .8;
                        .small {
                            font-size: em(24,36);
                            font-weight: 700;
                            text-transform: none;
                            line-height: 1;
                            @media screen and (max-width: $point-md-max) {
                                font-size: em(28,36);
                            }
                        }
                    }
                }
            }
            .big-value {
                display: flex;
                align-items: flex-end;
                color: $shell-red;
                font-weight: 700;
                @media screen and (max-width: $point-scaling-25) {
                    font-size: em(14);
                }
                @media screen and (max-width: $point-md-max) {
                    font-size: em(11);
                }
                .num {
                    padding-right: .1em;
                    font-size: em(100);
                    line-height: .95;
                }
                .unit {
                    font-size: em(36);
                    text-transform: uppercase;
                    @media screen and (max-width: $point-md-max) {
                        line-height: 1.5;
                    }
                }
            }
        }
        .bottom-link {
            margin-top: 2em;
            text-align: center;
            a {
                font-size: em(24);
                font-weight: 700;
                color: $shell-red;
                text-decoration: underline;
                @media screen and (max-width: $point-lg-max) {
                    font-size: em(18);
                }
                @media screen and (max-width: $point-md-max) {
                    font-size: em(16);
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .products-tab-content {
        .img-wrap {
            width: 100%;
            @media screen and (max-width: $point-lg-max) {
                text-align: center;
            }
            > img {
                width: 100%;
                @media screen and (max-width: $point-lg-max) {
                    width: 100%;
                    max-width: 500px;
                }
            }
        }
        .text-content {
            position: relative;
            padding-top: 10%;
            padding-bottom: 10%;
            padding-left: 10%;
            line-height: 1.75;
            font-size: em(24);
            @media screen and (max-width: $point-scaling-cancel) {
                font-size: em(22);
            }
            @media screen and (max-width: $point-lg-max) {
                display: inline-block;
                text-align: left;
                padding-bottom: 0;
                padding-left: 20px;
                padding-right: 20px;
            }
            @media screen and (max-width: $point-md-max) {
                font-size: em(20);
            }
            @media screen and (max-width: $point-sm-max) {
                font-size: em(18);
            }
            > p {
                margin: 0;
                span {
                    display: inline-block;
                }
            }
            .mb-1em {
                margin-bottom: 1em;
            }
            .bg {
                position: absolute;
                z-index: -1;
                width: 162%;
                height: 135%;
                top: -3%;
                left: -58%;
                background-image: url("../../img/loyalty-program/tabs-rewards_products-tab_bg.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: top left;
                @media screen and (max-width: $point-lg-max) {
                    width: 180%;
                    height: 135%;
                }
                @media screen and (max-width: $point-sm-max) {
                    left: -68%;
                }
            }
        }
        .bottom-link {
            margin-top: 4em;
            text-align: center;
            @media screen and (max-width: $point-lg-max) {
                margin-top: 4em;
            }
            a {
                font-size: em(24);
                font-weight: 700;
                color: $shell-red;
                text-decoration: underline;
                @media screen and (max-width: $point-lg-max) {
                    font-size: em(18);
                }
                @media screen and (max-width: $point-md-max) {
                    font-size: em(16);
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

}