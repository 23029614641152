/* =================================
Page Personal Cabinet
================================= */

.personal-cabinet {

    &__show-menu {
        @media screen and (max-width: $point-lg-max) {
            .personal-cabinet__sidebar {
                position: static;
                width: auto;
                padding-left: 0;
                padding-right: 0;
                padding-top: em(40);
                margin-bottom: em(30);
            }
            .personal-cabinet__sidebar-close {
                right: 0;
            }
            .personal-cabinet__title {
                .btn {
                    display: none;
                }
            }
        }
    }

    &__wrapper {
        position: relative;
        padding-left: 380px;
        padding-top: 5em;
        @media screen and (max-width: $point-scaling-10) {
            padding-left: 350px;
            padding-top: 4em;
        }
        @media screen and (max-width: $point-scaling-25) {
            padding-left: 300px;
            padding-top: 3em;
        }
        @media screen and (max-width: $point-lg-max) {
            padding-left: 0;
            padding-top: 2em;
        }
    }

    &__sidebar {
        position: absolute;
        width: 380px;
        top: 5em;
        left: 0;
        @media screen and (max-width: $point-scaling-10) {
            width: 350px;
            top: 4em;
        }
        @media screen and (max-width: $point-scaling-25) {
            width: 300px;
            top: 3em;
        }
        @media screen and (max-width: 992px) {
            left: -300%;
            top: 0;
            background-color: #fff;
            transition: all .3s;
            z-index: 8;
            width: calc(100% + 30px);
            padding-top: em(70);
            padding-bottom: em(20);
            padding-left: 15px;
            padding-right: 15px;
            border-bottom: 1px solid $shell-light-gray;
        }
        &-close {
            display: none;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            top: .25em;
            right: 15px;
            //@include transform(translateY(-80%));
            font-size: em(30);
            color: $shell-red;
            cursor: pointer;
            @media screen and (max-width: 992px) {
                display: flex;
            }
        }
        &--active {
            left: -15px;
        }
    }

    &__content {
        width: 100%;
        padding-left: 10%;
        min-height: 80vh;
        @media screen and (max-width: $point-scaling-10) {
            padding-left: 8%;
        }
        @media screen and (max-width: $point-scaling-25) {
            padding-left: 6%;
        }
        //@media screen and (max-width: 1500px) {
        //    padding-left: 6%;
        //}
        @media screen and (max-width: $point-lg-max) {
            padding-left: 0;
        }
        @media screen and (max-width: $point-sm-min) {
            min-height: auto
        }
    }

    &__title {
        margin-bottom: 1.5em;
        font-size: em(30);
        font-weight: 700;
        color: $shell-very-dark-gray;
        display: none;
        .title-text {
            font-size: inherit;
            font-weight: inherit;
            display: flex;
            align-items: center;
            img {
                padding-right: 10px;
                width: 42px;
                height: 42px;
            }
        }
        &-flex {
            align-items: center;
            justify-content: space-between;
        }
        @media screen and (max-width: 992px) {
            display: flex;
        }
        @media screen and (max-width: 480px) {
            .title-text {
                font-size: 21px;
                margin-bottom: 0;
            }
        }
        .btn {
            font-size: em(12);
            min-width: 6em;
            padding: 0.3em;
            @media screen and (max-width: 480px) {
                min-width: 5em;
                padding: 0.2em;
                font-size: em(10);
            }
        }
    }

    table {
        color: $shell-very-dark-gray;
    }

    .btns-wrap {
        .btn__round {
            .icon {
                @include transform(rotate(180deg));
            }
        }
    }

    &__filters {
        //@media screen and (max-width: $point-scaling-10) {
        //    font-size: em(15);
        //}
        //@media screen and (max-width: $point-scaling-25) {
        //    font-size: em(13);
        //}
        @media screen and (max-width: $point-lg-max) {
            display: none;
            position: fixed;
            z-index: 1000;
            width: 100%;
            height: auto;
            min-height: 100vh;
            min-height: calc(var(--vh, 1vh) * 100);
            top: 0;
            left: 0;
            padding: 130px 15px 30px 15px;
            background-color: rgba(255,255,255,.95);
            font-size: 1em;
        }
        .row {
            .col-xl-3, .col-xl-9 {
                @media screen and (max-width: 1500px) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
                &.col-12 {
                    @media screen and (max-width: $point-md-max) {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }

        }
        .close-btn {
            top: 70px;
            right: 15px;
        }
        .form-group {
            padding-right: 0;
            margin-bottom: 30px;
            position: relative;
            &__icon {
                position: absolute;
                width: 24px;
                height: 24px;
                right: 20px;
                top: 50%;
                @include transform(translateY(-50%));
                opacity: .3;
                cursor: pointer;
                @include transition(all, .2s, ease-in-out);
                @media screen and (max-width: $point-sm-max) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
            }
            &.active, &.in-focus {
                .form-group__icon {
                    @media screen and (max-width: $point-sm-max) {
                        top: 18px;
                    }
                }
            }
            input {
                &#date-from,
                &#date-to {
                    @media screen and (max-width: $point-sm-max) {
                        padding-right: 5px;
                    }
                }
            }
            &.input-select-history-transaction {
                height: 80px;
                .select-multi {
                    height: 100%;
                    border: 1px solid #d9d9d9;
                    &__button {
                        position: relative;
                        height: 100%;
                    }
                    &__dropdown {
                        border: 1px solid #d9d9d9;
                    }
                    &__value {
                        position: absolute;
                        bottom: 10px;
                        left: 20px;
                    }
                    &__label {
                        top: 20px;
                        position: absolute;
                        left: 20px;
                    }
                    &__option {
                        border-bottom: 1px solid #ddd;
                    }
                    //&__search {
                    //    &-input {
                    //        border: none;
                    //        border-bottom: 1px solid #d9d9d9;
                    //        width: 73%;
                    //        padding: 0.5em 0;
                    //        box-shadow: none;
                    //        margin: 0 1em;
                    //        padding-right: 30px;
                    //    }
                    //    .icon {
                    //        position: absolute;
                    //        top: 16px;
                    //        right: 45px;
                    //    }
                    //}
                    &__icon {
                        right: 2em;
                    }
                    //&__result-list {
                    //    max-height: 270px;
                    //    overflow-y: auto;
                    //    margin-right: 20px;
                    //    margin-bottom: 15px;
                    //}
                }
            }
            .select-multi {
                &__result-list {
                    max-height: 220px;
                }
            }
        }
        .btns-wrap {
            .btn__w-100 {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &__filters-mob {
        display: none;
        @media screen and (max-width: $point-lg-max) {
            display: block;
        }
        .title-text {
            position: relative;
            display: inline-block;
            padding-right: 2em;
            font-size: em(18);
            color: $shell-red;
            > img {
                position: absolute;
                width: 1.5em;
                height: auto;
                right: 0;
                top: 50%;
                @include transform(translateY(-50%));
            }
        }
    }

    .btns-more-table {
        //font-size: 14px;
    }

}

.personal-cabinet {

    &__table-wrap {
        padding-top: em(40);

        //@media screen and (max-width: $point-scaling-10) {
        //    font-size: em(15);
        //}
        //@media screen and (max-width: $point-scaling-25) {
        //    font-size: em(13);
        //}

        .table-title {
            margin-bottom: em(40,18);
            font-size: em(18);
            font-weight: 700;
            color: $shell-very-dark-gray;
        }

        .table-content {
            .table-wrap {
                max-width: 100%;
                margin-bottom: 4em;
                overflow-x: auto;
            }
            table {
                width: 100%;
                font-size: em(18);
                text-align: center;
                @media screen and (max-width: $point-xl-max) {
                    font-size: 1em;
                }
                @media screen and (max-width: $point-md-max) {
                    font-size: em(13);
                    table-layout: auto;
                }
                tr {
                    &:nth-child(2n) {
                        background-color: $shell-very-pale-gray;
                    }
                }
                th {
                    height: em(90,18);
                    padding: em(10,21) em(18,21);
                    border-top: 1px solid $shell-pale-gray;
                    border-bottom: 1px solid $shell-pale-gray;
                    font-size: em(21,18);
                    font-weight: 400;
                    color: $shell-dark-gray;

                    -webkit-box-shadow: 0px 6px 0px 0px #ededed;
                    -moz-box-shadow: 0px 6px 0px 0px #ededed;
                    box-shadow: 0px 6px 0px 0px #ededed;

                    position: relative;

                    @media screen and (max-width: $point-md-max) {
                        padding: em(10,21) em(5,21);
                    }

                    &:first-child {
                        border-left: 1px solid $shell-pale-gray;
                    }
                    &:last-child {
                        border-right: 1px solid $shell-pale-gray;
                    }
                }
                td {
                    padding: 1em;
                    @media screen and (max-width: $point-md-max) {
                        padding: 1em em(5,18);
                    }
                    .date,
                    .time {
                        margin: 0;
                        white-space: nowrap;
                    }
                    p {
                        margin-bottom: 0;
                    }
                    .azs-address {
                        @media screen and (max-width: $point-md-max) {
                            display: none;
                        }
                    }
                }
                .btn-more-info {
                    position: relative;
                    display: inline-block;
                    width: 1em;
                    height: 1em;
                    font-size: 1.5em;
                    color: $shell-red;
                    .icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        @include transform(translate(-50%,-50%));
                    }
                }
                .fuel-type {
                    position: relative;
                    margin-bottom: 0;
                    padding-left: 3em;
                    @media screen and (max-width: $point-scaling-10) {
                        padding-left: 2em;
                    }
                    .btn-more-info {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        @include transform(translateY(-50%));
                    }
                    .type {
                        margin: 0 0 .5em 0;
                    }
                    .transaction {
                        margin: 0;
                        padding-left: 0.5em;
                        color: $shell-mid-gray;
                        font-size: .9em;
                        white-space: normal;
                        @media screen and (max-width: $point-md-max) {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }

}

.state-sum {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @media screen and (max-width: $point-lg-max) {
        flex-direction: column;
        align-items: flex-start;
    }
    .text {
        font-size: em(30);
        font-weight: 700;
        color: $color-base-grey;
        @media screen and (max-width: $point-lg-max) {
            font-size: em(26);
            margin-bottom: 1em;
        }
    }
    .value {
        font-size: em(60);
        font-weight: 700;
        line-height: 1;
        color: $shell-red;
        @media screen and (max-width: $point-lg-max) {
            font-size: em(50);
        }
        .unit {
            font-size: .5em;
        }
    }
}

.shellsmart-club-block {
    margin-top: 85px;
    border-top: 1px solid #d9d9d9;
    padding-top: 58px;
}