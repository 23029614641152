/* =================================
Page Promotion Telegrambot
================================= */

.telegrambot {

    .smart-card-bg {
        padding-top: 85px;
        padding-bottom: 40px;
        background-image: url("../../../img/promotions/telegrambot/bg-smart-card.jpg");
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        @media screen and (max-width: $point-md-max) {
            margin-left: -15px;
            margin-right: -15px;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 42px;
            background-image: url("../../../img/promotions/telegrambot/bg-smart-card_mob.jpg");
        }
    }

    .stock-rules {
        .title > a {
            padding-left: 0;
            &::before {
                content: none;
            }
        }
    }

    .telegram-link {
        .img-wrap {
            position: relative;
            .link {
                display: block;
                position: absolute;
                width: 60%;
                height: 46px;
                left: 50%;
                bottom: 20px;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
                opacity: 0.5;
                -webkit-transition: all .2s ease-in-out;
                -moz-transition: all .2s ease-in-out;
                -ms-transition: all .2s ease-in-out;
                -o-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;

                @media screen and (max-width: $point-lg-max) {
                    width: 80%;
                    height: 40px;
                }
                @media screen and (max-width: 420px) {
                    width: 80%;
                    height: 36px;
                    bottom: 15px;
                }
            }
        }
    }

}