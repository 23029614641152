/* =================================
Styles General Popups
================================= */

.popup {
    display: none;
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: em(152);
    padding-bottom: 5vh;
    background-color: rgba(255,255,255,.95);

    @media screen and (max-width: $point-scaling-10) {
        padding-top: em(144,15);
    }
    @media screen and (max-width: $point-scaling-25) {
        padding-top: em(134,13);
    }
    @media screen and (max-width: $point-scaling-cancel) {
        padding-top: em(134);
    }
    @media screen and (max-width: $point-xl-max) {
        padding-top: em(142);
    }
    @media screen and (max-width: $point-lg-max) {
        padding-top: em(88);
    }
    @media screen and (max-width: $point-sm-max) {
        padding-top: em(78);
    }
    @media screen and (max-width: $point-iphone-5-max) {
        padding-top: em(68);
    }

    .container-fluid {
        height: 100%;
    }

    &__wrapper {
        position: relative;
        height: 100%;
        padding-top: 6vh;
        @media screen and (max-width: $point-lg-max) {
            padding-top: 4em;
        }
    }

    .custom-scroll {
        max-height: 100%;
        margin-right: -15px;
        overflow-y: auto;
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        width: 2.5em;
        height: 2.5em;
        top: 0;
        right: 0;
        padding-right: em(8,32);
        font-size: em(32);
        color: $shell-red;
        cursor: pointer;
        @media screen and (max-width: $point-lg-max) {
            font-size: em(28);
            width: 2em;
            height: 2em;
        }
        .icon {
            @include transform(translateX(.25em))
        }
    }

    &__content {
        width: 100%;
        max-width: 700px;
        //max-height: 100%;
        margin: 0 auto;
        background-color: #ffffff;
        //overflow-y: auto;
    }

    &__timeout {
        background-color: transparent;
        .popup__close {
            display: none;
        }
        .popup__content {
            -webkit-box-shadow: none !important;
            -moz-box-shadow: none !important;
            box-shadow: none !important;
        }
    }
}

.logged-in {
    .popup {
        @media screen and (max-width: $point-lg-max) {
            padding-top: em(140);
        }
        @media screen and (max-width: $point-sm-max) {
            padding-top: em(125);
        }
        @media screen and (max-width: $point-iphone-5-max) {
            padding-top: em(108);
        }
    }
}
