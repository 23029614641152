.million-reasons {
    overflow-x: hidden;

    &__main {
        margin-bottom: 100px;
        @media screen and (max-width: 768px) {
            margin-bottom: 120px;
            .container-fluid {
                padding-left: 0;
                padding-right: 0;
            }
        }
        &-mobile-image {
            @media screen and (min-width: 768px) {
                display: none;
            }
        }
        &-wrap {
            position: relative;
            background-image: url("../../../img/promotions/millionreasons/million-banner.jpg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 705px;
            @media screen and (max-width: 1800px) {
                height: 650px;
            }
            @media screen and (max-width: 1600px) {
                height: 600px;
            }
            @media screen and (max-width: 1400px) {
                height: 550px;
            }
            @media screen and (max-width: 1280px) {
                height: 500px;
                background-position: left center;
            }
            @media screen and (max-width: 768px) {
                height: auto;
                img {
                    width: 100%;
                }
            }
        }
    }
    &__digit {
        color: $color-yellow;
        font-size: 24px;
        font-weight: bold;
        font-family: "ShellBold", sans-serif;
        position: absolute;
        @media screen and (max-width: 768px) {
            display: none;
        }
        &.large {
            font-size: 164px;
            bottom: -9%;
            left: 50%;
            transform: translateX(-50%);
            @media screen and (max-width: 1800px) {
                font-size: 144px;
            }
            @media screen and (max-width: 1600px) {
                font-size: 132px;
            }
            @media screen and (max-width: 1400px) {
                font-size: 120px;
            }
            @media screen and (max-width: 1280px) {
                left: 55%;
            }
            @media screen and (max-width: 1000px) {
                left: 60%;
                font-size: 104px;
            }
        }
        &.big {
            font-size: 76px;
            bottom: 8%;
            opacity: 0.85;
            left: 36%;
            @media screen and (max-width: 1800px) {
                font-size: 72px;
            }
            @media screen and (max-width: 1600px) {
                font-size: 64px;
            }
            @media screen and (max-width: 1400px) {
                font-size: 54px;
            }
            @media screen and (max-width: 1280px) {
                left: 40%;
            }
            @media screen and (max-width: 1000px) {
                font-size: 48px;
            }
        }
        &.medium {
            opacity: 0.8;
            font-size: 44px;
            left: 32%;
            bottom: 21%;
            @media screen and (max-width: 1800px) {
                font-size: 40px;
            }
            @media screen and (max-width: 1600px) {
                font-size: 36px;
            }
            @media screen and (max-width: 1280px) {
                left: 38%;
            }
            @media screen and (max-width: 1000px) {
                left: 42%;
                font-size: 32px;
            }
        }
        &.small {
            opacity: 0.7;
            bottom: 30%;
            left: 38%;
            font-size: 27px;
            @media screen and (max-width: 1800px) {
                font-size: 24px;
            }
            @media screen and (max-width: 1600px) {
                font-size: 22px;
            }
            @media screen and (max-width: 1280px) {
                left: 42%;
            }
            @media screen and (max-width: 1000px) {
                left: 45%;
                font-size: 20px;
            }
        }
    }
    &__register {
        background-image: url("../../../img/promotions/millionreasons/million-bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @media screen and (max-width: 768px) {
            background-image: none;
            position: relative;
        }
        &-bg-mobile {
            position: absolute;
            width: 100%;
            top: -35px;
            left: 0;
            z-index: -1;
            @media screen and (min-width: 768px) {
                display: none;
            }
            @media screen and (min-width: 400px) {
                top: -70px;
            }
        }
        &-wrap {
            position: relative;
            .million-reasons {
                &__digit {
                    &.large {
                        font-size: 94px;
                        top: -4%;
                        left: 0%;
                        transform: rotate(-15deg);
                        bottom: auto;
                        filter: blur(2px);
                        @media screen and (max-width: 1400px) {
                            font-size: 80px;
                        }
                        @media screen and (max-width: 768px) {
                            display: block;
                            font-size: 48px;
                            top: -25%;
                            left: 2%;
                        }
                    }
                    &.big {
                        font-size: 80px;
                        bottom: 4%;
                        opacity: 1;
                        transform: rotate(15deg);
                        left: 15%;
                        @media screen and (max-width: 1400px) {
                            font-size: 64px;
                        }
                        @media screen and (max-width: 768px) {
                            display: block;
                            font-size: 30px;
                            bottom: auto;
                            left: 4%;
                            top: 18%;
                        }
                    }
                    &.medium {
                        opacity: 0.8;
                        font-size: 72px;
                        left: auto;
                        right: 8%;
                        transform: rotate(5deg);
                        bottom: auto;
                        top: -10%;
                        @media screen and (max-width: 1400px) {
                            font-size: 64px;
                        }
                        @media screen and (max-width: 768px) {
                            display: block;
                            font-size: 36px;
                            bottom: auto;
                            top: -24%;
                            right: 4%;
                        }
                    }
                    &.small {
                        font-size: 64px;
                        bottom: 2%;
                        right: 2%;
                        left: auto;
                        transform: rotate(10deg);
                        opacity: 1;
                        filter: blur(2px);
                        @media screen and (max-width: 1400px) {
                            font-size: 52px;
                        }
                        @media screen and (max-width: 768px) {
                            display: block;
                            font-size: 27px;
                            bottom: auto;
                            top: 25%;
                            filter: blur(0.5px);
                        }
                    }
                }
            }
        }
        &-title {
            font-size: 36px;
            font-weight: 700;
            color: #404040;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 16px;
            position: relative;
            z-index: 10;
            @media screen and (max-width: 768px) {
                font-size: 24px;
            }
        }
        &-subtitle {
            color: $shell-red;
            text-align: center;
            margin-bottom: 60px;
            font-weight: 700;
            font-size: 30px;
            position: relative;
            z-index: 10;
            @media screen and (max-width: 768px) {
                font-size: 22px;
                margin-bottom: 50px;
            }
        }
        &-button {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 10;
            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 290px;
                height: 80px;
                font-size: 21px;
                color: #fff;
                background-color: $shell-red;
                text-align: center;
                @media screen and (max-width: 768px) {
                    width: 210px;
                    height: 60px;
                    font-size: 18px;
                }
                &:hover,
                &:active,
                &:focus {
                    background-color: $btn-red-bg-hover;
                    border: 1px solid $btn-red-border-hover;
                    color: $btn-red-color-hover;
                }
            }
        }
    }
    &__slider {
        padding: 240px 0px 200px;
        margin: 0 150px;
        overflow: hidden;
        @media screen and (max-width: 1000px) {
            overflow: initial;
        }
        @media screen and (max-width: 768px) {
            margin: 0;
            padding: 200px 0 100px;
        }
        &-wrap {
            color: #fff;
            position: relative;
            .million-reasons {
                &__digit {
                    &.small {
                        font-size: 64px;
                        top: 11%;
                        bottom: auto;
                        left: 15%;
                        right: auto;
                        color: #fff;
                        transform: rotate(-4deg);
                        opacity: 1;
                        filter: blur(2px);
                        @media screen and (max-width: 768px) {
                            display: block;
                            font-size: 40px;
                            top: 5%;
                            filter: blur(1px);
                            left: 2%;
                        }
                    }
                    &.big {
                        font-size: 96px;
                        top: -4%;
                        opacity: 1;
                        color: #fff;
                        transform: rotate(8deg);
                        right: 20%;
                        left: auto;
                        filter: blur(2px);
                        @media screen and (max-width: 768px) {
                            display: block;
                            font-size: 48px;
                            right: 12%;
                            top: 0;
                        }
                    }
                }
            }
        }
        &-counter {
            font-size: 48px;
            color: $shell-red;
            font-weight: 700;
            @media screen and (max-width: 768px) {
                font-size: 24px;
            }
        }
        &-nav {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 60px;
            @media screen and (max-width: 768px) {
                column-gap: 24px;
                width: 100%;
            }
        }
        &-arrow {
            width: 80px;
            height: 80px;
            border: 1px solid $shell-red;
            border-radius: 150%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.25s;
            @media screen and (max-width: 768px) {
                width: 50px;
                height: 50px;
            }
            &::after {
                content: "";
                position: absolute;
                transition: all 0.25s;
                width: 14px;
                height: 14px;
                border-top: 1px solid $shell-red;
                border-left: 1px solid $shell-red;
                transform: rotate(-45deg);
                @media screen and (max-width: 768px) {
                    width: 12px;
                    height: 12px;
                }
            }
            &--left {
                &::after {
                    right: 35%;
                }
            }
            &--right {
                &::after {
                    left: 35%;
                    transform: rotate(135deg);
                }
            }
            &:hover {
                background-color: $shell-red;
                &::after {
                    border-color: #fff;
                }
            }
        }
    }
    &__slide {
        transition: all 0.25s;
        position: relative;
        border-radius: 32px;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transition: all 0.25s;
            height: 100%;
            border-radius: 32px;
            background: rgba(255, 255, 255, 0.5);
        }
        img {
            border-radius: 32px;
            width: 100%;
        }
        &.swiper-slide-active {
            transform: scale(1.2) translateY(-65px);
            z-index: 99999;
            @media screen and (max-width: 768px) {
                transform: scale(1.2) translateY(-44px);
            }
            &::after {
                background: transparent;
            }
        }
    }
    .registration {
        padding-top: 20px;
        padding-bottom: 50px;
        @media screen and (max-width: $point-md-max) {
            padding-top: 50px;
            padding-bottom: 25px;
        }
        &__top-text {
            margin: 0;
            font-size: 30px;
            font-weight: 700;
            color: #404040;
            text-align: center;
            margin-bottom: 8px;
            @media screen and (max-width: $point-md-max) {
                font-size: 22px;
                padding-left: 16px;
                padding-right: 16px;
            }
            &.color-red {
                margin-bottom: 8px;
                color: $shell-red;
                &.mb-1em {
                    margin-bottom: 1em !important;
                }
            }
        }
        &__list {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 70px;
            @media screen and (max-width: $point-md-max) {
                flex-wrap: wrap;
                justify-content: center;
            }
            &_item {
                position: relative;
                z-index: 1;
                width: 24%;
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                    margin-bottom: 30px;
                }
            }
            &_icon {
                display: block;
                width: 41%;
                margin: 0 auto;
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                }
                > img {
                    width: 100%;
                }
            }
            &_descr {
                padding: 10% 10px 0 10px;
                font-size: 24px;
                font-weight: 700;
                color: #404040;
                text-align: center;
                @media screen and (max-width: $point-md-max) {
                    font-size: 4.25vw;
                    padding: 10% 5px 0 5px;
                }
                a {
                    display: inline-block;
                    color: $shell-red;
                }
                span {
                    display: inline-block;
                }
            }
        }
    }
}
