.double-smart {
    overflow: hidden;
    .container-fluid {
        @media screen and (max-width: 576px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__main {
        &-wrap {
            background-repeat: no-repeat;
            background-image: url("../../../img/promotions/double-smart/background.png");
            background-size: 100% 100%;
            background-position: bottom center;
            height: 700px;
            padding: 70px;
            background-color: rgb(171, 25, 28);
            @media screen and (max-width: 1680px) {
                height: 510px;
            }
            @media screen and (max-width: 1400px) {
                height: 500px;
            }
            @media screen and (max-width: 1280px) {
                height: 520px;
            }
            @media screen and (max-width: 1100px) {
                height: 390px;
            }
            @media screen and (max-width: 768px) {
                padding: 40px;
                height: 830px;
                background-image: url("../../../img/promotions/double-smart/background-mobile.png");
                background-size: 100%;// 100%;
                background-position: bottom center;
            }
            @media screen and (max-width: 600px) {
                padding: 24px;
                height: 80vh; 
            }
            @media screen and (max-width: 390px) {
                padding: 24px;
                height: 80vh; 
            }
        }
        &-logo {
            margin-bottom: 100px;
            @media screen and (max-width: 1680px) {
                margin-bottom: 90px;
            }
            @media screen and (max-width: 1400px) {
                margin-bottom: 80px;
            }
            @media screen and (max-width: 1280px) {
                margin-bottom: 70px;
            }
            @media screen and (max-width: 1100px) {
                margin-bottom: 50px;
            }
            @media screen and (max-width: 768px) {
                margin-bottom: 40px;
            }
            img {
                max-width: 290px;
                width: 100%;
                @media screen and (max-width: 1680px) {
                    max-width: 290px;
                }
                @media screen and (max-width: 1400px) {
                    max-width: 270px;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 250px;
                }
                @media screen and (max-width: 1100px) {
                    max-width: 230px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 250px;
                }
                @media screen and (max-width: 600px) {
                    max-width: 220px;
                }
                @media screen and (max-width: 390px) {
                    max-width: 150px;
                }
            }
        }
        &-title {
            color: #ffffff;
            font-size: 70px;
            font-family: "ShellFutura", sans-serif;
            font-weight: bold;
            &.mobile {
                display: none;
            }
            @media screen and (max-width: 1680px) {
                font-size: 60px;
            }
            @media screen and (max-width: 1400px) {
                font-size: 55px;
            }
            @media screen and (max-width: 1280px) {
                font-size: 50px;
            }
            @media screen and (max-width: 1100px) {
                font-size: 40px;
            }
            @media screen and (max-width: 768px) {
                font-size: 50px;
                &.mobile {
                    display: flex;
                }
                &.desktop {
                    display: none;
                }
            }
            @media screen and (max-width: 600px) {
                font-size: 30px;
                line-height: 50px;
            }
            @media screen and (max-width: 390px) {
                font-size: 26px;
                line-height: 45px;
            }
        }
    }
    &__conditions {
        background-repeat: no-repeat;
        background-image: url("../../../img/promotions/double-smart/background-flue.png");
        background-size: 100% 90%;
        background-position: 0 5vh;
        @media screen and (max-width: 768px) {
            background-image: none;
        }
        &-flex {
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 1280px) {
                flex-direction: column;
                column-gap: 20px;
                row-gap: 20px;
            }
            .separate {
                color: #fff;
                font-family: "ShellFutura", sans-serif;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 24px;
                margin: 0 30px;
                @media screen and (max-width: 1440px) {
                    margin: 0 12px;
                }
            }
        }
        &-wrap {
            background-repeat: no-repeat;
            background-image: url("../../../img/promotions/double-smart/2confetti.png");
            background-size: 100% 100%;
            background-position: right top;
            height: 75vh; //550px;
            padding-top: 5%;
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 1680px) {
                // height: 500px;
            }
            @media screen and (max-width: 1400px) {
                // height: 450px;
                height: 70vh; //550px;
            }
            @media screen and (max-width: 1280px) {
                // height: 420px;
            }
            @media screen and (max-width: 920px) {
                @media screen and (min-height: 920px) {
                    height: 40vh;
                }
            }
            @media screen and (max-width: 768px) {
                height: 1300px;
                background-image: url("../../../img/promotions/double-smart/2confetti-mobile.png");
                // background-image: none;
                flex-wrap: wrap;
                // padding-top: 5%;
                padding-top: 12vw;
            }
            @media screen and (max-width: 600px) {
                height: 800px;
            }
            @media screen and (max-width: 390px) {
                height: 780px;
            }
        }
        &-content {
            width: 70%;
            height: 100%;
            flex: 0 0 65%;
            @media screen and (max-width: 1680px) {
                max-width: 70%;
                flex: 0 0 70%;
            }
            @media screen and (max-width: 768px) {
                max-width: 100%;
                flex: 0 0 100%;
                position: relative;
            }
            .condition {
                display: flex;
                height: 100%;
                .content {
                    margin-left: auto;
                    width: 100%;
                    height: 100%; //800px;
                    font-size: 40px;
                    color: rgb(221, 29, 33);
                    font-weight: bold;
                    line-height: 40px;
                    font-family: "ShellFutura", sans-serif;
                    text-align: right;

                    @media screen and (max-width: 1680px) {
                        font-size: 35px;
                        line-height: 36px;
                    }
                    @media screen and (max-width: 1400px) {
                        font-size: 30px;
                    }
                    @media screen and (max-width: 1280px) {
                        font-size: 25px;
                    }
                    @media screen and (max-width: 768px) {
                        margin-right: auto;
                        text-align: center;
                        font-size: 25px;
                    }
                    @media screen and (max-width: 600px) {
                        font-size: 20px;
                    }
                    @media screen and (max-width: 390px) {
                        font-size: 20px;
                    }
                    .large {
                        font-size: 70px;
                        height: 70px;
                        @media screen and (max-width: 1680px) {
                            font-size: 48px;
                            height: 48px;
                        }
                        @media screen and (max-width: 1280px) {
                            font-size: 35px;
                            height: 35px;
                        }
                        @media screen and (max-width: 768px) {
                            font-size: 40px;
                            height: 40px;
                        }
                        @media screen and (max-width: 600px) {
                            font-size: 30px;
                            height: 30px;
                            line-height: 35px;
                        }
                        @media screen and (max-width: 390px) {
                            font-size: 28px;
                            height: 28px;
                        }
                    }
                    .description {
                        font-size: 30px;
                        line-height: 60px;
                        margin-top: 10px;
                        margin-bottom: 3%;
                        color: rgb(64, 64, 64);
                        &.mobile {
                            display: none;
                        }
                        @media screen and (max-width: 1680px) {
                            font-size: 25px;
                            line-height: 55px;
                        }
                        @media screen and (max-width: 1400px) {
                            font-size: 20px;
                            line-height: 45px;
                        }
                        @media screen and (max-width: 768px) {
                            font-size: 25px;
                            &.mobile {
                                display: block;
                            }
                            &.desktop {
                                display: none;
                            }
                        }
                        @media screen and (max-width: 600px) {
                            font-size: 18px;
                            line-height: 35px;
                        }
                        @media screen and (max-width: 360px) {
                            font-size: 18px;
                        }
                        p {
                            margin-bottom: -10px;
                        }
                    }
                    .variants {
                        display: flex;
                        // justify-content: flex-end;
                        justify-content: space-between;
                        align-items: center;
                        // height:25%;
                        height: 10vw;
                        width: 100%;
                        margin-top: 15px;
                        @media screen and (max-width: 1680px) {
                            // height:23%;//30
                        }
                        @media screen and (max-width: 1400px) {
                            // height:21%;//28
                        }
                        @media screen and (max-width: 1280px) {
                            // height:20%;//28
                        }
                        @media screen and (max-width: 1100px) {
                            // height:19%;//25
                        }
                        @media screen and (max-width: 920px) {
                            // height:18%;//28
                            height: 12vw;
                        }
                        @media screen and (max-width: 768px) {
                            height: 50%;
                            background-image: url("../../../img/promotions/double-smart/flue-mobile.png");
                            background-size: 100% 100%;
                            flex-direction: column;
                            justify-content: flex-end;
                            align-items: flex-end;
                            margin: 5% 0 5% 0;
                        }
                        @media screen and (max-width: 600px) {
                            height: 40%;
                        }
                        @media screen and (max-width: 390px) {
                            height: 42%;
                        }
                        .label {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                            height: 100%;
                            width: 48%;
                            // margin-left: -12%;
                            @media screen and (max-width: 768px) {
                                height: 30%;
                                width: 60%;
                                margin-bottom: 10px;
                                &.two {
                                    margin-top: 10px;
                                    margin-right: 30%;
                                }
                            }
                            @media screen and (max-width: 600px) {
                                height: 35%;
                                justify-content: flex-start;
                            }
                            @media screen and (max-width: 390px) {
                                height: 30%;
                            }
                            .point {
                                height: 100%;
                                // margin-right: -35%;
                                margin-right: -2vw;
                                animation: animPulseInviteTitle 1.2s ease-in-out
                                    infinite;
                                // @media screen and (max-width: 1680px) {
                                //     // margin-right: -35%;
                                // }
                                // @media screen and (max-width: 1280px) {
                                //     // margin-right: -35%;
                                // }
                                // @media screen and (max-width: 920px) {
                                //     // margin-right: -35%;
                                // }
                                // @media screen and (max-width: 768px) {
                                //     // margin-right: -25%;
                                //     margin-right: -2vw;
                                // }
                                // @media screen and (max-width: 600px) {
                                //     // margin-right: -20%;
                                //     margin-right: -2vw;
                                // }
                                // @media screen and (max-width: 390px) {
                                //     // margin-right: -20%;
                                //     margin-right: -2vw;
                                // }
                            }
                        }
                    }
                    .info {
                        display: flex;
                        justify-content: flex-end;
                        // height: 15%;
                        margin-top: 3%;
                        // height: 25%;
                        height: 8vw;
                        color: rgb(64, 64, 64);
                        &.mobile {
                            display: none;
                        }
                        @media screen and (max-width: 1650px) {
                            // height:22%;
                        }
                        @media screen and (max-width: 1400px) {
                            // height:20%;
                        }
                        @media screen and (max-width: 1150px) {
                            // height:18%;
                        }
                        @media screen and (max-width: 920px) {
                            // height:15%;
                            height: 10vw;
                        }
                        @media screen and (max-width: 768px) {
                            display: flex;
                            height: 29%;
                            flex-direction: column;
                            align-items: center;
                        }
                        @media screen and (max-width: 600px) {
                            justify-content: center;
                        }

                        .descript {
                            &.mobile {
                                display: none;
                            }
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            // height: 100%;
                            height: min-content;
                            margin-right: 5%;
                            @media screen and (max-width: 768px) {
                                &.desktop {
                                    display: none;
                                }
                                &.mobile {
                                    display: block;
                                    margin-bottom: 5%;
                                    margin-right: 0;
                                    p {
                                        font-size: 25px;
                                        margin-bottom: 5px;
                                    }
                                }
                            }
                            @media screen and (max-width: 600px) {
                                &.mobile {
                                    p {
                                        font-size: 18px;
                                        margin-bottom: 0;
                                        line-height: 30px;
                                    }
                                }
                            }
                            @media screen and (max-width: 390px) {
                                &.mobile {
                                    p {
                                        font-size: 16px;
                                        margin-bottom: 0;
                                        line-height: 25px;
                                    }
                                }
                            }

                            p {
                                font-size: 30px;
                                // margin-bottom: -5px;
                                margin-top: 0;
                                @media screen and (max-width: 1680px) {
                                    font-size: 25px;
                                    // margin-bottom: -5px;
                                }
                                @media screen and (max-width: 1400px) {
                                    font-size: 20px;
                                    margin-bottom: -5px;
                                }
                                @media screen and (max-width: 1100px) {
                                    font-size: 17px;
                                }
                                @media screen and (max-width: 920px) {
                                    font-size: 14px;
                                    // margin-bottom: -5px;
                                }
                            }
                        }
                        .apps {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                            height: min-content;
                            width: min-content;
                            height: 100%;
                            margin-right: 10px;
                            // @media screen and (max-width: 1400px) {
                            //     height: 0%;
                            //     height: 100%;
                            // }
                            @media screen and (max-width: 768px) {
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                // height: min-content;
                                width: 100%;
                                height: 100%;
                                margin-right: 20%;
                            }
                            @media screen and (max-width: 600px) {
                                width: 80%;
                                height: 60%;
                                margin-right: 0;
                                margin-left: 10px;
                            }
                            @media screen and (max-width: 390px) {
                                width: 90%;
                                height: 50%;
                            }
                            .qr {
                                height: 100%;
                                margin-right: 15px;
                                margin-left: 15px;
                                @media screen and (max-width: 768px) {
                                    margin-left: 0px;
                                    margin-right: 10%;
                                }
                                @media screen and (max-width: 390px) {
                                    margin-left: 5%;
                                    margin-right: 5%;
                                }
                            }
                            .links {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                @media screen and (max-width: 390px) {
                                    a {
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                }
                &--main {
                    display: block;
                    text-align: center;
                    img {
                        max-width: 180px;
                        margin-left: 16px;
                        margin-top: -16px;
                        @media screen and (max-width: 1800px) {
                            max-width: 160px;
                        }
                        @media screen and (max-width: 1000px) {
                            display: block;
                            margin: 12px auto;
                        }
                    }
                }
            }
        }
    }
    .smart-points {
        height: max-content;
        .sale {
            &.mobile {
                display: none;
            }
            padding-top: 5%; //35px;
            padding-bottom: 3%;
            font-size: 40px;
            color: rgb(221, 29, 33);
            font-weight: bold;
            line-height: 40px;
            font-family: "ShellFutura", sans-serif;
            text-align: center;
            width: 100%;
            @media screen and (max-width: 1680px) {
                font-size: 30px;
                // padding-top: 3%//30px;
                padding-top: 10%;
            }
            @media screen and (max-width: 1400px) {
                font-size: 30px;
                padding-top: 3%;
            }
            @media screen and (max-width: 920px) {
                font-size: 20px;
                padding-top: 2% //5px;;
            }
            @media screen and (max-width: 768px) {
                &.mobile {
                    display: block;
                    font-size: 35px;
                    line-height: 15px;
                    padding-top: 20px;
                    @media screen and (max-width: 600px) {
                        font-size: 30px;
                        line-height: 15px;
                        padding-top: 0px;
                        padding-bottom: 0px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    @media screen and (max-width: 390px) {
                        font-size: 25px;
                        // margin: 0 15px 0 15px;
                        height: 50%;
                    }
                }
                &.desktop {
                    display: none;
                }
            }
            p {
                margin-bottom: 4%;
            }
            .yellow-card-long {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 30vh;
                align-items: center;
                padding: 0% 2% 0% 2%;
                background-image: url("../../../img/promotions/double-smart/yellow-card-long.png");
                background-size: 100% 100%;
                justify-content: space-evenly;
                .sales-element {
                    display: flex;
                    width: 40%;
                }
                @media screen and (max-width: 920px) {
                    height: 25vw;
                }
            }
            .yellow-card {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 70%;
                margin-bottom: 5%;
                // margin: 15% 0 15% 0;
                padding: 0% 0% 5% 0%;
                background-image: url("../../../img/promotions/double-smart/yellow-card.png");
                background-size: 100% 100%;
                align-items: flex-start;
                @media screen and (max-width: 600px) {
                    height: 50%;
                    width: 90%;
                    margin-top: 20px;
                }
                @media screen and (max-width: 390px) {
                    height: 100%;
                    width: 90%;
                }
                .sale-part {
                    display: flex;
                    align-items: center;
                    width: 120%;
                    height: 50%;
                    padding-left: 10%;
                    .grey-text {
                        display: flex;
                        flex-direction: row;
                        width: 120%;
                        padding-left: 10%;
                        font-size: 30px;
                        font-weight: bold;
                        line-height: 45px;
                        color: #404040;
                    }
                    .red {
                        padding: 0 5px 0 5px;
                        font-size: 45px;
                        color: rgb(221, 29, 33);
                    }
                    @media screen and (max-width: 600px) {
                        width: 100%;
                        height: 150px;
                        padding-left: 10%;
                        .grey-text {
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            padding-left: 10%;
                            font-size: 18px;
                            font-weight: bold;
                            line-height: 25px;
                            color: #404040;
                        }
                        .red {
                            padding: 0 5px 0 5px;
                            font-size: 22px;
                            color: rgb(221, 29, 33);
                        }
                    }
                    @media screen and (max-width: 390px) {
                        padding-left: 5%;
                        .grey-text {
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            padding-left: 5%;
                            font-size: 18px;
                            font-weight: bold;
                            line-height: 25px;
                            color: #404040;
                        }
                        .red {
                            padding: 0 5px 0 5px;
                            font-size: 22px;
                            color: rgb(221, 29, 33);
                        }
                    }
                }
                img {
                    height: 50%;
                }
            }
        }
    }
    .registration {
        padding-top: 20px;
        @media screen and (max-width: $point-md-max) {
            padding-top: 50px;
            padding-bottom: 25px;
        }
        &__top-text {
            margin: 0;
            font-size: 30px;
            font-weight: 700;
            color: #404040;
            text-align: center;
            margin-bottom: 8px;
            @media screen and (max-width: $point-md-max) {
                font-size: 22px;
                padding-left: 16px;
                padding-right: 16px;
            }
            @media screen and (max-width: 600px) {
                .desktop-br {
                    display: none;
                }
            }
            &.color-red {
                margin-bottom: 8px;
                color: $shell-red;
                &.mb-1em {
                    margin-bottom: 1em !important;
                }
            }
        }
        &__list {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 70px;
            @media screen and (max-width: 768px) {
                //(max-width: $point-md-max) {
                flex-wrap: wrap;
                justify-content: center;
            }
            &_item {
                position: relative;
                z-index: 1;
                width: 24%;
                @media screen and (max-width: 768px) {
                    //$point-md-max) {
                    width: 50%;
                    margin-bottom: 30px;
                }
            }
            &_icon {
                display: block;
                width: 41%;
                margin: 0 auto;
                @media screen and (max-width: 768px) {
                    //(max-width: $point-md-max) {
                    width: 50%;
                }
                > img {
                    width: 100%;
                }
            }
            &_descr {
                padding: 10% 10px 0 10px;
                font-size: 24px;
                font-weight: 700;
                color: #404040;
                text-align: center;
                @media screen and (max-width: 768px) {
                    //(max-width: $point-md-max) {
                    font-size: 4.25vw;
                    padding: 10% 5px 0 5px;
                }
                a {
                    display: inline-block;
                    color: $shell-red;
                }
                span {
                    display: inline-block;
                }
            }
        }
    }
    .only-fuel {
        .confetti {
            @media screen and (max-width: 768px) {
                &.desktop {
                    display: none;
                }
            }
        }
        &__wraper {
            margin-top: 50px;
            height: 710px;
            @media screen and (max-width: 1680px) {
                height: 520px;
            }
            @media screen and (max-width: 1400px) {
                height: 500px;
                margin-top: 30px;
            }
            @media screen and (max-width: 1280px) {
                height: 520px;
                margin-top: 20px;
            }
            @media screen and (max-width: 1100px) {
                height: 430px;
            }
            @media screen and (max-width: 768px) {
                height: 1600px;
            }
            @media screen and (max-width: 600px) {
                background-color: rgb(183, 33, 26);
                height: 110vh;
                @media screen and (min-height: 860px) {
                    height: 100vh;
                }
            }
            @media screen and (max-width: 390px) {
                @media screen and (min-height: 860px) {
                    height: 90vh;
                }
            }
            .baner {
                &.mobile {
                    display: none;
                }
                height: 100%;
                background-repeat: no-repeat;
                background-image: url("../../../img/promotions/double-smart/double-smart-baner.png");
                background-size: 100%;
                background-position: right -10px;
                padding-top: 7%;
                padding-bottom: 7%;
                @media screen and (max-width: 600px) {
                    padding-top: 5%;
                }
                .desktop-red-card {
                    display: flex;
                    flex-direction: column;
                    width: 35%;
                    height: 100%;
                    background-image: url("../../../img/promotions/double-smart/desktop-red-card.png");
                    background-size: 100% 100%;
                    align-items: flex-start;
                    padding-top: 1%;
                    padding-left: 3%;
                    p {
                        color: #ffffff;
                        font-size: 230%;
                        line-height: 30px;
                        font-family: "ShellFutura", sans-serif;
                        font-weight: bold;
                    }
                    img {
                        // height: 12%;
                        // width: 60%;
                        margin: 8% 0 8% 0;
                    }
                    @media screen and (max-width: 1680px) {
                        padding: 1% 0 1% 3%;
                        p {
                            color: #ffffff;
                            font-size: 210%;
                            line-height: 22px;
                            font-family: "ShellFutura", sans-serif;
                            font-weight: bold;
                        }
                        img {
                            // height: 15%;
                            margin: 4% 0 4% 0;
                        }
                    }
                    @media screen and (max-width: 1400px) {
                        padding: 1% 0 1% 4%;
                        p {
                            color: #ffffff;
                            font-size: 180%;
                            line-height: 15px;
                            font-family: "ShellFutura", sans-serif;
                            font-weight: bold;
                        }
                        img {
                            // height: 15%;
                            margin: 6% 0 6% 0;
                        }
                    }
                    @media screen and (max-width: 1280px) {
                        padding: 2% 0 1% 4%;
                        p {
                            color: #ffffff;
                            font-size: 160%;
                            line-height: 15px;
                            font-family: "ShellFutura", sans-serif;
                            font-weight: bold;
                        }
                        img {
                            // height: 13%;
                            margin: 8% 0 8% 0;
                        }
                    }
                    @media screen and (max-width: 1100px) {
                        padding: 1% 0 1% 4%;
                        p {
                            color: #ffffff;
                            font-size: 130%;
                            line-height: 15px;
                            font-family: "ShellFutura", sans-serif;
                            font-weight: bold;
                        }
                        img {
                            // height: 13%;
                            margin: 5% 0 5% 0;
                        }
                    }
                }
                @media screen and (max-width: 768px) {
                    &.desktop {
                        display: none;
                    }
                    &.mobile {
                        display: block;
                        background-image: url("../../../img/promotions/double-smart/mobile-baner.png");
                        background-size: 100% ;//100%;
                        background-position: bottom center;
                        // padding-left: 20px;
                        // padding-right: 20px;
                        
                        .mobile-card-info {
                            @media screen and (max-width: 768px) {
                                padding: 40px 40px 0 40px;
                            }
                            @media screen and (max-width: 600px) {
                                // height: 100vh;
                                padding: 25px 25px 0 25px;
                            } 
                            @media screen and (max-width: 390px) {
                                padding: 15px 15px 0 15px;
                                // @media screen and (min-height: 860px) {
                                //     height: 90vh;
                                // }
                            }
                            .red-card {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                height: 35%;
                                margin-top: 5%;
                                padding: 0% 3% 0% 3%;
                                background-image: url("../../../img/promotions/double-smart/red-card.png");
                                // background-size: contain;
                                // background-position: center center;
                                background-size: 100% 100%;
                                align-items: center;

                                @media screen and (max-width: 768px) {
                                    padding-top: 3%;
                                    p {
                                        color: #ffffff;
                                        font-size: 230%;
                                        line-height: 30px;
                                        font-family: "ShellFutura", sans-serif;
                                        font-weight: bold;
                                    }
                                    img {
                                        height: 15%;
                                        margin: 8% 0 8% 0;
                                    }
                                }
                                @media screen and (max-width: 600px) {
                                    p {
                                        font-size: 120%;
                                        line-height: 10px;
                                    }
                                    img {
                                        height: 15%;
                                        margin: 6% 0 6% 0;
                                    }
                                }
                                @media screen and (max-width: 390px) {
                                    p {
                                        font-size: 120%;
                                        line-height: 7px;
                                    }
                                    img {
                                        height: 15%;
                                        margin: 6% 0 6% 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .card-info {
                    height: 100%;
                }
            }
        }
    }
    .mobile-pdf {
        @media screen and (max-width: 600px) {
            margin: 0 10px 0 10px;
        }
        p {
            @media screen and (max-width: 768px) {
                font-size: 23px;
            }
            @media screen and (max-width: 600px) {
                font-size: 20px;
            }
            @media screen and (max-width: 390px) {
                font-size: 18px;
            }
        }
    }
}
