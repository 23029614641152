/* =================================
Select Multi Custom
================================= */

.slider-social-img {
    width: 100%;
    overflow: hidden;

    .slider {
        margin-left: -15px;
        margin-right: -15px;
        padding-bottom: em(120);
        white-space: nowrap;
        @media screen and (max-width: $point-xl-max) {
            padding-bottom: em(80);
        }
    }

    .slick-arrow {
        top: auto;
        bottom: 0;
        @include transform(none);
        color: $shell-very-dark-gray;
        
        @media screen and (max-width: $point-xl-max) {
            font-size: 2em;
        }

        &:hover {
            color: $color-red;
        }

        &.slick-prev {
            left: 15px;
            padding-left: 0;
        }

        &.slick-next {
            right: 15px;
            padding-right: 0;
        }

    }

    .slide-item {
        display: inline-block;
        width: 33.3%;
        padding-left: 15px;
        padding-right: 15px;
        @media screen and (max-width: $point-lg-max) {
            width: 50%;
        }
        @media screen and (max-width: $point-lg-max) {
            width: 100%;
        }
    }

}