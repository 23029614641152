/* =================================
Section for Shell Navigator
================================= */

.shell-navigator {
    .row {
        @media screen and (max-width: $point-lg-max) {
            margin-left: -5px;
            margin-right: -5px;
        }
        > * {
            @media screen and (max-width: $point-lg-max) {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }

    &__filters {
        @media screen and (max-width: $point-scaling-cancel) {
            font-size: em(13);
        }

        @media screen and (max-width: $point-md-max) {
            display: none;
            position: absolute;
            z-index: 1000;
            width: 100%;
            height: auto;
            min-height: 100vh;
            min-height: calc(var(--vh, 1vh) * 100);
            top: 0;
            left: 0;
            padding: 130px 15px 30px 15px;
            background-color: rgba(255, 255, 255, 0.95);
            font-size: 1em;
        }

        .close-btn {
            top: 70px;
            right: 15px;
        }

        .form-group.btn-checkbox {
            .btn__checkbox {
                padding-left: 0.25em;
                padding-right: 0.25em;
                font-weight: bold;
            }
        }

        > .row {
            .col-filters-1 {
                @media screen and (max-width: 1500px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        .select-multi__result-list {
            max-height: 210px;
            padding: 0.5em 0.5em 0.5em;
            .select-multi__option {
                font-size: 15px;
            }
        }

        .col-filter {
            @media screen and (min-width: 576px) {
                max-width: calc(100% / 6);
                flex: 0 0 calc(100% / 6);
            }
        }
        .col-filter-pd {
            padding-left: 7px;
            padding-right: 7px;
        }
    }

    &__filters_mob {
        display: none;
        @media screen and (max-width: $point-md-max) {
            display: block;
        }
        .open-filters-link {
            position: relative;
            display: inline-block;
            padding-right: 2em;
            margin-top: 1em;
            font-size: em(18);
            color: $shell-red;
            > img {
                position: absolute;
                width: 1.5em;
                height: auto;
                right: 0;
                top: 50%;
                @include transform(translateY(-50%));
            }
        }
        .btns-wrap {
            text-align: right;
            @media screen and (max-width: $point-scaling-cancel) {
                font-size: em(13);
            }
            .btn {
                min-width: auto;
            }
        }
    }

    &__search {
        @media screen and (max-width: $point-scaling-cancel) {
            font-size: em(13);
        }
        .search-input-wrap {
            width: calc(100% + 30px);
            @media screen and (max-width: $point-lg-max) {
                width: 100%;
            }
            input {
                padding-top: em(23, 24);
                padding-bottom: em(24, 24);
                @media screen and (max-width: $point-lg-max) {
                    padding-right: em(90, 24);
                }
                @include placeholder {
                    color: $placeholder-color;
                    font-weight: 400;
                    font-size: em(18, 24);
                }
            }
            .btn {
                position: absolute;
                width: em(73, 22);
                height: calc(100% - 2px);
                top: 1px;
                right: 1px;
                padding-left: 0.5em;
                padding-right: 0.5em;
            }
            .ui-autocomplete {
                position: absolute;
                z-index: 10;
                left: 0;
                top: 100%;
            }
        }
        .col-btn-search {
            width: em(73);
            height: em(73);
        }
    }

    &__map {
        @media screen and (max-width: $point-lg-max) {
            width: auto;
            margin-left: -30px;
            margin-right: -30px;
        }
        @media screen and (max-width: $point-sm-max) {
            margin-left: -15px;
            margin-right: -15px;
        }
        .map-block {
            min-height: 60vh;
        }
        .gm-style {
            .gm-style-iw.gm-style-iw-c {
                max-height: 550px !important;
                @media screen and (max-width: $point-md-max) {
                    max-width: 260px !important;
                    max-height: 331px !important;
                }
                .gm-style-iw-d {
                    max-height: 532px !important;
                    @media screen and (max-width: $point-md-max) {
                        max-height: 313px !important;
                    }
                }
            }
            .gm-ui-hover-effect {
                width: 40px !important;
                height: 40px !important;
                top: 4px !important;
                right: 4px !important;
                > img {
                    width: 25px !important;
                    height: auto !important;
                    content: url("../../img/cancel_red.svg");
                }
            }
        }
        .window-info {
            min-width: 380px;
            padding: 8px 4px 4px 8px;
            font-family: $font-ShellFutura;
            font-size: 16px;
            @media screen and (max-width: $point-scaling-10) {
                font-size: 14px;
            }
            @media screen and (max-width: $point-scaling-25) {
                font-size: 13px;
            }
            @media screen and (max-width: $point-xl-max) {
                min-width: 350px;
                padding: 4px 2px 2px 4px;
                font-size: 13px;
            }
            @media screen and (max-width: $point-md-max) {
                min-width: auto;
            }
            > * {
                margin-bottom: 0.5em;
            }
            b {
                font-weight: 700;
            }
            .icon {
                margin-right: 0.5em;
                font-size: 1.25em;
            }
            .azs-address {
                line-height: 1.2;
            }
            .phone-worktime {
                > * {
                    width: 50%;
                    float: left;
                    @media screen and (max-width: $point-md-max) {
                        width: 100%;
                        float: none;
                    }
                }
                .phone {
                    padding-right: 1.5em;
                    white-space: nowrap;
                    @media screen and (max-width: $point-md-max) {
                        margin-bottom: 0.5em;
                    }
                    > * {
                        vertical-align: top;
                    }
                }
                .worktime {
                    > * {
                        vertical-align: top;
                    }
                }
            }
            .prices {
                margin-bottom: 0.75em;
                .prices-title {
                    line-height: 1.2;
                    .date {
                        display: inline-block;
                    }
                }
                table {
                    width: 100%;
                    margin-top: 0.5em;
                    tr {
                        &:nth-child(2n + 1) {
                            background-color: #fff2b2;
                        }
                        td {
                            padding: 2px 5px;
                            &:first-child {
                                padding-left: 1em;
                                @media screen and (max-width: $point-md-max) {
                                    padding-left: 0.5em;
                                }
                            }
                            &:last-child {
                                color: $shell-red;
                            }
                        }
                    }
                }
            }
            .services {
                margin-bottom: 1em;
                line-height: 1.2;
            }
            .btns-wrap {
                margin-bottom: 0.5em;
                .btn {
                    padding: em(8) em(20);
                }
            }
        }
    }

    .btns-wrap {
        margin-bottom: 20px;
        .btn {
            padding-left: 0.5em;
            padding-right: 0.5em;
        }
    }
}
