/* =================================
Section Personal Promotion Ferrari Toy Cars
================================= */

.personal-cabinet {
    .ferrari-lk {
        &__header {
            padding: 30px 12px 40px 30px;
            background: url("../../../img/promotions/ferrari-promo/header-bg--desktop.jpg"),
                #6db3f2;
            background-position: center;
            background-size: cover;
            position: relative;
            @media screen and (max-width: $point-sm-max) {
                padding-top: 80px;
            }
            .title {
                font-size: 30px;
                font-weight: bold;
                color: $white;
                text-transform: uppercase;
                margin-bottom: 10px;
                @media screen and (max-width: $point-md-max) {
                    font-size: 24px;
                    max-width: 70%;
                }
                @media screen and (max-width: $point-sm-max) {
                    max-width: 100%;
                    text-align: center;
                    margin-bottom: 10px;
                }
                @media screen and (max-width: 390px) {
                    font-size: 21px;
                }
            }
            .subtitle {
                margin-bottom: 16px;
                color: $shell-yellow;
                font-size: 20px;
                text-transform: uppercase;
                font-weight: bold;
                @media screen and (max-width: $point-md-max) {
                    font-size: 18px;
                }
                @media screen and (max-width: $point-sm-max) {
                    text-align: center;
                    margin-bottom: 24px;
                    font-size: 16px;
                }
                @media screen and (max-width: 390px) {
                    font-size: 15px;
                }
            }
            .logo {
                position: absolute;
                top: 30px;
                right: 30px;
            }
            &-cars {
                position: absolute;
                bottom: -40px;
                right: -10px;
                img {
                    filter: drop-shadow(-4px 3px 4px rgba(#000, 0.65));
                }
                @media screen and (max-width: $point-md-max) {
                    bottom: -24px;
                    right: -4px;
                    img {
                        max-width: 400px;
                    }
                }
                @media screen and (max-width: 576px) {
                    display: none;
                }
                &--mob {
                    display: none;
                    position: initial;
                    bottom: auto;
                    right: auto;
                    text-align: center;
                    @media screen and (max-width: 576px) {
                        display: block;
                    }
                    img {
                        width: 100%;
                        margin: 0 auto;
                    }
                }
            }
        }
        &__info {
            font-size: 24px;
            margin: 65px 0 35px 0;
            font-weight: bold;
            span {
                color: $shell-red;
            }
            @media screen and (max-width: $point-sm-max) {
                margin: 24px 0 30px 0;
                font-size: 20px;
            }
        }
        &__table-descr {
            width: 100%;
            margin-top: 40px;
            margin-bottom: 30px;
            font-size: em(18);
            font-weight: 700;
            color: $shell-very-dark-gray;
            position: relative;
            line-height: 145%;
            @media screen and (max-width: $point-md-max) {
                width: 100%;
                text-align: center;
                font-size: em(16);
            }
            @media screen and (max-width: 768px) {
                margin-top: 20px;
                margin-bottom: 16px;
            }
            p {
                margin: 0;
            }
            .color-red {
                color: $shell-red;
            }
            .color-blue {
                color: $shell-light-blue;
            }
            .cars {
                width: 450px;
                position: absolute;
                top: -130px;
                right: 16px;
                @media screen and (max-width: 1680px) {
                    width: 320px;
                }
                @media screen and (max-width: 1400px) {
                    width: 300px;
                }
                @media screen and (max-width: 1280px) {
                    width: 220px;
                }
                @media screen and (max-width: 768px) {
                    width: 150px;
                    right: 16px;
                }
                @media screen and (max-width: 576px) {
                    display: none;
                }
            }
        }
        &__table-wrap {
            margin-bottom: 45px;
            @media screen and (max-width: $point-md-max) {
                display: none;
            }
            &_mob {
                display: none;
                @media screen and (max-width: $point-md-max) {
                    display: block;
                }
            }
            .blue-text {
                color: $shell-light-blue !important;
            }
            table {
                position: relative;
                font-size: 1em;
                th,
                td {
                    border: 1px solid #aaa;
                    @media screen and (max-width: $point-md-max) {
                        &.col-50 {
                            width: 50%;
                        }
                        &.col-left {
                            text-align: left !important;
                        }
                    }
                }
                th {
                    padding: 10px 10px;
                    background-color: #fff;
                    font-size: em(20);
                    font-weight: 400;
                    text-align: center;
                    @media screen and (max-width: $point-xl-max) {
                        font-size: em(18);
                    }
                    @media screen and (max-width: $point-md-max) {
                        font-size: em(16);
                        padding: 12px 8px;
                        text-align: center;
                    }
                    &:first-child {
                        padding-left: 25px;
                        text-align: left;
                        min-width: 300px;
                        @media screen and (max-width: $point-md-max) {
                            text-align: center;
                            min-width: auto;
                            padding-left: 12px;
                        }
                    }
                    .big-text {
                        font-size: em(21, 20);
                        font-weight: 700;
                        color: $shell-red;
                    }
                }
                td {
                    padding: 20px 10px;
                    text-align: center;
                    @media screen and (max-width: $point-md-max) {
                        padding: 12px 8px;
                    }
                    @media screen and (max-width: 576px) {
                        &:nth-child(1) {
                            text-align: left;
                        }
                    }
                    &.bg-red {
                        padding-top: 20px;
                        padding-bottom: 20px;
                        padding-left: 25px;
                        text-align: left;
                    }
                    &.bg-yellow {
                        background-color: #fbce07;
                    }
                    &.bg-gray {
                        background-color: #ececec;
                    }
                    .big-text {
                        font-size: em(24);
                        color: $shell-red;
                        font-weight: 700;
                        @media screen and (max-width: $point-md-max) {
                            font-size: em(20);
                        }
                    }
                    &.bg-grey {
                        background-color: #ececec;
                    }
                    &.bg-light-blue {
                        background-color: #c8e7f7;
                    }
                }
                .bg-red {
                    background-color: $shell-red;
                    color: $white;
                }
                .bg-red-light {
                    background-color: rgba(247, 146, 148, 0.66);
                }
                .bg-blue {
                    background-color: #3f98c5;
                    color: $white;
                }
                .bg-blue-light {
                    background-color: rgba(198, 233, 246, 1);
                }
                .bg-yellow-light {
                    background-color: #fbe680;
                }
                .bb-white {
                    border-bottom: 1px solid #fff;
                }
                .bl-yellow {
                    border-left: 1px solid #fbce07;
                }
                .custom-td {
                    text-align: left;
                    padding-left: 16px;
                    @media screen and (max-width: $point-md-max) {
                        padding-left: 0;
                    }
                    b > img {
                        max-width: 75%;
                        margin-bottom: 5px;
                        @media screen and (max-width: $point-md-max) {
                            width: 60%;
                            max-width: 230px;
                        }
                    }
                    .fs-16 {
                        font-size: 1em;
                    }
                    .fs-21 {
                        font-size: em(21);
                        @media screen and (max-width: $point-md-max) {
                            font-size: em(18);
                        }
                    }
                    @media screen and (max-width: $point-md-max) {
                        text-align: center;
                        &--flex {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            span {
                                margin: 0 10px;
                            }
                            img {
                                max-width: 110px;
                            }
                        }
                    }
                    @media screen and (max-width: 414px) {
                        &--flex {
                            span {
                                margin: 0 8px;
                            }
                            img {
                                max-width: 110px;
                            }
                        }
                    }
                }
                .content-list {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    > li {
                        position: relative;
                        padding: 15px 15px;
                        text-align: center;
                        border-bottom: 1px solid $shell-very-dark-gray;
                        &:first-child {
                            padding-top: 10px;
                            &::before {
                                content: none;
                            }
                        }
                        &:last-child {
                            padding-bottom: 10px;
                            border-bottom: none;
                        }
                        &::before {
                            content: "або";
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 3em;
                            height: 1.5em;
                            position: absolute;
                            top: 0;
                            left: 50%;
                            @include transform(translate(-50%, -50%));
                            background-color: $shell-red;
                            font-weight: 500;
                            color: $white;
                            font-size: em(17);
                            @include border-radius(50%);
                        }
                    }
                }
            }
        }
        &__ban-img {
            margin-bottom: 20px;
            @media screen and (max-width: $point-md-max) {
                margin-bottom: 40px;
            }
            .img-wrap {
                > img {
                    &.deskt-tabl {
                        @media screen and (max-width: $point-md-max) {
                            display: none;
                        }
                    }
                    &.mob {
                        display: none;
                        @media screen and (max-width: $point-md-max) {
                            display: inline-block;
                        }
                    }
                }
            }
        }
        &__btn-wrap {
            text-align: center;
        }
    }
}
