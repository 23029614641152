/* =================================
Section Personal History Transaction
================================= */

.history-transaction {

    .export-wrapper {
        display: flex;
        justify-content: flex-start;
        margin-top: em(-58);
        margin-bottom: 1em;
        @media screen and (max-width: 1900px) {
            margin-top: em(-54);
        }
        @media screen and (max-width: 1650px) {
            margin-top: em(-50);
        }
        @media screen and (max-width: $point-lg-max) {
            justify-content: flex-end;
            margin-top: em(-25);
            margin-bottom: 0;
        }
        a {
            display: flex;
            align-items: center;
            color: $shell-red;
            font-size: em(21);
            @media screen and (max-width: 1900px) {
                font-size: em(20);
            }
            @media screen and (max-width: 1650px) {
                font-size: em(18);
            }
            @media screen and (max-width: $point-lg-max) {
                font-size: em(18);
            }
            img {
                padding-left: em(8,21);
                width: em(36,21);
                height: em(36,21);
            }
        }
    }

}

.popup-table {
    &__history-transaction {
        .popup-table__table-wrap {
            table {
                @media screen and (max-width: $point-lg-max) {
                    font-size: em(14);
                }
                tr {
                    &:last-child {
                        font-size: em(24,18);
                        font-weight: 700;
                        color: $shell-red;
                        @media screen and (max-width: $point-lg-max) {
                            font-size: em(18,18);
                        }
                    }
                }
                th {
                    &:first-child {
                        padding-left: 1.5em;
                        @media screen and (max-width: $point-lg-max) {
                            padding-left: 10px;
                        }
                    }
                }
                tr {
                    td {
                        &:first-child {
                            padding-left: 1.5em;
                            @media screen and (max-width: $point-lg-max) {
                                max-width: 40%;
                                padding-left: 10px;
                                overflow-wrap: break-word;
                                word-wrap: break-word;
                                word-break: break-word;
                            }
                        }
                        &:not(:first-child) {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
        .popup-table__header {
            .payment-type {
                padding-left: 1.5em;
                @media screen and (max-width: $point-lg-max) {
                    padding-left: 10px;
                    font-size: em(14);
                }
            }
        }
    }
}