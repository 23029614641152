/* =================================
Text Styles
================================= */

// Colors Text
.color-red {color: $shell-red;}
.color-yellow {color: $shell-yellow;}
.color-blue {color: $shell-blue;}
.color-very-dark-gray {color: $shell-very-dark-gray;}
.color-white {color: $white;}

// Align Text
.text-left {text-align: left;}
.text-right {text-align: right;}
.text-center {text-align: center;}
.text-justify {text-align: justify;}
.text-last-center {
    -ms-text-align-last: center;
    text-align-last: center;
}
.text-center-lg {
    @media screen and (max-width: $point-lg-max) {
        text-align: center;
    }
}

// Weight text
.weight-bold, .bold {font-weight: 700;}

// Size text
.fs-18 {font-size: em(18);}
.fs-20 {font-size: em(20);}
.fs-24 {font-size: em(24);}
.fs-26 {font-size: em(26);}
.fs-30 {font-size: em(30);}
.fs-48 {font-size: em(48);}
.fs-lg-24 {
    @media screen and (max-width: $point-lg-max) {
        font-size: em(24);
    }
}
.fs-lg-20 {
    @media screen and (max-width: $point-lg-max) {
        font-size: em(20);
    }
}
.fs-lg-18 {
    @media screen and (max-width: $point-lg-max) {
        font-size: em(18);
    }
}
.fs-lg-16 {
    @media screen and (max-width: $point-lg-max) {
        font-size: em(16);
    }
}
.fs-sm-20 {
    @media screen and (max-width: $point-lg-max) {
        font-size: em(20);
    }
}

//
.nowrap {
    white-space: nowrap;
}

// Text Block
.text-block {

    @media screen and (max-width: $point-md-max) {
        text-align: justify !important;
    }

    &.text-w {
        width: 100%;
        max-width: 83.3%;
        margin: 0 auto;
        @media screen and (max-width: $point-xl-max) {
            max-width: 100%;
        }
    }

    > * {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    a {
        color: $shell-red;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    p {
        margin: 1em 0;
    }
    strong, b {
        font-weight: 700;
    }
    ul, ol {
        text-align: left;
    }

    h1, h2, h3, h4 {
        font-weight: 700;
    }

    h1 {
        font-size: em(22);
    }
    h2, h3, h4 {
        font-size: em(20);
    }
}