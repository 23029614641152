/* =================================
Page Loyalty Program Styles
================================= */

.loyalty-program {
    .image-block {
        position: relative;
    }
    .steps-links {
        display: flex;
        justify-content: space-around;
        position: absolute;
        width: 100%;
        height: 66%;
        left: 0;
        bottom: 0;
        padding-left: 5%;
        @media screen and (max-width: $point-md-max) {
            display: block;
            padding-left: 0;
        }
        .step-link {
            width: 30%;
            height: 100%;
            @media screen and (max-width: $point-md-max) {
                position: absolute;
                width: 45%;
                height: 50%;
                &.step1 {
                    top: 0;
                    left: 5%;
                }
                &.step2 {
                    width: 40%;
                    height: 63%;
                    top: 9%;
                    right: 5%;
                }
                &.step3 {
                    width: 50%;
                    height: 48%;
                    bottom: 0;
                    left: 5%;
                }
            }
        }
    }
}