/* =================================
Section shell-market
================================= */

.shell-market {
    margin-bottom: -82px;
    padding-bottom: 1em !important;
    background-image: url("../../img/bg_shell-market.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;

    @media screen and (max-width: $point-lg-max) {
        margin-bottom: -50px;
        background-image: url("../../img/bg_shell-market_mob.png");
    }
    @media screen and (max-width: $point-md-max) {
        background-image: url("../../img/bg_shell-market_mob.png");
        background-size: 100% 80%;
    }

    .section-title {
        margin-bottom: 3em;
        + p {
            line-height: 2;
            @media screen and (max-width: $point-lg-max) {
                line-height: inherit;
            }
        }
    }

    .market-links {
        padding-bottom: 10%;
        @media screen and (max-width: $point-lg-max) {
            padding-bottom: 4em;
        }
        > a {
            display: inline-block;
            width: em(280);
            margin-bottom: 1em;
            @media screen and (max-width: $point-lg-max) {
                width: em(240);
            }
            &:last-child {
                margin-bottom: 0;
            }
            > img {
                width: 100%;
                height: auto;
            }
        }
    }
    .bottom-link {
        margin-top: 2em;
        text-align: center;
        a {
            font-size: em(24);
            font-weight: 700;
            color: $shell-red;
            text-decoration: underline;
            @media screen and (max-width: $point-lg-max) {
                font-size: em(18);
            }
            @media screen and (max-width: $point-md-max) {
                font-size: em(16);
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
}
