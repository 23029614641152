.cookie-confirm, .browser-cookie-disabled {
    position:fixed;
    display: none;
    bottom:0;
    padding: 17px 0;
    width: 100%;
    z-index: 9999;


    a {color:#dd1d21;}

    .buttons {text-align: center}

    .confirm, .decline {
        display: inline-block;
        border: 2px solid #ed2023;
        width: 100%;
        line-height: 53px;
        text-align: center;

        @media screen and (max-width: $point-md-max) {
            margin-top: 15px;
        }
    }

    .confirm {
        background:#ed2023;
        color:#fff;
    }

    .decline {
        color: #ed2023;
        border: 2px solid #ed2023;
    }
}

.cookie-confirm{
    background: #fbce07;
    color:#000;
}

.browser-cookie-disabled {
    background: #dd1d21;
    color:#fff;
}
