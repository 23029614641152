/* =================================
Section images-section
================================= */

.images-section {
    margin-bottom: 60px;
    padding-top: 20px;
    @media screen and (max-width: 991px) {
        padding-top: 0;
    }

    &.mb-15 {
        margin-bottom: 15px;
    }
    &.mb-20 {
        margin-bottom: 20px;
    }

    .images-wrap {
        width: auto;
        @media screen and (max-width: 991px) {
            //max-width: 480px;
            margin-left: auto;
            margin-right: auto;
        }
        @media screen and (max-width: $point-md-max) {
            max-width: none;
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    .img-wrap {
        position: relative;
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 0;
        overflow: hidden;
        > img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
        &.w-100 {
            > img {
                width: 100%;
            }
        }
    }

}