/* =================================
Section Promotion Big
================================= */

.promotion-descr {
    .text-block {
        font-size: em(20);
        text-align: justify;
        @media screen and (max-width: $point-scaling-25) {
            font-size: em(21);
        }
        @media screen and (max-width: $point-lg-max) {
            font-size: em(18);
        }
    }
    hr {
        width: 100%;
        height: 1px;
        margin-top: 3em;
        padding: 0;
        border: none;
        background-color:#d9d9d9;
    }
}