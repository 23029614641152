/* =================================
Buttons Checkbox
================================= */

.form-group {
    &.btn-checkbox {

        &.not-checked {
            .btn {
                &__checkbox {
                    background-color: $btn-checkbox-bg-not-checked1;
                }
            }
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            visibility: hidden;
            opacity: 0;
            &:checked {
                ~ .btn {
                    &__checkbox {
                        background-color: $btn-checkbox-bg-checked;
                        border: 1px solid $btn-checkbox-border-hover;
                        color: $btn-checkbox-color-hover;
                    }
                }
            }
        }

        .btn {
            &__checkbox {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: .25em;
                padding-bottom: .25em;
                height: em(80);
                margin-bottom: 0;
                background-color: $btn-checkbox-bg;
                border: 1px solid $btn-checkbox-border;
                color: $btn-checkbox-color;

                -webkit-box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;
                -moz-box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;
                box-shadow: 0px 5px 0px 0px $shell-very-pale-gray;

                &:hover,
                &:active,
                &:focus {
                    @media screen and (min-width: $point-lg-min) {
                        background-color: $btn-checkbox-bg-checked;
                        border: 1px solid $btn-checkbox-border-hover;
                        color: $btn-checkbox-color-hover;
                    }
                }
                img {
                    width: 100%;
                    max-width: em(90);
                    height: auto;
                }
            }
            &__big {
                height: em(80,24);
            }
        }

    }
}