/* =================================
Buttons Switcher Styles
================================= */

.btns-switcher {
    margin-bottom: 1.5em;

    &__label {
        margin-bottom: 1rem;
        font-size: em(18);
        color: $shell-very-dark-gray;
        &.fs-20 {
            font-size: em(20);
        }
    }

    &__btn {
        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            visibility: hidden;
            opacity: 0;
            &:checked {
                + label {
                    &.btn__yellow-border {
                        background-color: $btn-yellow-border-bg-hover;
                        border-color: $btn-yellow-border-border-hover;
                        color: $btn-yellow-border-color-hover;
                    }
                }
            }
            &:disabled {
                + label {
                    &.btn {
                        cursor: not-allowed;
                        &__yellow-border {
                            border-color: $shell-pale-gray;
                            color: $shell-pale-gray;
                            &:hover,
                            &:active,
                            &:focus {
                                background-color: $btn-yellow-border-bg;
                                border-color: $shell-pale-gray;
                                color: $shell-pale-gray;
                            }
                        }
                    }
                }
            }
        }
        label {
            &.btn {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 .25em;
                min-height: em(80,24);
                line-height: 1.2;
                @media screen and (max-width: $point-lg-max) {
                    min-height: em(61,22);
                }
            }
            .num {
                display: inline-block;
                margin-right: .1em;
            }
            .unit {
                display: inline-block;
                margin-left: .1em;
                font-size: .75em;
            }
        }
    }

}