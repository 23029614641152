/* =================================
Section top-section
================================= */

.top-section {
    //margin-top: -3em;
    &.slider-offers {
        padding: 0;
        @media screen and (max-width: $point-lg-max) {
            .container-fluid {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}