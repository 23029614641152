.auth-forms {
    text-align: center;

    .text-left {
        text-align: left;
    }

    .link.disabled{
        opacity: 0.3;
    }

    &__auth {
        position: relative;
        .bg-left {
            position: absolute;
            width: 5%;
            height: 100%;
            top: 0;
            left: 0;
            @media screen and (max-width: $point-lg-max) {
                display: none;
            }
        }
        .bg-right {
            position: absolute;
            width: 18%;
            height: 100%;
            top: 0;
            right: 0;
            @media screen and (max-width: $point-lg-max) {
                display: none;
            }
        }
    }

    &__registration {
        position: relative;
        .bg-left {
            position: absolute;
            width: 15%;
            height: 100%;
            top: 0;
            left: 0;
            @media screen and (max-width: $point-lg-max) {
                display: none;
            }
        }
        .bg-right {
            position: absolute;
            width: 15%;
            height: 100%;
            top: 0;
            right: 0;
            @media screen and (max-width: $point-lg-max) {
                display: none;
            }
        }
    }

    &__phone-error {
        .form-sub-text.mb-4em {
            margin-bottom: 4em;
        }
        .btns-wrap.mb-3em {
            margin-bottom: 3em;
        }
        .link {
            font-size: em(24);
            &::before {
                width: 100%;
            }
        }
    }

    &__title {
        margin-bottom: 40px;
        font-size: em(65);
        font-weight: 700;
        color: $shell-red;
        @media screen and (max-width: $point-lg-max) {
            font-size: em(48);
        }
    }

    .auth-forms__title {
        .alert.alert-success {
            padding: 0;
            background-color: transparent;
            border: none;
            border-radius: 0;
            font-size: em(34,65);
            color: inherit;
            @media screen and (max-width: $point-lg-max) {
                margin: 0 -10%;
            }
            @media screen and (max-width: $point-sm-max) {
                margin: 0;
            }
        }
    }

    .form-wrap-col-1 {
        width: 100%;
        max-width: em(500);
        margin: 0 auto;
    }

    .form-wrap-col-2 {
        width: 100%;
        max-width: em(1040);
        margin: 0 auto;
    }

    .form-group__checkbox {
        font-size: em(18);
        @media screen and (max-width: $point-lg-max) {
            font-size: 1em;
        }
        @media screen and (max-width: 640px) {
            margin-bottom: 0;
        }
        input[type=checkbox] {
            &+label {
                text-align: left;
                &::before {
                    margin-right: 1em;
                }
            }
        }
    }

}
