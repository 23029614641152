// Bootstrap
@import "~bootstrap";
// FontIcons
@import "modules/font.scss";
// Slick Slider
@import "~slick-carousel/slick/slick";
// Swiper
@import "~swiper/swiper";
// Bootstrap Select
@import "~bootstrap-select/dist/css/bootstrap-select.min.css";
@import "~aos/src/sass/aos";
// General
@import "general/vars";
@import "general/mixin";
@import "general/mixin-flex";
@import "general/function";
@import "general/fonts";
@import "general/general-style";

// Components
@import "components/animations";

@import "components/header/header";
@import "components/breadcrumbs";
@import "components/btns";
@import "components/burger";
@import "components/social";
@import "components/form";
@import "components/form-upload-file";
@import "components/input-style";
@import "components/text";
@import "components/sliders";
@import "components/slider-offers";
@import "components/slider-social-img";
@import "components/titles";
@import "components/personal-menu";
@import "components/select-multi";
@import "components/select-bootstrap";
@import "components/scrollbar";
@import "components/datepicker";
@import "components/image-block";
@import "components/rules";
@import "components/tabs-bootstrap";
@import "components/accordion";
@import "components/btns-switcher";
@import "components/counter";
@import "components/loader";
@import "components/scaling";
@import "components/btns-checkbox";
@import "components/popup";
@import "components/popup-navigator-route";
@import "components/ui-autocomplete";
@import "components/youtube-modal";

// Sections
@import "sections/top-section";
@import "sections/information-important";
@import "sections/actions";
@import "sections/auth-forms";
@import "sections/personal/balls-validity";
@import "sections/personal/edit-user-data";
@import "sections/personal/liter-wallet";
@import "sections/personal/history-transaction";
@import "sections/personal/popup-table";
@import "sections/personal/popup-user-data";
@import "sections/personal/orders";
@import "sections/personal/promotion";
@import "sections/personal/ferrari-toy-cars";
@import "sections/title-image";
@import "sections/promotion-descr";
@import "sections/tabs-rewards";
@import "sections/tabs-how-to-register";
@import "sections/shell-market";
@import "sections/buy-certificate";
@import "sections/basket-certificate";
@import "sections/images";
@import "sections/stock-rules";
@import "sections/shell-cafe";
@import "sections/contacts";
@import "sections/shell-navigator";
@import "sections/personal/bmw-m";
@import "sections/personal/ferrari-promo";

// Pages
@import "pages/personal-cabinet";
@import "pages/shell-v-power";
@import "pages/promotions/general";
@import "pages/promotions/everyjourney";
@import "pages/promotions/telegrambot";
@import "pages/promotions/covid-19";
@import "pages/promotions/change-smart";
@import "pages/promotions/championship";
@import "pages/promotions/shell-cafe";
@import "pages/promotions/ferrari-toy-cars";
@import "pages/promotions/fortune";
@import "pages/loyalty-program";
@import "pages/buy-certificate";
@import "pages/promotions/gas-station-winner";
@import "pages/promotions/bmw-m";
@import "pages/promotions/ferrari-promo";
@import "pages/promotions/referral-program";
@import "pages/promotions/million-reason";
@import "pages/promotions/magic-sphere";
@import "pages/promotions/double-smart";
@import "pages/promotions/power_duo";

// Footer
@import "components/footer";
@import "components/cookie-confirm";
