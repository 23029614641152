/*===================================================
 Fonts
===================================================*/

// ShellFutura

// Book (default)
@font-face {
    font-family: "ShellFutura";
    src: url("../../fonts/ShellFutura-Book/ShellFutura-Book.eot");
    src: url("../../fonts/ShellFutura-Book/ShellFutura-Book.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/ShellFutura-Book/ShellFutura-Book.woff") format("woff"),
        url("../../fonts/ShellFutura-Book/ShellFutura-Book.woff")
            format("woff2"),
        url("../../fonts/ShellFutura-Book/ShellFutura-Book.ttf")
            format("truetype"),
        url("../../fonts/ShellFutura-Book/ShellFutura-Book.svg#svgFontName")
            format("svg");
    font-style: normal;
    font-weight: 400;
}

// Bold
@font-face {
    font-family: "ShellFutura";
    src: url("../../fonts/ShellFutura-Bold/ShellFutura-Bold.eot");
    src: url("../../fonts/ShellFutura-Bold/ShellFutura-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/ShellFutura-Bold/ShellFutura-Bold.woff") format("woff"),
        url("../../fonts/ShellFutura-Bold/ShellFutura-Bold.woff2")
            format("woff2"),
        url("../../fonts/ShellFutura-Bold/ShellFutura-Bold.ttf")
            format("truetype"),
        url("../../fonts/ShellFutura-Bold/ShellFutura-Bold.svg#svgFontName")
            format("svg");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "ShellHand";
    src: url("../../fonts/ShellHand-Medium/ShellHand-Medium.eot");
    src: url("../../fonts/ShellHand-Medium/ShellHand-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/ShellHand-Medium/ShellHand-Medium.woff") format("woff"),
        url("../../fonts/ShellHand-Medium/ShellHand-Medium.woff2")
            format("woff2"),
        url("../../fonts/ShellHand-Medium/ShellHand-Medium.ttf")
            format("truetype"),
        url("../../fonts/ShellHand-Medium/ShellHand-Medium.svg#svgFontName")
            format("svg");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "ShellBold";
    src: url("../../fonts/SHELLBOLD.eot");
    src: url("../../fonts/SHELLBOLD.eot") format("embedded-opentype"),
        url("../../fonts/SHELLBOLD.woff2") format("woff2"),
        url("../../fonts/SHELLBOLD.woff") format("woff"),
        url("../../fonts/SHELLBOLD.ttf") format("truetype"),
        url("../../fonts/SHELLBOLD.svg#SHELLBOLD") format("svg");
    font-weight: bold;
}
