/* =================================
Header
================================= */

.logged-in {
    .main-header {
        &__nav {
            @media screen and (max-width: $point-lg-max) {
                padding-top: em(140);
            }
            @media screen and (max-width: $point-sm-max) {
                padding-top: em(125);
            }
            @media screen and (max-width: $point-iphone-5-max) {
                padding-top: em(108);
            }
        }
    }
}

.main-header {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    background-color: $bg-header;
    font-size: em(17);

    @media screen and (max-width: $point-scaling-10) {
        font-size: em(16);
    }

    @media screen and (max-width: $point-scaling-25) {
        font-size: em(15);
    }

    @media screen and (max-width: $point-xl-max) {
        font-size: 1em;
    }

    @media screen and (max-width: $point-sm-max) {
        font-size: em(14);
    }

    @media screen and (max-width: $point-iphone-5-max) {
        font-size: em(12);
    }

    // Open search style
    &.open-search {
        .main-header__search-deskt {
            .search-form-wrap {
                left: 0;
            }
        }
    }

    // Open menu in mobile
    &.open-menu {

    }

    // Top Header Line
    &__top {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: em(15) 0;
        background-color: #ffffff;
        @media screen and (max-width: $point-lg-max) {
            margin-left: -30px;
            margin-right: -30px;
            padding-left: 30px;
            padding-right: 30px;
        }
        @media screen and (max-width: $point-sm-max) {
            margin-left: -15px;
            margin-right: -15px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .main-logo {
        a {
            display: inline-block;
            width: em(196);
            @media screen and (max-width: $point-lg-max) {
                width: em(54);
            }
            > img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }

    &__user-block {
        display: flex;
        align-items: center;
        > * {
            margin-right: 3.5vw;
            @media screen and (max-width: $point-xl-max) {
                margin-right: em(20);
            }
            @media screen and (max-width: $point-lg-max) {
                margin-right: em(30);
            }
            @media screen and (max-width: $point-sm-max) {
                margin-right: em(20);
            }
            &:last-child,
            &:last-of-type {
                margin-right: 0;
            }
        }
        .btns-wrap {
            display: flex;
            font-size: em(16,17);
            margin-right: 0;
            .btn {
                margin-bottom: 0;
                margin-right: 2vw;
                padding-left: 1.2em;
                padding-right: 1.2em;
                @media screen and (max-width: $point-xl-max) {
                    margin-right: em(20);
                }
                @media screen and (max-width: $point-lg-max) {
                    width: em(40);
                    height: em(40);
                    margin-right: em(30);
                    padding: 0.4em 0.25em;
                    min-width: auto;
                }
                @media screen and (max-width: $point-sm-max) {
                    margin-right: em(22);
                }
                &:last-child {
                    margin-right: 0;
                    @media screen and (max-width: $point-lg-max) {
                        margin-right: em(30);
                    }
                    @media screen and (max-width: $point-sm-max) {
                        margin-right: em(22);
                    }
                }
                .icon {
                    margin-right: .5em;
                    font-size: 1.25em;
                    vertical-align: middle;
                    @media screen and (max-width: $point-lg-max) {
                        margin-right: 0;
                        font-size: 1.25em;
                    }
                }
                > span {
                    vertical-align: middle;
                }
                &.btn-shell-pay {
                    @media screen and (max-width: $point-lg-max) {
                        width: auto;
                        padding-right: 0.5em;
                        padding-left: 0.5em;
                        white-space: nowrap;
                        .icon {
                            font-size: 1.35em;
                        }
                    }
                }
            }
        }

    }

    &__phone {
        .phone-mob {
            font-size: 1.5em;
            color: $shell-very-dark-gray;
            > a {
                .icon {
                    vertical-align: middle;
                }
            }
        }
    }
    .phone-deskt {
        > a {
            font-size: em(20);
            &:hover {
                text-decoration: none;
            }
        }
    }

    &__search-mob {
        display: none;
        font-size: 1.25em;
        @media screen and (max-width: $point-lg-max) {
            display: block;
            margin-right: em(30,24);
            font-size: 1.5em;
            color: $shell-very-dark-gray;
        }
        @media screen and (max-width: $point-sm-max) {
            margin-right: em(22,24);
        }
        > a {
            .icon {
                vertical-align: middle;
            }
        }
    }

    &__user-info {
        display: flex;
        align-items: center;
        justify-content: flex-end;
       /* @media screen and (max-width: $point-lg-max) {
            margin-right: em(30);
        }
        @media screen and (max-width: $point-sm-max) {
            margin-right: em(22);
        }*/

        .basket {
            display: inline-block;
            width: 2em;
            height: 100%;
            margin-right: 2vw;
            text-align: center;
            @media screen and (max-width: $point-lg-max) {
                display: none;
            }

            .product-count {
                display: inline-block;
                width: 100%;
                font-size: em(20);
                line-height: 1;
            }
            .icon {
                font-size: 1.7em;
                color: $shell-red;
            }
        }

        .text {
            width: 100%;
            margin-right: 0.75em;
            font-size: em(18);
            p {
                margin-bottom: 0;
            }
        }

        .btn.btn-logout {
            flex-basis: 10px;
            min-width: em(53);
            padding-left: .6em;
            padding-right: .6em;
            background-color: $shell-red;
            color: #ffffff;
            @media screen and (max-width: $point-lg-max) {
                width: auto;
                height: auto;
                min-width: auto;
                padding-top: 0;
                padding-left: 0;
                padding-right: 0;
                padding-bottom: .2em;
                background-color: transparent;
                font-size: 1.35em;
                color: $shell-very-dark-gray;
                margin-right: em(30,20) !important;
            }
            @media screen and (max-width: $point-sm-max) {
                margin-right: em(22,20) !important;
            }
            .icon {
                margin-right: 0 !important;
                vertical-align: sub;
            }
        }

        &_mob {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 3.3em;
            padding-top: 0;
            padding-bottom: 0;

            position: relative;
            z-index: 10;
            border-top: 1px solid $shell-pale-gray;
            background-color: #ffffff;
            @media screen and (max-width: $point-lg-max) {
                margin-left: -30px;
                margin-right: -30px;
                padding: 0 30px;
            }
            @media screen and (max-width: $point-sm-max) {
                margin-left: -15px;
                margin-right: -15px;
                padding: 0 15px;
            }
            .text {
                > p {
                    display: inline-block;
                }
            }
            .basket {
                display: flex;
                align-items: center;
                width: auto;
                height: auto;
                margin-right: 0;
                white-space: nowrap;

                .product-count {
                    width: auto;
                    padding-top: .25em;
                }
                .icon {
                    margin-right: .25em;
                }
            }
        }

    }

    &__burger {
        display: none;
        padding-top: 0.4em;
        @media screen and (max-width: $point-lg-max) {
            display: block;
        }
    }

    // Bottom Header Line
    &__bottom {
        position: relative;
        z-index: 1;
        display: flex;
        overflow: hidden;
        padding-bottom: 5px;
        padding-top: 1px;
        @media screen and (max-width: $point-lg-max) {
            position: static;
            display: block;
            //padding-bottom: 0;
            padding-top: 0;
            overflow: visible;
        }
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $shell-pale-gray;
            @media screen and (max-width: $point-lg-max) {
                content: none;
            }
        }
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 5px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $shell-yellow;
            @media screen and (max-width: $point-lg-max) {
                z-index: 10;
            }
        }
    }

    &__nav {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-right: 6%;
        @media screen and (max-width: $point-lg-max) {
            display: none;
            position: absolute;
            width: 100%;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            top: 0;
            left: 0;
            margin-right: 0;
            padding: em(88) 40px 0 15px;
            background-color: $bg-menu-mob;
            font-size: 17px;
        }
        @media screen and (max-width: $point-iphone-5-max) {
            padding: em(110) 40px 0 15px;
        }
        .main-menu {
            display: flex;
            justify-content: space-between;
            width: 100%;
            @media screen and (max-width: $point-lg-max) {
                flex-direction: column;
                padding-top: 3vh;
                padding-top: calc(var(--vh, 1vh) * 3);
            }
            > a {
                position: relative;
                display: inline-block;
                padding: 1.25em .25em .9em .25em;
                @include transition(color, .2s, ease-in-out);
                @media screen and (max-width: $point-lg-max) {
                    padding: 1.5vh .25em;
                    padding: calc(var(--vh, 1vh) * 1.5) .25em;
                }
                @media screen and (max-width: $point-iphone-5-max) {
                    padding: 1vh .25em;
                    padding: calc(var(--vh, 1vh) * 1) .25em;
                }
                &:hover,
                &:active,
                &:focus,
                &.active {
                    &::after {
                        opacity: 1;
                    }
                }
                &:hover {
                    color: $shell-red;
                }
                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 5px;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    background-color: $shell-red;
                    opacity: 0;
                    @include transition(opacity, .2s, ease-in-out);
                    @media screen and (max-width: $point-lg-max) {
                        content: none;
                    }
                }
            }
            > span {
                display: inline-block;
                padding: 1.25em .25em .9em .25em;
                @media screen and (max-width: $point-lg-max) {
                    padding: 1.5vh .25em;
                    padding: calc(var(--vh, 1vh) * 1.5) .25em;
                }
                @media screen and (max-width: $point-iphone-5-max) {
                    padding: 1vh .25em;
                    padding: calc(var(--vh, 1vh) * 1) .25em;
                }
            }
            .stores {
                padding-top: 1em;
                a {
                    display: inline-block;
                    width: 150px;
                    height: em(40);
                    padding: .25em 0;
                    margin-right: 0;
                    margin-bottom: 1em;
                    border: 1px solid $shell-pale-gray;
                    text-align: center;
                    &:first-child {
                        @media screen and (max-width: $point-lg-max) {
                            margin-bottom: 1.5vh;
                            margin-bottom: calc(var(--vh, 1vh) * 3);
                        }
                        @media screen and (max-width: $point-iphone-5-max) {
                            margin-bottom: 1vh;
                            margin-bottom: calc(var(--vh, 1vh) * 1);
                        }
                    }
                }
                img {
                    width: auto;
                    height: 100%;
                }
            }
        }
        .go-to-bottom {
            position: absolute;
            left: 50%;
            bottom: 5vh;
            bottom: calc(var(--vh, 1vh) * 5);
            @include transform(translateX(-50%));
            font-size: em(14);
            @media screen and (max-width: $point-iphone-5-max) {
                bottom: 2vh;
                bottom: calc(var(--vh, 1vh) * 2);
                font-size: em(10);
            }
        }
    }

    &__search-deskt {
        @media screen and (max-width: $point-lg-max) {
            display: none;
            position: absolute;
            width: 100%;
            height: auto;
            top: 100%;
            left: 0;
            margin-right: 0;
            padding: 3em 15px 3em 15px;
            background-color: $bg-menu-mob;
        }
        .search-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 2;
            width: 3em;
            height: calc(100% - 5px);
            top: 0;
            right: 0;
            @media screen and (max-width: $point-lg-max) {
                display: none;
            }
            .search-icon-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1.25em .25em .9em .25em;
                &:hover,
                &:active,
                &:focus,
                &.active {
                    color: $shell-red;
                }
            }
        }
        .search-form-wrap {
            display: flex;
            justify-content: space-between;
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            left: 100%;
            top: 0;
            padding: 5px 0;
            background-color: #ffffff;
            @include transition(all, .2s, ease-in-out);

            @media screen and (max-width: $point-lg-max) {
                position: static;
                flex-direction: column;
                padding: 0;
            }

            .search-form,
            .search-close,
            .close-btn,
            .form-group,
            input[type="text"],
            .btns-wrap,
            .btn {
                height: 100%;
            }
            .search-form {
                display: flex;
                justify-content: space-between;
                width: 100%;
                @media screen and (max-width: $point-lg-max) {
                    position: relative;
                }
                .form-group {
                    width: 100%;
                    @media screen and (max-width: $point-lg-max) {
                        margin-bottom: 0;
                    }
                }
                input[type="text"] {
                    padding-left: 85px;
                    padding-top: .5em;
                    padding-bottom: .5em;
                    @media screen and (max-width: $point-lg-max) {
                        padding: 1em 4.5em 1em 1em;
                        font-size: 18px;
                    }
                    &:hover,
                    &:focus{
                        border-color: $border-input;
                    }
                    @include placeholder {
                        color: $placeholder-color;
                        font-size: em(18,25);
                        line-height: 1;
                        @media screen and (max-width: $point-lg-max) {
                            font-size: em(19,25);
                        }
                    };
                }
                .btns-wrap {
                    flex-basis: em(183);
                    @media screen and (max-width: $point-lg-max) {
                        position: absolute;
                        width: 4em;
                        height: 100%;
                        top: 0;
                        right: 0;
                    }
                    .btn {
                        width: 100%;
                        @media screen and (max-width: $point-lg-max) {
                            width: 100%;
                            height: 100%;
                            min-width: auto;
                            padding: .25em 0 0 0;
                            font-size: 20px;
                            text-align: left;
                        }
                        &__red {
                            background-color: $btn-red-bg-hover;
                            border: 1px solid $btn-red-border;
                            color: $btn-red-color-hover;
                            @media screen and (max-width: $point-lg-max) {
                                background-color: transparent;
                                border: none;
                                color: inherit;
                            }
                            &:hover,
                            &:active,
                            &:focus {
                                background-color: $btn-red-bg;
                                border: 1px solid $btn-red-border-hover;
                                color: $btn-red-color;
                                @media screen and (max-width: $point-lg-max) {
                                    background-color: transparent;
                                    border: none;
                                    color: inherit;
                                }
                            }
                        }
                        span {
                            @media screen and (max-width: $point-lg-max) {
                                display: none;
                            }
                        }
                        .icon {
                            display: none;
                            @media screen and (max-width: $point-lg-max) {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
            .search-close {
                flex-basis: em(54);
                @media screen and (max-width: $point-lg-max) {
                    display: none;
                }
                .close-btn {
                    position: static;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    border: 1px solid $shell-pale-gray;
                    font-size: 1.75em;
                    @include transition(color, .2s, ease-in-out);
                    &:hover,
                    &:active,
                    &:focus {
                        color: $shell-red;
                    }
                }
            }
        }
    }

}

.go-to-bottom {
    margin: 0 auto;
    > a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: em(60);
        height: em(60);
        margin: 0 auto;
        border: 1px solid $shell-very-dark-gray;
        @include border-radius(50%);
        color: $shell-very-dark-gray;
        .icon {
            font-size: 2em;
        }
    }
}