/* =================================
Popup Personal Liter Wallet
================================= */

.popup-table {
    display: none;

    position: fixed;
    z-index: 99;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: 160px;
    padding-bottom: 5vh;
    background-color: rgba(255,255,255,.95);

    @media screen and (max-width: $point-lg-max) {
        padding-top: 130px;
    }

    .container-fluid {
        height: 100%;
    }

    &__wrapper {
        position: relative;
        height: 100%;
        padding-top: 10vh;
        padding-bottom: 2em;
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        width: 2.5em;
        height: 2.5em;
        top: 0;
        right: 0;
        font-size: em(32);
        color: $shell-red;
        cursor: pointer;
        @media screen and (max-width: $point-lg-max) {
            font-size: em(28);
        }
        .icon {
            @include transform(translateX(.25em))
        }
    }

    &__content {
        width: 100%;
        max-width: 820px;
        max-height: 100%;
        margin: 0 auto;
        overflow-y: auto;
        @media screen and (max-width: $point-md-max) {
            width: auto;
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    &__header {
        .payment-type {
            padding: 1em 1em 1em 1em;
            border: 1px solid $shell-pale-gray;
            border-bottom: none;
            color: $shell-dark-gray;
            font-size: em(18);
            > p {
                position: relative;
                margin: 0;
                padding-left: 2em;
                > img {
                    position: absolute;
                    width: 1.5em;
                    height: auto;
                    left: 0;
                    top: 50%;
                    @include transform(translateY(-50%));
                }
            }
        }
    }

    &__table-wrap {
        @media screen and (max-width: $point-lg-max) {
            margin-bottom: 2em;
        }
        table {
            width: 100%;
            font-size: em(18);
            text-align: center;
            @media screen and (max-width: $point-lg-max) {
                font-size: 1em;
            }
            tr {
                &:nth-child(2) {
                    border-top: 5px solid transparent;
                }
                &:nth-child(2n + 1) {
                    background-color: #fff2b2;
                }
                &:nth-child(2n) {
                    background-color: #ffe566;
                }
            }
            th {
                padding: 1em;
                border-top: 1px solid $shell-pale-gray;
                border-bottom: 1px solid $shell-pale-gray;
                background-color: #ffffff;
                font-weight: 400;
                color: $shell-dark-gray;

                -webkit-box-shadow: 0px 6px 0px 0px #ededed;
                -moz-box-shadow: 0px 6px 0px 0px #ededed;
                box-shadow: 0px 6px 0px 0px #ededed;

                @media screen and (max-width: $point-lg-max) {
                    padding: 1em 8px;
                }

                &:first-child {
                    border-left: 1px solid $shell-pale-gray;
                    text-align: left;
                }
                &:last-child {
                    border-right: 1px solid $shell-pale-gray;
                }
            }
            td {
                padding: 1em;
                @media screen and (max-width: $point-lg-max) {
                    padding: 1em 8px;
                }
                &:first-child {
                    text-align: left;
                }
                &[colspan] {
                    background-color: #fff2b2;
                }
                .date,
                .time {
                    white-space: nowrap;
                }
            }
        }
    }

    &__text-content {
        padding: 2em;
        margin-bottom: 3em;
        background-color: #fff2b2;
        font-size: em(18);
        @media screen and (max-width: $point-lg-max) {
            margin-bottom: 2em;
            padding: 1em;
            font-size: 1em;
        }
        > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
        + .popup-table__footer {
            padding-left: 2em;
            @media screen and (max-width: $point-lg-max) {
                padding-left: 1em;
            }
        }
    }

    &__footer {
        font-size: em(18);
        @media screen and (max-width: $point-lg-max) {
            font-size: 1em;
        }
    }

}