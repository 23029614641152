.everyjourney {
    @media screen and (max-width: 1919px) {
        .main {
            background-size: cover;
            background-position: center;
        }
    }
    @media screen and (max-width: 1900px) and (min-width: 1681px) {
        .stock {
            background-size: 100% 50%;
        }
    }
    @media screen and (max-width: 1680px) {
        .main {
            background-size: cover;
            background-position: center;
            &__header {
                padding-top: 60px;
            }
        }
        .stock {
            margin-top: -200px;
            padding-top: 180px;
            background-size: 130% 52%;
            background-position: 50% -12%;
        }
    }
    @media screen and (max-width: 1600px) {
        .stock {
            &__block {
                &-content {
                    padding: 40px 40px 80px 40px;
                    &-title {
                        font-size: em(26);
                    }
                    &--left {
                        padding-bottom: 63px;
                    }
                }
                &-fingers {
                    width: 150px;
                    bottom: -65px;
                    right: -35px;
                }
            }
            &__slider {
                &-gallery {
                    margin-top: 17px;
                }
            }
            &__content {
                &-text {
                    &--top {
                        font-size: 34px;
                        margin-bottom: 5px;
                    }
                    &--bottom {
                        font-size: 30px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1440px) {
        .main {
            background-position: 77%;
            &__header {
                font-size: 36px;
                padding-top: 40px;
            }
        }
        .stock {
            background-position: 50% -20%;
            background-size: 150% 54%;
            padding-top: 115px;
            &__header {
                font-size: 48px;
            }
            &__content {
                &-icons {
                    img {
                        &:first-child {
                            width: 120px;
                        }
                        &:last-child {
                            width: 145px;
                        }
                    }
                }
                &-text {
                    &--top {
                        font-size: 30px;
                    }
                }
            }
            &__block {
                &-content {
                    &-title {
                        font-size: 22px;
                    }
                    &-wrapper {
                        h3 {
                            font-size: 24px;
                        }
                    }
                    &--left {
                        padding-bottom: 46px;
                    }
                }
            }
            &__fuel {
                &-logo {
                    img {
                        width: 33%;
                    }
                }
            }
            &__slider {
                &-gallery {
                    margin-top: 4px;
                }
                &-arrow {
                    outline: none;
                    bottom: 125px;
                    &--left {
                        left: -17px;
                    }
                    &--right {
                        right: -17px;
                    }
                    img {
                        height: 36px;
                    }
                }
            }
        }
        .smart-card {
            &__header {
                &--red {
                    font-size: 48px;
                }
            }
            &__contacts {
                &-link {
                    font-size: 24px;
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .main {
            min-height: 560px;
            &__header {
                font-size: 28px;
            }
        }
        .stock {
            padding-top: 150px;
            background-size: 130% 50%;
            background-position: 50% -9%;
            &__header {
                font-size: 38px;
            }
            &__content {
                &-icons {
                    img {
                        &:first-child {
                            width: 100px;
                        }
                        &:last-child {
                            width: 125px;
                        }
                    }
                }
                &-text {
                    &--top {
                        font-size: 22px;
                    }
                    &--bottom {
                        font-size: 24px;
                        span {
                            font-size: 64px;
                        }
                    }
                }
            }
            &__block {
                &-title {
                    font-size: 30px;
                }
                &-content {
                    padding: 25px 25px 65px 25px;
                    &-title {
                        font-size: 19px;
                    }
                    &-wrapper {
                        padding: 25px 40px;
                    }
                    &--left {
                        padding-bottom: 45px;
                    }
                }
                &-fingers {
                    width: 80px;
                    bottom: -40px;
                    right: -20px;
                }
            }
            &__slider {
                &-titles {
                    margin-top: 35px;
                    .swiper-wrapper {
                        .swiper-slide {
                            font-size: 24px;
                        }
                    }
                }
                &-gallery {
                    margin-top: 12px;
                    .swiper-wrapper {
                        .swiper-slide {
                            height: 200px;
                            img {
                                max-width: 70px;
                            }
                        }
                        .swiper-slide-active {
                            img {
                                max-width: calc(100% + 30px);
                            }
                        }
                    }
                }
            }
        }
        .smart-card {
            &__header {
                font-size: 24px;
                &--red {
                    font-size: 36px;
                }
            }
            &__contacts {
                &-link {
                    img {
                        width: 40px;
                        height: 40px;
                    }
                    font-size: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .main {
            min-height: 500px;
            background-position: 70%;
            &__logo {
                img {
                    width: 135px;
                }
            }
            &__header {
                padding-top: 40px;
                font-size: 21px;
            }
        }
        .stock {
            padding-top: 145px;
            &__header {
                font-size: 31px;
                span {
                    display: block;
                }
            }
            &__content {
                &-line {
                    height: 70px;
                    width: 4px;
                    margin: 0 30px;
                }
                &-icons {
                    img {
                        &:first-child {
                            width: 70px;
                        }
                        &:last-child {
                            width: 95px;
                        }
                    }
                }
                &-text {
                    &--top {
                        font-size: 15px;
                    }
                    &--bottom {
                        font-size: 16px;
                        span {
                            font-size: 56px;
                        }
                    }
                }
            }
            &__block {
                &-title {
                    font-size: 23px;
                    span {
                        font-size: 14px;
                    }
                }
                &-content {
                    &-title {
                        font-size: 15px;
                    }
                    &-wrapper {
                        margin-top: 24px;
                        h3 {
                            font-size: 18px;
                        }
                    }
                    &--left {
                        padding-bottom: 37px;
                    }
                }
                &-fingers {
                    width: 60px;
                    bottom: -35px;
                }
            }
            &__slider {
                &-titles {
                    margin-top: 27px;
                    .swiper-wrapper {
                        .swiper-slide {
                            font-size: 18px;
                        }
                    }
                }
                &-arrow {
                    bottom: 85px;
                }
                &-gallery {
                    margin-top: 10px;
                    .swiper-wrapper {
                        .swiper-slide {
                            height: 155px;
                            img {
                                max-width: 35px;
                            }
                        }
                        .swiper-slide-active {
                            img {
                                max-width: calc(100% + 30px);
                            }
                        }
                    }
                }
            }
        }
        .smart-card {
            &__header {
                font-size: 18px;
                &--red {
                    font-size: 32px;
                }
            }
            &__image {
                img {
                    padding-left: 40px;
                }
            }
            &__contacts {
                &-link {
                    font-size: 14px;
                    img {
                        margin-right: 20px;
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .main {
            align-items: baseline;
            padding-top: 60px !important;
            min-height: 700px;
            background-image: url('../../../img/promotions/everyjourney/bg-main--sm.jpg');
            &__header {
                padding-top: 40px;
                font-size: 30px;
            }
        }
        .stock {
            background-size: 181% 30%;
            background-position: 50% 0%;
            padding-top: 200px;
            &__header {
                span {
                    &:nth-child(2) {
                        padding-top: 10px;
                    }
                }
            }
            &__block {
                &--first {
                    margin-top: -40px;
                    margin-left: 0!important;
                    margin-right: 0!important;
                }
                &--second {
                    margin-top: 50px;
                    margin-right: 0!important;
                    margin-left: 0!important;
                }
                &-title {
                    font-size: 30px;
                }
                &-content {
                    &-title {
                        font-size: 18px;
                    }
                }
                &-fingers {
                    width: 105px;
                }
            }
            &__slider {
                &-arrow {
                    bottom: 105px;
                }
                &-titles {
                    margin-top: 35px;
                    .swiper-wrapper {
                        .swiper-slide {
                            font-size: 24px;
                        }
                    }
                }
                &-gallery {
                    margin-top: 10px;
                    .swiper-wrapper {
                        .swiper-slide {
                            height: 215px;
                            img {
                                max-width: 55px;
                            }
                        }
                        .swiper-slide-active {
                            img {
                                max-width: calc(100% + 30px);
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        .smart-card {
            &__header {
                &--red {
                    text-align: left;
                    padding-left: 16px;
                    padding-bottom: 20px;
                }
            }
            &__content {
                padding-top: 30px;
                padding-bottom: 25px;
            }
            &__image {
                img {
                    max-width: 260px;
                    padding-left: 10px;
                }
                padding-bottom: 20px;
            }
            &__contacts {
                &-item {
                    font-size: 16px;
                }
                &-link {
                    img {
                        margin-right: 15px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 568px) {
        .main {
            min-height: 650px;
            background-position: 60%;
            &__header {
                padding-top: 24px;
            }
        }
        .stock {
            background-size: 230% 40%;
            background-position: 50% -4%;
            padding-top: 240px;
            &__header {
                font-size: 37px;
                padding: 0 35px;
            }
            &__content {
                &-line {
                    margin: 0 15px;
                    height: 50px;
                }
                &-icons {
                    img {
                        &:first-child {
                            width: 80px;
                        }
                        &:last-child {
                            width: 100px;
                        }
                    }
                }
                &-text {
                    &--top {
                        font-size: 18px;
                    }
                    &--bottom {
                        font-size: 18px;
                        span {
                            font-size: 48px;
                        }
                    }
                }
            }
            &__block {
                &-fingers {
                    width: 120px;
                }
            }
            &__fuel {
                &-logo {
                    img {
                        margin-right: 18px;
                    }
                }
            }
        }
        .smart-card {
            margin-top: 50px;
            &__header {
                padding: 30px 0 18px 0;
            }
        }
    }
    @media screen and (max-width: 481px) {
        .main {
            &__header {
                font-size: 24px;
            }
        }
        .stock {
            background-position: 52% -5%;
            padding-top: 200px;
            &__header {
                font-size: 32px;
                padding: 0 10px;
                span {
                    &:nth-child(2) {
                        display: block;
                        padding-top: 10px;
                    }
                }
            }
            &__content {
                justify-content: center;
                padding-top: 35px;
                display: block;
                &-line {
                    margin: 0 24px;
                    height: 70px;
                }
                &-icons {
                    justify-content: center;
                    img {
                        &:first-child {
                            width: 100px;
                        }
                        &:last-child {
                            width: 120px;
                        }
                    }
                }
                &-text {
                    padding-left: 0;
                    padding-top: 20px;
                    text-align: center;
                    &--top {
                        font-size: 18px;
                    }
                    &--bottom {
                        font-size: 24px;
                        span {
                            font-size: 42px;
                        }
                    }
                }
            }
            &__block {
                &-title {
                    font-size: 24px;
                }
                &-content {
                    padding: 15px 15px 35px 15px;
                    &-title {
                        font-size: 16px;
                    }
                    &-wrapper {
                        h3 {
                            margin-bottom: 35px;
                        }
                    }
                }
                &-fingers {
                    width: 100px;
                    right: -10px;
                    bottom: -20px;
                }
            }
            &__slider-gallery {
                .swiper-wrapper {
                    .swiper-slide {
                        height: 185px;
                    }
                }
            }
            &__slider {
                &-arrow {
                    &--left {
                        left: -4px;
                    }
                    &--right {
                        right: -4px;
                    }
                    img {
                        height: 28px;
                    }
                }
            }
        }
        .smart-card {
            &__contacts {
                &-link {
                    font-size: 13px;
                }
            }
        }
    }
    @media screen and (max-width: 390px) {
        .main {
            background-position: 80%;
        }
        .stock {
            &__slider {
                &-titles {
                    .swiper-wrapper {
                        .swiper-slide {
                            font-size: 18px;
                        }
                    }
                }
            }
            &__content {
                &-text {
                    padding-bottom: 30px;
                }
            }
            &__block {
                &-fingers {
                    width: 90px;
                }
            }
        }
        .smart-card {
            &__header {
                font-size: 15px;
                &--red {
                    font-size: 24px;
                }
            }
            &__contacts {
                &-link {
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }
                    font-size: 12px;
                    span {
                        margin-left: 5px;
                    }
                    &:last-child {
                        font-size: 11px;
                        display: flex;
                        align-items: center;
                    }
                    .sms-number {
                        span {
                            margin-left: 0;
                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 360px) {
        .stock {
            background-size: 230% 38%;
            &__header {
                font-size: 24px;
                padding: 0 10px;

            }
            &__content {
                &-line {
                    margin: 0 10px;
                    height: 28px;
                }
                &-icons {
                    img {
                        &:first-child {
                            width: 85px;
                        }
                        &:last-child {
                            width: 105px;
                        }
                    }
                }
                &-text {
                    &--top {
                        font-size: 16px;
                    }
                    &--bottom {
                        font-size: 18px;
                        span {
                            font-size: 36px;
                        }
                    }
                }
            }
        }
        .smart-card {
            &__header {
                font-size: 14px;
                padding: 18px 4px;
                &--red {
                    font-size: 20px;
                }
            }
            &__contacts {
                &-link {
                    &:last-child {
                        font-size: 10px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 345px) {
        .smart-card {
            &__contacts {
                &-link {
                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
}