/* =================================
Section Personal Promotion Ferrari Toy Cars
================================= */

.personal-cabinet {

    .ferrari-toy-cars {
        &__header {
            margin-bottom: 35px;
            padding: 2% 15px 2% 8%;
            background-color: $shell-red;
            background-image: url("../../../img/promotions/ferrari-toy-cars/lk_heder-bg.jpg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top right;
            font-size: 1.875vw;
            font-weight: 700;
            color: $white;
            @media screen and (max-width: $point-xl-max) {
                padding-left: 2%;
                font-size: 2.5vw;
            }
            @media screen and (max-width: $point-lg-max) {
                font-size: 3vw;
            }
            @media screen and (max-width: $point-md-max) {
                padding-top: 7%;
                padding-bottom: 35%;
                background-image: url("../../../img/promotions/ferrari-toy-cars/lk_heder-bg_mob.jpg");
                background-position: center bottom;
                background-size: 100% auto;
                font-size: 24px;
                text-align: center;
            }
        }
        &__table-descr {
            width: 72%;
            margin-bottom: 40px;
            font-size: em(18);
            font-weight: 700;
            color: $shell-very-dark-gray;
            @media screen and (max-width: $point-md-max) {
                width: 100%;
                text-align: center;
                font-size: em(16);
            }
            p {
                margin: 0;
            }
            .color-red {
                color: $shell-red;
            }
        }
        &__table-wrap {
            margin-bottom: 45px;
            @media screen and (max-width: $point-md-max) {
                display: none;
            }
            &_mob {
                display: none;
                @media screen and (max-width: $point-md-max) {
                    display: block;
                }
            }
            table {
                position: relative;
                font-size: 1em;
                -webkit-box-shadow: 0px 5px 0px 0px #ededed;
                -moz-box-shadow: 0px 5px 0px 0px #ededed;
                box-shadow: 0px 5px 0px 0px #ededed;
                &::before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 48px;
                    position: absolute;
                    bottom: 100%;
                    right: -1px;
                    background-image: url("../../../img/promotions/ferrari-toy-cars/lk_table-header.png");
                    background-repeat: no-repeat;
                    background-position: top right;
                    background-size: contain;
                    @media screen and (max-width: $point-md-max) {
                        height: 30px;
                    }
                }
                th, td {
                    border: 1px solid $shell-very-dark-gray;
                }
                th {
                    padding: 10px 10px;
                    background-color: #ebebeb;
                    font-size: em(20);
                    font-weight: 400;
                    text-align: center;
                    @media screen and (max-width: $point-xl-max) {
                        font-size: em(18);
                    }
                    @media screen and (max-width: $point-md-max) {
                        padding: 15px;
                        font-size: em(18);
                    }
                    &:first-child {
                        padding-left: 25px;
                        text-align: left;
                    }
                    .big-text {
                        font-size: em(21,20);
                        font-weight: 700;
                        color: $shell-red;
                    }
                }
                td {
                    padding: 10px 10px;
                    text-align: center;
                    @media screen and (max-width: $point-md-max) {
                        padding: 15px;
                    }
                    &.bg-red {
                        padding-top: 20px;
                        padding-bottom: 20px;
                        padding-left: 25px;
                        text-align: left;
                    }
                    &.bg-blue {
                        padding-left: 25px;
                        text-align: left;
                    }
                    .big-text {
                        font-size: em(24);
                        color: $shell-red;
                        font-weight: 700;
                    }
                }
                .bg-red {
                    background-color: $shell-red;
                    color: $white;
                }
                .bg-red-light {
                    background-color: rgba(247,146,148,.66);
                }
                .bg-blue {
                    background-color: #008fc4;
                }
                .bg-blue-light {
                    background-color: rgba(198,233,246,1);
                }
                .bg-yellow-light {
                    background-color: #fbe680;
                }
                .custom-td {
                    > img {
                        max-width: 75%;
                        margin-bottom: 5px;
                        @media screen and (max-width: $point-md-max) {
                            width: 60%;
                            max-width: 230px;
                        }
                    }
                    .fs-16 {
                        font-size: 1em;
                    }
                    .fs-21 {
                        font-size: em(21);
                        @media screen and (max-width: $point-md-max) {
                            font-size: em(18);
                        }
                    }
                }
                .content-list {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    > li {
                        position: relative;
                        padding: 15px 15px;
                        text-align: center;
                        border-bottom: 1px solid $shell-very-dark-gray;
                        &:first-child {
                            padding-top: 10px;
                            &::before {
                                content: none;
                            }
                        }
                        &:last-child {
                            padding-bottom: 10px;
                            border-bottom: none;
                        }
                        &::before {
                            content: 'або';
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 3em;
                            height: 1.5em;
                            position: absolute;
                            top: 0;
                            left: 50%;
                            @include transform(translate(-50%,-50%));
                            background-color: $shell-red;
                            font-weight: 500;
                            color: $white;
                            font-size: em(17);
                            @include border-radius(50%);
                        }
                    }
                }
            }
        }
        &__ban-img {
            margin-bottom: 20px;
            @media screen and (max-width: $point-md-max) {
                margin-bottom: 40px;
            }
            .img-wrap {
                > img {
                    &.deskt-tabl {
                        @media screen and (max-width: $point-md-max) {
                            display: none;
                        }
                    }
                    &.mob {
                        display: none;
                        @media screen and (max-width: $point-md-max) {
                            display: inline-block;
                        }
                    }
                }
            }
        }
        &__btn-wrap {
            text-align: center;
        }
    }

}