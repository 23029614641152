/* =================================
Select Multi Custom
================================= */

.select-multi {
    width: 100%;
    height: auto;
    font-size: 1em;

    &.filled {
        .select-multi__label {
            top: 0.75em;
            @include transform(none);
        }
    }

    &.open {
        .select-multi__icon {
            @include transform(translateY(-50%) rotate(180deg));
        }
    }

    &__plain {
        .select-multi__button {
            border: none;
            padding: 10px;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
    }

    &__button {
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 1.6em 2em 0.359em 1em;
        border: 1px solid $border-input;
        background-color: #fff;
        outline: none;
        font-size: em(24);
        font-weight: 400;
        color: $color-base;

        -webkit-box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;
        -moz-box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;
        box-shadow: inset 5px 5px 0px 0px $shell-very-pale-gray;

        @include transition(all, 0.3s, ease-in-out);
        cursor: pointer;

        @media screen and (max-width: $point-lg-max) {
            font-size: em(22);
        }

        &:hover,
        &:focus {
            border-color: $border-input-hover;
        }
    }

    &__label {
        position: absolute;
        max-width: calc(100% - 3em);
        left: em(24, 18);
        top: 50%;
        @include transform(translateY(-50%));
        font-size: em(18, 24);
        line-height: 1;
        color: $placeholder-color;
        cursor: pointer;
        @include transition(all, 0.2s, ease-in-out);

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @media screen and (max-width: $point-lg-max) {
            font-size: em(16, 22);
        }
    }

    &__value {
        position: relative;
        display: inline-block;
        width: 100%;
        min-height: 1em;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        .count {
            position: absolute;
            top: 50%;
            left: 0;
            @include transform(translateY(-50%));
            padding: 2px 6px;
            background-color: $shell-red;
            font-size: em(16, 24);
            font-weight: 700;
            color: #ffffff;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 1.5em;
        @include transform(translateY(-50%));
        font-size: em(10, 24);
        color: $placeholder-color;
        @include transition(all, 0.2s, ease-in-out);
    }

    &__dropdown {
        display: none;
        position: absolute;
        z-index: 100;
        width: 100%;
        //min-width: 250px;
        top: 100%;
        left: 0;
        border: 1px solid $shell-pale-gray;
        background-color: #ffffff;
        font-size: em(18);
        text-align: left;
    }

    &__search {
        &-input {
            border: none !important;
            border-bottom: 1px solid #d9d9d9 !important;
            width: 73% !important;
            padding: 0.5em 0 !important;
            box-shadow: none !important;
            margin: 0 1em !important;
            padding-right: 30px !important;
        }
        .icon {
            position: absolute;
            top: 16px;
            right: 45px;
        }
    }

    &__result-list {
        max-height: 270px;
        overflow-y: auto;
        margin-right: 10px;
        margin-bottom: 15px;
        padding: 0.5em 1em 0 em(24, 18);
    }

    &__option-list,
    &__option-list-selected {
        list-style: none;
        padding-left: 0;
    }

    &__option-list-selected {
        margin-bottom: 0;
    }

    &__option {
        position: relative;
        padding: 0.5em 0.75em 0.5em 0;
        border-bottom: 1px solid $shell-pale-gray;
        cursor: pointer;
        @include transition(all, 0.2s, ease-in-out);
        &:hover {
            @media screen and (min-width: $point-lg-min) {
                color: $shell-red;
            }
        }
        &.blue {
            &.selected,
            &:hover {
                color: $shell-blue;
            }
        }
        &.selected {
            color: $shell-red;
            padding-right: 0.75em;
            &::after {
                content: "×";
                display: block;
                width: auto;
                height: auto;
                position: absolute;
                top: 50%;
                right: 0;
                @include transform(translateY(-50%));
                color: $color-base;
            }
        }
    }

    &__control {
        position: relative;
        //display: flex;
        //align-items: stretch;
        width: 100%;
        height: em(60, 18);
        padding-left: em(60, 18);
        color: $color-base;
        .select-multi__apply {
            font-size: em(14);
        }
    }

    &__reset {
        position: absolute;
        width: em(60, 18);
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        //flex-basis: em(60,18);
        background-color: $shell-very-pale-gray;
    }

    &__apply {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: $shell-red;
        color: #ffffff;
        &:hover {
            color: #ffffff;
        }
    }
}
