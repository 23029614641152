/* =================================
Buttons Styles
================================= */

.btns-wrap {
    .btn {
        margin-bottom: 1em;
        margin-right: 1em;
        &:last-child {
            margin-bottom: 0;
            margin-right: 0;
        }
    }
}

.btn {
    display: inline-block;
    width: auto;
    height: auto;
    min-width: em(160);
    padding: .875em 2em;

    border: none;
    outline: none;
    @include border-radius(0);

    background-color: #fff;

    font-size: 1em;
    color: inherit;

    @media screen and (max-width: $point-lg-max) {
        padding: .856em 1.5em;
    }

    &:hover,
    &:active,
    &:focus {
        border: none;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    &__yellow {
        background-color: $btn-yellow-bg;
        color: $btn-yellow-color;
        &:hover,
        &:active,
        &:focus {
            background-color: $btn-yellow-bg-hover;
            color: $btn-yellow-color-hover;
        }
    }

    &__red {
        background-color: $btn-red-bg;
        border: 1px solid $btn-red-border;
        color: $btn-red-color;
        @media screen and (max-width: $point-lg-max) {
            background-color: $btn-red-bg-hover;
            border: 1px solid $btn-red-border-hover;
            color: $btn-red-color-hover;
        }
        &:hover,
        &:active,
        &:focus {
            background-color: $btn-red-bg-hover;
            border: 1px solid $btn-red-border-hover;
            color: $btn-red-color-hover;
        }
    }

    &__red-yellow {
        background-color: $btn-red-yellow-bg;
        border: 2px solid $btn-red-yellow-border;
        color: $btn-red-yellow-color;
        &:hover,
        &:active,
        &:focus {
            background-color: $btn-red-yellow-bg-hover;
            border: 2px solid $btn-red-yellow-border-hover;
            color: $btn-red-yellow-color-hover;
        }
    }

    &__yellow-border {
        background-color: $btn-yellow-border-bg;
        border: 1px solid $btn-yellow-border-border;
        color: $btn-yellow-border-color;
        &:hover,
        &:active,
        &:focus {
            background-color: $btn-yellow-border-bg-hover;
            border: 1px solid $btn-yellow-border-border-hover;
            color: $btn-yellow-border-color-hover;
        }
        &:disabled {
            background-color: $btn-yellow-border-bg;
            border-color: $shell-pale-gray;
            color: $shell-pale-gray;
            &:hover,
            &:active,
            &:focus {
                background-color: $btn-yellow-border-bg;
                border-color: $shell-pale-gray;
                color: $shell-pale-gray;
            }
        }
    }

    &__big {
        font-size: em(24);
        @media screen and (max-width: $point-lg-max) {
            font-size: em(22);
        }
    }

    &__round {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        min-width: auto;
        height: 2em;
        padding: 0;
        font-size: 2em;
        @include border-radius(50%);
    }

    &__w-100 {
        width: 100%;
        min-width: auto;
    }

    &--medium {
        font-size: em(18);
        @media screen and (max-width: $point-lg-max) {
            font-size: em(16);
        }
    }

    &--refuel {
        border: 2px solid transparent;
        background-color: #fbce07;
        color: #dd1d21;
        text-align: center;
        font-size: 1.5em;
        width: auto;
        margin: 0 auto;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: 10px;
        padding: 32px 20px;
        &:hover {
            border: 2px solid #fff;
            color: #dd1d21;
        }
        @media screen and (max-width: 1080px) {
            font-size: 1.2em;
        }
        @media screen and (max-width: 425px) {
            font-size: 1em;
            padding: 12px;
        }
        @media screen and (max-width: 375px) {
            font-size: 12px;
        }
    }

    &__lk-promotion {
        background-color: #e30613;
        font-size: 1.5em;
        color: #fff;
    }

    @media screen and (max-width: 992px) {
        &-save__user-date {
            background-color: $btn-red-bg-hover;
            border: 1px solid $btn-red-border-hover;
            color: $btn-red-color-hover;
        }
    }

    &:disabled,
    &__disabled {
        background-color: #cccccc;
        border: 1px solid #cccccc;
        color: #fff;
        cursor: default;
        &:hover {
            background-color: #cccccc;
            border: 1px solid #cccccc;
            color: #fff;
            cursor: default;
        }
    }
}

.close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5em;
    height: 1.5em;
    font-size: 1.75em;
    color: $shell-red;
}

.link {
    display: inline-block;
    color: $shell-red;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        color: $shell-red;
        text-decoration: underline;
    }
}

.btn-go-top-fixed {
    position: fixed;
    z-index: 90;
    right: 35px;
    bottom: 100px;
    display: inline-block;
    @include transition(all, .2s, ease-in-out);
    @media screen and (max-width: $point-lg-max) {
        right: 30px;
    }
    @media screen and (max-width: $point-sm-max) {
        right: 15px;
    }
    &.hide {
        opacity: 0;
        visibility: hidden;
    }
    .btn {
        font-size: em(28);
        .icon {
            @include transform(rotate(180deg));
        }
        &:hover,
        &:active,
        &:focus {
            background-color: #ffffff;
            border: 1px solid $shell-red;
            color: $shell-red;
        }
    }
}