/* =================================
Page Promotion Ferrari Toy Cars
================================= */

$ferrari-cars-grey: #38383c;
$ferrari-cars-grey-light: #575656;

.ferrari-cars {
    width: 100%;
    overflow: hidden;

    .container-fluid {
        @media screen and (max-width: $point-md-max) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .title-block {
        position: relative;
        &__text-content {
            position: absolute;
            width: 100%;
            height: auto;
            top: 0;
            left: 0;
            padding-top: 3vw;
            padding-left: 3.7vw;
            @media screen and (max-width: $point-md-max) {
                padding-top: 8%;
                padding-left: 20px;
            }
            img {
                width: 11vw;
                height: auto;
                margin-bottom: 1vw;
                @media screen and (max-width: $point-md-max) {
                    width: 27vw;
                    margin-bottom: 10%;
                }
            }
            h1 {
                font-weight: 700;
                text-transform: uppercase;
                @media screen and (max-width: $point-md-max) {
                    text-align: center;
                }
                > span {
                    display: inline-block;
                }
                .color-yellow {
                    font-size: 4.75vw;
                    @media screen and (max-width: $point-md-max) {
                        font-size: 11vw;
                        margin-bottom: 10%;
                    }
                }
                .color-white {
                    font-size: 2.485vw;
                    @media screen and (max-width: $point-md-max) {
                        font-size: 6vw;
                    }
                }
            }
        }
        &__img-bg {
            > img {
                width: 100%;
                height: auto;
                &.deskt-tabl {
                    @media screen and (max-width: $point-md-max) {
                        display: none;
                    }
                }
                &.mob {
                    display: none;
                    @media screen and (max-width: $point-md-max) {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .video-block {
        position: relative;
        z-index: 0;
        padding-top: 9%;
        padding-bottom:11%;
        margin-top: -5vw;
        @media screen and (max-width: $point-md-max) {
            padding-top: 20%;
            padding-bottom: 20%;
            margin-top: -9vw;
        }
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 50%;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            background-image: url("../../../img/promotions/ferrari-toy-cars/video__top-img.png");
            background-repeat: no-repeat;
            background-position: top left;
            background-size: 101% auto;
            @media screen and (max-width: $point-md-max) {
                background-image: url("../../../img/promotions/ferrari-toy-cars/video__top-img_mob.png");
            }
        }
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 50%;
            position: absolute;
            z-index: 0;
            bottom: 0;
            left: 0;
            background-image: url("../../../img/promotions/ferrari-toy-cars/video__bottom-img.png");
            background-repeat: no-repeat;
            background-position: bottom left;
            background-size: 101% auto;
        }
        &__yellow-bg {
            position: relative;
            z-index: 3;
            padding-left: 13%;
            padding-right: 13%;
            background-color: $shell-yellow;
            @media screen and (max-width: $point-md-max) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        &__video-wrap {
            position: relative;
            width: 100%;
            padding-top: 55%;
            iframe {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
    }

    .action-descr {
        position: relative;
        z-index: 1;
        padding-top: 10%;
        margin-top: -5.5vw;
        @media screen and (max-width: $point-md-max) {
            margin-top: -7vw;
            padding-top:22%;
        }
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 50%;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            background-image: url("../../../img/promotions/ferrari-toy-cars/action-descr__top-img.png");
            background-repeat: no-repeat;
            background-position: top left;
            background-size: 101% auto;
            @media screen and (max-width: $point-md-max) {
                background-image: url("../../../img/promotions/ferrari-toy-cars/action-descr__top-img_mob.png");
            }
        }
        &__info-circle {
            position: absolute;
            z-index: 2;
            width: 14%;
            padding: 6px;
            top: 0;
            right: 0;
            background-color: $shell-red;
            @include border-radius(50%);
            -webkit-box-sizing: content-box;
            -moz-box-sizing: content-box;
            box-sizing: content-box;
            @include transform(translate(-15%,-40%));
            -webkit-box-shadow: 10px 2px 0px 0px rgba(0,0,0,0.25);
            -moz-box-shadow: 10px 2px 0px 0px rgba(0,0,0,0.25);
            box-shadow: 10px 2px 0px 0px rgba(0,0,0,0.25);
            animation: animCircleLabel 1.2s ease-in-out infinite;
            @media screen and (max-width: $point-md-max) {
                width: 28%;
                padding: 3px;
                -webkit-box-shadow: 5px 2px 0px 0px rgba(0,0,0,0.25);
                -moz-box-shadow: 5px 2px 0px 0px rgba(0,0,0,0.25);
                box-shadow: 5px 2px 0px 0px rgba(0,0,0,0.25);
                animation: animCircleLabelMob 1.2s ease-in-out infinite;
            }
            &_height {
                padding-top: 100%;
                border: 1px solid $shell-yellow;
                @include border-radius(50%);
                -webkit-box-sizing: content-box;
                -moz-box-sizing: content-box;
                box-sizing: content-box;
            }
            &_content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%,-50%));
                font-size: 1.25vw;
                font-weight: 700;
                color: $shell-yellow;
                text-align: center;
                text-transform: uppercase;
                @media screen and (max-width: $point-md-max) {
                    font-size: 3.25vw;
                }
                > p {
                    margin: 0 0 .5em 0;
                }
                > img {
                    width: 65%;
                }
            }
        }
        &__yellow-bg {
            position: relative;
            z-index: 1;
            background-color: $shell-yellow;
            padding-bottom: 10%;
            @media screen and (max-width: $point-md-max) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        &__red-block {
            width: 82%;
            margin: 0 auto;
            margin-bottom: 45px;
            padding: 25px 20px;
            background-color: $shell-red;
            font-size: 2.1vw;
            font-weight: 700;
            color: $white;
            text-align: center;
            @media screen and (max-width: $point-md-max) {
                width: 100%;
                @include border-radius(10px);
                font-size: 5.85vw;
            }
            > p {
                margin: 0;
            }
            .text1 {
                text-transform: uppercase;
            }
            .text2 {
                font-size: .9em;
            }
            .text3 {
                font-size: .9em;
                color: $shell-yellow;
            }
            .num {
                font-size: 1.1em;
            }
        }
        &__shopping {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            padding-left: 24%;
            padding-right: 9%;
            @media screen and (max-width: $point-md-max) {
                display: block;
                padding-left: 0;
                padding-right: 0;
            }
            &::before {
                content: '';
                display: block;
                width: 24%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-image: url("../../../img/promotions/ferrari-toy-cars/action-descr__filling-hose.png");
                background-repeat: no-repeat;
                background-position: 0 30px;
                background-size: 94% auto;
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                    top: -8vw;
                    left: -20px;
                }
                @media screen and (max-width: $point-sm-max) {
                    width: 60%;
                    top: -15vw;
                }
            }
            &_fuel {
                width: 35%;
                @media screen and (max-width: $point-md-max) {
                    width: 100%;
                    padding: 0 15px;
                    margin-bottom: 30px;
                }
                .title-text {
                    padding-left: .5em;
                    font-size: 1.5vw;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: $ferrari-cars-grey;
                    @media screen and (max-width: $point-md-max) {
                        font-size: 5.75vw;
                        width: 48%;
                        margin-bottom: 10vw;
                        float: right;
                        text-align: right;
                    }
                    span {
                        color: $shell-red;
                        text-transform: none;
                    }
                    .num {
                        font-size: 1.1em;
                    }
                }
                .fuels {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 96px;
                    margin-bottom: 10px;
                    padding: 10px;
                    border: 2px solid $shell-red;
                    @include border-radius(8px);
                    @media screen and (max-width: 1400px) {
                        height: 70px;
                    }
                    @media screen and (max-width: $point-xl-max) {
                        height: 55px;
                    }
                    @media screen and (max-width: $point-lg-max) {
                        height: 45px;
                    }
                    @media screen and (max-width: $point-md-max) {
                        width: 100%;
                        height: 20vw;
                    }
                    &.v-power {
                        justify-content: space-between;
                        padding: 10px 20px;
                        > img {
                            width: 40%;
                        }
                    }
                    p {
                        margin: 0;
                        font-size: 1.5vw;
                        font-weight: 700;
                        color: $ferrari-cars-grey;
                        white-space: nowrap;
                        @media screen and (max-width: $point-md-max) {
                            font-size: 6vw;
                        }
                    }
                    > img.gas {
                        width: 28%;
                        margin-left: 2%;
                    }
                }
            }
            &_or {
                width: 9%;
                @media screen and (max-width: $point-md-max) {
                    width: 14%;
                    margin: 0 auto;
                }
                .circle {
                    position: relative;
                    width: 100%;
                    margin: 0;
                    padding-top: 100%;
                    border: 3px solid $ferrari-cars-grey;
                    @include border-radius(50%);
                    -webkit-box-sizing: content-box;
                    -moz-box-sizing: content-box;
                    box-sizing: content-box;
                    @media screen and (max-width: $point-md-max) {
                        border-width: 2px;
                    }
                    > span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        @include transform(translate(-50%,-50%));
                        font-size: 1.25vw;
                        font-weight: 700;
                        color: $ferrari-cars-grey;
                        @media screen and (max-width: $point-md-max) {
                            font-size: 3.5vw;
                        }
                    }
                }
            }
            &_bag {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 38%;
                @media screen and (max-width: $point-md-max) {
                    width: 100%;
                }
                .img-wrap {
                    width: 52%;
                    @media screen and (max-width: $point-md-max) {
                        width: 50%;
                    }
                }
                .text {
                    width: 45%;
                    font-size: 1.5vw;
                    font-weight: 700;
                    color: $ferrari-cars-grey;
                    text-transform: uppercase;
                    @media screen and (max-width: $point-md-max) {
                        font-size: 6vw;
                        width: 50%;
                    }
                    span {
                        color: $shell-red;
                        text-transform: none;
                    }
                    .num {
                        font-size: 1.1em;
                    }
                }
            }
        }
    }

    .cars-control {
        position: relative;
        z-index: 2;
        padding-top: 9%;
        margin-top: -8vw;
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 50%;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            background-image: url("../../../img/promotions/ferrari-toy-cars/cars-control__top-bg.png");
            background-repeat: no-repeat;
            background-position: top left;
            background-size: 101% auto;
        }
        &__red-bg {
            position: relative;
            z-index: 3;
            padding-bottom: 10%;
            padding-left: 6%;
            padding-right: 6%;
            background-color: $shell-red;
            color: $white;
            @media screen and (max-width: $point-md-max) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        &__models {
            @media screen and (max-width: $point-md-max) {
                padding-top: 30px;
            }
        }
        &__models-title {
            width: 70%;
            font-size: 2.1vw;
            font-weight: 700;
            color: inherit;
            text-transform: uppercase;
            @include transform(translateY(-1.5vw));
            @media screen and (max-width: $point-md-max) {
                font-size: 5.85vw;
            }
        }
        &__models-wrap {
            margin-top: -45px;
            padding-top: 45px;
            .cars-control__model-row {
                &:nth-child(1) {
                    .cars-control__model-img {
                        margin-top: -3.5vw;
                        @media screen and (max-width: 1600px) {
                            margin-top: -3vw;
                        }
                        @media screen and (max-width: $point-xl-max) {
                            margin-top: -2.5vw;
                        }
                        @media screen and (max-width: $point-md-max) {
                            margin-top: 0;
                        }
                    }
                }
                &:nth-child(2) {
                    .cars-control__model-img {
                        margin-top: -2vw;
                        @media screen and (max-width: 1600px) {
                            margin-top: -1.5vw;
                        }
                        @media screen and (max-width: $point-xl-max) {
                            margin-top: -1vw;
                        }
                        @media screen and (max-width: $point-md-max) {
                            margin-top: 0;
                        }
                    }
                }
                &:nth-child(3) {
                    .cars-control__model-img {
                        margin-top: 0px;
                    }
                }
            }
        }
        &__model-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 50px;
            @media screen and (max-width: $point-md-max) {
                flex-direction: column;
                align-items: flex-end;
            }
        }
        &__model-img {
            position: relative;
            z-index: 1;
            order: 2;
            width: 26%;
            padding: 0 15px 0 25px;
            background-color: $shell-red;
            @include transition(all, .3s, ease-in-out);
            @media screen and (max-width: $point-lg-max) {
                width: 32%;
            }
            @media screen and (max-width: $point-md-max) {
                order: 1;
                width: 90%;
                background-color: transparent;
                margin-bottom: 20px;
            }
            &:hover {
                @include transform(scale(1.4));
                @media screen and (max-width: $point-lg-max) {
                    @include transform(scale(1));
                }
            }
            > img {
                width: 100%;
            }
        }
        &__model-descr {
            position: relative;
            order: 1;
            width: 60%;
            padding-right: 3.5%;
            @media screen and (max-width: $point-md-max) {
                order: 2;
                width: 100%;
                padding-right: 0;
            }
            .line {
                position: absolute;
                width: 1px;
                height: 40px;
                top: 14px;
                right: 0;
                background-color: $shell-black;
                @media screen and (max-width: $point-md-max) {
                    height: 15vw;
                    top: auto;
                    bottom: 100%;
                    right: auto;
                    left: 9px;
                }
                &::before {
                    content: '';
                    display: block;
                    width: 38vw;
                    height: 1px;
                    position: absolute;
                    top: 0;
                    right: 100%;
                    background-color: $shell-black;
                    @media screen and (max-width: $point-md-max) {
                        content: none;
                    }
                }
                &::after {
                    content: '';
                    display: block;
                    width: 300px;
                    height: 1px;
                    position: absolute;
                    bottom: 0;
                    left: 100%;
                    background-color: $shell-black;
                    @media screen and (max-width: $point-lg-max) {
                        width: 30vw;
                    }
                    @media screen and (max-width: $point-md-max) {
                        width: 10vw;
                        bottom: auto;
                        top: 0;
                    }
                }
            }
            .model-title {
                position: relative;
                display: inline-block;
                padding-right: em(50,24);
                font-size: 24px;
                font-weight: 700;
                color: $shell-yellow;
                text-transform: uppercase;
                background-color: $shell-red;
                @media screen and (max-width: 1400px) {
                    font-size: 20px;
                }
                @media screen and (max-width: $point-lg-max) {
                    font-size: 18px;
                }
                @media screen and (max-width: $point-md-max) {
                    padding-left: em(40,24);
                }
                &::after {
                    content: '';
                    display: block;
                    width: 1em;
                    height: 1em;
                    position: absolute;
                    top: 0.125em;
                    right: 0;
                    background-color: $shell-black;
                    @media screen and (max-width: $point-md-max) {
                        right: auto;
                        left: 0;
                        top: 0;
                    }
                }
            }
            .model-descr {
                margin: 0;
                font-size: 18px;
                font-weight: 700;
                @media screen and (max-width: 1400px) {
                    font-size: 15px;
                }
                @media screen and (max-width: $point-lg-max) {
                    font-size: 14px;
                }
            }
        }
        &__app {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @media screen and (max-width: $point-md-max) {
                flex-direction: column;
            }
            &_list {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 41%;
                margin-right: 8%;
                @media screen and (max-width: $point-md-max) {
                    width: 100%;
                    margin-right: 0;
                    flex-direction: column;
                }
            }
            &_link {
                display: inline-block;
                width: 46%;
                @media screen and (max-width: $point-md-max) {
                    margin-bottom: 30px;
                    width: 51%;
                    max-width: 230px;
                }
                > img {
                    width: 100%;
                }
            }
            &_title {
                margin: 0;
                font-size: 1.6vw;
                font-weight: 700;
                color: inherit;
                text-transform: uppercase;
                @media screen and (max-width: $point-md-max) {
                    font-size: 5.85vw;
                    margin-bottom: 50px;
                }
            }
        }
        &__steps {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: $point-md-max) {
                flex-direction: column;
            }
            &_step {
                width: 30%;
                @media screen and (max-width: $point-md-max) {
                    width: 100%;
                    margin-bottom: 40px;
                }
                &:nth-child(1) {
                    padding-top: 100px;
                    @media screen and (max-width: $point-md-max) {
                        padding-top: 0;
                    }
                }
                &:nth-child(2) {
                    padding-top: 50px;
                    @media screen and (max-width: $point-md-max) {
                        padding-top: 0;
                    }
                }
                &:nth-child(3) {
                    .cars-control__steps_media {
                        width: 70%;
                        margin: 0 auto;
                        margin-bottom: 25px;
                        padding: 8px;
                        background-color: $shell-yellow;
                        @include border-radius(50%);
                        -webkit-box-sizing: content-box;
                        -moz-box-sizing: content-box;
                        box-sizing: content-box;
                        @media screen and (max-width: $point-md-max) {
                            width: 60%;
                        }
                    }
                    .cars-control__steps_media-h {
                        position: relative;
                        padding-top: calc(100% - 4px);
                        border: 2px solid $shell-red;
                        @include border-radius(50%);
                        -webkit-box-sizing: content-box;
                        -moz-box-sizing: content-box;
                        box-sizing: content-box;
                        overflow: hidden;
                        > img:not(.bluetooth) {
                            position: absolute;
                            width: 100%;
                            top: 50%;
                            left: 50%;
                            @include transform(translate(-50%,-50%));
                            animation: animRotate 1.2s ease-in-out infinite;
                        }
                        .bluetooth {
                            position: absolute;
                            width: 12%;
                            top: 3%;
                            left: 48%;
                        }
                    }
                }
            }
            &_media {
                position: relative;
                width: 70%;
                margin: 0 auto;
                margin-bottom: 25px;
                @media screen and (max-width: $point-md-max) {
                    width: 60%;
                }
                > img {
                    width: 100%;
                }
            }
            &_num {
                position: absolute;
                top: 50%;
                left: -22%;
                font-size: 3.75vw;
                font-weight: 700;
                @include transform(translateY(-50%));
                @media screen and (max-width: $point-md-max) {
                    left: -18%;
                    font-size: 11vw;
                }
            }
            &_descr {
                font-size: 1.25vw;
                font-weight: 700;
                text-align: center;
                @media screen and (max-width: $point-md-max) {
                    font-size: 4.8vw;
                }
            }
        }
    }

    .cars-standart {
        position: relative;
        z-index: 2;
        padding-top: 9%;
        padding-bottom: 9%;
        margin-top: -8vw;
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 50%;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            background-image: url("../../../img/promotions/ferrari-toy-cars/cars-standart__top-bg.png");
            background-repeat: no-repeat;
            background-position: top left;
            background-size: 101% auto;
        }
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 50%;
            position: absolute;
            z-index: 0;
            bottom: 0;
            left: 0;
            background-image: url("../../../img/promotions/ferrari-toy-cars/cars-standart__bottom-bg.png");
            background-repeat: no-repeat;
            background-position: bottom left;
            background-size: 101% auto;
        }
        &__yellow-bg {
            position: relative;
            z-index: 3;
            padding-left: 6%;
            padding-right: 6%;
            background-color: $shell-yellow;
        }
        &__models {
            @media screen and (max-width: $point-md-max) {
                padding-top: 30px;
            }
        }
        &__models-title {
            font-size: 2.1vw;
            font-weight: 700;
            color: $shell-red;
            text-align: right;
            text-transform: uppercase;
            @include transform(translateY(-3vw));
            @media screen and (max-width: $point-md-max) {
                font-size: 5.85vw;
            }
        }
        &__models-wrap {
            margin-top: -45px;
            padding-top: 55px;
            .cars-control__model-row {
                &:nth-child(1) {
                    .cars-control__model-img {
                        margin-top: -65px;
                        @media screen and (max-width: $point-md-max) {
                            margin-top: 0;
                        }
                    }
                }
                &:nth-child(2) {
                    margin-bottom: 0;
                    .cars-control__model-img {
                        margin-top: -40px;
                        @media screen and (max-width: $point-md-max) {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        &__model-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 50px;
            @media screen and (max-width: $point-md-max) {
                flex-direction: column;
                align-items: flex-end;
            }
        }
        &__model-img {
            position: relative;
            z-index: 1;
            order: 2;
            width: 26%;
            padding: 0 15px 0 25px;
            background-color: $shell-yellow;
            @include transition(all, .3s, ease-in-out);
            @media screen and (max-width: $point-md-max) {
                order: 1;
                width: 90%;
                background-color: transparent;
                margin-bottom: 20px;
            }
            &:hover {
                @include transform(scale(1.4));
                @media screen and (max-width: $point-lg-max) {
                    @include transform(scale(1));
                }
            }
            > img {
                width: 100%;
            }
        }
        &__model-descr {
            position: relative;
            order: 1;
            width: 60%;
            padding-right: 3.5%;
            @media screen and (max-width: $point-md-max) {
                order: 2;
                width: 100%;
                padding-right: 0;
            }
            .line {
                position: absolute;
                width: 1px;
                height: 5vw;
                top: 14px;
                right: 0;
                background-color: $shell-black;
                @media screen and (max-width: $point-md-max) {
                    height: 15vw;
                    top: auto;
                    bottom: 100%;
                    right: auto;
                    left: 9px;
                }
                &::before {
                    content: '';
                    display: block;
                    width: 38vw;
                    height: 1px;
                    position: absolute;
                    top: 0;
                    right: 100%;
                    background-color: $shell-black;
                    @media screen and (max-width: $point-md-max) {
                        content: none;
                    }
                }
                &::after {
                    content: '';
                    display: block;
                    width: 300px;
                    height: 1px;
                    position: absolute;
                    bottom: 0;
                    left: 100%;
                    background-color: $shell-black;
                    @media screen and (max-width: $point-lg-max) {
                        width: 30vw;
                    }
                    @media screen and (max-width: $point-md-max) {
                        width: 10vw;
                        bottom: auto;
                        top: 0;
                    }
                }
            }
            .model-title {
                position: relative;
                display: inline-block;
                padding-right: em(50,24);
                font-size: 24px;
                font-weight: 700;
                color: $shell-red;
                text-transform: uppercase;
                background-color: $shell-yellow;
                @media screen and (max-width: 1400px) {
                    font-size: 20px;
                }
                @media screen and (max-width: $point-lg-max) {
                    font-size: 18px;
                }
                @media screen and (max-width: $point-md-max) {
                    padding-left: em(40,24);
                }
                &::after {
                    content: '';
                    display: block;
                    width: 1em;
                    height: 1em;
                    position: absolute;
                    top: 0.125em;
                    right: 0;
                    background-color: $shell-black;
                    @media screen and (max-width: $point-md-max) {
                        right: auto;
                        left: 0;
                        top: 0;
                    }
                }
            }
            .model-descr {
                margin: 0;
                font-size: 18px;
                font-weight: 700;
                color: #373737;
                @media screen and (max-width: 1400px) {
                    font-size: 15px;
                }
                @media screen and (max-width: $point-lg-max) {
                    font-size: 14px;
                }
            }
        }
    }

    .refueling {
        margin-top: -60px;
        @media screen and (max-width: $point-md-max) {
            margin-top: 0;
        }
        &__pit-stop {
            position: relative;
            .img-wrap {
                > img {
                    &.deskt-tabl {
                        @media screen and (max-width: $point-md-max) {
                            display: none;
                        }
                    }
                    &.mob {
                        display: none;
                        @media screen and (max-width: $point-md-max) {
                            display: inline-block;
                        }
                    }
                }
            }
            &_text {
                position: absolute;
                width: auto;
                top: 13%;
                left: 6%;
                font-size: 3.75vw;
                font-weight: 700;
                animation: animPulseSmall 1.2s ease-in-out infinite;
                @media screen and (max-width: $point-md-max) {
                    font-size: 10vw;
                }
                p {
                    margin: 0;
                }
                .color-white {
                    text-transform: uppercase;
                }
                .color-yellow {
                    font-size: .5em;
                }
            }
            &_label {
                position: absolute;
                width: 17%;
                right: 7%;
                bottom: 17%;
                padding: 1%;
                background-color: $shell-yellow;
                @include border-radius(50%);
                -webkit-box-sizing: content-box;
                -moz-box-sizing: content-box;
                box-sizing: content-box;
                @media screen and (max-width: $point-md-max) {
                    width: 29%;
                    right: auto;
                    left: 20px;
                    bottom: 7%;
                    padding: 4px;
                }
            }
            &_label-h {
                position: relative;
                padding-top: calc(100% - 4px);
                border: 2px solid $shell-red;
                @include border-radius(50%);
                -webkit-box-sizing: content-box;
                -moz-box-sizing: content-box;
                box-sizing: content-box;
                p {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    @include transform(translate(-50%,-50%));
                    font-size: 1.5vw;
                    font-weight: 700;
                    line-height: 1.75;
                    color: $shell-red;
                    text-transform: uppercase;
                    text-align: center;
                    @media screen and (max-width: $point-md-max) {
                        font-size: 3.75vw;
                    }
                }
            }
        }
        &__yellow-bg {
            margin-top: -8vw;
            padding-left: 6%;
            padding-right: 6%;
            padding-top: 9vw;
            padding-bottom: 40px;
            background-color: $shell-yellow;
            @media screen and (max-width: $point-md-max) {
                margin-top: -20vw;
                padding-top: 23vw;
                padding-bottom: 30px;
            }
        }
        &__table {
            &_title {
                margin-bottom: 60px;
                font-size: 2.1vw;
                font-weight: 700;
                text-align: center;
                text-transform: uppercase;
                color: $ferrari-cars-grey;
                @media screen and (max-width: $point-md-max) {
                    font-size: 5.85vw;
                    margin-bottom: 14vw;
                }
            }
            &_red-block {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                margin-bottom: 85px;
                padding: 0 9%;
                background-color: $shell-red;
                font-size: 1.9vw;
                font-weight: 700;
                color: $white;
                text-transform: uppercase;
                @media screen and (max-width: $point-md-max) {
                    flex-wrap: wrap;
                    justify-content: center;
                    padding: 0 15px;
                    font-size: 4vw;
                    margin-bottom: 25px;
                }
                > img {
                    position: absolute;
                    width: 17%;
                    left: 50%;
                    bottom: 25%;
                    @include transform(translateX(-50%));
                    @media screen and (max-width: $point-md-max) {
                        width: 30%;
                        bottom: 100%;
                    }
                    &.deskt-tabl {
                        @media screen and (max-width: $point-md-max) {
                            display: none;
                        }
                    }
                    &.mob {
                        display: none;
                        @media screen and (max-width: $point-md-max) {
                            display: inline-block;
                        }
                    }
                }
                .left-text,
                .right-text {
                    display: inline-block;
                    margin: 0;
                    padding: 1em 0;
                }
                .left-text {
                    margin-right: 2vw;
                    @media screen and (max-width: $point-md-max) {
                        margin-right: 0.125em;
                    }
                }
            }
            &_descr {
                margin-bottom: 0.5em;
                font-size: 1.25vw;
                font-weight: 700;
                color: $ferrari-cars-grey-light;
                text-align: center;
                @media screen and (max-width: $point-md-max) {
                    font-size: 3.2vw;
                }
            }
            &_table-img {
                position: relative;
                margin-bottom: 15px;
                > img {
                    width: 100%;
                    &.deskt-tabl {
                        @media screen and (max-width: $point-md-max) {
                            display: none;
                        }
                    }
                    &.mob {
                        display: none;
                        @media screen and (max-width: $point-md-max) {
                            display: inline-block;
                        }
                    }
                }
                .label {
                    position: absolute;
                    width: 39.7%;
                    top: 11%;
                    right: -1.3%;
                    animation: animPulseSmall 1.2s ease-in-out infinite;
                    @media screen and (max-width: $point-md-max) {
                        width: 33%;
                        top: 11.5%;
                        right: -1%;
                    }
                    > img {
                        width: 100%;
                        &.deskt-tabl {
                            @media screen and (max-width: $point-md-max) {
                                display: none;
                            }
                        }
                        &.mob {
                            display: none;
                            @media screen and (max-width: $point-md-max) {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
            &_notes {
                font-size: 1vw;
                font-weight: 700;
                color: $ferrari-cars-grey-light;
                @media screen and (max-width: $point-md-max) {
                    font-size: 10px;
                }
            }
        }
    }

    .registration {
        padding-top: 80px;
        @media screen and (max-width: $point-md-max) {
            padding-top: 25px;
        }
        &__top-text {
            margin: 0;
            font-size: 1.9vw;
            font-weight: 700;
            color: $ferrari-cars-grey;
            text-align: center;
            @media screen and (max-width: $point-md-max) {
                font-size: 5.85vw;
                padding-left: 20px;
                padding-right: 20px;
            }
            &.color-red {
                margin-bottom: 1em;
                color: $shell-red;
            }
        }
        &__list-block {
            position: relative;
            padding-top: 8%;
            margin-top: 80px;
            @media screen and (max-width: $point-md-max) {
                margin-top: 25px;
            }
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 50%;
                position: absolute;
                z-index: 0;
                top: 0;
                left: 0;
                background-image: url("../../../img/promotions/ferrari-toy-cars/registration__top-bg.png");
                background-repeat: no-repeat;
                background-position: top left;
                background-size: 101% auto;
                @media screen and (max-width: $point-md-max) {
                    background-image: url("../../../img/promotions/ferrari-toy-cars/registration__top-bg_mob.png");
                }
            }
        }
        &__yellow-bg {
            background-color: $shell-yellow;
            padding-bottom: 90px;
            @media screen and (max-width: $point-md-max) {
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 20px;
            }
        }
        &__list {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 50px;
            @media screen and (max-width: $point-md-max) {
                flex-wrap: wrap;
                justify-content: center;
            }
            &_item {
                position: relative;
                z-index: 1;
                width: 20%;
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                    margin-bottom: 30px;
                }
            }
            &_icon {
                display: block;
                width: 41%;
                margin: 0 auto;
                padding: 5px;
                background-color: $shell-red;
                @include border-radius(50%);
                @media screen and (max-width: $point-md-max) {
                    width: 50%;
                    padding: 2px;
                }
            }
            &_icon-h {
                position: relative;
                display: block;
                padding-top: calc(100% - 4px);
                border: 2px solid $shell-yellow;
                @include border-radius(50%);
                > img {
                    position: absolute;
                    width: auto;
                    max-height: 55%;
                    top: 50%;
                    left: 50%;
                    @include transform(translate(-50%,-50%));
                }
            }
            &_descr {
                padding: 10% 30px 0 30px;
                font-size: 1.25vw;
                font-weight: 700;
                color: $ferrari-cars-grey;
                text-align: center;
                @media screen and (max-width: $point-md-max) {
                    font-size: 4.25vw;
                    padding: 10% 5px 0 5px;
                }
                a {
                    display: inline-block;
                    color: $shell-red;
                }
                span {
                    display: inline-block;
                }
            }
        }
    }

    .rules {
        margin-top: 20px;
    }

}

@keyframes animCircleLabel {
    0% {
        @include transform(translate(-15%,-40%) scale(1));
    }
    50% {
        @include transform(translate(-15%,-40%) scale(1.15));
    }
    100% {
        @include transform(translate(-15%,-40%) scale(1));
    }
}
@keyframes animCircleLabelMob {
    0% {
        @include transform(translate(-26%,-35%) scale(1));
    }
    50% {
        @include transform(translate(-26%,-35%) scale(1.15));
    }
    100% {
        @include transform(translate(-26%,-35%) scale(1));
    }
}

@keyframes animPulseSmall {
    0% {
        @include transform(scale(1));
    }
    50% {
        @include transform(scale(1.1));
    }
    100% {
        @include transform(scale(1));
    }
}

@keyframes animRemoteControl {
    0% {
        @include transform(scale(1));
    }
    50% {
        @include transform(scale(1.1));
    }
    100% {
        @include transform(scale(1));
    }
}

@keyframes animRotate {
    0% {
        @include transform(translate(-50%,-50%) rotate(0deg));
    }
    50% {
        @include transform(translate(-50%,-50%) rotate(10deg));
    }
    100% {
        @include transform(translate(-50%,-50%) rotate(0deg));
    }
}