/* =================================
Titles Styles
================================= */

.section-title {
    margin-bottom: em(65);

    @media screen and (max-width: $point-xl-max) {
        font-size: em(14);
    }
    @media screen and (max-width: $point-lg-max) {
        font-size: em(10);
    }

    &.from-icon {
        position: relative;
        padding-left: em(95);
        //display: flex;
        //align-items: flex-end;
        .title-text {
            display: flex;
            align-items: flex-end;
            min-height: em(75,36);
        }
    }

    &.border-b {
        padding-bottom: em(30);
        border-bottom: 1px solid $shell-pale-gray;
    }

    &.color-red {
        .title-text {
            color: $shell-red;
        }
    }

    .img-wrap {
        position: absolute;
        width: em(75);
        left: 0;
        bottom: em(20);
        //margin-right: 1.5em;
        @media screen and (max-width: $point-md-max) {
            top: calc(50% - 1.5em);
            @include transform(translateY(-50%));
        }
        > img {
            width: 100%;
            height: auto;
        }
    }

    .title-text {
        margin-bottom: -.2em;
        font-size: em(36);
        font-weight: 700;
        color: $shell-very-dark-gray;
        @media screen and (max-width: $point-md-max) {
            align-items: center;
        }
        &.fs-48 {
            font-size: em(48);
        }
        > a {
            color: inherit;
            &:hover {
                color: $shell-red;
            }
        }
    }

}