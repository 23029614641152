/* =================================
Section Actions List
================================= */

section.actions {
    padding-bottom: 1em;
}

.actions {

    &.title-only {
        .actions__item .title-wrap .title-text {
            padding-bottom: 0;
        }
    }

    &__list {
        > .row > div {
            margin-bottom: em(30);
        }
        .btns-wrap {
            position: relative;
            padding-top: 3em;
            padding-bottom: 1em;
            text-align: center;
            .btn__round {
                position: absolute;
                top: 50%;
                right: 0;
                margin-top: 1em;
                @include transform(translateY(-50%));
                .icon {
                    @include transform(rotate(180deg));
                }
            }
        }
    }

    &__item {
        position: relative;
        height: 100%;
        background-color: $shell-yellow;
        overflow: hidden;
        @include transition(all, .2s, ease-in-out);

        &:hover {
            .title-wrap {
                .title-text {
                    text-decoration: underline;
                }
            }
        }

        a {
            display: inline-block;
            width: 100%;
        }

        &--img {
            position: relative;
            width: 100%;
            padding-top: 65%;
            .img-wrap {
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .title-wrap {
            width: 100%;
            padding: 1em 7%;
            .title-text {
                position: relative;
                z-index: 2;
                //font-size: em(30);
                font-size: 1.5vw;
                font-weight: 700;
                color: $shell-red;
                @include transition(all, .2s, ease-in-out);
                margin-bottom: 0;
                padding-bottom: 1.75em;
                //@media screen and (max-width: 1500px) {
                //    font-size: em(26);
                //}
                @media screen and (max-width: $point-lg-max) {
                    font-size: em(20);
                    padding-bottom: .5em;
                }
                @media screen and (min-width: 1920px) {
                    font-size: 28.8px;
                }
            }
            .text-bottom {
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                margin-bottom: 0;
                margin-top: .6em;
                padding: 0 7%;
                padding-bottom: 1em;
                //font-size: em(22);
                font-size: 1.2vw;
                color: $shell-red;
                @include transition(all, .2s, ease-in-out);
                @media screen and (max-width: $point-lg-max) {
                    position: static;
                    padding: 0;
                    font-size: em(16);
                }
                @media screen and (min-width: 1920px) {
                    font-size: 23px;
                }
            }
        }
    }

    &__old {
        .actions__list {
            .btns-wrap {
                padding-bottom: 0;
                .btn {
                    margin-bottom: 0;
                }
            }
        }
        .actions__item {
            background-color: $shell-pale-gray;
            &:hover {
                background-color: $shell-yellow;
                .actions__item--img {
                    &::after {
                        opacity: 0;
                    }
                }
                .title-wrap {
                    .title-text {
                        color: $shell-red;
                    }
                    .text-bottom {
                        color: $shell-red;
                    }
                }
            }
            .actions__item--img {
                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    background-color: rgb(255,255,255);
                    opacity: .6;
                    @include transition(all, .2s, ease-in-out);
                }
            }
            .title-wrap {
                .title-text {
                    color: $shell-dark-gray;
                }
                .text-bottom {
                    color: $shell-dark-gray;
                }
            }
        }
    }

}